import * as tslib_1 from "tslib";
import { FirebaseSyncService } from '../services/firebase-sync.service';
import { JobDataService } from './job-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { PostcodeManagerService } from '../services/postcode-manager.service';
import { PricingService } from './pricing.service';
import { environment } from '../../environments/environment';
import { Clients, TradeTypes } from './globals.service';
import * as i0 from "@angular/core";
import * as i1 from "./firebase-sync.service";
import * as i2 from "./job-data.service";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "./postcode-manager.service";
import * as i5 from "./pricing.service";
import * as i6 from "./globals.service";
export class MetaDataService {
    constructor(firebaseSync, jobDataService, db, postcodeManager, pricingService, clients, eTradeTypes) {
        this.firebaseSync = firebaseSync;
        this.jobDataService = jobDataService;
        this.db = db;
        this.postcodeManager = postcodeManager;
        this.pricingService = pricingService;
        this.clients = clients;
        this.eTradeTypes = eTradeTypes;
        /*
          Question types
          1: free text
          2: booking windows
          3: contact details
          4: confirmation
          5: quantity
          6: 2 options
          7: select tap model
          8: numeric
          9: yes/no
          10: simple 2 options
          11: handyman duration
          12: Job notes & photos
          13: carpentry duration
        */
        this.metaDataLoaded = false;
        this.currentPage = '';
        this.contactEmail = '';
        this.contactPhoneNumber = '';
        this.contactEmergencyNumber = '';
        this.contactPhoneHours = '';
        this.faqData = [];
        this.whereDidYouHearAboutUsData = [];
        this.priceEstimatePopupPlumbing = 'The price provided is an estimated cost based on the information you have submitted. If the works differ upon inspection by our Tradie this estimate will be altered accordingly.<br /><br />Furthermore, any alterations to existing pipework may result in additional costs.<br /><br />Your Tradie will discuss this in further detail with you when on-site.';
        this.priceEstimatePopupElectrical = 'The price provided is an estimated cost based on the information you have submitted. If the works differ upon inspection by our Tradie this estimate will be altered accordingly.<br /><br />Your Tradie will discuss this in further detail with you when on-site.';
        this.tradeTypes = [];
        this.tapsJobTypes = [];
        this.standardQuestions = [];
        this.currentTriageQuestion = null;
        this.postcodeCheck = false;
        this.homeInspectionID = 5;
        this.postcodeManager.setupPostcodes();
    }
    loadMetaData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var service = this;
            var triageData = this.db.doc('/metadata/triage').ref;
            var siteData = this.db.doc('/metadata/site').ref;
            yield triageData.get().then(function (result) {
                var data = result.data();
                service.tradeTypes = JSON.parse(data.tradeTypes);
                service.tapsJobTypes = JSON.parse(data.tapsJobTypes);
                service.standardQuestions = JSON.parse(data.standardQuestions);
            }).catch(function (error) {
                console.log('ERROR: ' + error);
            });
            yield siteData.get().then(function (result) {
                var data = result.data();
                service.contactEmail = data.email;
                service.contactPhoneHours = data.phoneHours;
                service.contactPhoneNumber = data.phoneNumber;
                service.contactEmergencyNumber = data.emergencyNumber;
                let wdyhau = data.whereDidYouHearAboutUs;
                let faq = data.faq;
                if (wdyhau != null && wdyhau.length > 0) {
                    service.whereDidYouHearAboutUsData = JSON.parse(wdyhau);
                }
                var cancelFee = 65;
                if (environment.clientID == service.clients.AANZ) {
                    cancelFee = 35;
                }
                if (faq != null && faq.length > 0) {
                    service.faqData = JSON.parse(faq);
                    // replace the key with the value in faq titles and descriptions
                    let faqReplace = {
                        "{{productName}}": environment.productName,
                        "{{contactEmail}}": service.contactEmail,
                        "{{contactPhoneNumber}}": service.contactPhoneNumber,
                        "{{contactPhoneHours}}": service.contactPhoneHours,
                        "{{cancelFee}}": cancelFee.toString(),
                    };
                    service.faqData.forEach(f => {
                        for (let key in faqReplace) {
                            let value = faqReplace[key];
                            f.title = f.title.split(key).join(value);
                            f.description = f.description.split(key).join(value);
                        }
                    });
                }
            }).catch(function (error) {
                console.log('ERROR: ' + error);
            });
            this.pricingService.setupPricingAreas();
            this.metaDataLoaded = true;
        });
    }
    setCurrentPricingArea() {
        this.pricingService.pricingAreas.forEach(area => {
            if (area.coveredSuburbs.indexOf(this.jobDataService.postcode) > -1) {
                this.pricingService.currentPricingArea = area;
            }
        });
    }
    checkPostcode(postcode) {
        this.postcodeCheck = false;
        if (this.jobDataService.trade.id == this.eTradeTypes.StormReadiness) {
            this.postcodeManager.SRPostcodes.forEach(element => {
                if (element == postcode) {
                    this.postcodeCheck = true;
                }
            });
        }
        else {
            this.postcodeManager.coveredPostcodes.forEach(element => {
                if (element == postcode) {
                    this.postcodeCheck = true;
                }
            });
        }
        return this.postcodeCheck;
    }
    pushRACDataLayerPageView(page, title) {
        if (environment.clientID != this.clients.RACWA) {
            return;
        }
        window.dataLayer.push({
            event: 'virtualPageView',
            page: {
                url: page,
                title: title
            }
        });
    }
    convertURLName(name) {
        var newName = name.split(' ').join('-');
        newName = newName.split('_').join('-');
        return newName.toLowerCase();
    }
}
MetaDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaDataService_Factory() { return new MetaDataService(i0.ɵɵinject(i1.FirebaseSyncService), i0.ɵɵinject(i2.JobDataService), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.PostcodeManagerService), i0.ɵɵinject(i5.PricingService), i0.ɵɵinject(i6.Clients), i0.ɵɵinject(i6.TradeTypes)); }, token: MetaDataService, providedIn: "root" });
export class FAQItem {
    constructor(_title, _description) {
        this.title = '';
        this.description = '';
        this.show = false;
        this.title = _title;
        this.description = _description;
    }
}
export class Suburb {
    constructor() {
        this.postcode = 0;
        this.name = '';
    }
}
export class TradeType {
    constructor() {
        this.id = 0;
        this.urlID = '';
        this.name = '';
        this.icon = '';
        this.overrideBlackoutDays = -1;
        this.link = '';
        this.endPopup = '';
        this.hourlyRate = 0;
        this.calloutFee = 0;
        this.nextDayBooking = false;
        this.jobTypes = [];
        this.bookingType = 1; // 1 = scheduled, 2 = HIP, 3 = EHA
        // out of hours
        this.oohHourlyRate = null;
        this.oohCalloutFee = null;
    }
}
export class JobType {
    constructor() {
        this.id = 0;
        this.name = '';
        this.icon = '';
        this.route = '';
        this.partsIconBase = '';
        this.partsIconPremium = '';
        this.partsCostBase = 0;
        this.partsCostPremium = 0;
        this.partsNameBase = 'Standard';
        this.partsNamePremium = 'Premium';
        this.partsInfoBase = '';
        this.partsInfoPremium = '';
        this.productCode = '';
        this.attendanceHours = 1;
        this.emergency = false;
        this.questions = [];
        this.inclusions = '';
        this.calloutFee = 0;
        this.additionalPartsFree = false;
        // out of hours
        this.oohCalloutFee = null;
        this.oohAttendanceHours = null;
        this.showGeneralNotes = true;
        // used by booking platform only
        this.jobTypeIndex = -1;
        this.currentBasePartsCost = 0;
        this.currentPremiumPartsCost = 0;
        this.estimatedCurrentPrice = 0;
        this.estimatedFullPrice = 0;
        this.estimatedDuration = 0;
        this.currentQuantity = 0;
        this.firstQuestionIndex = -1;
        this.standardModel = false;
        this.currentAttendanceHours = 0;
        this.currentHourlyMultiplier = 0;
    }
}
export class TriageQuestion {
    constructor() {
        this.id = 0;
        this.icon = '';
        this.description = '';
        this.jobTypeID = 0;
        this.questionTypeID = 0;
        this.listOrder = 0;
        this.nextPopup = '';
        this.options = [];
        this.data = '';
        this.progressStep = 1;
        this.required = true;
        this.outgoingField = true;
        this.additionalFixedCost = 0;
        this.additionalFixedCostAnswer = '';
        this.additionalFixedCostAnswerMatrix = [];
        this.additionalPerCost = 0;
        this.additionalPerCostAnswer = '';
        this.modifiedBasePartsCost = 0;
        this.modifiedBasePartsAnswer = '';
        this.modifiedPremiumPartsCost = 0;
        this.modifiedPremiumPartsAnswer = '';
        this.modifiedAttendanceHours = 0;
        this.modifiedAttendanceHoursAnswer = '';
        this.modifiedHourlyMultiplier = 0;
        this.modifiedHourlyMultiplierAnswer = '';
        this.quantity = false;
        this.startPricing = false;
        this.hourlyMultiplier = 0;
        this.partsMultiplier = 0;
        this.partsAdd = 0;
        this.defaultAnswer = '';
        this.showStandardButtons = true;
        this.showConfirmButtons = false;
    }
}
export class TriageQuestionOption {
    constructor() {
        this.id = 0;
        this.name = '';
        this.icon = '';
        this.data = [];
        this.fullAnswer = '';
    }
}
export class TriageQuestionData {
    constructor() {
        this.question = null;
        this.answer = '';
        this.fullAnswer = '';
        this.show = false;
        this.showPopup = false;
        this.popupShown = false;
        this.index = 0;
        this.valid = false;
        // used by booking platform only
        this.jobTypeIndex = -1;
    }
}
export class AdditionalFixedPriceMatrixItem {
    constructor() {
        this.answer = '';
        this.price = 0;
    }
}
