<my-content placeholder="main">
    <div class="completeContainer" *ngIf="success == true">
        <div class="stepTitle">PAYMENT RECEIVED</div>
        <div class="jobReferenceTitle">Your job reference number is</div>
        <div class="jobReference">{{ booking }}</div>
        <!-- <div class="completeInfo">If this is not correct please contact us using the reference number above.</div>
        <div class="completeInfo" style="margin-top: 0px;">If this is not correct please contact us using the reference number above.</div> -->

        <div class="contactNumberContainer">
            <div class="contactNumberInfo">If you have any further questions please contact</div>
            <div class="contactNumber">{{ metaData.contactPhoneNumber }}</div>
        </div>

        <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 30px;" [routerLink]="['/home']">Back to Home</div>
    </div>

    <div class="completeContainer" *ngIf="success == false">
      <div class="stepTitle">PAYMENT CANCELLED</div>
      <div class="jobReferenceTitle">Your reference number is</div>
      <div class="jobReference">{{ booking }}</div>
      <div class="completeInfo">It looks like you didn't complete the payment.</div>
      <div class="completeInfo" style="margin-top: 0px;">If this is not correct please contact us using the reference number above.</div>

      <div class="contactNumberContainer">
        <div class="contactNumberInfo">If you have any further questions please contact</div>
        <div class="contactNumber">{{ metaData.contactPhoneNumber }}</div>
      </div>

      <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 30px;" [routerLink]="['/home']">Back to Home</div>
    </div>
  </my-content>