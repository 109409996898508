import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MetaDataService } from '../services/meta-data.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { Clients } from '../services/globals.service';
import { APIService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
export class CardpaymentComponent {
    //public component: CardpaymentComponent = this;
    constructor(db, toastr, route, metaData, clients, apiService) {
        this.db = db;
        this.toastr = toastr;
        this.route = route;
        this.metaData = metaData;
        this.clients = clients;
        this.apiService = apiService;
        this.faSpinner = faSpinner;
        this.clientID = 0;
        this.sessionID = '0';
        this.booking = 0;
        this.clientID = environment.clientID;
    }
    ngOnInit() {
        this.route.queryParams // get querystring params
            .subscribe(params => {
            this.sessionID = params.session;
            this.booking = params.booking;
            var component = this;
            if (this.clientID == this.clients.AANZ) {
                var dtobj = {};
                dtobj.sessionID = component.sessionID;
                component.apiService.callAPI("rpcapi/HomeServices/GetWindcavePaymentURL", dtobj, (reply) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (reply.status == true) {
                        window.location.href = reply.windcavePaymentURL;
                    }
                    else {
                        component.toastr.error('Redirecting to payment failed, please try again.');
                        console.log('ERROR: ' + reply.windcavePaymentURL);
                    }
                }), (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    // handle error
                    component.toastr.error('Redirecting to payment failed, please try again.');
                    console.log('ERROR: ' + error);
                }));
            }
            else {
                var stripe = window.Stripe(environment.stripeAPIKey);
                stripe.redirectToCheckout({
                    sessionId: component.sessionID,
                }).then(function (result) {
                    component.toastr.error('Booking error: ' + result.error.message);
                });
            }
        });
    }
}
