import { Component, OnInit } from '@angular/core';
import { MetaDataService } from '../services/meta-data.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';

import { environment } from '../../environments/environment';

@Component({
  selector: 'HomeServices-cardpayment-complete',
  templateUrl: './cardpayment-complete.component.html',
  styleUrls: ['./cardpayment-complete.component.css']
})
export class CardpaymentCompleteComponent implements OnInit {

  public booking: string = '';
  public success: boolean = false;

  public clientID: number = 0;
  public capability: any = {};

  constructor(
    public db: AngularFirestore,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public metaData: MetaDataService
  ) {
    this.clientID = environment.clientID;
    this.capability = environment.capability;

    this.metaData.pushRACDataLayerPageView('/cardpaymentcomplete', 'cardpaymentcomplete');
  }

  ngOnInit() {
    this.route.queryParams // get querystring params
    .subscribe(params => {
      this.booking = params.booking;
      this.success = (params.success == 'true');
    });
  }

}
