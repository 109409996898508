import { Routes } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MasterComponent } from './master/master.component';
/* Views */
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { FaqComponent } from './faq/faq.component';
import { ContactusComponent } from './contactus/contactus.component';
import { TriageComponent } from './triage/triage.component';
import { CompleteComponent } from './complete/complete.component';
import { StartComponent } from './start/start.component';
import { TapsComponent } from './taps/taps.component';
import { CompleteinspectionComponent } from './completeinspection/completeinspection.component';
import { EmergencyComponent } from './emergency/emergency.component';
import { EmergencyCompleteComponent } from './emergency-complete/emergency-complete.component';
import { CardpaymentComponent } from './cardpayment/cardpayment.component';
import { CardpaymentCompleteComponent } from './cardpayment-complete/cardpayment-complete.component';
import { environment } from '../environments/environment';
const appRoutes = [
    {
        path: 'home',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: HomeComponent
            }
        ]
    },
    {
        path: 'faq',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: FaqComponent
            }
        ]
    },
    {
        path: 'contactus',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: ContactusComponent
            }
        ]
    },
    {
        path: 'privacy',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: PrivacyComponent
            }
        ]
    },
    {
        path: 'terms',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: TermsComponent
            }
        ]
    },
    {
        path: 'emergency',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: EmergencyComponent
            }
        ]
    },
    {
        path: 'triage',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: TriageComponent
            }
        ]
    },
    {
        path: 'complete',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: CompleteComponent
            }
        ]
    },
    {
        path: 'emergencycomplete',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: EmergencyCompleteComponent
            }
        ]
    },
    {
        path: 'completeinspection',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: CompleteinspectionComponent
            }
        ]
    },
    {
        path: 'start',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: StartComponent
            }
        ]
    },
    {
        path: 'taps',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: TapsComponent
            }
        ]
    },
    {
        path: 'cardpayment',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: CardpaymentComponent
            }
        ]
    },
    {
        path: 'cardpaymentcomplete',
        component: MasterComponent,
        children: [
            {
                outlet: 'master',
                path: '',
                component: CardpaymentCompleteComponent
            }
        ]
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];
const ɵ0 = environment.firebase.storageBucket;
export class AppModule {
}
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0 };
