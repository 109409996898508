import { Component, OnInit } from '@angular/core';
import { MetaDataService, JobType } from '../services/meta-data.service';
import { Router } from '@angular/router';
import { JobDataService } from '../services/job-data.service';
import { ToastrService } from 'ngx-toastr';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { Clients } from '../services/globals.service';

@Component({
  selector: 'HomeServices-taps',
  templateUrl: './taps.component.html',
  styleUrls: ['./taps.component.css']
})
export class TapsComponent implements OnInit {

  public toastr: ToastrService = null;
  public router: Router = null;
  public metaData: MetaDataService = null;
  public jobDataService: JobDataService = null;

  public stepValid: boolean = false;
  public clientID: number = 0;

  public clients: Clients = null;

  public selectedJobType: JobType = null;

  constructor(_metaDataService: MetaDataService, _router: Router, _jobDataService: JobDataService, _toastr: ToastrService, _clients: Clients) {
    this.router = _router;
    this.toastr = _toastr;

    this.metaData = _metaDataService;
    this.jobDataService = _jobDataService;

    this.clientID = environment.clientID;
    this.clients = _clients;
  }

  ngOnInit() {
  }

  public tapsTypeClick(typeID: number) {
    this.stepValid = false;
    
    this.metaData.tapsJobTypes.forEach(element => {
      if (element.id == typeID) {
        this.selectedJobType = element;
        this.stepValid = true;
      }
    });

    //this.router.navigate(['/' + this.jobDataService.jobType.route]);
  }

  public backClick() {
    this.router.navigate(['/start'], { queryParams: { trade: this.jobDataService.trade.urlID, postcode: this.jobDataService.postcode } });
  }

  public nextClick() {
    if (this.selectedJobType == null) {
      this.toastr.error('Please select where your tap is located.', 'Error');
      return;
    }
    
    this.jobDataService.jobTypes.push(this.selectedJobType);
    this.router.navigate(['/' + this.selectedJobType.route]);
  }

}
