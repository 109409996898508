import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  public apiURL: string = environment.webserviceURL;

  constructor(private http: HttpClient) { }

  public callAPI(methodPath: string, params: any, callback: (reply: any) => void, errorCallback: (error: any) => void = null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    this.http.post<any>(this.apiURL + methodPath, params, httpOptions).pipe(
      map(reply => {
        return reply;
      }),
      catchError(error => {
        console.log('API Error: ' + error.message);
        throw error.message;
        //return this.handleError(error);
      })
    ).subscribe(reply => {
      callback(reply);
    },
    error => {
      if (errorCallback != null) {
        errorCallback(error);
      }
    });
  }
  private handleError(error: HttpErrorResponse) {
    return throwError('An unexpected error has occurred');
  }
}
