import { Injectable } from '@angular/core';

import { FirebaseSyncService } from '../services/firebase-sync.service';
import { JobDataService } from './job-data.service';
import { post } from 'selenium-webdriver/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { PostcodeManagerService } from '../services/postcode-manager.service';
import { PricingService } from './pricing.service';
import { environment } from '../../environments/environment';
import { Clients, TradeTypes } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  /*
    Question types
    1: free text
    2: booking windows
    3: contact details
    4: confirmation
    5: quantity
    6: 2 options
    7: select tap model
    8: numeric
    9: yes/no
    10: simple 2 options
    11: handyman duration
    12: Job notes & photos
    13: carpentry duration
  */

  public metaDataLoaded: boolean = false;

  public currentPage: string = '';

  public contactEmail: string = '';
  public contactPhoneNumber: string = '';
  public contactEmergencyNumber: string = '';
  public contactPhoneHours: string = '';
  public faqData: Array<FAQItem> = [];
  public whereDidYouHearAboutUsData: Array<string> = [];

  public priceEstimatePopupPlumbing: string = 'The price provided is an estimated cost based on the information you have submitted. If the works differ upon inspection by our Tradie this estimate will be altered accordingly.<br /><br />Furthermore, any alterations to existing pipework may result in additional costs.<br /><br />Your Tradie will discuss this in further detail with you when on-site.'
  public priceEstimatePopupElectrical: string = 'The price provided is an estimated cost based on the information you have submitted. If the works differ upon inspection by our Tradie this estimate will be altered accordingly.<br /><br />Your Tradie will discuss this in further detail with you when on-site.';

  public tradeTypes: Array<TradeType> = [];
  public tapsJobTypes: Array<JobType> = [];

  public standardQuestions: Array<TriageQuestion> = [];
  public currentTriageQuestion: TriageQuestionData = null;

  public postcodeCheck: boolean = false;

  public homeInspectionID: number = 5;

  constructor(
    public firebaseSync: FirebaseSyncService,
    public jobDataService: JobDataService,
    public db: AngularFirestore,
    public postcodeManager: PostcodeManagerService,
    public pricingService: PricingService,
    public clients: Clients,
    public eTradeTypes: TradeTypes
    ) {
    this.postcodeManager.setupPostcodes();
  }

  public async loadMetaData() {
    var service = this;
    var triageData = this.db.doc('/metadata/triage').ref;
    var siteData = this.db.doc('/metadata/site').ref;
    
    await triageData.get().then(function (result) {
      var data = result.data();
      service.tradeTypes = JSON.parse((<any>data).tradeTypes);
      service.tapsJobTypes = JSON.parse((<any>data).tapsJobTypes);
      service.standardQuestions = JSON.parse((<any>data).standardQuestions);
    }).catch(function (error) {
      console.log('ERROR: ' + error);
    });

    await siteData.get().then(function (result) {
      var data = result.data();
      service.contactEmail = (<any>data).email;
      service.contactPhoneHours = (<any>data).phoneHours;
      service.contactPhoneNumber = (<any>data).phoneNumber;
      service.contactEmergencyNumber = (<any>data).emergencyNumber;

      let wdyhau: string = (<any>data).whereDidYouHearAboutUs;
      let faq: string = (<any>data).faq;

      if (wdyhau != null && wdyhau.length > 0) {
        service.whereDidYouHearAboutUsData = JSON.parse(wdyhau);
      }
      
      var cancelFee = 65;
      if (environment.clientID == service.clients.AANZ) {
        cancelFee = 35;
      }
      
      if (faq != null && faq.length > 0) {
        service.faqData = JSON.parse(faq);
        
        // replace the key with the value in faq titles and descriptions
        let faqReplace: {[index: string]: string} = {
          "{{productName}}": environment.productName,
          "{{contactEmail}}": service.contactEmail,
          "{{contactPhoneNumber}}": service.contactPhoneNumber,
          "{{contactPhoneHours}}": service.contactPhoneHours,
          "{{cancelFee}}": cancelFee.toString(),
        };
        
        service.faqData.forEach(f => {
          for (let key in faqReplace) {
            let value = faqReplace[key];
            f.title = f.title.split(key).join(value);
            f.description = f.description.split(key).join(value);
          }
        });
      }

    }).catch(function (error) {
      console.log('ERROR: ' + error);
    });

    this.pricingService.setupPricingAreas();
    this.metaDataLoaded = true;
  }

  public setCurrentPricingArea() {
    this.pricingService.pricingAreas.forEach(area => {
      if (area.coveredSuburbs.indexOf(this.jobDataService.postcode) > -1) {
        this.pricingService.currentPricingArea = area;
      }
    });
  }

  public checkPostcode(postcode: string): boolean {
    this.postcodeCheck = false;

    if (this.jobDataService.trade.id == this.eTradeTypes.StormReadiness) {
      this.postcodeManager.SRPostcodes.forEach(element => {
        if (element == postcode) {
          this.postcodeCheck = true;
        }
      });
    } else {
      this.postcodeManager.coveredPostcodes.forEach(element => {
        if (element == postcode) {
          this.postcodeCheck = true;
        }
      });
    }

    return this.postcodeCheck;
  }

  public pushRACDataLayerPageView(page: string, title: string) {
    if (environment.clientID != this.clients.RACWA) {
      return;
    }

    (<any>window).dataLayer.push({
      event: 'virtualPageView',
      page: {
        url: page,
        title: title
      }
    });
  }

  public convertURLName(name: string) {
    var newName = name.split(' ').join('-');
    newName = newName.split('_').join('-');

    return newName.toLowerCase();
  }

}

export class FAQItem {
  public title: string = '';
  public description: string = '';
  public show: boolean = false;

  constructor(_title: string, _description: string) {
    this.title = _title;
    this.description = _description;
  }
}

export class Suburb {
  public postcode: number = 0;
  public name: string = '';
}

export class TradeType {
  public id: number = 0;
  public urlID: string = '';
  public name: string = '';
  public icon: string = '';
  public overrideBlackoutDays: number = -1;
  public link: string = '';
  public endPopup: string = '';
  public hourlyRate: number = 0;
  public calloutFee: number = 0;
  public nextDayBooking: boolean = false;
  public jobTypes: Array<JobType> = [];
  public bookingType: number = 1; // 1 = scheduled, 2 = HIP, 3 = EHA

  // out of hours
  public oohHourlyRate: number = null;
  public oohCalloutFee: number = null;
}

export class JobType {
  public id: number = 0;
  public name: string = '';
  public icon: string = '';
  public route: string = '';
  public partsIconBase: string = '';
  public partsIconPremium: string = '';
  public partsCostBase: number = 0;
  public partsCostPremium: number = 0;
  public partsNameBase: string = 'Standard';
  public partsNamePremium: string = 'Premium';
  public partsInfoBase: string = '';
  public partsInfoPremium: string = '';
  public productCode: string = '';
  public attendanceHours: number = 1;
  public emergency: boolean = false;
  public questions: Array<TriageQuestion> = [];
  public inclusions: string = '';

  public calloutFee: number = 0;
  public additionalPartsFree: boolean = false;

  // out of hours
  public oohCalloutFee: number = null;
  public oohAttendanceHours: number = null;

  public showGeneralNotes: boolean = true;

  // used by booking platform only
  public jobTypeIndex: number = -1;
  public currentBasePartsCost: number = 0;
  public currentPremiumPartsCost: number = 0;
  public estimatedCurrentPrice: number = 0;
  public estimatedFullPrice: number = 0;
  public estimatedDuration: number = 0;
  public currentQuantity: number = 0;
  public firstQuestionIndex: number = -1;
  public standardModel: boolean = false;
  public currentAttendanceHours: number = 0;
  public currentHourlyMultiplier: number = 0;
}

export class TriageQuestion {
  public id: number = 0;
  public icon: string = '';
  public description: string = '';
  public jobTypeID: number = 0;
  public questionTypeID: number = 0;
  public listOrder: number = 0;
  public nextPopup: string = '';
  public options: Array<TriageQuestionOption> = [];
  public data: string = '';
  public progressStep: number = 1;
  public required: boolean = true;
  public outgoingField: boolean = true;

  public additionalFixedCost: number = 0;
  public additionalFixedCostAnswer: string = '';
  public additionalFixedCostAnswerMatrix: Array<AdditionalFixedPriceMatrixItem> = [];
  public additionalPerCost: number = 0;
  public additionalPerCostAnswer: string = '';

  public modifiedBasePartsCost: number = 0;
  public modifiedBasePartsAnswer: string = '';
  public modifiedPremiumPartsCost: number = 0;
  public modifiedPremiumPartsAnswer: string = '';

  public modifiedAttendanceHours: number = 0;
  public modifiedAttendanceHoursAnswer: string = '';
  public modifiedHourlyMultiplier: number = 0;
  public modifiedHourlyMultiplierAnswer: string = '';

  public quantity: boolean = false;
  public startPricing: boolean = false;
  public hourlyMultiplier: number = 0;
  public partsMultiplier: number = 0;
  public partsAdd: number = 0;

  public defaultAnswer: string = '';

  public showStandardButtons: boolean = true;
  public showConfirmButtons: boolean = false;
}

export class TriageQuestionOption {
  public id: number = 0;
  public name: string = '';
  public icon: string = '';
  public data: Array<string> = [];
  public fullAnswer: string = '';
}

export class TriageQuestionData {
  public question: TriageQuestion = null;
  public answer: string = '';
  public fullAnswer: string = '';
  public show: boolean = false;
  public showPopup: boolean = false;
  public popupShown: boolean = false;
  public index: number = 0;
  public valid: boolean = false;

  // used by booking platform only
  public jobTypeIndex: number = -1;
}

export class AdditionalFixedPriceMatrixItem {
  public answer: string = '';
  public price: number = 0;
}
