<my-content placeholder="headerContent">
  <div class="emergencyTitle" *ngIf="clientID != clients.RACWA">EMERGENCY SERVICES</div>
  <div class="emergencyTitle" *ngIf="clientID == clients.RACWA">EMERGENCY REPAIRS</div>
  
  <div class="emergencySubTitle" *ngIf="clientID == clients.RACWA">
    We currently offer emergency repairs for electrical, locksmith and plumbing.
  </div>
  
  <div class="emergencyServicesContainer">
    <div class="emergencyService">
      <div class="serviceIcon">
        <img src="assets/img/{{ clientID }}/emergency/timer-w.png" />
      </div>
      <div class="serviceName">1 HOUR RESPONSE</div>
    </div>
    <div class="emergencyService" *ngIf="emergencyTriageEnabled && currentPrice > 0">
      <div class="serviceIcon">
        <img src="assets/img/{{ clientID }}/emergency/money-w.png" />
      </div>
      <div class="serviceName">${{ currentPrice.toFixed(2) }}</div>
    </div>
    <div class="emergencyService" *ngIf="emergencyTriageEnabled == false">
      <div class="serviceIcon">
        <img src="assets/img/{{ clientID }}/emergency/money-w.png" />
      </div>
      <div *ngIf="memberPricing == false">
        <div class="serviceName">${{ (afterHours == false ? tradeTypes[0].price.toFixed(2) : tradeTypes[0].priceAfterHours.toFixed(2)) }}</div>
      </div>
      <div *ngIf="memberPricing == true">
        <div class="serviceName"style="text-decoration: line-through;">${{ (afterHours == false ? tradeTypes[0].price.toFixed(2) : tradeTypes[0].priceAfterHours.toFixed(2)) }}</div>
        <div class="serviceName">${{ (afterHours == false ? (tradeTypes[0].price * 0.9).toFixed(2) : (tradeTypes[0].priceAfterHours * 0.9).toFixed(2)) }}</div>
      </div>
    </div>
    <div class="emergencyService">
      <div class="serviceIcon">
        <img src="assets/img/{{ clientID }}/emergency/24hour-w.png" />
      </div>
      <div class="serviceName">24/7/365</div>
    </div>
  </div>
</my-content>

<my-content placeholder="main">
  <div class="stepContainer" *ngIf="showCommonPropertyCheck">
    <div style="text-align: center;">
      <div class="stepTitleRaw">IS YOUR JOB ON COMMON PROPERTY?</div>
      <div class="commonPropertyLink"><a href="assets/img/3013/commonproperty.jpg" target="_blank"><span class="commonPropertyClick">Click here</span> for information on common property.</a></div>
      <div class="yesNoButtonContainer">
        <div class="optionSelect standardButton buttonPointer yesNoButton"
        (click)="setCommonPropertyAnswer(true)">YES</div>
        <div class="optionSelect standardButton buttonPointer yesNoButton"
        (click)="setCommonPropertyAnswer(false)">NO</div>
      </div>
    </div>
  </div>

  <div class="stepContainer" *ngIf="showPostcodeCheck">
    <div class="postcodeStep">
      <div class="stepTitle">WHERE IS YOUR JOB?</div>
      <div class="stepDescription">{{ coverageAreaText }}</div>
      <input style="margin-top: 160px;" type="tel" placeholder="Enter your postcode" [(ngModel)]="postcode" class="postcodeText" />
      <button type="button" (click)="checkPostcode()" class="wizardButton searchNowButton">CHECK</button>
    </div>
  </div>
  
  <div *ngIf="showAreaNotCovered" class="stepContainer">
    <div *ngIf="!emailSubmitted">
      <div class="stepTitle">SORRY, THIS SERVICE IS NOT CURRENTLY AVAILABLE IN YOUR AREA</div>
      <div class="stepDescription">Please call us to discuss your booking request.</div>
      <div class="stepTitle notAvailablePhone"><a href="tel:{{ metaData.contactPhoneNumber }}">{{ metaData.contactPhoneNumber }}</a></div>
    </div>
    <div *ngIf="emailSubmitted">
      <div class="stepTitle">THANK YOU FOR YOUR FEEDBACK</div>
      <div class="stepDescription">We've kept a record of your request and will be in touch as soon as we can service your area.</div>
      <button type="button" class="wizardButton searchNowButton" [routerLink]="['/home']">HOME</button>
    </div>
  </div>

  <div class="stepContainer" *ngIf="showMemberShipCheck">
      <div style="text-align: center;">
        <div class="stepTitleRaw">{{ jobDataService.capability.memberShipConfig.question }}</div>
        <div class="stepDescription">{{ jobDataService.capability.memberShipConfig.description }}</div>
        <div *ngIf="jobDataService.capability.memberShipConfig.questionType === questionTypes.YesNo">
          <div class="yesNoButtonContainer">
            <div class="optionSelect standardButton buttonPointer yesNoButton"
                (click)="setMemberShipAnswer(true)">YES</div>
            <div class="optionSelect standardButton buttonPointer yesNoButton"
              (click)="setMemberShipAnswer(false)">NO</div>
          </div>
        </div>
    </div>
  </div>

  <div *ngIf="showEmergencyInfo">

    <div *ngIf="currentStep == emergencyStep.SelectTrade">
      <div class="stepTitle" *ngIf="emergencyTriageEnabled">BOOK ONLINE</div>
      <div class="emergencyTrades" *ngIf="emergencyTriageEnabled">
        <div *ngFor="let trade of tradeTypes" class="homeTrade buttonPointer"  (click)="tradeClicked(trade)">
            <img src="assets/img/{{ clientID }}/home/tradetypes/{{ trade.icon }}" />
            <div class="tradeName">{{ trade.name.toUpperCase() }}</div>

            <div class="tradeName" *ngIf="memberPricing == false">${{ (afterHours == false ? trade.price.toFixed(2) : trade.priceAfterHours.toFixed(2)) }}</div>

            <div class="tradeName" style="text-decoration: line-through;" *ngIf="memberPricing == true">${{ (afterHours == false ? trade.price.toFixed(2) : trade.priceAfterHours.toFixed(2)) }}</div>
            <div class="tradeName" *ngIf="memberPricing == true">${{ (afterHours == false ? (trade.price * 0.9).toFixed(2) : (trade.priceAfterHours * 0.9).toFixed(2)) }}</div>

            <div class="standardButton tradeBookButton buttonPointer emergencyBookNow">BOOK NOW</div>
        </div>
      </div>

      <!-- <div class="stepTitle" *ngIf="emergencyTriageEnabled == false">SERVICES</div>
      <div class="callNowContainer howItWorks">
        TEST
      </div> -->

      <div class="stepTitle">HOW IT WORKS</div>
      <div class="callNowContainer howItWorks">
        <div class="callNowPoint">
          <div class="callNowIcon">
            <img src="assets/img/{{ clientID }}/emergency/truck.png" />
          </div>
          <div class="callNowName">
            We will attend within an hour and spend up to an hour at your home to fix the issue or make it safe
          </div>
        </div>
        <div class="callNowPoint">
          <div class="callNowIcon">
            <img src="assets/img/{{ clientID }}/emergency/trade.png" />
          </div>
          <div class="callNowName">
            Our friendly Tradie will talk you through everything that is required so no need to worry!
          </div>
        </div>
        <div class="callNowPoint">
          <div class="callNowIcon">
            <img src="assets/img/{{ clientID }}/emergency/card.png" />
          </div>
          <div class="callNowName">Payment can only be made via a Credit Card (Visa/Mastercard) <span *ngIf="clientID == clients.RACWA">and is required to be made prior to our trade leaving your home</span></div>
        </div>
      </div>
      <div class="stepTitle">CALL NOW</div>
      <div class="stepTitle emergencyNumber"><a href="tel:{{ metaData.contactEmergencyNumber }}">{{ metaData.contactEmergencyNumber }}</a></div>
    </div><!-- END SELECT TRADE STEP -->

    <div class="stepTitle" *ngIf="tradeSelected">EMERGENCY {{ selectedTrade.name.toUpperCase() }}</div>

    <div class="centerParent" *ngIf="currentStep == emergencyStep.Notes">
      <div class="centerChild questionBox">
        <div class="stepDescription">Would you like to add more information about your job?</div>
        <textarea class="freeText" [(ngModel)]="notes" placeholder="eg. there's a dog in my front yard" (change)="validateStep()"></textarea>
      </div>
    </div><!-- END NOTES STEP -->

    <div class="centerParent" *ngIf="currentStep == emergencyStep.ContactDetails">
      <div class="centerChild questionBox contactContainer">
        <div class="stepDescription">Please enter your contact information.</div>

        <div class="contactTitle" style="margin-top: 0px !important;">Booking Details</div>
        <label>Name</label>
        <input type="text" (change)="validateStep()"
          [(ngModel)]="jobDataService.jobData.customerContact.contactName" />
        <label>Email Address</label>
        <input type="email" (change)="validateStep()"
          [(ngModel)]="jobDataService.jobData.customerContact.contactEmail" />
        <label>Phone Number</label>
        <input type="tel" (change)="validateStep()"
          [(ngModel)]="jobDataService.jobData.customerContact.contactPhone" />

        <div class="contactTitle">Service Address</div>
        <label>Street Address</label>
        <input type="text" (change)="validateStep()" [(ngModel)]="jobDataService.jobData.streetAddress"
          autocorrect="off" autocapitalize="off" spellcheck="false" #address />
        <label>Unit</label>
        <input type="text" (change)="validateStep()" [(ngModel)]="jobDataService.jobData.streetUnit" />
        <label>Suburb</label>
        <input type="text" (change)="validateStep()" disabled [(ngModel)]="jobDataService.jobData.suburbName" />
        <label>Post Code</label>
        <input type="text" (change)="validateStep()" disabled [(ngModel)]="jobDataService.jobData.postcode" />

        <div class="contactTitle">Onsite Contact Details</div>
        <label for="chkSameAsContactDetails"><input type="checkbox" (change)="validateStep()"
          id="chkSameAsContactDetails" [(ngModel)]="siteContactSameAsBooking" /> Same as booking details</label>
        <label *ngIf="!siteContactSameAsBooking">Name</label>
        <input *ngIf="!siteContactSameAsBooking" type="text" (change)="validateStep()"
          [(ngModel)]="jobDataService.jobData.siteContact.contactName" />
        <label *ngIf="!siteContactSameAsBooking">Phone Number</label>
        <input *ngIf="!siteContactSameAsBooking" type="tel" (change)="validateStep()"
          [(ngModel)]="jobDataService.jobData.siteContact.contactPhone" />

        <label><input type="checkbox" (change)="validateStep()" [(ngModel)]="agreeTermsPrivacy"
            id="chkTermsPrivacy" /> I have read and agree with the <span (click)="popupService.openSpecialPopup(specialPopupType.TermsAndConditions)"
            class="termsPrivacySpan buttonPointer">GHR Terms</span> & <span (click)="popupService.openSpecialPopup(specialPopupType.PrivacyPolicy)"
            class="termsPrivacySpan buttonPointer">Privacy Policy</span></label>
        <label class="agreeEstimate" for="chkAgreeEstimate"><input type="checkbox" (change)="validateStep()"
            [(ngModel)]="agreePriceEstimate" id="chkAgreeEstimate" /> I acknowledge that the cost provided is an
          estimated cost</label>
        <div style="height: 25px;"></div> <!--SPACER-->
      </div>
    </div><!-- END CONTACT DETAILS STEP -->

    <div class="centerParent" *ngIf="currentStep == emergencyStep.Confirmation">
      <div class="centerChild questionBox confirmContainer">
        <div class="stepDescription">Please confirm your booking details.</div>
        <div class="confirmBox boxNoFill lighterBox pricingBox">
          <div class="confirmIcon"><img
              src="assets/img/{{ clientID }}/home/tradetypes/{{ selectedTrade.icon }}" />
          </div>
          <div class="confirmInfo">
            <div class="confirmTitle">{{ selectedTrade.name.toUpperCase() }}</div>
            <div class="confirmSubTitle">Emergency {{ selectedTrade.name }}</div>
            <div class="confirmAnswer">
                <div class="confirmAnswerText">Notes: {{ notes }}</div>
                <div class="confirmAnswerEdit editButton buttonPointer" (click)="editNotes()">edit</div>
                <div style="clear: both;"></div>
              </div>
          </div>
          <div style="clear: both;"></div>

          <div class="confirmPrice">
            <div class="confirmPriceTitle">
              TOTAL PRICE
            </div>
            <div class="confirmPriceText">{{ '$' + selectedTrade.price.toFixed(2) }}</div>
            <div style="clear: both;"></div>

            <div class="pricingDisclaimer">All prices include GST.</div>
          </div>
        </div>
        <div class="confirmBox boxNoFill lighterBox">
          <div class="confirmIcon"><img
              src="assets/img/{{ clientID }}/icons/contactdetails.png" /></div>
          <div class="confirmInfo">
            <div class="confirmTitle">CONTACT DETAILS</div>
            <div class="confirmSubTitle">Booking Details</div>
            <div class="confirmAnswer">
              <div class="confirmAnswerText">
                {{ jobDataService.jobData.customerContact.contactName }}<br />
                {{ jobDataService.jobData.streetUnit.length > 0 ? jobDataService.jobData.streetUnit + '/' : '' }}{{ jobDataService.jobData.streetAddress }}<br />
                {{ jobDataService.jobData.customerContact.contactEmail }}<br />
                {{ jobDataService.jobData.customerContact.contactPhone }}
              </div>
              <div class="confirmAnswerEdit editButton buttonPointer" (click)="editContactDetails()">edit</div>
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
      </div>
    </div><!-- END PAYMENT STEP -->

    <div class="centerParent" *ngIf="currentStep == emergencyStep.Payment">
      <div class="centerChild questionBox">
        <div class="stepDescription">Please complete the payment for this booking.</div>
      </div>
    </div><!-- END PAYMENT STEP -->

    <div *ngIf="tradeSelected">
      <div class="desktopBackNext">
        <div class="wizardButtonContainer">
          <div class="wizardButtonBack buttonPointer" (click)="prevStep()">
            < BACK
          </div>
          <div class="wizardButtonNext buttonPointer" [class.valid]="currentStepValid" (click)="nextStep()">
            {{ nextButtonText }} >
          </div>
          <div style="clear: both;"></div>
        </div>
      </div>
    </div>

  </div> <!-- END EMERGENCY INFO -->

  <!-- COMMON PROPERTY CALL POPUP -->
  <div class="popupBlocker" *ngIf="showCommonPropertyCallPopup"></div>
  <div class="popupContainer" *ngIf="showCommonPropertyCallPopup">
    <div class="popupHeader">
      <div class="popupTitle">COMMON PROPERTY JOBS</div>
      <div class="popupClose buttonPointer" (click)="toggleCommonPropertyCallPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        It looks like you need work done on common property, please call us on {{ metaData.contactPhoneNumber }} between the hours of {{ metaData.contactPhoneHours }}
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleCommonPropertyCallPopup(false)">Close</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END COMMON PROPERTY CALL POPUP -->

  <div class="popupBlocker" *ngIf="emailSubmitLoading"></div>
  <div class="loadingSpinner" *ngIf="emailSubmitLoading">
      <fa-icon style="font-size: 3em;" [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>

  <div class="popupBlocker" *ngIf="endLoading"></div>
  <div class="loadingSpinner" *ngIf="endLoading">
      <fa-icon style="font-size: 3em;" [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>

  <!-- <div class="popupBlocker" *ngIf="metaDataLoading"></div>
  <div class="loadingSpinner" *ngIf="metaDataLoading">
      <fa-icon style="font-size: 3em;" [icon]="faSpinner" [spin]="true"></fa-icon>
  </div> -->
</my-content>
