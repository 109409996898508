import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MetaDataService } from '../services/meta-data.service';
import { JobDataService, JobContact, JobData } from '../services/job-data.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreModule } from '@angular/fire/firestore';

import { MapsAPILoader } from '@agm/core';

import { environment } from '../../environments/environment';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { Clients, QuestionTypes } from '../services/globals.service';
import { APIService } from '../services/api.service';
import { PopupService, SpecialPopupType } from '../services/popup.service';

@Component({
  selector: 'HomeServices-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.css']
})
export class EmergencyComponent implements OnInit {

  public specialPopupType = SpecialPopupType;

  public faSpinner = faSpinner;

  public clientID: number = 0;

  public emergencyTriageEnabled: boolean = environment.emergencyTriageEnabled;

  public postcode: string = '';
  public email: string = '';

  public coverageAreaText: string = environment.coverageAreaText;

  public showPostcodeCheck: boolean = true;
  public showAreaNotCovered: boolean = false;
  public showEmergencyInfo: boolean = false;
  public showMemberShipCheck: boolean = false;
  public showCommonPropertyCheck: boolean = false;
  public showCommonPropertyCallPopup: boolean = false;
  
  public memberPricing: boolean = false;

  public emailSubmitted: boolean = false;
  public emailSubmitLoading: boolean = false;
  public metaDataLoading: boolean = false;

  public siteContactSameAsBooking: boolean = false;

  public tradeTypes: Array<EmergencyTradeType> = [];
  public selectedTrade: EmergencyTradeType = null;
  public currentPrice: number = 0;
  public afterHours: boolean = true;

  public emergencyStep = eEmergencyStep;
  public currentStep: eEmergencyStep = eEmergencyStep.SelectTrade;
  public currentStepValid: boolean = false;
  public tradeSelected: boolean = false;

  public notes: string = '';
  public agreeTermsPrivacy: boolean = false;
  public agreePriceEstimate: boolean = false;

  public nextButtonText: string = 'NEXT';

  public endLoading: boolean = false;

  public addressRef: ElementRef;

  @ViewChild('address', {static: false}) set content(content: ElementRef) {
    if (content === undefined || content === null) {
      return;
    }

    this.addressRef = content;

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.addressRef.nativeElement, {
        types: ["address"]
      });

      autocomplete.setComponentRestrictions({ 'country': ['au'] });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          } else {
            // valid
            var streetNumber = '';
            var streetName = '';
            var postcode = '';
            var suburbName = '';

            place.address_components.forEach(s => {
              s.types.forEach(t => {
                if (t == 'street_number') {
                  streetNumber = s.long_name;
                } else if (t == 'route') {
                  streetName = s.long_name;
                } else if (t == 'postal_code') {
                  postcode = s.long_name;
                } else if (t == 'locality') {
                  suburbName = s.long_name;
                }
              });
            });

            this.jobDataService.jobData.postcode = postcode;
            this.jobDataService.jobData.suburbName = suburbName;
            this.jobDataService.jobData.streetAddress = streetNumber + ' ' + streetName;
          }
        });
      });
    });
  } // address setter

  constructor(
    public metaData: MetaDataService,
    public jobDataService: JobDataService,
    public toastr: ToastrService,
    public db: AngularFirestore,
    public router: Router,
    public route: ActivatedRoute,
    public mapsAPILoader: MapsAPILoader,
    public ngZone: NgZone,
    public clients: Clients,
    public apiService: APIService,
    public popupService: PopupService,
    public questionTypes: QuestionTypes
  ) {
    this.clientID = environment.clientID;

    var component = this;

    if (this.metaData.metaDataLoaded == false) {
      this.metaDataLoading = true;
      this.metaData.loadMetaData().then(function() {
        component.jobDataService.trade = component.metaData.tradeTypes[0];
        component.metaDataLoading = false;
        component.setupScreen();
      });
    } else {
      this.jobDataService.trade = this.metaData.tradeTypes[0];
      this.setupScreen();
    }

    this.tradeTypes.length = 0;

    if (environment.clientID == this.clients.RACWA) {
      this.tradeTypes.push(new EmergencyTradeType({
        id: 1,
        name: 'Plumbing',
        urlID: 'plumbing',
        icon: 'plumbing.png',
        price: 218.9,
        priceAfterHours: 412.5,
        productCode: 'EMER_PL',
        description: 'An emergency plumbing callout including 1 hour on-site.'
      }));

      this.tradeTypes.push(new EmergencyTradeType({
        id: 2,
        name: 'Electrical',
        urlID: 'electrical',
        icon: 'electrical.png',
        price: 218.9,
        priceAfterHours: 412.5,
        productCode: 'EMER_EL',
        description: 'An emergency electrical callout including 1 hour on-site.'
      }));

      this.tradeTypes.push(new EmergencyTradeType({
        id: 3,
        name: 'Locksmith',
        urlID: 'locksmith',
        icon: 'locksmith.png',
        price: 218.9,
        priceAfterHours: 412.5,
        productCode: 'EMER_LS',
        description: 'An emergency electrical callout including 1 hour on-site.'
      }));
      
    } else {
      this.tradeTypes.push(new EmergencyTradeType({
        id: 1,
        name: 'Plumbing',
        urlID: 'plumbing',
        icon: 'plumbing.png',
        price: 299,
        priceAfterHours: 399,
        productCode: 'EMER_PL',
        description: 'An emergency plumbing callout including 1 hour on-site.'
      }));

      this.tradeTypes.push(new EmergencyTradeType({
        id: 2,
        name: 'Electrical',
        urlID: 'electrical',
        icon: 'electrical.png',
        price: 299,
        priceAfterHours: 399,
        productCode: 'EMER_EL',
        description: 'An emergency electrical callout including 1 hour on-site.'
      }));

      this.tradeTypes.push(new EmergencyTradeType({
        id: 3,
        name: 'Locksmith',
        urlID: 'locksmith',
        icon: 'locksmith.png',
        price: 299,
        priceAfterHours: 399,
        productCode: 'EMER_LS',
        description: 'An emergency locksmith callout including 1 hour on-site.'
      }));
    }
  }

  public setupScreen() {
    this.route.queryParams // get querystring params
    .subscribe(params => {
      if (params.postcode == null || params.postcode === undefined) {
        this.postcode = '';
      } else {
        this.postcode = params.postcode;
      }
    });

    if (this.postcode.length > 0) {
      this.metaData.checkPostcode(this.postcode);
    } else {
      this.metaData.postcodeCheck = false;
    }

    if (this.metaData.postcodeCheck) {
      this.showPostcodeCheck = false;

      if (this.clientID == this.clients.RACWA) {
        this.showMemberShipCheck = true;
      } else {
        this.showEmergencyInfo = true;
      }
      
      this.jobDataService.postcode = this.postcode;
    }
  }

  ngOnInit() {
    if (environment.emergencyEnabled == false) {
      this.router.navigate(['/home']);
    }

    this.checkAfterHours();

    window.scrollTo(0, 0);
  }

  public toggleCommonPropertyCallPopup(show: boolean) {
    this.showCommonPropertyCallPopup = show;
  }

  public setCommonPropertyAnswer(answer: boolean) {
    if (answer == true) {
      this.showCommonPropertyCallPopup = true;
      return;
    }

    this.showPostcodeCheck = !this.metaData.postcodeCheck;
    this.showEmergencyInfo = this.metaData.postcodeCheck;
    this.showCommonPropertyCheck = false;
  }

  public checkAfterHours() {
    const today = new Date();
    var hoursMinutes: number = (today.getHours() + (today.getMinutes() / 100));

    if (hoursMinutes >= environment.emergencyAfterHoursStart
        || hoursMinutes < environment.emergencyAfterHoursEnd) {
      this.afterHours = true;
    } else {
      this.afterHours = false;
    }

    if (this.selectedTrade != null && this.tradeSelected == true) {
      if (this.afterHours == true) {
        this.currentPrice = this.selectedTrade.priceAfterHours;
      } else {
        this.currentPrice = this.selectedTrade.price;
      }

      if (this.memberPricing == true) {
        this.currentPrice = this.currentPrice * 0.9;
      }
    }
  } // checkAfterHours

  // this may need to handle different question types in future, keeping it simple for now
  public setMemberShipAnswer(isMember: boolean) {
    this.jobDataService.capability.memberShipConfig.answer = isMember;
    this.showMemberShipCheck = false;
    this.showEmergencyInfo = true;

    this.jobDataService.memberQuestionAnswered = true;

    this.memberPricing = isMember;
  }

  public tradeClicked(trade: EmergencyTradeType) {
    this.selectedTrade = trade;
    this.currentStep = eEmergencyStep.Notes;
    this.tradeSelected = true;
    this.notes = '';
    this.validateStep();
    this.checkAfterHours();
    window.scrollTo(0, 0);
  }

  public editContactDetails() {
    this.currentStep = eEmergencyStep.ContactDetails;
    this.validateStep();
    window.scrollTo(0, 0);
  }

  public editNotes() {
    this.currentStep = eEmergencyStep.Notes;
    this.validateStep();
    window.scrollTo(0, 0);
  }

  public prevStep() {
    switch (this.currentStep) {
      case eEmergencyStep.Notes:
        this.currentStep = eEmergencyStep.SelectTrade;
        this.selectedTrade = null;
        this.tradeSelected = false;
        this.currentPrice = 0;
        break;

      case eEmergencyStep.ContactDetails:
        this.currentStep = eEmergencyStep.Notes;
        break;

      case eEmergencyStep.Confirmation:
        this.currentStep = eEmergencyStep.ContactDetails;
        break;

      case eEmergencyStep.Payment:
        this.currentStep = eEmergencyStep.Confirmation;
        break;
    }

    this.validateStep();
    window.scrollTo(0, 0);
  }

  public nextStep() {
    this.validateStep(true);

    if (this.currentStepValid == false) {
      return;
    }
    
    switch (this.currentStep) {
      case eEmergencyStep.Notes:
        this.currentStep = eEmergencyStep.ContactDetails;
        break;

      case eEmergencyStep.ContactDetails:
        this.currentStep = eEmergencyStep.Confirmation;
        break;

      case eEmergencyStep.Confirmation:
        //this.currentStep = eEmergencyStep.Payment;
        this.goToPayment();
        break;
    }

    this.validateStep();
    window.scrollTo(0, 0);
  }

  public validateStep(showToast: boolean = false) {
    switch (this.currentStep) {
      case eEmergencyStep.SelectTrade:
        break;

      case eEmergencyStep.Notes:
        this.currentStepValid = true;
        break;

      case eEmergencyStep.ContactDetails:
        if (this.siteContactSameAsBooking) {
          this.jobDataService.jobData.siteContact.contactName = this.jobDataService.jobData.customerContact.contactName;
          this.jobDataService.jobData.siteContact.contactPhone = this.jobDataService.jobData.customerContact.contactPhone;
        }

        // customer name
        if (this.jobDataService.jobData.customerContact.contactName.length == 0) {
          if (showToast) { this.toastr.error('Please enter your name.', 'Error'); }
          this.currentStepValid = false;
          return;
        } else {
          if (/[/.:{(})?=@;.'"]/.test(this.jobDataService.jobData.customerContact.contactName) == true) {
            if (showToast) { this.toastr.error('Please enter a valid name.', 'Error'); }
            this.currentStepValid = false;
            return;
          }
        }

        // site contact name
        if (this.jobDataService.jobData.siteContact.contactName.length == 0) {
          if (showToast) { this.toastr.error('Please enter your onsite contact name.', 'Error'); }
          this.currentStepValid = false;
          return false;
        } else {
          if (/[/.:{(})?=@;.'"]/.test(this.jobDataService.jobData.siteContact.contactName) == true) {
            if (showToast) { this.toastr.error('Please enter a valid onsite contact name.', 'Error'); }
            this.currentStepValid = false;
            return false;
          }
        }

        // customer email
        if (this.jobDataService.jobData.customerContact.contactEmail.length == 0) {
          if (showToast) { this.toastr.error('Please enter your email address.', 'Error'); }
          this.currentStepValid = false;
          return;
        } else {
          if (/(.+)@(.+){2,}\.(.+){2,}/.test(this.jobDataService.jobData.customerContact.contactEmail) == false) {
            if (showToast) { this.toastr.error('Please enter a valid email address.', 'Error'); }
            this.currentStepValid = false;
            return;
          }
        }

        // customer phone number
        if (this.jobDataService.jobData.customerContact.contactPhone.length == 0) {
          if (showToast) { this.toastr.error('Please enter your contact phone number.', 'Error'); }
          this.currentStepValid = false;
          return;
        } else {
          if (/[a-zA-Z:/@.'";=?]/.test(this.jobDataService.jobData.customerContact.contactPhone) == true) {
            if (showToast) { this.toastr.error('Please enter a valid phone number.', 'Error'); }
            this.currentStepValid = false;
            return;
          }
        }

        // site contact phone number
        if (this.jobDataService.jobData.siteContact.contactPhone.length == 0) {
          if (showToast) { this.toastr.error('Please enter your onsite contact phone number.', 'Error'); }
          this.currentStepValid = false;
          return false;
        } else {
          if (/[a-zA-Z:/@.'";=?]/.test(this.jobDataService.jobData.siteContact.contactPhone) == true) {
            if (showToast) { this.toastr.error('Please enter a valid onsite contact phone number.', 'Error'); }
            this.currentStepValid = false;
            return false;
          }
        }

        // home address
        if (this.jobDataService.jobData.streetAddress.length == 0) {
          if (showToast) { this.toastr.error('Please enter your street address.', 'Error'); }
          this.currentStepValid = false;
          return;
        }

        // subrub name will come from google autocomplete
        if (this.jobDataService.jobData.suburbName.length == 0) {
          if (showToast) { this.toastr.error('Please enter your suburb.', 'Error'); }
          this.currentStepValid = false;
          return;
        }

        // postcode will come from google autocomplete
        if (this.jobDataService.jobData.postcode.length == 0) {
          if (showToast) { this.toastr.error('Please enter your postcode.', 'Error'); }
          this.currentStepValid = false;
          return;
        } else {
          if (isNaN(Number(this.jobDataService.jobData.postcode)) == true) {
            if (showToast) { this.toastr.error('Please enter a valid postcode.', 'Error'); }
            this.currentStepValid = false;
            return;
          } else {
            // validate postcode coverage
            this.metaData.checkPostcode(this.jobDataService.jobData.postcode);

            if (this.metaData.postcodeCheck == false) {
              if (showToast) { this.toastr.error('Your postcode is not currently covered by our service.', 'Error'); }
              this.currentStepValid = false;
              return;
            }
          }
        }

        if (this.agreeTermsPrivacy == false) {
          if (showToast) { this.toastr.error('To continue, you must agree to our Terms and Privacy Policy.', 'Error'); }
          this.currentStepValid = false;
          return;
        }

        if (this.agreePriceEstimate == false) {
          if (showToast) { this.toastr.error('To continue, you must agree that you understand your price is an estimate.', 'Error'); }
          this.currentStepValid = false;
          return;
        }

        this.currentStepValid = true;
        break;

      case eEmergencyStep.Confirmation:
        this.currentStepValid = true;
        break;

      case eEmergencyStep.Payment:
        this.currentStepValid = false;
        break;
    }

    this.nextButtonText = 'NEXT';
    if (this.currentStep == eEmergencyStep.Confirmation) {
      this.nextButtonText = 'CONFIRM & GO TO PAYMENT';
    }
  }

  public goToPayment() {
    if (environment.emergencyEnabled == false) {
      return;
    }

    var component: EmergencyComponent = this;
    var newJobRef = 1001;
    var now = new Date();

    var latestBooking = this.db.collection('/jobrefs').ref.orderBy('jobref', 'desc').limit(1);

    this.endLoading = true;

    latestBooking.get().then(function (results) {
      if (!results.empty) {
        newJobRef = (results.docs[0].data().jobref + 1);
      }

      var exGST: number = component.currentPrice / 1.1;

      var jobData: JobData = new JobData();
      jobData.bookingWindows = [];
      jobData.customerContact = component.jobDataService.jobData.customerContact;
      jobData.siteContact = component.jobDataService.jobData.siteContact;
      jobData.customerReference = '';
      jobData.description = '';
      jobData.externalJobID = '';
      jobData.jobReference = '';
      jobData.postcode = component.jobDataService.jobData.postcode;
      jobData.services = [];
      jobData.streetAddress = component.jobDataService.jobData.streetAddress;
      jobData.streetUnit = component.jobDataService.jobData.streetUnit;
      jobData.suburbName = component.jobDataService.jobData.suburbName;
      jobData.estimatedPrice = parseFloat(exGST.toFixed(2));
      jobData.estimatedHours = 1;

      var service: any = {};
      service.externalServiceID = component.selectedTrade.productCode; // TODO
      service.priceMarkup = 0;
      service.discount = 0;
      service.triageResponses = [];

      var response: any = {};
      response.questionTypeID = 1;
      response.triageAnswer = component.notes;
      response.triageFullAnswer = component.notes;
      response.triageQuestion = 'Would you like to add more information about your job?';
      
      service.triageResponses.push(response);
      jobData.services.push(service);

      var paymentID = component.uuidv4();

      var newBooking: any = {};
      newBooking.jobref = newJobRef;
      newBooking.data = JSON.stringify(jobData);
      newBooking.processed = false;
      newBooking.processedDate = null;
      newBooking.insertedDate = new Date(now.toUTCString());
      newBooking.bookingType = 3; // emergency
      // temporary mortgage HIP offer code / the block offer code
      newBooking.offerCode = '';
      newBooking.channel = component.jobDataService.jobChannel;
      newBooking.tries = 0;
      newBooking.paymentID = paymentID;

      var newRef: any = {};
      newRef.jobref = newJobRef;

      component.db.doc('/bookings/' + environment.jobPrefix + '_' + newJobRef.toString()).set(newBooking).then(function () {
        component.db.doc('/jobrefs/' + environment.jobPrefix + '_' + newJobRef.toString()).set(newRef).then(function () {
          component.endLoading = false;

          var stripe: any = (<any>window).Stripe(environment.stripeAPIKey);

          var dtobj: any = {};
          dtobj.lineItems = [];
          dtobj.successURL = environment.localURL + '/emergencycomplete?booking=' + newJobRef.toString() + '&success=true';
          dtobj.cancelURL = environment.localURL + '/emergencycomplete?booking=' + newJobRef.toString() + '&success=false';
          dtobj.bookingID = newJobRef;
          dtobj.email = component.jobDataService.jobData.customerContact.contactEmail;
          dtobj.paymentID = paymentID;

          dtobj.lineItems.push({
            Amount: component.currentPrice * 100, // x100 because this is passed in cents
            Currency: 'AUD',
            Description: component.selectedTrade.description,
            //Images: [], don't add this if not passing a value
            Name: 'Emergency ' + component.selectedTrade.name,
            Quantity: 1,
          });

          component.apiService.callAPI("rpcapi/HomeServices/StripePayment", dtobj, async reply => {
            if (reply.status == true) {
              //component.endLoading = false;

              stripe.redirectToCheckout({
                sessionId: reply.stripeSessionID
              }).then(function (result) {
                component.toastr.error('Booking error: ' + result.error.message);
              });
            } else {
              component.endLoading = false;
            }
          },
          async error => {
            // handle error
            component.toastr.error('Booking failed, please try again.');
            console.log('ERROR: ' + error);
            component.endLoading = false;
          });

        }); // add jobref

      }).catch(function (error) {
        component.toastr.error('Booking failed, please try again.');
        console.log('ERROR: ' + error);
        component.endLoading = false;
      }); // add booking

    }).catch(function (error) {
      component.toastr.error('Booking failed, please try again.');
      console.log('ERROR: ' + error);
      component.endLoading = false;
    }); // get next jobref

    // stripe.redirectToCheckout({
    //   items: [{sku: 'sku_FpAWHlnMxRu1Dw', quantity: 1}],

    //   //successUrl: environment.localURL + '/emergencycomplete?booking=' + bookingRef + '&success=true',
    //   //cancelUrl: environment.localURL + '/emergencycomplete?booking=' + bookingRef + '&success=false',
    // })
    // .then(function (result) {
    //   if (result.error) {
    //     // If `redirectToCheckout` fails due to a browser or network
    //     // error, display the localized error message to your customer.
    //     /* var displayError = document.getElementById('error-message');
    //     displayError.textContent = result.error.message; */
    //   }
    // });
  }

  public checkPostcode() {
    this.showPostcodeCheck = false;

    if (this.postcode.length > 0) {
      this.metaData.checkPostcode(this.postcode);
    }

    if (this.metaData.postcodeCheck == false) {
      this.showAreaNotCovered = true;
    } else {
      this.showEmergencyInfo = true;
      this.jobDataService.postcode = this.postcode;

      if (this.jobDataService.capability.hasMemberShip == true && this.showPostcodeCheck == false && this.jobDataService.memberQuestionAnswered == false) {
        this.showMemberShipCheck = true;
        this.showEmergencyInfo = false;
      } else {
        this.showMemberShipCheck = false;
        this.showEmergencyInfo = true;
      }
    }
  }

  public submitServiceAreaEmail() {
    if (this.email.length == 0) {
      this.toastr.error('Please enter an email address.', 'Error');
      return;
    } else {
      if (/(.+)@(.+){2,}\.(.+){2,}/.test(this.email) == false) {
        this.toastr.error('Please enter a valid email address.', 'Error');
        return false;
      }
    }

    var component: any = this;
    var toastr = this.toastr;
    var db = this.db;

    var now = new Date();

    var newEmail: any = {};
    newEmail.emailAddress = this.email;
    newEmail.postCode = this.postcode;
    newEmail.trade = 'emergency';
    newEmail.tradeID = -1;
    newEmail.insertedDate = new Date(now.toUTCString());

    this.emailSubmitLoading = true;

    db.collection('/customernotcovered').add(newEmail).then(function () {
      component.emailSubmitted = true;
      component.emailSubmitLoading = false;
    }).catch(function (error) {
      toastr.error('Submitting failed, please try again.');
    });
  }

  public uuidv4(): string {
    return (<any>[1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
} // EmergencyComponent

export enum eEmergencyStep {
  SelectTrade,
  Notes,
  ContactDetails,
  Confirmation,
  Payment
}

export class EmergencyTradeType {
  public id: number = 0;
  public name: string = '';
  public urlID: string = '';
  public icon: string = '';
  public price: number = 0;
  public priceAfterHours: number = 0;
  public productCode: string = '';
  public description: string = '';

  public constructor(init?:Partial<EmergencyTradeType>) {
    Object.assign(this, init);
  }
}