import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  constructor() { }
}

@Injectable({
  providedIn: 'root'
})

// https://stackoverflow.com/questions/26501688/a-typescript-guid-class
export class Guid {
  public static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

export class General {
  //
}

export class EmergencyTradeTypes {
  readonly Plumbing: number = 1;
  readonly Electrical: number = 2;
  readonly Locksmith: number = 3;
}

export class TradeTypes {
  readonly Plumbing: number = 1;
  readonly Electrical: number = 2;
  readonly Locksmith: number = 3;
  readonly Handyman: number = 4;
  readonly HomeInspections: number = 5;
  readonly Glazing: number = 6;
  readonly Carpentry: number = 7;
  readonly Plastering: number = 8;
  readonly Painting: number = 9;
  readonly HVAC: number = 10;
  readonly RoofPlumbing: number = 11;
  readonly Fencing: number = 12;
  readonly BrickLaying: number = 13;
  readonly BrickPaving: number = 14;
  readonly Antennas: number = 15;
  readonly StormReadiness: number = 16;
  readonly Tiling: number = 17;
  readonly Gardening: number = 18;
  readonly GarageDoors: number = 19;
  readonly SpecialOffers: number = 20;
}

export class Clients {
  readonly Demo: number = 0;

  readonly Huski: number = 3355;
  readonly RACWA: number = 3412;
  readonly AANZ: number = 3978;

  constructor() { }
}

export class QuestionTypes {
  readonly FreeText: number = 1;
  readonly BookingWindows: number = 2;
  readonly ContactDetails: number = 3;
  readonly Confirmation: number = 4;
  readonly Quantity: number = 5;
  readonly TwoOptions: number = 6;
  readonly SelectTapModel: number = 7;
  readonly Numeric: number = 8;
  readonly YesNo: number = 9;
  readonly SimpleTwoOptions: number = 10;
  readonly HandymanDuration: number = 11;
  readonly JobNotesPhotos: number = 12;
  readonly CarpentryDuration: number = 13;
  readonly PlasteringDuration: number = 14;
  readonly PaintingDuration: number = 15;
  readonly HVACDuration: number = 16;
  readonly RoofPlumbingDuration: number = 17;
  readonly FencingDuration: number = 18;
  readonly BrickLayingDuration: number = 19;
  readonly BrickPavingDuration: number = 20;
  readonly AntennasDuration: number = 21;
  readonly StormReadinessText: number = 22;
  readonly TilingDuration: number = 23;
  readonly PaintingWholeApartment: number = 24;
  readonly PaintingSingleRoom: number = 25;
  readonly GardeningDuration: number = 26;
  readonly GutterCleaningDuration: number = 27;
  readonly SolarCleaningDuration: number = 28;
  readonly AirConditioningDuration: number = 29;
  readonly SmokeAlarmDuration: number = 30;
}

export enum ServiceTypes {
  // plumbing
  ReplacementKitchenLaundryTap = 0,
  ReplacementGardenTap = 1,
  ReplacementBathroomTap = 2,
  ReplacementTapWashers = 3,
  ReplacementDishwasher = 4,
  NoHotWater = 5, // emergency
  UnblockToiletDrain = 6, // emergency
  BurstPipe = 7, // emergency
  PlumbingGeneral = 8,
  
  // electrical
  ReplaceLightSwitch = 9,
  InstallNewInternalPowerPoint = 10,
  ReplaceInternalPowerPoint = 11,
  InstallNewExternalPowerPoint = 12,
  ReplaceExternalPowerPoint = 13,
  ReplaceCeilingFan = 14,
  InstallNewExhaustFan = 15,
  ReplaceSmokeAlarm = 16,
  InstallExtraInternetPoint = 17,
  ElectricalGeneral = 18,

  // single service trades
  Locksmith = 19,
  Handyman = 20,
  Plastering = 21,
  Painting = 22,
  HVAC = 23,
  RoofPlumbing = 24,
  Fencing = 25,
  BrickLaying = 26,
  BrickPaving = 27,
  Carpentry = 28,
  Glazing = 29,
  HomeInspections = 30,
  Antennas = 31,
  Gardening = 32,
  GarageDoors = 35,

  // painting
  PaintingWholeApartment = 33,
  PaintingSingleRoom = 34,

  //special offers
  GutterCleaning = 36,
  SolarCleaning = 37,
  AirConditioning = 38,
  SmokeAlarm = 39,
}

export enum JobChannelType {
  ONLINE = 1,
  ADMIN = 2,
  TMC = 3,
  CALLCENTRE = 4,
}
