import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HostListener } from '@angular/core';

import { MetaDataService, TradeType, JobType } from '../services/meta-data.service';
import { JobDataService } from '../services/job-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../environments/environment';

import { Clients, QuestionTypes, TradeTypes } from '../services/globals.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PostcodeManagerService } from '../services/postcode-manager.service';

@Component({
  selector: 'HomeServices-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
  
  public faSpinner = faSpinner;

  public bundlingEnabled: boolean = environment.bundlingEnabled;

  public metaData: MetaDataService = null;

  public url: string = '';
  public router: Router = null;
  public route: ActivatedRoute = null;
  public jobDataService: JobDataService = null;
  public toastr: ToastrService = null;
  public db: AngularFirestore = null;
  public clients: Clients = null;

  public coverageAreaText: string = environment.coverageAreaText;

  public clientID: number = 0;

  public questionTypes: QuestionTypes = null;

  public showPostcodeCheck: boolean = false;
  public showAreaNotCovered: boolean = false;
  public showServicesList: boolean = false;
  public showEmergencyPopup: boolean = false;
  public showCommonPropertyCallPopup: boolean = false;
  public showMemberShipCheck: boolean = false;
  public showCommonPropertyCheck: boolean = false;

  public emailSubmitted: boolean = false;
  public emailSubmitLoading: boolean = false;
  public metaDataLoading: boolean = false;

  public trade: TradeType = null;
  public postcode: string = '';
  public email: string = '';
  public serviceAreaEmail: string = '';

  public jobTypeSelectors: Array<JobTypeSelector> = [];

  constructor(_metaDataService: MetaDataService, _router: Router,
    _activatedRoute: ActivatedRoute, _jobDataService: JobDataService,
    _toastr: ToastrService, _db: AngularFirestore, _clients: Clients, _questionTypes: QuestionTypes,
    public postcodeManager: PostcodeManagerService,
    public tradeTypes: TradeTypes) {
    this.router = _router;
    this.route = _activatedRoute;
    this.toastr = _toastr;
    this.db = _db;
    this.clients = _clients;
    this.questionTypes = _questionTypes;

    this.metaData = _metaDataService;
    this.jobDataService = _jobDataService;

    this.clientID = environment.clientID;
    this.metaData.currentTriageQuestion = null;
  }

  ngOnInit() {
    if ((<any>window).skipToHome == true) {
      (<any>window).skipToHome = false;
      this.router.navigate(['/home']);
      return;
    }
    
    var component = this;

    if (this.metaData.metaDataLoaded == false) {
      this.metaDataLoading = true;

      this.metaData.loadMetaData().then(function () {
        component.setupScreen();
        component.metaDataLoading = false;
      });
    } else {
      component.setupScreen();
    }

    window.scrollTo(0, 0);

  } // ngOnInit

  public setupScreen() {
    this.url = this.router.url;

    this.route.queryParams // get querystring params
      .subscribe(params => {
        var tradeType = params.trade;
        this.postcode = params.postcode;

        if (this.postcode == null) {
          this.postcode = '';
        }

        this.metaData.tradeTypes.forEach(element => {
          if (element.urlID.toLowerCase() == tradeType.toLowerCase()) {
            this.trade = element;
            this.jobDataService.trade = this.trade;
          }
        });
      });

    if (this.trade == null) {
      this.router.navigate(['/home']);
    }

    this.jobTypeSelectors.length = 0;

    this.trade.jobTypes.forEach(jt => {
      this.jobTypeSelectors.push(new JobTypeSelector(jt));
    });

    if (this.postcode.length > 0) {
      this.metaData.checkPostcode(this.postcode);
      this.jobDataService.postcode = this.postcode;
    }

    if (this.metaData.postcodeCheck == true) {
      if (this.trade.jobTypes.length == 1) {
        this.jobTypeSelectors[0].selected = true;
        this.selectJobTypes();
        //this.jobTypeClick(this.trade.jobTypes[0]);
      }
    }

    this.showPostcodeCheck = !this.metaData.postcodeCheck;
    this.showServicesList = this.metaData.postcodeCheck;

    if (this.jobDataService.capability.hasMemberShip == true && this.showPostcodeCheck == false && this.jobDataService.memberQuestionAnswered == false) {
      this.showMemberShipCheck = true;
      this.showServicesList = false;

      this.metaData.pushRACDataLayerPageView('/membercheck/' + this.convertURLName(this.jobDataService.trade.name), 'membercheck - ' + this.convertURLName(this.jobDataService.trade.name));
    } else {
      if (this.showPostcodeCheck == true) {
        this.metaData.pushRACDataLayerPageView('/postcodecheck/' + this.convertURLName(this.jobDataService.trade.name), 'postcodecheck - ' + this.convertURLName(this.jobDataService.trade.name));
      } else {
        this.metaData.pushRACDataLayerPageView('/start/' + this.convertURLName(this.jobDataService.trade.name), 'start - ' + this.convertURLName(this.jobDataService.trade.name));
      }
    }
  }

  public setCommonPropertyAnswer(answer: boolean) {
    if (answer == true) {
      this.showCommonPropertyCallPopup = true;
      return;
    }

    this.showPostcodeCheck = !this.metaData.postcodeCheck;
    this.showServicesList = this.metaData.postcodeCheck;
    this.showCommonPropertyCheck = false;
  }

  public toggleCommonPropertyCallPopup(show: boolean) {
    this.showCommonPropertyCallPopup = show;
  }

  public toggleEmergencyPopup(show: boolean) {
    this.showEmergencyPopup = show;
  }

  public checkPostcode() {
    this.showPostcodeCheck = false;

    if (this.postcode.length > 0) {
      this.metaData.checkPostcode(this.postcode);
      this.jobDataService.postcode = this.postcode;
    }

    if (this.metaData.postcodeCheck) {
      // 20190625 - DM - check for any intermediate steps
      if (this.jobDataService.capability.hasMemberShip) {
        this.showMemberShipCheck = true;
      } else {
        this.showServicesList = true;

        this.checkSkipServicesList();
      }

      this.postcodeManager.selectedPostcodeNextDayBooking = false;

      // set public holidays for this postcode
      this.postcodeManager.postcodeMap.forEach(m => {
        var check: any = false;

        if (m.covered) {
          m.postcodes.forEach(p => {
            if (p == this.postcode) {
              this.postcodeManager.selectedPublicHolidays = m.publicHolidays;
              this.postcodeManager.selectedPostcodeNextDayBooking = m.nextDayBooking;
            }
          });
        }
      });

      this.metaData.pushRACDataLayerPageView('/start/' + this.convertURLName(this.jobDataService.trade.name), 'start - ' + this.convertURLName(this.jobDataService.trade.name));

    } else {
      this.showAreaNotCovered = true;
    }
  } // checkPostcode

  public convertURLName(name: string) {
    var newName = name.split(' ').join('-');
    newName = newName.split('_').join('-');

    return newName.toLowerCase();
  }

  // this may need to handle different question types in future, keeping it simple for now
  public setMemberShipAnswer(isMember: boolean) {
    this.jobDataService.capability.memberShipConfig.answer = isMember;
    this.showMemberShipCheck = false;
    this.showServicesList = true;

    this.jobDataService.memberQuestionAnswered = true;

    this.checkSkipServicesList();
  }

  public checkSkipServicesList() {
    if (this.metaData.postcodeCheck == true) {
      if (this.trade.jobTypes.length == 1) {
        this.jobTypeSelectors[0].selected = true;
        this.selectJobTypes();
        //this.jobTypeClick(this.trade.jobTypes[0]);
      }
    }
  }

  public submitServiceAreaEmail() {
    if (this.email.length == 0) {
      this.toastr.error('Please enter an email address.', 'Error');
      return;
    } else {
      if (/(.+)@(.+){2,}\.(.+){2,}/.test(this.email) == false) {
        this.toastr.error('Please enter a valid email address.', 'Error');
        return false;
      }
    }

    var component: any = this;
    var toastr = this.toastr;
    var db = this.db;

    var now = new Date();

    var newEmail: any = {};
    newEmail.emailAddress = this.email;
    newEmail.postCode = this.postcode;
    newEmail.trade = this.trade.name;
    newEmail.tradeID = this.trade.id;
    newEmail.insertedDate = new Date(now.toUTCString());

    this.emailSubmitLoading = true;

    db.collection('/customernotcovered').add(newEmail).then(function () {
      component.emailSubmitted = true;
      component.emailSubmitLoading = false;
    }).catch(function (error) {
      toastr.error('Submitting failed, please try again.');
    });
  }

  public jobTypeClick(jts: JobTypeSelector) {
    if (jts.jobType.emergency) {
      if (environment.emergencyEnabled == true) {
        this.router.navigate(['/emergency'], { queryParams: { postcode: this.postcode } });
      } else {
        this.toggleEmergencyPopup(true);
      }
    } else {
      if (jts.selected) {
        jts.selected = false;
      } else {
        jts.selected = true;

        if (this.bundlingEnabled == false) {
          this.selectJobTypes();
        }
      }
    }
  } // jobTypeClick

  public selectJobTypes() {
    this.jobDataService.jobTypes.length = 0;

    var route: string = 'triage';
    var jobTypeIndex: number = 0;

    this.jobTypeSelectors.forEach(jt => {
      if (jt.selected) {
        if (jt.jobType.route == 'taps') {
          route = jt.jobType.route;
        } else {
          jt.jobType.jobTypeIndex = jobTypeIndex;
          this.jobDataService.jobTypes.push(jt.jobType);
        }
      }

      jobTypeIndex += 1;
    });

    if (this.jobDataService.jobTypes.length == 0 && route != 'taps') {
      return; // todo: handle error
    }

    var firstJobType: JobType = this.jobDataService.jobTypes[0];

    if (environment.GATrackingID.length > 0) {
      if (route != 'triage') {
        this.router.navigate(['/' + route]);
      } else {
        if (firstJobType.questions.length > 0) {
          this.router.navigate(['/triage'], { queryParams: { jobType: firstJobType.productCode, stepType: firstJobType.questions[0].questionTypeID } });
        } else {
          this.router.navigate(['/' + route]);
        }
      }
    } else {
      this.router.navigate(['/' + route]);
    }
    
  } // selectJobTypes

}

export class JobTypeSelector
{
  public jobType: JobType = null;
  public selected: boolean = false;

  constructor(_jobType: JobType) {
    this.jobType = _jobType;
  }
}