import { Component, OnInit } from '@angular/core';
import { MetaDataService } from '../services/meta-data.service';
import { JobDataService } from '../services/job-data.service';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

import { Clients, JobChannelType } from '../services/globals.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public pageContentParams = {
    productName: environment.productName,
    contactPhoneNumber: '',
    contactEmail: '',
    contactPhoneHours: '',
  };

  public environment = environment;
  
  public faSpinner = faSpinner;

  public route: ActivatedRoute = null;

  public metaData: MetaDataService = null;
  public jobData: JobDataService = null;
  public router: Router = null;
  public clients: Clients = null;

  public clientID: number = 0;

  public showSomethingElsePopup: boolean = false;
  public metaDataLoading: boolean = false;

  constructor(_metaData: MetaDataService, _jobData: JobDataService, _router: Router, _clients: Clients, _activatedRoute: ActivatedRoute) {
    this.metaData = _metaData;
    this.jobData = _jobData;
    this.router = _router;
    this.clients = _clients;
    this.route = _activatedRoute;

    var component = this;

    if (this.metaData.metaDataLoaded == false) {
      this.metaDataLoading = true;
      this.metaData.loadMetaData().then(function () {
        component.metaDataLoading = false;
        component.loadMetaData();
      });
    } else {
      this.loadMetaData();
    }

    this.clientID = environment.clientID;
  }

  private loadMetaData() {
    // load params used in text content
    this.pageContentParams.contactPhoneNumber = this.metaData.contactPhoneNumber;
    this.pageContentParams.contactEmail = this.metaData.contactEmail;
    this.pageContentParams.contactPhoneHours = this.metaData.contactPhoneHours;
  }

  ngOnInit() {
    this.route.queryParams // get querystring params
    .subscribe(params => {
      if (params.broker != null && params.broker.length > 0) {
        this.jobData.brokerCode = params.broker;
      }

      if (params.channel != null && params.channel.length > 0) {
        if (JobChannelType[params.channel.toUpperCase()] != undefined) {
          var channelTypeString = params.channel.toUpperCase() as keyof typeof JobChannelType;
          this.jobData.jobChannel = JobChannelType[channelTypeString];
        }
      }
    });

  }

  public gotoRoute(tradeId:number){

    var queryParamsObject: any = {};

    if (this.jobData.capability.hasMemberShip) {
      queryParamsObject.isMember = this.jobData.capability.memberShipConfig.answer;
    }
    queryParamsObject.trade = tradeId;
    queryParamsObject.postcode = this.jobData.postcode;

    this.router.navigate(['/start'], { queryParams: queryParamsObject });
  }
  public scheduledClicked(target: Element) {
    target.scrollIntoView();
  }

  public emergencyClicked() {
    this.router.navigate(['/emergency'], { queryParams: { postcode: this.jobData.postcode } });
  }

  public toggleSomethingElsePopup(show: boolean) {
    this.showSomethingElsePopup = show;
  }

  // temporary bright and duggan thing
  public antiViralDeepCleanClicked() {
    window.location.href = 'https://restorx.com.au/strata-services/';
  }

}
