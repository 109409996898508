<my-content placeholder="main">
  <div class="stepCountPosition">
    <div class="stepCountContainer" id="stepCountContainer">
      <div class="stepLabel buttonPointer" [class.progressLight]="stepsProgress[0]" (click)="goToStep(0)">
        STEP 1<div class="stepSubLabel">Your Job</div>
        <img src="assets/img/{{ clientID }}/progresslight.png" />
      </div>
      <div class="stepLabel buttonPointer" [class.progressLight]="stepsProgress[1]" (click)="goToStepType(2)">
        STEP 2<div class="stepSubLabel">Schedule</div>
        <img src="assets/img/{{ clientID }}/progresslight.png" />
      </div>
      <div class="stepLabel buttonPointer" [class.progressLight]="stepsProgress[2]" (click)="goToStepType(3)">
        STEP 3<div class="stepSubLabel">Your Details</div>
        <img src="assets/img/{{ clientID }}/progresslight.png" />
      </div>
      <div class="stepLabel buttonPointer" [class.progressLight]="stepsProgress[3]" (click)="goToStepType(4)">
        STEP 4<div class="stepSubLabel">Confirmation</div>
        <img src="assets/img/{{ clientID }}/progresslight.png" />
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>

  <div class="toolBoxContainer">
    <div class="toolboxLeft buttonPointer" (click)="toggleMoreDetails()">
      <div class="toolboxIcon"><img src="assets/img/{{ clientID }}/icons/toolbox.png" /></div>
      <div class="toolboxText">
        <div *ngIf="discountApplied == true">
          <div class="toolboxAmount"><span
              style="text-decoration: line-through;">{{ '$' + fullPrice.toFixed(2) }}</span>&nbsp;{{ '$' + currentPrice.toFixed(2) }}
          </div>
        </div>

        <div *ngIf="discountApplied == false">
          <div class="toolboxAmount">{{ '$' + currentPrice.toFixed(2) }}</div>
        </div>
        <div class="toolboxShowDetails">Show Details</div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="toolboxRight buttonPointer" (click)="toggleFAQ()">
      FAQ<img src="assets/img/{{ clientID }}/icons/faq.png" />
    </div>
    <div style="clear: both;"></div>
  </div>

  <!-- TRIAGE POPUP -->
  <div class="popupBlocker" *ngIf="showPopup"></div>
  <div class="popupContainer" *ngIf="showPopup">
    <div class="popupHeader">
      <div class="popupTitle">PLEASE NOTE</div>
      <div class="popupClose buttonPointer" (click)="closePopup()">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent" [innerHtml]="popupContent"></div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="closePopup()">Confirm</div>
      </div>
    </div>
  </div>
  <!-- END TRIAGE POPUP -->

  <!-- LOCKSMITH POPUP -->
  <div class="popupBlocker" *ngIf="showLocksmithPopup"></div>
  <div class="popupContainer" *ngIf="showLocksmithPopup">
    <div class="popupHeader">
      <div class="popupTitle">LOCKSMITH JOBS</div>
      <div class="popupClose buttonPointer" (click)="toggleLocksmithPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        <div class="commonJobItem" *ngFor="let job of commonLocksmithJobs">{{ job }}</div>
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleLocksmithPopup(false)">Close</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END LOCKSMITH POPUP -->

  <!-- HANDYMAN POPUP -->
  <div class="popupBlocker" *ngIf="showHandymanPopup"></div>
  <div class="popupContainer" *ngIf="showHandymanPopup">
    <div class="popupHeader">
      <div class="popupTitle" *ngIf="clientID != clients.AANZ">HANDYMAN JOBS</div>
      <div class="popupTitle" *ngIf="clientID == clients.AANZ">HANDYPERSON JOBS</div>
      <div class="popupClose buttonPointer" (click)="toggleHandymanPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        <div class="commonJobItem" *ngFor="let job of commonHandymanJobs">{{ job }}</div>
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleHandymanPopup(false)">Close</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END HANDYMAN POPUP -->

  <!-- CARPENTRY POPUP -->
  <div class="popupBlocker" *ngIf="showCarpentryPopup"></div>
  <div class="popupContainer" *ngIf="showCarpentryPopup">
    <div class="popupHeader">
      <div class="popupTitle">CARPENTRY JOBS</div>
      <div class="popupClose buttonPointer" (click)="toggleCarpentryPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        <div class="commonJobItem" *ngFor="let job of commonCarpentryJobs">{{ job }}</div>
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleCarpentryPopup(false)">Close</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END CARPENTRY POPUP -->

  <!-- STORM READINESS POPUP -->
  <div class="popupBlocker" *ngIf="showStormReadinessPopup"></div>
  <div class="popupContainer" *ngIf="showStormReadinessPopup">
    <div class="popupHeader">
      <div class="popupTitle">SERVICE INCLUSIONS AND EXCLUSIONS</div>
      <div class="popupClose buttonPointer" (click)="toggleStormReadinessPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        <div style="margin-bottom: 15px; font-weight: bold;">Storm Readiness Service includes:</div>
        <div style="text-align: left;">
          <ul>
            <li>Gutter cleaning and accessible downpipe clearing - 90 minutes for 2 technicians or up to 3 hours labour.</li>
            <li>Reporting on gutter and downpipe condition</li>
            <li>Removal of minor accessible dangerous/overhanging tree limbs or branches</li>
            <li>Recommendations for storm preparation in your yard</li>
            <li>A job completion report, showing before and after photographs, emailed within 3 working days</li>
          </ul>
        </div>
        <div style="margin-bottom: 15px; font-weight: bold;">Exclusions:</div>
        <div style="text-align: left;">
          <ul>
            <li>Extra labour where required will be charged out at $100 inc GST per hour.</li>
            <li>Rubbish removal. If required, a fee of $27.50 inc GST per cubic metre of debris may be applied on top of the $300 inc GST to dispose of rubbish.</li>
            <li>Drainage adequacy reporting (e.g. size, positioning of drains and downpipes)</li>
            <li>Reporting and removal of drainage blockages under ground level (e.g. storm drains)</li>
            <li>Clearing of majorly blocked downpipes that require specialist equipment (i.e. water jet)</li>
            <li>Reporting of any roof issues or adequacy of roof drainage.</li>
            <li>Repair of issues reported on drains and downpipes.</li>
            <li>Removal of any tree limbs inaccessible via ladder or roof or deemed unsafe by tradesperson.</li>
            <li>Removal of any medium or large tree limbs or branches.</li>
            <li>Repair or removal of any reported issues or recommendations for your yard.</li>
          </ul>
        </div>
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleStormReadinessPopup(false)">Close</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END STORM READINESS POPUP -->

  <!-- GO BACK POPUP -->
  <div class="popupBlocker" *ngIf="showGoBackPopup"></div>
  <div class="popupContainer" *ngIf="showGoBackPopup">
    <div class="popupHeader">
      <div class="popupTitle">PLEASE NOTE</div>
      <div class="popupClose buttonPointer" (click)="toggleGoBackPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        Are you sure you want to go back?<br /><br />This will cause you to lose your current booking progress.
      </div>
      <div class="popupButtons">
        <div class="standardButton doubleButtons buttonPointer" (click)="closeGoBackPopup(false)">NO</div>
        <div class="standardButton doubleButtons buttonPointer" (click)="closeGoBackPopup(true)">YES</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END GO BACK POPUP -->

  <!-- DELETE SERVICE POPUP -->
  <div class="popupBlocker" *ngIf="showDeleteServicePopup"></div>
  <div class="popupContainer" *ngIf="showDeleteServicePopup">
    <div class="popupHeader">
      <div class="popupTitle">PLEASE NOTE</div>
      <div class="popupClose buttonPointer" (click)="toggleDeleteServicePopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        Are you sure you want to remove this service?
      </div>
      <div class="popupButtons">
        <div class="standardButton doubleButtons buttonPointer" (click)="closeDeleteServicePopup(false)">NO</div>
        <div class="standardButton doubleButtons buttonPointer" (click)="closeDeleteServicePopup(true)">YES</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END DELETE SERVICE POPUP -->

  <!-- ADD SERVICES POPUP -->
  <div class="popupBlocker" *ngIf="showAddServicesPopup"></div>
  <div class="popupContainer" *ngIf="showAddServicesPopup">
    <div class="popupHeader">
      <div class="popupTitle">ADD SERVICES</div>
      <div class="popupClose buttonPointer" (click)="toggleAddServicesPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        <div *ngFor="let selector of addServicesList" class="addServicesContainer handymanDurationContainer greyBox buttonPointer selectServiceButton" [class.selected]="selector.selected" (click)="jobTypeSelectorClicked(selector)">
          <div class="typeIcon">
            <img src="assets/img/{{ clientID }}/icons/jobtypes/{{ selector.jobType.icon }}" />
          </div>
          <div class="typeName addServicesName">
            {{ selector.jobType.name }}
            <div class="handymanSubtext">{{ selector.jobType.inclusions }}</div>
          </div>
          <div style="clear: both;"></div>
        </div>
      </div>
      <div class="popupButtons">
        <div class="standardButton doubleButtons buttonPointer" (click)="toggleAddServicesPopup(false)">CANCEL</div>
        <div class="standardButton doubleButtons buttonPointer" (click)="addServicesFromPopup()">CONFIRM</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END ADD SERVICES POPUP -->
  
  <div class="stepTitle triageStepTitle" *ngIf="currentQuestion.jobTypeIndex != -100" [class.uppercaseLabel]="jobDataService.jobTypes[currentJobTypeIndex].name == 'HVAC'">{{ jobDataService.jobTypes[currentJobTypeIndex].name.toUpperCase() }}</div>
  <div class="stepTitle triageStepTitle" *ngIf="currentQuestion.jobTypeIndex == -100" [class.uppercaseLabel]="jobDataService.jobTypes[currentJobTypeIndex].name == 'HVAC'">YOUR {{ jobDataService.trade.name.toUpperCase() }} JOB</div>
  <div class="stepTitle triageStepTitle serviceCountTitle" *ngIf="jobDataService.jobTypes.length > 1 && currentQuestion.jobTypeIndex != -100">Service {{ (currentJobTypeIndex + 1) }} of {{ jobDataService.jobTypes.length }}</div>
  <div class="stepTitle triageStepTitle serviceCountTitle" *ngIf="jobDataService.jobTypes.length > 1 && currentQuestion.jobTypeIndex == -100">Booking Details</div>

  <div class="questionGenerator" *ngFor="let question of triageQuestions">
    <div class="questionContainer" id="questionContainer" *ngIf="question.show">

      <div [ngSwitch]="question.question.questionTypeID" style="text-align: center;">
        <!-- QUESTION BOX -->
        <div class="questionBox">
          <div class="stepDescription" [innerHtml]="question.question.description" style="text-align: justify;" *ngIf="jobDataService.trade.id == tradeTypes.StormReadiness"></div>
          <div class="stepDescription" [innerHtml]="question.question.description" *ngIf="jobDataService.trade.id != tradeTypes.StormReadiness"></div>

          <!-- Start Free Text -->
          <div *ngSwitchCase="questionTypes.FreeText">
            <textarea class="freeText" [(ngModel)]="question.answer" (change)="freeTextChange()"></textarea>
            <div *ngIf="jobDataService.trade.id == tradeTypes.Locksmith"
              class="jobTypeContainer greyBox buttonPointer handymanButton" (click)="toggleLocksmithPopup(true)">Click
              here to see common Locksmith jobs</div>
          </div>
          <!-- End Free Text -->

          <!-- Start Booking Windows -->
          <div *ngSwitchCase="questionTypes.BookingWindows">
            <div class="bookingWindow greyBox buttonPointer" (click)="initBookingOption(0)">
              <div class="bookingWindowText">
                {{ bookingWindows[0].date == null ? 'Booking Window One' : (bookingWindows[0].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[0].time.from }}
              </div>
              <div class="bookingWindowIcon"><img
                  src="assets/img/{{ clientID }}/icons/calendar.png" /></div>
              <div style="clear: both;"></div>
            </div>

            <div class="bookingWindow greyBox buttonPointer" (click)="initBookingOption(1)">
              <div class="bookingWindowText">
                {{ bookingWindows[1].date == null ? 'Booking Window Two' : (bookingWindows[1].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[1].time.from }}
              </div>
              <div class="bookingWindowIcon"><img
                  src="assets/img/{{ clientID }}/icons/calendar.png" /></div>
              <div style="clear: both;"></div>
            </div>

            <div class="outOfHoursDisclaimer" *ngIf="outOfHoursEnabled">
              * Highlighted dates and times will be charged at an out of hours rate.
            </div>
          </div>
          <!-- End Booking Windows -->

          <!-- Start Contact Details -->
          <div *ngSwitchCase="questionTypes.ContactDetails" class="contactContainer lighterBox boxNoFill">
            <div class="contactTitle" style="margin-top: 0px !important;">Booking Details</div>
            
            <label>Name</label>
            <input type="text" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.customerContact.contactName" />
            
            <label>Email Address</label>
            <input type="email" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.customerContact.contactEmail" />
            
            <label>Confirm Email Address</label>
            <input type="email" (change)="validateOnChange()" [(ngModel)]="confirmEmail" />
            
            <label *ngIf="clientID != clients.AANZ">Phone Number</label>
            <label *ngIf="clientID == clients.AANZ">Mobile Number (if you don’t have a mobile please enter your landline number however you will not receive our text confirmations)</label>
            <input type="tel" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.customerContact.contactPhone" />
            
            <div *ngIf=showMembershipInput()>
              <label [innerHtml]="jobDataService.capability.memberShipConfig.membershipName"></label>
              <input type="text" [(ngModel)]="jobDataService.capability.memberShipConfig.memberNumber" />
            </div>

            <div class="contactTitle">Service Address</div>
            <label>Street Address</label>
            <label *ngIf="clientID == clients.AANZ">Please start typing your address and our booking platform will locate this for you</label>
            <input type="text" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.streetAddress" autocorrect="off" autocapitalize="off" spellcheck="false" #address />
            
            <label>Unit</label>
            <label *ngIf="clientID == clients.AANZ">If you live in a unit please add your unit number below</label>
            <input type="text" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.streetUnit" />
            
            <label>Suburb</label>
            <input type="text" (change)="validateOnChange()" disabled [(ngModel)]="jobDataService.jobData.suburbName" />
            
            <label>Post Code</label>
            <input type="text" (change)="validateOnChange()" disabled [(ngModel)]="jobDataService.jobData.postcode" />

            <div class="contactTitle">Onsite Contact Details</div>
            <label for="chkSameAsContactDetails"><input type="checkbox" (change)="validateOnChange()"
              id="chkSameAsContactDetails" [(ngModel)]="siteContactSameAsBooking" /> Same as booking details</label>
            
            <label *ngIf="!siteContactSameAsBooking">Name</label>
            <input *ngIf="!siteContactSameAsBooking" type="text" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.siteContact.contactName" />
            
            <label *ngIf="!siteContactSameAsBooking && clientID != clients.AANZ">Phone Number</label>
            <label *ngIf="!siteContactSameAsBooking && clientID == clients.AANZ">Mobile Number (if you don’t have a mobile please enter your landline number however you will not receive our text confirmations)</label>
            <input *ngIf="!siteContactSameAsBooking" type="tel" (change)="validateOnChange()" [(ngModel)]="jobDataService.jobData.siteContact.contactPhone" />

            <label>
              <input type="checkbox" (change)="validateOnChange()" [(ngModel)]="agreeTermsPrivacy"
                id="chkTermsPrivacy" /> I have read and agree with the <span (click)="openTermsPopup()"
                class="termsPrivacySpan buttonPointer">{{(clientID != clients.AANZ ? 'GHR Terms' : 'AA Home Terms')}}</span> & <span (click)="openPrivacyPopup()"
                class="termsPrivacySpan buttonPointer">Privacy Policy</span>
            </label>
            
            <label for="chkMarketing" *ngIf="clientID == clients.AANZ">
              <input id="chkMarketing" type="checkbox" *ngIf="clientID == clients.AANZ" [(ngModel)]="marketingOptIn" /> I would like to receive exclusive offers and information for my home.</label>
            
            <label class="agreeEstimate" for="chkAgreeEstimate">
              <input type="checkbox" (change)="validateOnChange()" [(ngModel)]="agreePriceEstimate" id="chkAgreeEstimate" /> I acknowledge that the cost provided is an estimated cost
            </label>

            <div *ngIf="metaData.whereDidYouHearAboutUsData.length > 0">
              <label>Where did you hear about {{ productName }}?</label>
              <div class="fullWidth">
                <select [(ngModel)]="whereDidYouHearAboutUs">
                  <option *ngFor="let reason of metaData.whereDidYouHearAboutUsData" value="{{reason}}">{{ reason }}</option>
                </select>
              </div>
            </div>

          </div>
          <!-- End Contact Details -->

          <!-- Start Confirmation -->
          <div *ngSwitchCase="questionTypes.Confirmation" class="confirmContainer">
            <div class="confirmBox boxNoFill lighterBox pricingBox" [class.discountPadding]="discountApplied == true">
              <div class="confirmIcon"><img src="assets/img/{{ clientID }}/home/tradetypes/{{ jobDataService.trade.icon }}" /></div>
              <div class="confirmInfo">
                <div class="confirmTitle" [class.uppercaseLabel]="jobDataService.trade.name == 'HVAC'">{{ jobDataService.trade.name.toUpperCase() }}</div>
                
                <div class="jobTypeInfoContainer" *ngFor="let jobType of jobDataService.jobTypes">
                    <div class="confirmSubTitle">{{ jobType.name }}</div>
  
                    <div class="confirmAnswer">
                      <div class="confirmAnswerText">Quantity:</div>
                      <div class="confirmAnswerEdit">x{{ jobType.currentQuantity }} ({{ jobType.estimatedDuration }}hrs)</div>
                      <div style="clear: both;"></div>
                    </div>
  
                    <div class="confirmAnswer">
                        <div class="confirmAnswerText">Estimate:</div>
                        <div class="confirmAnswerEdit">{{ '$' + jobType.estimatedFullPrice.toFixed(2) }}</div>
                        <div style="clear: both;"></div>
                      </div>
                  </div>
  
                  <div class="confirmSubTitle">Total Estimated Duration: {{ estimatedDuration }}hrs</div>

              </div>
              <div style="clear: both;"></div>

              <div class="confirmPrice">
                <div *ngIf="discountApplied == true">
                  <div class="confirmPriceTitle">
                    {{ jobDataService.trade.id == tradeTypes.HomeInspections ? 'TOTAL PRICE' : 'ESTIMATED PRICE' }}
                  </div>
                  <div class="confirmPriceText" style="text-decoration: line-through;">{{ '$' + fullPrice.toFixed(2) }}
                  </div>
                  <div style="clear: both;"></div>

                  <div class="confirmPriceTitle">{{ discountText }}</div>
                  <div class="confirmPriceText">{{ '$' + currentPrice.toFixed(2) }}</div>
                  <div style="clear: both;"></div>
                </div>

                <div *ngIf="discountApplied == false">
                  <div class="confirmPriceTitle">
                    {{ jobDataService.trade.id == tradeTypes.HomeInspections ? 'TOTAL PRICE' : 'ESTIMATED PRICE' }}
                  </div>
                  <div class="confirmPriceText">{{ '$' + currentPrice.toFixed(2) }}</div>
                  <div style="clear: both;"></div>
                </div>

                <div class="pricingDisclaimer">All prices include GST.<span *ngIf="outOfHoursPriceApplied"> An out of
                    hours rate has been applied to this booking.</span></div>
                <div class="pricingDisclaimer" *ngIf="clientID == clients.AANZ">* AA Members, AA Insurance home and landlord policy holders, AA Home Response and AA Home Response Plus subscribers, all receive a 10% discount.</div>
              </div>
            </div>

            <div class="confirmBox boxNoFill lighterBox">
              <div class="confirmIcon"><img
                  src="assets/img/{{ clientID }}/icons/calendar_green.png" /></div>
              <div class="confirmInfo">
                <div class="confirmTitle">PREFERRED BOOKING</div>
                <div class="confirmSubTitle">Booking Option One</div>
                <div class="confirmAnswer">
                  <div class="confirmAnswerText">
                    {{ bookingWindows[0].date == null ? '' : (bookingWindows[0].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[0].time.from }}
                  </div>
                  <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(2)">edit</div>
                  <div style="clear: both;"></div>
                </div>
                <div class="confirmSubTitle">Booking Option Two</div>
                <div class="confirmAnswer">
                  <div class="confirmAnswerText">
                    {{ bookingWindows[1].date == null ? '' : (bookingWindows[1].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[1].time.from }}
                  </div>
                  <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(2)">edit</div>
                  <div style="clear: both;"></div>
                </div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="confirmBox boxNoFill lighterBox">
              <div class="confirmIcon"><img
                  src="assets/img/{{ clientID }}/icons/contactdetails.png" /></div>
              <div class="confirmInfo">
                <div class="confirmTitle">CONTACT DETAILS</div>
                <div class="confirmSubTitle">Booking Details</div>
                <div class="confirmAnswer">
                  <div class="confirmAnswerText">
                    {{ jobDataService.jobData.customerContact.contactName }}<br />
                    {{ jobDataService.jobData.streetUnit.length > 0 ? jobDataService.jobData.streetUnit + '/' : '' }}{{ jobDataService.jobData.streetAddress }}&nbsp;{{ jobDataService.jobData.suburbName }}&nbsp;{{ jobDataService.jobData.postcode }}<br />
                    {{ jobDataService.jobData.customerContact.contactEmail }}<br />
                    {{ jobDataService.jobData.customerContact.contactPhone }}
                  </div>
                  <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(3)">edit</div>
                </div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Confirmation -->

          <!-- Start Quantity -->
          <div *ngSwitchCase="questionTypes.Quantity" class="quantityContainer boxNoFill lighterBox">
            <div *ngIf="jobDataService.jobTypes[currentJobTypeIndex].standardModel">
              <div class="quantityIcon" *ngIf="jobDataService.jobTypes[currentJobTypeIndex].standardModel"><img src="assets/img/{{ clientID }}/icons/{{ jobDataService.jobTypes[currentJobTypeIndex].partsIconBase }}" /></div>
              <div class="modelTitle">{{ jobDataService.jobTypes[currentJobTypeIndex].partsNameBase.toUpperCase() }}</div>
              <div class="modelInfo">{{ jobDataService.jobTypes[currentJobTypeIndex].partsInfoBase }}</div>
              <!-- <div class="modelInfo">{{ '$' + currentBasePartsCost.toFixed(2) }}</div> -->
            </div>

            <div *ngIf="!jobDataService.jobTypes[currentJobTypeIndex].standardModel">
              <div class="quantityIcon" *ngIf="!jobDataService.jobTypes[currentJobTypeIndex].standardModel"><img src="assets/img/{{ clientID }}/icons/{{ jobDataService.jobTypes[currentJobTypeIndex].partsIconPremium }}" /></div>
              <div class="modelTitle">{{ jobDataService.jobTypes[currentJobTypeIndex].partsNamePremium.toUpperCase() }}</div>
              <div class="modelInfo">{{ jobDataService.jobTypes[currentJobTypeIndex].partsInfoPremium }}</div>
              <!-- <div class="modelInfo">{{ '$' + currentPremiumPartsCost.toFixed(2) }}</div> -->
            </div>

            <div class="modelInfo quantityDiscountInfo">If you select more than one item you will receive a discount.
            </div>
            <div class="quantityInputContainer">
              <div class="quantityButton buttonPointer" (click)="updateNumAnswer(question, -1)">
                <</div> <div class="quantityInput"><input type="text" disabled [(ngModel)]="question.answer" />
              </div>
              <div class="quantityButton buttonPointer" (click)="updateNumAnswer(question, 1)">></div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Quantity -->

          <!-- Start 2 Options -->
          <div *ngSwitchCase="questionTypes.TwoOptions">
            <div class="optionBox boxNoFill lighterBox">
              <div style="float: left;">
                <img class="optionTypeIcon"
                  src="assets/img/{{ clientID }}/icons/{{ question.question.options[0].icon }}" />
              </div>
              <div style="float: left;" class="optionInfoBox">
                <div>{{ question.question.options[0].name.toUpperCase() }}</div>
                <div class="optionSelect standardButton buttonPointer"
                  [class.selected]="question.answer == question.question.options[0].id"
                  (click)="setAnswer(question, question.question.options[0].id, question.question.options[0].fullAnswer)">
                  SELECT</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="optionBox boxNoFill lighterBox">
              <div style="float: left;">
                <img class="optionTypeIcon"
                  src="assets/img/{{ clientID }}/icons/{{ question.question.options[1].icon }}" />
              </div>
              <div style="float: left;" class="optionInfoBox">
                <div>{{ question.question.options[1].name.toUpperCase() }}</div>
                <div class="optionSelect standardButton buttonPointer"
                  [class.selected]="question.answer == question.question.options[1].id"
                  (click)="setAnswer(question, question.question.options[1].id, question.question.options[1].fullAnswer)">
                  SELECT</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End 2 Options -->

          <!-- Start Select Tap Model -->
          <div *ngSwitchCase="questionTypes.SelectTapModel" class="selectModelContainer">
            <div class="modelContainer boxNoFill lighterBox">
              <img src="assets/img/{{ clientID }}/icons/jobtypes/mixer.png" />
              <div class="modelTitle">{{ jobDataService.jobTypes[currentJobTypeIndex].partsNameBase.toUpperCase() }}</div>
              <div class="modelInfo">{{ jobDataService.jobTypes[currentJobTypeIndex].partsInfoBase }}</div>
              <div class="modelInfo">Supply & Install<br />{{ '$' + (jobDataService.jobTypes[currentJobTypeIndex].currentBasePartsCost + (jobDataService.jobTypes.length > 1 ? (currentJobTypeIndex == 0 ? jobDataService.trade.calloutFee : 0) : jobDataService.trade.calloutFee) + (jobDataService.trade.hourlyRate * jobDataService.jobTypes[currentJobTypeIndex].attendanceHours)).toFixed(2) }}</div>
              <div class="optionSelect standardButton buttonPointer autoCenter" [class.selected]="jobDataService.jobTypes[currentJobTypeIndex].standardModel" (click)="selectModel('standard', question)">SELECT</div>
            </div>
            <div class="modelContainer boxNoFill lighterBox" style="margin-left: 5%;">
              <img src="assets/img/{{ clientID }}/icons/premiumtap.png" />
              <div class="modelTitle">{{ jobDataService.jobTypes[currentJobTypeIndex].partsNamePremium.toUpperCase() }}</div>
              <div class="modelInfo">{{ jobDataService.jobTypes[currentJobTypeIndex].partsInfoPremium }}</div>
              <div class="modelInfo">Supply & Install<br />{{ '$' + (jobDataService.jobTypes[currentJobTypeIndex].currentPremiumPartsCost + (jobDataService.jobTypes.length > 1 ? (currentJobTypeIndex == 0 ? jobDataService.trade.calloutFee : 0) : jobDataService.trade.calloutFee) + (jobDataService.trade.hourlyRate * jobDataService.jobTypes[currentJobTypeIndex].attendanceHours)).toFixed(2) }}</div>
              <div class="optionSelect standardButton buttonPointer autoCenter" [class.selected]="!jobDataService.jobTypes[currentJobTypeIndex].standardModel" (click)="selectModel('premium', question)">SELECT</div>
            </div>
            <div style="clear: both;"></div>
          </div>
          <!-- End Select Tap Model -->

          <!-- Start Numeric -->
          <div *ngSwitchCase="questionTypes.Numeric" class="quantityContainer boxNoFill lighterBox">
            <div class="modelTitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>
            <div class="quantityInputContainer">
              <div class="quantityButton buttonPointer" (click)="updateNumAnswer(question, -1)">
                <</div> <div class="quantityInput"><input type="text" disabled [(ngModel)]="question.answer" />
              </div>
              <div class="quantityButton buttonPointer" (click)="updateNumAnswer(question, 1)">></div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Numeric -->

          <!-- Start Yes/No -->
          <div *ngSwitchCase="questionTypes.YesNo" class="quantityContainer boxNoFill lighterBox">
            <div class="modelTitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>
            <div class="yesNoButtonContainer">
              <div class="optionSelect standardButton buttonPointer yesNoButton"
                [class.selected]="question.answer == 'YES'" (click)="setAnswer(question, 'YES')">YES</div>
              <div class="optionSelect standardButton buttonPointer yesNoButton"
                [class.selected]="question.answer == 'NO'" (click)="setAnswer(question, 'NO')">NO</div>
            </div>
          </div>
          <!-- End Yes/no -->

          <!-- Start Simple 2 Options -->
          <div *ngSwitchCase="questionTypes.SimpleTwoOptions" class="quantityContainer boxNoFill lighterBox">
            <div class="modelTitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>
            <div class="yesNoButtonContainer">
              <div class="optionSelect standardButton buttonPointer yesNoButton"
                [class.selected]="question.answer == question.question.options[0].name"
                (click)="setAnswer(question, question.question.options[0].name)">{{ question.question.options[0].name }}
              </div>
              <div class="optionSelect standardButton buttonPointer yesNoButton"
                [class.selected]="question.answer == question.question.options[1].name"
                (click)="setAnswer(question, question.question.options[1].name)">{{ question.question.options[1].name }}
              </div>
            </div>
          </div>
          <!-- End Simple 2 Options -->

          <!-- Start Handyman Duration -->
          <div *ngSwitchCase="questionTypes.HandymanDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Picture Hanging, Change Lightbulbs, Hooks</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '2'"
              (click)="setAnswer(question, 2, '2 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/2hour.png" />
              </div>
              <div class="typeName">
                Medium Jobs Such As;
                <div class="handymanSubtext">Furniture Assembly, Multiple Small Items</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Major Plastering Work, Multiple Odd Jobs, Balcony / Deck Staining</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                <div *ngIf="clientID != clients.AANZ">I'm Not Sure</div>
                <div *ngIf="clientID == clients.AANZ">Not Sure</div>
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div *ngIf="jobDataService.trade.id == tradeTypes.Handyman && clientID != clients.AANZ"
              class="jobTypeContainer greyBox buttonPointer handymanButton"
              (click)="toggleHandymanPopup(true)">Click here to see common Handyman jobs</div>

            <div *ngIf="jobDataService.trade.id == tradeTypes.Handyman && clientID == clients.AANZ"
              class="jobTypeContainer greyBox buttonPointer handymanButton"
              (click)="toggleHandymanPopup(true)">Click here to see common Handyperson jobs</div>
          </div>
          <!-- End Handyman Duration -->

          <!-- Start Job Notes & Photos -->
          <div *ngSwitchCase="questionTypes.JobNotesPhotos" class="generalContainer">
            <div *ngIf="jobDataService.jobTypes[currentJobTypeIndex].showGeneralNotes">
              <div class="modelTitle">General Notes</div>
              <textarea class="freeText generalNotes" [(ngModel)]="question.answer"
                (change)="freeTextChange()"></textarea>
              <br /><br />
            </div>
            <div class="modelTitle" [class.generalNotesTitle]="jobDataService.jobTypes[currentJobTypeIndex].showGeneralNotes">Job Photos</div>
            <div class="bookingPhotoContainer">
              <div *ngFor="let photo of bookingPhotos" class="bookingPhoto">
                <img src="{{ photo.base64 }}" />
                <div class="remove buttonPointer" (click)="removeBookingPhoto(photo)">Remove</div>
              </div>
              <div style="clear: both;"></div>
            </div><br />
            <input type="file" accept="image/png, image/jpeg" name="bookingPhotos" id="bookingPhotos"
              class="fileSelector" multiple (change)="bookingPhotoSelected($event.target, $event.target.files)" />
            <label for="bookingPhotos" class="standardButton buttonPointer">Choose photos</label><br />
            <label class="photoRules">You can upload up to 5 photos in .png or .jpg format.</label>
          </div>
          <!-- End Job Notes & Photos -->

          <!-- Start Carpentry Duration -->
          <div *ngSwitchCase="questionTypes.CarpentryDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Replace Tiles, Install Door Handles, Install Hooks</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '2'"
              (click)="setAnswer(question, 2, '2 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/2hour.png" />
              </div>
              <div class="typeName">
                Medium Jobs Such As;
                <div class="handymanSubtext">Wall Repairs, Door Repairs, Multiple Small Items</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Skirting Board Repairs, Door Installation, Kitchen Cabinetry, Deck/Patio
                  Repairs</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div *ngIf="jobDataService.trade.id == tradeTypes.Carpentry"
              class="jobTypeContainer greyBox buttonPointer handymanButton" (click)="toggleCarpentryPopup(true)">Click
              here to see common Carpentry jobs</div>
          </div>
          <!-- End Carpentry Duration -->

          <!-- Start Plastering Duration -->
          <div *ngSwitchCase="questionTypes.PlasteringDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Area under 4m&#178;<br />(Painting is not included in price)</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Required area 4m&#178; or over<br />(Painting is not included in price)</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Plastering Duration -->

          <!-- Start Painting Duration -->
          <div *ngSwitchCase="questionTypes.PaintingDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">1 Room<br />Area under 20m&#178;</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Multiple Rooms<br /> Areas 20m&#178; or over</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Painting Duration -->

          <!-- Start HVAC Duration -->
          <div *ngSwitchCase="questionTypes.HVACDuration" class="quantityContainer boxNoFill lighterBox">
              <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

              <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'Gas Heater Service'"
                (click)="setAnswer(question, 1, 'Gas Heater Service')">
                <div class="typeName">
                  Gas Heater Service
                </div>
                <div style="clear: both;"></div>
              </div>

              <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'Split System Air Conditioner Service'"
                (click)="setAnswer(question, 1, 'Split System Air Conditioner Service')">
                <div class="typeName">
                    Split System Air Conditioner Service
                </div>
                <div style="clear: both;"></div>
              </div>

              <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'Ducted Evaporative Cooler Service'"
                (click)="setAnswer(question, 1, 'Ducted Evaporative Cooler Service')">
                <div class="typeName">
                    Ducted Evaporative Cooler Service
                </div>
                <div style="clear: both;"></div>
              </div>

              <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'Refrigerated Ducted Air Conditioner Service'"
                (click)="setAnswer(question, 1, 'Refrigerated Ducted Air Conditioner Service')">
                <div class="typeName">
                    Refrigerated Ducted Air Conditioner Service
                </div>
                <div style="clear: both;"></div>
              </div>

              <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
                <div class="typeIcon">
                  <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
                </div>
                <div class="typeName">
                  I'm Not Sure
                  <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
                </div>
                <div style="clear: both;"></div>
              </div>
          </div>
          <!-- End HVAC Duration -->

          <!-- Start RoofPlumbing Duration -->
          <div *ngSwitchCase="questionTypes.RoofPlumbingDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Minor Works Such As;
                <div class="handymanSubtext">Roof leak investigations, tile replacements, silicone works, gutter cleaning</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Major Works Such As;
                <div class="handymanSubtext">Gutter replacements, custom made flashings, custom box gutters, roof painting, down pipe installations</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End RoofPlumbing Duration -->

          <!-- Start Fencing Duration -->
          <div *ngSwitchCase="questionTypes.FencingDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Minor fence repairs</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Paling instalments, fence replacements, new fences</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Fencing Duration -->

          <!-- Start BrickLaying Duration -->
          <div *ngSwitchCase="questionTypes.BrickLayingDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Brick replacements</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Outdoor area builds, renovation works, external BBQ builds</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End BrickLaying Duration -->

          <!-- Start BrickPaving Duration -->
          <div *ngSwitchCase="questionTypes.BrickPavingDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Paver replacements, installation of brick</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/4hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Garden path installs, driveways and patio areas</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End BrickPaving Duration -->

          <!-- Start Antennas Duration -->
          <div *ngSwitchCase="questionTypes.AntennasDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Antenna repairs, Antenna amplifier installations</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '3'"
              (click)="setAnswer(question, 3, '3 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/3hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">TV antenna installation, TV reception problems</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Antennas Duration -->

          <!-- Start Storm Readiness Text -->
          <div *ngSwitchCase="questionTypes.StormReadinessText">
            <textarea class="freeText" [(ngModel)]="question.answer" (change)="freeTextChange()"></textarea>
            <div class="jobTypeContainer greyBox buttonPointer handymanButton" (click)="toggleStormReadinessPopup(true)">
                Service inclusions and exclusions
            </div>
          </div>
          <!-- End Storm Readiness Text -->

          <!-- Start Tiling Duration -->
          <div *ngSwitchCase="questionTypes.TilingDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Tile replacements, tiling of small areas</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/3hour.png" />
              </div>
              <div class="typeName">
                Medium Jobs Such As;
                <div class="handymanSubtext">Grouting, tiling of areas, water-proofing</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Tiling Duration -->

          <!-- Painting Whole Apartment -->
          <div *ngSwitchCase="questionTypes.PaintingWholeApartment" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1"
              (click)="setAnswer(question, 1, '1 bedroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                One bedroom
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 2"
              (click)="setAnswer(question, 2, '2 bedroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Two bedrooms
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 3"
              (click)="setAnswer(question, 3, '3 bedroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Three bedrooms
              </div>
              <div style="clear: both;"></div>
            </div>

          </div>
          <!-- End Painting Whole Apartment -->

          <!-- Painting Single Room -->
          <div *ngSwitchCase="questionTypes.PaintingSingleRoom" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1"
              (click)="setAnswer(question, 1, 'Bedroom (small)')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Bedroom (small)
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 2"
              (click)="setAnswer(question, 2, 'Bedroom (large)')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Bedroom (large)
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 3"
              (click)="setAnswer(question, 3, 'Dining room')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Dining room
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 4"
              (click)="setAnswer(question, 4, 'Living room (small)')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Living room (small)
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 5"
              (click)="setAnswer(question, 5, 'Living room (large)')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Living room (large)
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 6"
              (click)="setAnswer(question, 6, 'Kitchen')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Kitchen
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 7"
              (click)="setAnswer(question, 7, 'Bathroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Bathroom
              </div>
              <div style="clear: both;"></div>
            </div>

          </div>
          <!-- End Painting Single Room -->

          <!-- Start Gardening Duration -->
          <div *ngSwitchCase="questionTypes.GardeningDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1 && question.fullAnswer != 'I\'m not sure'"
              (click)="setAnswer(question, 1, '1 hour')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/1hour.png" />
              </div>
              <div class="typeName">
                Small Jobs Such As;
                <div class="handymanSubtext">Lawn mowing, weeding, plat pruning, general maintenance</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == '4'"
              (click)="setAnswer(question, 4, '4 hours')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/3hour.png" />
              </div>
              <div class="typeName">
                Large Jobs Such As;
                <div class="handymanSubtext">Reseeding, grass laying, reticulation repairs/installs</div>
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.fullAnswer == 'I\'m not sure'"
              (click)="setAnswer(question, 1, 'I\'m not sure')">
              <div class="typeIcon">
                <img src="assets/img/{{ clientID }}/icons/handyman/notsure.png" />
              </div>
              <div class="typeName">
                I'm Not Sure
                <div class="handymanSubtext">When we call to confirm your booking we'll give you an updated estimate</div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <!-- End Gardening Duration -->

          <!-- Gutter Cleaning -->
          <div *ngSwitchCase="questionTypes.GutterCleaningDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1"
              (click)="setAnswer(question, 1, '2 bedroom/1 bathroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                2 bedroom
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 2"
              (click)="setAnswer(question, 2, '3 bedroom/2 bathroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                3 bedroom
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 3"
              (click)="setAnswer(question, 3, '4 bedroom/2 bathroom')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                4 bedroom
              </div>
              <div style="clear: both;"></div>
            </div>

          </div>
          <!-- End Gutter Cleaning -->

          <!-- Solar Cleaning -->
          <div *ngSwitchCase="questionTypes.SolarCleaningDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1"
              (click)="setAnswer(question, 1, '10 and under')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                1 to 10
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 2"
              (click)="setAnswer(question, 2, '15 and under')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                11 to 15
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 3"
              (click)="setAnswer(question, 3, '20 and under')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                16 to 20
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 4"
              (click)="setAnswer(question, 4, '25 and under')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                21 to 25
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 5"
              (click)="setAnswer(question, 5, '30 and under')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                26 to 30
              </div>
              <div style="clear: both;"></div>
            </div>

          </div>
          <!-- End Solar Cleaning -->

          <!-- Air Conditioning Installs -->
          <div *ngSwitchCase="questionTypes.AirConditioningDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1"
              (click)="setAnswer(question, 1, 'Supply and install Panasonic 3.2kW Reverse Cycle Split System & Air Purifier')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Supply and install Panasonic 3.2kW Reverse Cycle Split System & Air Purifier
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 2"
              (click)="setAnswer(question, 2, 'Supply and install Panasonic 4.3kW Reverse Cycle Split System & Air Purifier')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Supply and install Panasonic 4.3kW Reverse Cycle Split System & Air Purifier
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 3"
              (click)="setAnswer(question, 3, 'Supply and install Panasonic 7.1kW (Cooling) 8.0kW heating Reverse Cycle Split System & Air Purifier')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Supply and install Panasonic 7.1kW (Cooling) 8.0kW heating Reverse Cycle Split System & Air Purifier
              </div>
              <div style="clear: both;"></div>
            </div>

          </div>
          <!-- End Air Conditioning Installs -->

          <!-- Air Conditioning Installs -->
          <div *ngSwitchCase="questionTypes.SmokeAlarmDuration" class="quantityContainer boxNoFill lighterBox">
            <div class="handymanSubtitle" *ngIf="question.question.data.length > 0">{{ question.question.data }}</div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 1"
              (click)="setAnswer(question, 1, 'Supply and install 1 hardwired smoke alarm & perform complimentary RCD Maintenance Check')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Supply and install 1 hardwired smoke alarm & perform complimentary RCD Maintenance Check
              </div>
              <div style="clear: both;"></div>
            </div>

            <div class="handymanDurationContainer greyBox buttonPointer" [class.selected]="question.answer == 2"
              (click)="setAnswer(question, 2, 'Supply and install 2 hardwired smoke alarms & perform complimentary RCD Maintenance Check')">
              <div class="typeIcon">
              </div>
              <div class="typeName">
                Supply and install 2 hardwired smoke alarms & perform complimentary RCD Maintenance Check
              </div>
              <div style="clear: both;"></div>
            </div>

          </div>
          <!-- End Air Conditioning Installs -->

          <!-- Desktop back and next buttons -->
          <div *ngIf="!showBackToQuestionsButtons" class="desktopBackNext">
            <div class="wizardButtonContainer" *ngIf="currentQuestion.question.showStandardButtons">
              <div class="wizardButtonBack buttonPointer" (click)="prevStep(currentQuestion.index)">
                {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
              <div class="wizardButtonNext buttonPointer" [class.valid]="currentQuestion.valid"
                (click)="nextStep(currentQuestion.index)">NEXT ></div>
              <div style="clear: both;"></div>
            </div>

            <div class="wizardButtonContainer" *ngIf="currentQuestion.question.showConfirmButtons">
              <div class="wizardButtonBack buttonPointer" (click)="prevStep(currentQuestion.index)">
                {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
              <div class="wizardButtonNext valid buttonPointer" (click)="closeEndPopup()">CONFIRM</div>
              <div style="clear: both;"></div>
            </div>
          </div>

          <!-- Desktop buttons for going back to the current question from intermediary windows -->
          <div *ngIf="showBackToQuestionsButtons">
            <div class="wizardButtonContainer" *ngIf="!showBookingWindow">
              <div class="wizardButtonBack buttonPointer" (click)="goToStep(currentQuestion.index)">
                {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
              <div style="clear: both;"></div>
            </div>

            <div class="wizardButtonContainer" *ngIf="showBookingWindow">
              <div class="wizardButtonBack buttonPointer" (click)="closeBookingOption()">
                {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
              <div class="wizardButtonNext buttonPointer" [class.valid]="currentBookingOptionValid"
                (click)="confirmBookingOption()">CONFIRM</div>
              <div style="clear: both;"></div>
            </div>
          </div>

        </div>
        <!-- END QUESTION BOX -->

        <!-- Desktop Triage Sidebar -->
        <div class="triageSidebar" *ngIf="question.question.questionTypeID != 4">
          <div class="confirmBox boxNoFill lighterBox pricingBox" [class.discountPadding]="discountApplied == true">
            <div class="confirmIcon">
              <img src="assets/img/{{ clientID }}/home/tradetypes/{{ jobDataService.trade.icon }}" />
            </div>
            <div class="confirmInfo">
              <div class="confirmTitle" [class.uppercaseLabel]="jobDataService.trade.name == 'HVAC'">{{ jobDataService.trade.name.toUpperCase() }}</div>
              
              <div class="jobTypeInfoContainer" *ngFor="let jobType of jobDataService.jobTypes">
                <div class="confirmSubTitle jobTitleContainer">
                  <div class="jobTitleLeft" [class.selected]="question.jobTypeIndex == jobType.jobTypeIndex" (click)="jumpToJobType(jobType)">{{ jobType.name }}</div>
                  <div class="jobTitleRight" *ngIf="bundlingEnabled == true"><fa-icon class="deleteService buttonPointer" [icon]="faTrash" (click)="openDeleteServicePopup(jobType)"></fa-icon></div>
                  <div style="clear: both;"></div>
                </div>
                <div class="confirmAnswer">
                    <div class="confirmAnswerText">Quantity:</div>
                    <div class="confirmAnswerEdit">x{{ jobType.currentQuantity }} ({{ jobType.estimatedDuration }}hrs)</div>
                    <div style="clear: both;"></div>
                </div>
                <div class="confirmAnswer">
                    <div class="confirmAnswerText">Estimate:</div>
                    <div class="confirmAnswerEdit">{{ '$' + jobType.estimatedFullPrice.toFixed(2) }}</div>
                    <div style="clear: both;"></div>
                </div>
              </div>
              <div class="confirmSubTitle">Total Estimated Duration: {{ estimatedDuration }}hrs</div>
            </div>
            <div style="clear: both;"></div>
            <div class="confirmPrice" [class.bundling]="(jobDataService.trade.id == tradeTypes.Plumbing || jobDataService.trade.id == tradeTypes.Electrical) && bundlingEnabled == true">
              <div class="addServicesButton" *ngIf="(jobDataService.trade.id == tradeTypes.Plumbing || jobDataService.trade.id == tradeTypes.Electrical) && bundlingEnabled == true" (click)="toggleAddServicesPopup(true)">
                ADD SERVICES
              </div>
              <div *ngIf="discountApplied == true">
                <div class="confirmPriceTitle">
                    {{ jobDataService.trade.id == tradeTypes.HomeInspections ? 'TOTAL PRICE' : 'ESTIMATED PRICE' }}
                </div>
                <div class="confirmPriceText" style="text-decoration: line-through;">{{ '$' + fullPrice.toFixed(2) }}</div>
                <div style="clear: both;"></div>
                <div class="confirmPriceTitle">{{ discountText }}</div>
                <div class="confirmPriceText">{{ '$' + currentPrice.toFixed(2) }}</div>
                <div style="clear: both;"></div>
              </div>
              <div *ngIf="discountApplied == false">
                <div class="confirmPriceTitle">
                    {{ jobDataService.trade.id == tradeTypes.HomeInspections ? 'TOTAL PRICE' : 'ESTIMATED PRICE' }}
                </div>
                <div class="confirmPriceText">{{ '$' + currentPrice.toFixed(2) }}</div>
                <div style="clear: both;"></div>
              </div>
              <div class="pricingDisclaimer">All prices include GST.<span *ngIf="outOfHoursPriceApplied"> An out of
                hours rate has been applied to this booking.</span>
              </div>
              <div class="pricingDisclaimer" *ngIf="clientID == clients.AANZ">* AA Members, AA Insurance home and landlord policy holders, AA Home Response and AA Home Response Plus subscribers, all receive a 10% discount.</div>
            </div>
          </div>
          <!-- End Services & Pricing -->
          <div class="confirmBox boxNoFill lighterBox">
            <div class="confirmIcon">
              <img src="assets/img/{{ clientID }}/icons/calendar_green.png" />
            </div>
            <div class="confirmInfo">
                <div class="confirmTitle">PREFERRED BOOKING</div>
                <div class="confirmSubTitle">Booking Option One</div>
                <div class="confirmAnswer">
                  <div class="confirmAnswerText">
                      {{ bookingWindows[0].date == null ? '' : (bookingWindows[0].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[0].time.from }}
                  </div>
                  <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(2)">edit</div>
                  <div style="clear: both;"></div>
                </div>
                <div class="confirmSubTitle">Booking Option Two</div>
                <div class="confirmAnswer">
                  <div class="confirmAnswerText">
                      {{ bookingWindows[1].date == null ? '' : (bookingWindows[1].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[1].time.from }}
                  </div>
                  <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(2)">edit</div>
                  <div style="clear: both;"></div>
                </div>
            </div>
            <div style="clear: both;"></div>
          </div>
          <!-- End Booking Windows -->
          <div class="confirmBox boxNoFill lighterBox">
            <div class="confirmIcon"><img
                src="assets/img/{{ clientID }}/icons/contactdetails.png" /></div>
            <div class="confirmInfo">
                <div class="confirmTitle">CONTACT DETAILS</div>
                <div class="confirmSubTitle">Booking Details</div>
                <div class="confirmAnswer">
                  <div class="confirmAnswerText">
                      {{ jobDataService.jobData.customerContact.contactName }}<br />
                      {{ jobDataService.jobData.streetUnit.length > 0 ? jobDataService.jobData.streetUnit + '/' : '' }}{{ jobDataService.jobData.streetAddress }}&nbsp;{{ jobDataService.jobData.suburbName }}&nbsp;{{ jobDataService.jobData.postcode }}<br />
                      {{ jobDataService.jobData.customerContact.contactEmail }}<br />
                      {{ jobDataService.jobData.customerContact.contactPhone }}
                  </div>
                  <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(3)">edit</div>
                </div>
            </div>
            <div style="clear: both;"></div>
          </div>
          <!-- End Contact Details -->
          <div class="confirmBox boxNoFill lighterBox">
            <div class="confirmTitle sidebarFAQTitle">Frequently Asked Questions</div>
            <div *ngFor="let item of faqItems">
                <div class="faqTitle buttonPointer" (click)="expandFAQ(item)">{{ item.title }}</div>
                <div class="faqDescription" *ngIf="item.show">{{ item.description }}</div>
            </div>
          </div>
          <!-- End FAQ -->
        </div>
        <!-- End Triage Sidebar -->
        <div style="clear: both;"></div>

      </div>
    </div>
  </div>

  <!-- MOBILE TOOLBOX -->
  <div *ngIf="showMoreDetails" class="confirmContainer" style="margin-top: 5%;">
    <div class="stepDescription">Your booking details.</div>

    <div class="confirmBox boxNoFill lighterBox pricingBox" [class.discountPadding]="discountApplied == true">
      <div class="confirmIcon"><img src="assets/img/{{ clientID }}/home/tradetypes/{{ jobDataService.trade.icon }}" /></div>
      <div class="confirmInfo">
        <div class="confirmTitle" [class.uppercaseLabel]="jobDataService.trade.name == 'HVAC'">{{ jobDataService.trade.name.toUpperCase() }}</div>
        
        <div class="jobTypeInfoContainer" *ngFor="let jobType of jobDataService.jobTypes">
          <div class="confirmSubTitle jobTitleContainer">
            <div class="jobTitleLeft" [class.selected]="currentQuestion.jobTypeIndex == jobType.jobTypeIndex" (click)="jumpToJobType(jobType)">{{ jobType.name }}</div>
            <div class="jobTitleRight" *ngIf="bundlingEnabled == true"><fa-icon class="deleteService buttonPointer" [icon]="faTrash" (click)="openDeleteServicePopup(jobType)"></fa-icon></div>
            <div style="clear: both;"></div>
          </div>
          <div class="confirmAnswer">
              <div class="confirmAnswerText">Quantity:</div>
              <div class="confirmAnswerEdit">x{{ jobType.currentQuantity }} ({{ jobType.estimatedDuration }}hrs)</div>
              <div style="clear: both;"></div>
          </div>
          <div class="confirmAnswer">
              <div class="confirmAnswerText">Estimate:</div>
              <div class="confirmAnswerEdit">{{ '$' + jobType.estimatedFullPrice.toFixed(2) }}</div>
              <div style="clear: both;"></div>
          </div>
        </div>
        <div class="confirmSubTitle">Total Estimated Duration: {{ estimatedDuration }}hrs</div>
      </div>
      <div style="clear: both;"></div>

      <div class="confirmPrice" [class.bundling]="jobDataService.trade.id == tradeTypes.Plumbing || jobDataService.trade.id == tradeTypes.Electrical">
        <div class="addServicesButton" *ngIf="jobDataService.trade.id == tradeTypes.Plumbing || jobDataService.trade.id == tradeTypes.Electrical" (click)="toggleAddServicesPopup(true)">
          ADD SERVICES
        </div>
        <div *ngIf="discountApplied == true">
          <div class="confirmPriceTitle">
            {{ jobDataService.trade.id == tradeTypes.HomeInspections ? 'TOTAL PRICE' : 'ESTIMATED PRICE' }}</div>
          <div class="confirmPriceText" style="text-decoration: line-through;">{{ '$' + fullPrice.toFixed(2) }}</div>
          <div style="clear: both;"></div>

          <div class="confirmPriceTitle">{{ discountText }}</div>
          <div class="confirmPriceText">{{ '$' + currentPrice.toFixed(2) }}</div>
          <div style="clear: both;"></div>
        </div>

        <div *ngIf="discountApplied == false">
          <div class="confirmPriceTitle">
            {{ jobDataService.trade.id == tradeTypes.HomeInspections ? 'TOTAL PRICE' : 'ESTIMATED PRICE' }}</div>
          <div class="confirmPriceText">{{ '$' + currentPrice.toFixed(2) }}</div>
          <div style="clear: both;"></div>
        </div>

        <div class="pricingDisclaimer">All prices include GST.<span *ngIf="outOfHoursPriceApplied"> An out of hours rate
            has been applied to this booking.</span></div>
        <div class="pricingDisclaimer" *ngIf="clientID == clients.AANZ">* AA Members, AA Insurance home and landlord policy holders, AA Home Response and AA Home Response Plus subscribers, all receive a 10% discount.</div>
      </div>
    </div>

    <div class="confirmBox boxNoFill lighterBox">
      <div class="confirmIcon"><img src="assets/img/{{ clientID }}/icons/calendar_green.png" />
      </div>
      <div class="confirmInfo">
        <div class="confirmTitle">PREFERRED BOOKING</div>
        <div class="confirmSubTitle">Booking Option One</div>
        <div class="confirmAnswer">
          <div class="confirmAnswerText">
            {{ bookingWindows[0].date == null ? '' : (bookingWindows[0].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[0].time.from }}
          </div>
          <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(2)">edit</div>
          <div style="clear: both;"></div>
        </div>
        <div class="confirmSubTitle">Booking Option Two</div>
        <div class="confirmAnswer">
          <div class="confirmAnswerText">
            {{ bookingWindows[1].date == null ? '' : (bookingWindows[1].date | date: 'dd  /  MM  /  yyyy') + ' ' + bookingWindows[1].time.from }}
          </div>
          <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(2)">edit</div>
          <div style="clear: both;"></div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>

    <div class="confirmBox boxNoFill lighterBox">
      <div class="confirmIcon"><img src="assets/img/{{ clientID }}/icons/contactdetails.png" />
      </div>
      <div class="confirmInfo">
        <div class="confirmTitle">CONTACT DETAILS</div>
        <div class="confirmSubTitle">Booking Details</div>
        <div class="confirmAnswer">
          <div class="confirmAnswerText">
            {{ jobDataService.jobData.customerContact.contactName }}<br />
            {{ jobDataService.jobData.streetUnit.length > 0 ? jobDataService.jobData.streetUnit + '/' : '' }}{{ jobDataService.jobData.streetAddress }}&nbsp;{{ jobDataService.jobData.suburbName }}&nbsp;{{ jobDataService.jobData.postcode }}<br />
            {{ jobDataService.jobData.customerContact.contactEmail }}<br />
            {{ jobDataService.jobData.customerContact.contactPhone }}
          </div>
          <div class="confirmAnswerEdit editButton buttonPointer" (click)="goToStepType(3)">edit</div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>
  <!-- END MOBILE TOOLBOX -->

  <!-- Special step to show FAQ questions on mobile -->
  <div *ngIf="showFAQ" class="confirmContainer" style="margin-top: 5%;">
    <div class="stepDescription">Frequently asked questions.</div>

    <div *ngFor="let item of faqItems">
      <div class="faqTitle buttonPointer" (click)="expandFAQ(item)">{{ item.title }}</div>
      <div class="faqDescription" *ngIf="item.show">{{ item.description }}</div>
    </div>
  </div>

  <!-- Special step for selecting booking window date and time -->
  <div *ngIf="showBookingWindow" style="padding-bottom: 15px;">
    <div class="stepDescription">Please select a date and time.</div>

    <mat-form-field style="display: none;">
      <input matInput [matDatepickerFilter]="dateFilter" [matDatepicker]="picker" [(ngModel)]="selectedBookingDate"
        (dateChange)="validateBookingOption()" placeholder="">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker [dateClass]="dateClass" touchUi #picker (opened)="calendarOpened()"></mat-datepicker>
    </mat-form-field>

    <div class="bookingWindow greyBox buttonPointer" (click)="picker.open()">
      <div class="bookingWindowText">
        {{ selectedBookingDate == null ? 'Select date' : (selectedBookingDate | date: 'dd  /  MM  /  yyyy') }}</div>
      <div class="bookingWindowIcon"><img src="assets/img/{{ clientID }}/icons/calendar.png" />
      </div>
      <div style="clear: both;"></div>
    </div>

    <div class="bookingWindow greyBox buttonPointer" style="margin-bottom: -1px;" (click)="toggleTimeWindowDropdown()">
      <div class="bookingWindowText">{{ selectedBookingTime == null ? 'Select time' : selectedBookingTime }}</div>
      <div class="bookingWindowIcon"><img src="assets/img/{{ clientID }}/icons/clock.png" />
      </div>
      <div style="clear: both;"></div>
    </div>

    <div class="bookingWindow greyBox" *ngIf="showTimeWindowDropdown">
      <div *ngFor="let timeWindowOption of timeWindowOptions" class="timeWindow buttonPointer"
        [class.outOfHours]="timeWindowOption.outOfHours || currentWindowOutOfHours"
        (click)="setTimeWindow(timeWindowOption)">
        <div class="windowTo">{{ timeWindowOption.from }}</div>
        <div class="windowDivider">-</div>
        <div class="windowFrom">{{ timeWindowOption.to }}</div>
        <div style="clear: both;"></div>
      </div>
    </div>

    <div class="outOfHoursDisclaimer" style="margin-top: 25px;" *ngIf="outOfHoursEnabled">
      * Highlighted dates and times will be charged at an out of hours rate.
    </div>
  </div>

  <!-- Mobile back and next buttons -->
  <div *ngIf="!showBackToQuestionsButtons" class="mobileBackNext">
    <div class="wizardButtonContainer" *ngIf="currentQuestion.question.showStandardButtons">
      <div class="wizardButtonBack buttonPointer" (click)="prevStep(currentQuestion.index)">
        {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
      <div class="wizardButtonNext buttonPointer" [class.valid]="currentQuestion.valid"
        (click)="nextStep(currentQuestion.index)">NEXT ></div>
      <div style="clear: both;"></div>
    </div>

    <div class="wizardButtonContainer" *ngIf="currentQuestion.question.showConfirmButtons">
      <div class="wizardButtonBack buttonPointer" (click)="prevStep(currentQuestion.index)">
        {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
      <div class="wizardButtonNext buttonPointer valid" (click)="closeEndPopup()">CONFIRM</div>
      <div style="clear: both;"></div>
    </div>
  </div>

  <!-- Mobile buttons for going back to the current question from intermediary windows -->
  <div *ngIf="showBackToQuestionsButtons">
    <div class="wizardButtonContainer" *ngIf="!showBookingWindow">
      <div class="wizardButtonBack buttonPointer" (click)="closeMobileInfoStep()">
        {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
      <div style="clear: both;"></div>
    </div>

    <div class="wizardButtonContainer" *ngIf="showBookingWindow">
      <div class="wizardButtonBack buttonPointer" (click)="closeBookingOption()">
        {{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
      <div class="wizardButtonNext buttonPointer" [class.valid]="currentBookingOptionValid"
        (click)="confirmBookingOption()">CONFIRM</div>
      <div style="clear: both;"></div>
    </div>
  </div>

</my-content>