/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cardpayment-complete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../master/master.module.ngfactory";
import * as i4 from "../master/master.module";
import * as i5 from "@angular/common";
import * as i6 from "./cardpayment-complete.component";
import * as i7 from "@angular/fire/firestore";
import * as i8 from "ngx-toastr";
import * as i9 from "../services/meta-data.service";
var styles_CardpaymentCompleteComponent = [i0.styles];
var RenderType_CardpaymentCompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardpaymentCompleteComponent, data: {} });
export { RenderType_CardpaymentCompleteComponent as RenderType_CardpaymentCompleteComponent };
function View_CardpaymentCompleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "completeContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "stepTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PAYMENT RECEIVED"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "jobReferenceTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your job reference number is"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "jobReference"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "contactNumberContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "contactNumberInfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you have any further questions please contact"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "contactNumber"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "optionSelect standardButton buttonPointer"], ["style", "margin-left: auto; margin-right: auto; margin-top: 30px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["Back to Home"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 14, 0, "/home"); _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.booking; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.metaData.contactPhoneNumber; _ck(_v, 11, 0, currVal_1); }); }
function View_CardpaymentCompleteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "completeContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "stepTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PAYMENT CANCELLED"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "jobReferenceTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your reference number is"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "jobReference"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "completeInfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It looks like you didn't complete the payment."])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "completeInfo"], ["style", "margin-top: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If this is not correct please contact us using the reference number above."])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "contactNumberContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "contactNumberInfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you have any further questions please contact"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "contactNumber"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "optionSelect standardButton buttonPointer"], ["style", "margin-left: auto; margin-right: auto; margin-top: 30px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, ["Back to Home"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 18, 0, "/home"); _ck(_v, 17, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.booking; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.metaData.contactPhoneNumber; _ck(_v, 15, 0, currVal_1); }); }
export function View_CardpaymentCompleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "my-content", [["placeholder", "main"]], null, null, null, i3.View_ContentComponent_0, i3.RenderType_ContentComponent)), i1.ɵdid(1, 114688, null, 0, i4.ContentComponent, [i1.ElementRef, i4.ContentService], { placeholder: [0, "placeholder"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CardpaymentCompleteComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CardpaymentCompleteComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "main"; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.success == true); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.success == false); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CardpaymentCompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "HomeServices-cardpayment-complete", [], null, null, null, View_CardpaymentCompleteComponent_0, RenderType_CardpaymentCompleteComponent)), i1.ɵdid(1, 114688, null, 0, i6.CardpaymentCompleteComponent, [i7.AngularFirestore, i8.ToastrService, i2.ActivatedRoute, i9.MetaDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardpaymentCompleteComponentNgFactory = i1.ɵccf("HomeServices-cardpayment-complete", i6.CardpaymentCompleteComponent, View_CardpaymentCompleteComponent_Host_0, {}, {}, []);
export { CardpaymentCompleteComponentNgFactory as CardpaymentCompleteComponentNgFactory };
