<my-content placeholder="main">
    <div class="privacyContainer" *ngIf="clientID != clients.RACWA && clientID != clients.AANZ">
        <div class="title">
        {{ clientID != clients.Huski ? 'Global Home Response' : 'Huski' }}
        Home Services Website Terms and Conditions</div>
        <div>These are our terms and conditions. They set out the conditions for use of our Platforms, including our
        obligations to you, your rights, and your obligations to us.</div>
        <div>We reserve the right to revise and update these Terms and Conditions at any time. Please periodically
        review these Terms and Conditions. </div>
        <div>By using our Platform, you acknowledge and agree to abide by these terms.</div>
        <div class="heading">Background</div>
        <div>
        {{ clientID != clients.Huski ? 'Global Home Response' : 'Huski Home Services is provided by Global Home Response' }}
        (A.B.N. 96 350 743 600) (GHR)
        {{ clientID != clients.Huski ? 'operates' : 'who operate' }}
        an online platform,
        as well as mobile
        applications, through which customers ("Users") can book a Job request for the provision of Trade Services,
        by submitting a Booking Request. GHR then matches the most suitable Tradie from its database of Trades to
        the Booking Request.</div>
        <div>This is a contract between GHR and the Users.</div>
        <div>GHR enters into agreements with Users for provision of Trade Services to Users. The User is deemed to
        have
        entered into this Contract and a contractual relationship with GHR through the making and acceptance of a
        Booking.</div>
        <div class="heading">AND THE PARTIES HEREBY AGREE:</div>
        <div>Access to and use of the Website mobile phone applications is subject to the terms and conditions
        outlined
        in this agreement (the "Terms").</div>
        <div class="heading">1. Definitions and Interpretation</div>
        <div>1.1. In these Terms (including the recitals), unless the context otherwise requires:</div>
        <div>"Booking" means the booking made by you on the Website or mobile phone applications for the provision of
        Trade Services;</div>
        <div>"Booking System" means the online system which enables you to make a Booking;</div>
        <div>"Fee" means the fee, as advised by GHR from time to time, (inclusive of goods and service tax, if
        applicable) for the Trade Services charged and invoiced by GHR;</div>
        <div>"Intellectual Property Rights" means all present and future rights anywhere in the world in relation to
        copyright, trademarks, designs, patents or other proprietary rights, or any rights to registration of such
        rights whether existing before or after your access to the Website and/or mobile phone applications;</div>
        <div>"Site Content" means all material, content and information made available on the Website and/or mobile
        phone applications including but not limited to written text, graphics, images, photographs, logos,
        trademarks, audio material, video material and any other forms of expression;</div>
        <div>“Tradie” means the individual assigned by GHR to deliver the Trade Services that you have booked;</div>
        <div>"you", "your" means you as the user of the Website or mobile phone application.</div>
        <div>1.2. In these Terms, unless the context otherwise requires:</div>
        <div>(a) headings are for convenience only and do not affect its interpretation or construction;</div>
        <div>(b) the singular includes the plural and vice versa;</div>
        <div>(c) references to recitals, clauses, subclauses, paragraphs, annexures or schedules are references to
        recitals, clauses, subclauses, paragraphs, annexures and schedules of or to these Terms;</div>
        <div>(d) words importing a gender include other genders;</div>
        <div>(e) the word “person” means a natural person and any association, body or entity whether incorporated or
        not;</div>
        <div>(f) where any word or phrase is defined, any other part of speech or other grammatical form of that word
        or phrase has a cognate meaning;</div>
        <div>(g) a reference to any statute, proclamation, rule, code, regulation or ordinance includes any amendment,
        consolidation, modification, re-enactment or reprint of it or any statute, proclamation, rule, code,
        regulation or ordinance replacing it;</div>
        <div>(h) all monetary amounts are in Australian currency;</div>
        <div>(i) a reference to time refers to Australian Eastern Standard Time;</div>
        <div>(j) "includes" is not a word of limitation; no rule of construction applies to the disadvantage of a
        party
        because these Terms are prepared by (or on behalf of) that party;</div>
        <div>(k) a reference to any thing is a reference to the whole and each part of it;</div>
        <div>(l) a reference to a group of persons is a reference to all of them collectively and to each of them
        individually; and</div>
        <div>(m) a reference to a document includes all amendments or supplements to, or replacements or novations of,
        that document.</div>
        <div class="heading">2. Terms and Conditions</div>
        <div>2.1. Access to and use of this Website and mobile phone application is governed by these Terms.</div>
        <div>2.2. GHR periodically reviews the Terms and reserves the right to change the Terms, without any notice to
        you, by updating this document. You should review this document, as available on the Website and mobile
        phone applications, regularly as any changes to the Terms take immediate effect from the date of the
        publication on this document. Your continued use of the Website and/or mobile phone applications after any
        such changes are made will be deemed to constitute your acceptance of those changes.</div>
        <div>2.3. You acknowledge that any hyperlinks or other redirection tools taking you to other operated by third
        parties that appear on the Website or mobile phone applications ("Third Party Sites") are not controlled by
        GHR and do not form part of the Website or mobile phone applications. You agree that you will not hold GHR
        liable or in any way accountable for anything that occurs on any Third Party Site.</div>
        <div class="heading">3. Acceptance</div>
        <div>3.1. In order to use this Website or mobile phone applications and make a Booking, you must first agree
        to
        the Terms.</div>
        <div>3.2. You accept the Terms by clicking to accept/agree to the Terms.</div>
        <div>3.3. Notwithstanding clause 3.2, you may not accept the Terms or access the Website or mobile phone
        applications if:</div>
        <div> (a) you are not of legal age to form a binding contract with GHR; or</div>
        <div> (b) you are a person barred from entering into contracts under legislation,</div>
        <div>in which case, you must immediately leave the Website or mobile phone applications.</div>
        <div>3.4. By accepting the Terms, you agree that this is clear and unequivocal proof that the Terms are not
        unconscionable, that there is no unfair bargaining power or position, that there is no duress, and that you
        have carefully read and understood the Terms.</div>
        <div>3.5. If you do not understand any part of the Terms you should seek independent legal advice.</div>
        <div class="heading">4. User Details</div>
        <div>4.1. You will be required to register your personal information, including your name, address, phone
        number and payment details on the Website or mobile phone applications in order to make a Booking.</div>
        <div>4.2. You confirm that you are at least 18 years of age and possess the legal authority to use the Booking
        System in accordance with these Terms. You also agree that all information supplied by you in using the
        Website or mobile phone applications and the Booking System will be true and accurate at all times.</div>
        <div>4.3. GHR may refuse to deliver an order at its own discretion.</div>
        <div class="heading">5. Booking System</div>
        <div>5.1. To make a Booking you must use the Booking System to request a Booking. You will receive a Booking
        confirmation, that constitutes the contractual relationship between you and GHR that you are deemed to have
        entered into for the provision of the Trade Services.</div>
        <div>5.2. GHR will make your contact details, location, requested time and the scope of the Trade Service
        available to the most appropriate Tradie.</div>
        <div>5.3. You are able to request a preferred booking window. GHR will take your preference into account when
        facilitating the Trade Service, however a specific booking window cannot be guaranteed and will depend on
        availability.</div>
        <div>5.4. If the requested Booking cannot be facilitated, GHR will arrange an alternative time for the Trade
        Service with you and a Tradie.</div>
        <div>5.5. Once a booking window is confirmed we will make all efforts to attend within this window, however
        cannot guarantee that we will arrive within this timeframe. Should this be the case, GHR will contact you
        to advise.</div>
        <div>5.6. You are not permitted to engage the Trade Services of a Tradie other than through the Booking System
        on the Website or mobile phone applications.</div>
        <div>5.7. The Booking System is provided on the Website or mobile phone applications to enable you to make
        legitimate Bookings and for no other purposes.</div>
        <div>5.8. Any speculative, false or fraudulent Booking is prohibited. You agree that the Booking System will
        only be used to make legitimate Bookings for you or another person for whom you are legally authorised to
        act. You acknowledge that abuse of the Booking System may result in you being denied access to the Booking
        System.</div>
        <div>5.9. GHR has the right at any time to add, change or withdraw functions available on the Website or
        mobile
        phone applications at its own discretion.</div>
        <div class="heading">6. Pricing and Payment</div>
        <div>6.1. Upon booking a trade service, you will be provided with an estimated price to complete the job based
        on information you provided and our experience delivering these services. </div>
        <div>6.2. The Tradie attending to complete your Trade Service will conduct an assessment and will either
        confirm that the job can be completed for the price estimate provided or advise you of any additional costs
        required to complete the job.</div>
        <div>6.3. Either before commencing or during the job, you will be required to sign off acceptance of the final
        price, this sign off constitutes agreeance that you will make payment in full upon completion of the job.
        </div>
        <div>6.4. If you do not agree to the final price, this will constitute a cancellation and a cancellation fee
        will apply as per clause 7. </div>
        <div>6.5. Payment will be made by you via production of a valid Credit Card (VISA / Mastercard) and processed
        before the Tradie leaves the property.</div>
        <div>6.6. GHR, will issue you with a tax invoice once payment has been made.</div>
        <div class="heading">7. Changes and Cancellations</div>
        <div>7.1. You can cancel or amend a Booking free of charge, up to 24 hours before the Trade Service is
        scheduled to begin.</div>
        <div>7.2. If you cancel a Booking within 24 hours before the Trade Service is scheduled to begin, you may have
        to pay a cancellation fee of $65.</div>
        <div>7.3. The Agreement shall expire once the Trade Services under the Booking have been performed.</div>
        <div class="heading">8. Trade Services</div>
        <div>8.1. GHR operates the Website and mobile phone applications as a solution for the Trade Services and
        provides you with the platform to purchase the Trade Services.</div>
        <div>8.2. GHR uses their best efforts to monitor the Trade Services offered by a Tradie. Any issues regarding
        safety, quality, condition or description should be notified to GHR, who will then attempt to resolve the
        issue as per our Dispute Resolution Policy.</div>
        <div>8.3. All bookings are subject to Tradie availability.</div>
        <div>8.4. GHR will only complete Trade Services within the property boundary.</div>
        <div>8.5. GHR will not complete Trade Services on areas or equipment reasonably considered to be the province
        and responsibility of utility providers such as gas or water meters, gas pipelines, electricity cables and
        grids or water mains.</div>
        <div>8.6. GHR will only complete Trades Services at a property if there is a person 18 years of age or older
        at
        the property present to provide signed authority to proceed with works and also provide payment for works
        upon completion.</div>
        <div>8.7. Home Inspections refers to the provision of a Pre Purchase Building and Pest Inspection,
        which compromises the non-invasive assessment of the building. The inspection will not report on pools,
        spas, ponds etc.
        The customer is advised to obtain the services of a pool specialist to carry out an inspection and report.
        </div>
        <div class="heading">9. Dispute Resolution Policy</div>
        <div>9.1. GHR will use reasonable endeavours to resolve any dispute concerning the use of the Website or
        mobile
        phone applications.</div>
        <div>9.2. GHR will use reasonable endeavours to resolve any dispute concerning the Service Delivery provision
        of a Tradie.</div>
        <div>9.3. Where GHR are unable to resolve disputes in relation to the Trade Services carried out by a Tradie
        or
        any other issue will be referred, where appropriate, to external dispute resolution services or
        authorities.</div>
        <div class="heading">10. Security of Payment</div>
        <div>10.1. We have taken all practical steps from both a technical and systems perspective to ensure that all
        your information is well protected. A secure payment gateway is used to process all transactions and credit
        card details. GHR does not give any warranty or make any representation regarding the strength or
        effectiveness of the secure payment gateway and is not responsible for events arising from unauthorised
        access to your information.</div>
        <div class="heading">11. Privacy</div>
        <div>11.1. In this clause Personal Information means information or an opinion (including information or an
        opinion forming part of a database) whether true or not, and whether recorded in a material form or not,
        about an individual whose identity is apparent or can reasonably ascertained, from the information or
        opinion.</div>
        <div>11.2. GHR collects and stores Personal Information you enter. Personal Information collected by GHR will
        be maintained in accordance with our Privacy Policy available on the website. </div>
        <div>11.3. You agree that GHR will disclose your Personal Information, including your name and address to the
        Tradie for the Tradie to carry out the Trade Services.</div>
        <div>11.4. Both parties must abide by all relevant Australian Privacy Principles set out in the Privacy Act
        1998 in their collection, use, disclosure and storage of Personal Information.</div>
        <div class="heading">12. Intellectual Property</div>
        <div>12.1. The Site Content is protected by copyright and GHR reserves all Intellectual Property Rights which
        may subsist in the Site Content and/or the Website and/or mobile phone applications.</div>
        <div>12.2. By accepting the Terms, you are granted a non-exclusive licence to:</div>
        <div> (a) view the Website and/or mobile phone applications;</div>
        <div> (b) print pages from the Website and/or mobile phone applications in its original form; and</div>
        <div> (c) download any material from the Website and/or mobile phone applications for caching purposes only.
        </div>
        <div>12.3. You must not, without GHR’s prior written consent which may be withheld at its absolute discretion:
        </div>
        <div> (a) copy, republish, reproduce, duplicate or extract Site Content;</div>
        <div> (b) redistribute, sell, rent or licence any Site Content; or</div>
        <div> (c) edit, modify or vary the Site Content.</div>
        <div class="heading">13. Acceptable Use</div>
        <div>13.1. You must not use, or cause this Website or mobile phone applications to be used, in any way which:
        </div>
        <div> (a) breaches any of these Terms;</div>
        <div> (b) infringes GHR’s or any third party’s Intellectual Property Rights;</div>
        <div> (c) is fraudulent, illegal or unlawful; or</div>
        <div> (d) causes impairment of the availability or accessibility of the Website or mobile phone applications.
        </div>
        <div>13.2. You must not use, or cause this Website or mobile phone applications to be used, as a medium which
        stores, hosts, transmits sends or distributes any material which consists of:</div>
        <div> (a) spyware;</div>
        <div> (b) computer viruses;</div>
        <div> (c) Trojans;</div>
        <div> (d) worms;</div>
        <div> (e) keystroke loggers; or</div>
        <div> (f) any other malicious computer software.</div>
        <div class="heading">14. Indemnity and Liability</div>
        <div>14.1. General indemnity</div>
        <div>You agree to indemnify GHR, on demand, against any claim, action, damage, loss, liability, cost, charge,
        expense or payment which GHR may pay, suffer, incur or are liable for, in relation to any act you do or
        cause to be done, in breach of these Terms.</div>
        <div>14.2. General limitation of liability</div>
        <div>We will not be liable to you in contract, tort or equity in relation to any direct, indirect or
        consequential loss you incur in relation to the contents or use of or reliance on Site Content or otherwise
        in connection with the Website or mobile phone applications.</div>
        <div>14.3. Trade Services Liability</div>
        <div>To the extent permitted by law, GHR will not be responsible and will be excluded from all liability, for
        any loss or damage whatsoever (including personal injury, loss of life and damage to property) that you or
        another person may suffer in connection with the offer, advice or supply of (or default in supplying) the
        Trade Services.</div>
        <div>14.4. Information accuracy</div>
        <div>You acknowledge and agree that some of the Site Content may be provided by way of blogs or comments made
        by other users of the Website or mobile phone applications, and that GHR does not accept any liability for
        the accuracy of such information or your reliance on the same. The Site Content is provided to you as
        general information only and is not intended to substitute or replace the advice of a duly qualified
        professional (where applicable).</div>
        <div>14.5. Acceptance</div>
        <div>By using this Website or mobile phone applications, you agree and accept that the indemnity and
        limitations of liability provided in this clause 15 are reasonable.</div>
        <div class="heading">15. Warranties and Representations</div>
        <div>15.1. The use of this Website or mobile phone applications is at your own risk. The Site Content and
        everything from the Website or mobile phone applications is provided to you on an “as is” and “as
        available” basis without warranty or condition of any kind.</div>
        <div>15.2. None of GHR’s affiliates, directors, officers, employees, agents, contributors, third party content
        providers or licensors make any express or implied representation or warranty about the Site Content or
        Website or mobile phone applications.</div>
        <div>15.3 GHR warrant and certify all works as per statutory requirements. A 12-month workmanship warranty
        will
        be provided on all works completed, with the exception of drain clearing as per 15.10.</div>
        <div>15.4 Products and materials supplied by GHR will be covered by manufacturer’s warranty conditions, as
        provided by the manufacturer. GHR does not accept any responsibility for faulty products or materials.</div>
        <div>15.5 Any materials supplied by the customer which do not meet required standards will not be installed.
        No
        warranty applies to any parts supplied by the customer.</div>
        <div>15.6 All warranty claims should be reported to GHR immediately and will only be accepted as warranty
        rectification works, once a site inspection has been completed, to determine eligibility. Site visits to
        non - eligible claims, maybe charged as a service call.</div>
        <div>15.7 Warranty inspections and repairs will only be completed during business hours.</div>
        <div>15.8 Clearing of blocked drains and inspection of blocked and damaged drains is conducted at the risk of
        the Customer. GHR will not be held responsible for the location or condition of existing drainage systems,
        nor the products or materials located within the drainage systems.</div>
        <div>15.9 Investigation and maintenance of drains involves insertion of specialised drainage equipment into
        existing drains, in order to conduct works or investigations. Customer accepts responsibility for any
        damage caused to the plumbing drainage system that maybe caused while maintenance works to drainage system
        is conducted.</div>
        <div>15.10 Any damage caused to specialised drainage equipment, caused through investigation or maintenance of
        the drainage system will be the responsibility of the Customer. Recovery of equipment lost or lodged within
        the drainage system will be at the expense of the Customer.</div>
        <div>15.11 The Customer acknowledges that pipes affected by root growth and or blockages are generally damaged
        as a result of such interference. The removal of root growth and / or blockages alone carries no warranty
        that similar problems will not recur unless permanent repairs are</div>
        <div>15.12 Any electrical works required to upgrade the property to Australian and New Zealand wiring
        standards
        will be at an additional cost, which will be communicated by the Tradie as soon as it is identified.</div>
        <div>15.13 Any warranty relating to a Pre-Purchase Building and Pest Inspection conducted by GHR is covered
        within the specific report provided to you.</div>
        <div class="heading">16. Environmental and Occupational Health & Safety</div>
        <div>16.1 GHR will not provide Trade Services or may be limited where in the opinion of the Tradie, there is a
        real risk of danger to the health and safety of any person, or there is a real risk of causing significant
        damage to the environment.
        This may include situations such as wet roofs generally, 2-storey buildings (or buildings with a high-pitched roof) or the presence of asbestos.
        </div>
        <div class="heading">17. Emergency Jobs</div>
        <div>17.1 Where the customer requires assistance to enter the property,
        satisfactory proof of identity will be required by the attending Tradie.</div>
        <div>17.2 An Emergency Job provides customers with fast response
        assistance in the event of an emergency. On site assistance is provided by Tradies who provide a combination
        of services including assessment, repairs and temporary procedures that make the property safe and secure
        where they are unable to fix the problem. Service provided also includes advice on any further works
        required.</div>
        <div>17.3 A Tradie will attend to provide the services as soon as
        practicable, but a response time is not guaranteed and will vary depending on location, demand for service,
        amongst other things. GHR will not be liable for any failure or delay in providing service, where the
        failure or delay arises from causes beyond the reasonable control of GHR, including without limitation:
        Major Disasters or Events, adverse weather conditions, unavailability of materials and otherwise where the
        relevant Service is not reasonably available.</div>
        <div>17.4 An Emergency Job provides for up to one hour of labour from the
        time the Tradie arrives at the property. Where specialised (Example: Drain Camera, or high-pressure
        waterjet) or more extensive repairs are needed, the customer may request the Tradie to arrange an obligation
        free quote. Any further repairs or works required will incur an additional cost.</div>
        <div>17.5 Works will only be performed by the Tradie within the property
        boundary that they are attending. Where a problem occurs, originates or extends outside the boundaries of
        the property, repairs and Make Safe Procedures will be limited to work which can be carried out within the
        boundaries of the property.</div>
        <div>17.6 The following are not covered in relation to Emergency Jobs
        </div>
        <div> (a) The need for replacement parts</div>
        <div> (b) Major Structural Damage</div>
        <div> (c) Area Wide Disruption to Essential Services</div>
        <div> (d) Disruption to essential services as the result of disconnection
        by the relevant authority or service provider</div>
        <div> (e) Major Disasters</div>
        <div class="heading">18. General
        Provisions</div>
        <div>18.1 Any provision of, or
        the application of any provision of these Terms which is prohibited in any
        jurisdiction is, in that jurisdiction, ineffective only to the extent of that prohibition.</div>
        <div>18.2 Any provision of, or
        the application of any provision of these Terms which is void, illegal or
        unenforceable in any jurisdiction does not affect the validity, legality or enforceability of that
        provision in any other jurisdiction or of the remaining provisions in that or any other jurisdiction.</div>
        <div>18.3 If a clause is void,
        illegal or unenforceable, it may be severed without affecting the
        enforceability of the other provisions in these Terms.</div>
        <div>18.4 These Terms shall be
        governed by and construed in accordance with the law for the time being
        applicable to Victoria and you agree to submit to the non-exclusive jurisdiction of the Courts of Victoria.
        </div>
    </div>

    <div class="privacyContainer" *ngIf="clientID == clients.RACWA">
        <div class="title">RAC Home repair & maintenance service terms</div>
        <div class="heading">1. Formation of agreement</div>
        <div>(a) If you request the RAC or the Supplier to supply the services described in section 2 (HRM Service),
        or you accept performance of the HRM Service, you accept these Terms and an agreement is formed between you
        and the Supplier.</div>
        <div>(b) RAC is not a party to this agreement and you agree to release RAC from any liability under these
        Terms and in respect of the HRM Service.</div>

        <div class="heading">2. The HRM Service</div>

        <div class="heading">2.1 What is the HRM Service?</div>
        <div>(a) The HRM Service provides you with access to home repair and maintenance services, including Goods
        associated with those services. On-site assistance at your personal residential, domestic or residential
        investment property is provided by HRM Service Providers who offer a combination of services such as:
        plumbing and gas, electrical, locksmith, glazier, heating, ventilation and air-conditioning, handyman and
        general trade services. Visit the Website rac.com.au/home-life/home-repair to see the full list of services
        that are currently available.</div>
        <div>(b) The Supplier engages its employees, agents and/or subcontractors to provide the HRM Service to you on
        its behalf (<b>HRM Service Providers</b>) under standards approved by RAC.</div>
        <div>(c) The charge you pay for the HRM Service includes a fee that is payable to RAC.</div>
        <div>(d) The HRM Service is not:</div>
        <div> (i) a home insurance product or replacement for such a product; or</div>
        <div> (ii) an emergency rescue or disaster response service.</div>
        <div>(e) Where an emergency or disaster poses a risk to the personal safety and property of you and/or others,
        you should contact appropriate emergency or rescue services such as the State Emergency Service or the ‘000’
        Emergency Service or appropriate utility or essential services providers, prior to contacting the Supplier
        for assistance. </div>

        <div class="heading">2.2 Access to property</div>
        <div>(a) You agree to provide the Supplier and the HRM Service Provider clear and safe access to your property
        at the agreed date and time to:</div>
        <div> (i) deliver any Goods and perform the HRM Service;</div>
        <div> (ii) inspect any Goods or HRM Services supplied after receiving a complaint from you; and</div>
        <div> (iii) repair, replace or resupply any defective Goods or HRM Services supplied to you (as the case may
        be). </div>

        <div class="heading">2.3 Service availability</div>
        <div>(a) Subject to these Terms, the HRM Service is available within suburbs and postcodes as determined by
        the Supplier from time to time, having regard to Perth metropolitan areas from Two Rocks to Mandurah. The
        HRM Service may be available in selected regional areas on a limited basis.</div>
        <div>(b) The HRM Service is available Monday to Sunday from 8am to 5pm WST.</div>
        <div>(c) The supply of the HRM Service is subject to HRM Service Provider availability.</div>
        <div>(d) The delivery of the HRM Service will only occur within your property boundary.</div>

        <div class="heading">2.4 Service response times</div>
        <div>The Supplier will endeavour to provide assistance as quickly as is reasonably practicable, and otherwise
        within the agreed timeframe.</div>

        <div class="heading">2.5 Service interruptions</div>
        <div>(a) The Supplier may be delayed in or prevented from providing the HRM Service due to circumstances
        beyond its reasonable control, including Major Disasters, adverse weather conditions, and failures in
        telecommunications.</div>
        <div>(b) In such circumstances, the Supplier will have no obligation to provide the HRM Service, but will use
        reasonable endeavours to minimise the impact of such circumstances. </div>

        <div class="heading">2.6 Service limitations - General</div>
        <div>Where the Supplier reasonably determines that:</div>
        <div>(a) electrical works are required to upgrade the property to comply with Australian and New Zealand
        wiring standards;</div>
        <div>(b) the situation presents occupational health and safety risks to the HRM Service Provider; </div>
        <div>(c) the job is considered to be in the province and is the responsibility of utility providers such as
        gas or water meters, gas pipelines, electricity cables and grids or water mains </div>
        <div>(d) there a real risk or danger to the life, health, safety or property of any person or of causing
        significant damage to the environment; or</div>
        <div>(e) in relation to any Good required to provide the HRM Service, a product warranty is supplied by the
        manufacturer of the Good (Manufacturer’s Warranty) which requires an authorised service agent as determined
        by the manufacturer to complete warranty repairs (Authorised Service Agent).</div>
        <div>(f) the service provider reasonably determines that they cannot complete the requested works</div>

        <div class="heading">2.7 Service limitations - Drainage Systems</div>
        <div>(a) Clearing of blocked drains and inspection of blocked and damaged drains is conducted at your risk.
        The Supplier will not be held responsible for the location or condition of existing drainage systems, nor
        the products or materials located within the drainage systems.</div>
        <div>(b) Investigation and maintenance of drains involves insertion of specialised drainage equipment into
        existing drains, to conduct works or investigations. You accept responsibility for any damage caused to the
        plumbing drainage system that maybe caused while maintenance works to drainage system is conducted.</div>
        <div>(c) You acknowledge that pipes affected by root growth and or blockages are generally damaged as a result
        of such interference. The removal of root growth and / or blockages alone carries no warranty that similar
        problems will not recur unless permanent repairs are made.</div>

        <div class="heading">2.8 Service exclusions</div>
        <div>The HRM Service is not available for:</div>
        <div>(a) non-residential properties, such as properties used for commercial, retail, office, industrial or
        professional purposes;</div>
        <div>(b) portable, temporary or non-fixed dwellings;</div>
        <div>(c) buildings or structures such as caravans, trailers, campervans, recreational or other vehicles;</div>
        <div>(d) product warranty repairs where a Manufacturer’s Warranty is provided which requires an Authorised
        Service Agent to complete warranty repairs;</div>
        <div>(e) failure of alarms, home security systems and CCTV;</div>
        <div>(f) damaged swimming pools including parts, components, pumps, motors and plumbing or filtration systems;
        </div>
        <div>(g) damaged solar power systems or components;</div>
        <div>(h) damaged garden appliances, sprinkler or watering systems;</div>
        <div>or in response to:</div>
        <div>(i) Area Wide Disruption to Essential Services;</div>
        <div>(j) disruption to essential services as the result of disconnection by the relevant authority; or </div>
        <div>(k) Major Disasters.</div>

        <div class="heading">2.9 Service Cancellation</div>
        <div>(a) You may cancel a request for the provision of HRM Services (<b>HRM Service Request</b>) no later than
        24 hours before the agreed date and time for the Supplier to attend your property.</div>

        <div class="heading">3. Pricing and Payment</div>

        <div class="heading">3.1 Price</div>
        <div>(a) The Supplier may provide you with a verbal or electronic estimate for the HRM Service (Estimate). The
        Estimate is provided on the basis that you have provided full and frank disclosure of the nature and extent
        of the HRM Services required and given all material information to the Supplier (to the extent reasonably
        possible). Subject to section 3.1(b), the Estimate is valid for 14 days after it is given.</div>
        <div>(b) Upon arrival at your property the Supplier will review the Estimate and conduct a full assessment of
        the HRM Service to be provided (Scope).</div>
        <div>(c) The price payable for the delivery of the HRM Service provided to you is the Supplier’s formally
        quoted price provided pursuant to clause 3.1(b) above (Quote).</div>
        <div>(d) During the HRM Service, should there by a significant change to the Scope, the Supplier will provide
        a new Scope and Quote for acceptance by you.</div>
        <div>(e) In some circumstances an Estimate may not be possible and the Supplier will attend your property to
        provide a Quote. This attendance will be free of charge except in the event that the Supplier is unable to
        complete the Quote without undertaking any material and / or substantial fault finding work (eg excavation,
        use of specialised equipment) in order to diagnose and / or assess the issue. In this event, a fee may be
        payable in order to complete this diagnosis / assessment which will be provided to you for approval prior to
        commencement. Once the issue has been determined, a Quote will be provided for acceptance prior to
        commencing the HRM Service. </div>
        <div>(f) Discounted labour rates apply if you are a member of The Royal Automobile Club of W.A. (Inc). </div>
        <div>(g) Additional Services: </div>
        <div>If, during the performance of the HRM Service, the Supplier discovers or is requested by you that
        Additional Services are required:</div>
        <div>(i) the Supplier will notify you of the Additional Services and the applicable Quote for acceptance; and
        </div>
        <div>(ii) the Supplier may, in its discretion, elect to perform all or part of the Additional Services, having
        regard to the nature and scope of the Additional Services involved and the service exclusions in section
        2.8. </div>
        <div>(h) The Supplier is excluded from any liability to you in connection with any loss or damage you suffer
        if you decide not to proceed with any or all of the Additional Services required, if applicable.</div>
        <div>(i) All amounts payable by you to the Supplier are inclusive of GST, unless expressly stated otherwise.
        </div>

        <div class="heading">3.2 Payment</div>
        <div>(a) Payment is due on completion of the HRM Service and before the Supplier leaves your property.</div>
        <div>(b) Payment may be made by credit card, debit card or any other method of payment specified by the
        Supplier.</div>
        <div>(c) The Supplier will provide a receipt upon payment and send to you via the email you provide.</div>

        <div class="heading">4. Defects</div>
        <div>(a) You may, within the period of 12 months from the date the HRM Services are performed (Warranty
        Period), notify the Supplier of any HRM Services that you reasonably consider to be defective, as supplied
        by the Supplier, in any material respect, by giving written notice to the Supplier to that effect (Defect
        Notice). You must afford the Supplier an opportunity to inspect all alleged defective HRM Services
        (including any Goods) as soon as practicable following the issue of a Defect Notice.</div>
        <div>(b) Subject to sections 4(a) and 4(c), if the HRM Services are defective in any material respect, the
        Supplier’s liability is limited to:</div>
        <div>(i) for Goods – either replacing the Goods or repairing the Goods; or</div>
        <div>(ii) for HRM services – re-performing or otherwise remedying the defective HRM services.</div>
        <div>(c) The Supplier’s obligation to take the actions in section 4(b) are conditional upon the following:
        </div>
        <div>(i) for Goods not manufactured by the Supplier:</div>
        <div>A. the only warranty applicable to those Goods is the current Manufacturer’s Warranty (if any); and</div>
        <div>B. the Supplier will not be liable to repair or replace a Good if the Manufacturer’s Warranty requires an
        authorised service agent as determined by the manufacturer to complete warranty repairs (Authorised Service
        Agent) to complete warranty repairs; and</div>
        <div>C. if sub-section B applies, the Supplier’s obligation will be limited to referring your warranty claim
        to the Authorised Service Agent;</div>
        <div>(ii) the Supplier does not have any liability to the extent that any damage or defect is caused, directly
        or indirectly, by any act or omission on your part;</div>
        <div>(iii) defects or damage to HRM Services which are caused or relate to any of the following are excluded;
        </div>
        <div>A. your failure to properly maintain any Goods;</div>
        <div>B. your failure to follow any instructions or guidelines provided by the Supplier and/or the
        manufacturer;</div>
        <div>C. any use of the Goods otherwise than for any application specified in any instructions or guidelines
        provided by the Supplier and/or the manufacturer or for their ordinary use;</div>
        <div>D. the continued use of any Goods after any defect becomes apparent or would have become apparent to a
        reasonably prudent operator or user; or</div>
        <div>E. fair wear and tear or any accident; and</div>
        <div>(iv) the Supplier will not be liable if the workmanship in relation to Goods or HRM Services is repaired,
        altered or overhauled without the Supplier’s prior written consent.</div>

        <div class="heading">5. Risk and title</div>
        <div>(a) All risk for the Goods passes to you on delivery of those Goods. This applies to the extent that it
        is not inconsistent with any other arrangement agreed between you and the Supplier in writing.</div>
        <div>(b) Title in the Goods will not pass until you have paid all amounts owing for the particular Goods.
        </div>

        <div class="heading">6. Warranties</div>
        <div>(a) In addition to your rights under the Australian Consumer Law, the Supplier warrants that:</div>
        <div>(i) the HRM Service and each item of the Goods supplied: </div>
        <div>A. is fit for purpose; </div>
        <div>B. is free from defects in materials and workmanship; </div>
        <div>C. is suitable for normal use as reasonably contemplated by you;</div>
        <div>D. performs and will continue to perform at the level consistent with the published specifications and
        any other written or verbal representations made by the Supplier or manufacturer; and </div>
        <div>(ii) it shall at all times: </div>
        <div>A. use appropriate Goods and Materials of highest quality;</div>
        <div>B. employ appropriate techniques and standards; and</div>
        <div>C. provide all HRM Services with due care, skill and attention.</div>
        <div>(b) To the maximum extent permitted by law, any term, condition, guarantee or warranty which would
        otherwise be implied into these Terms is excluded.</div>

        <div class="heading">7. Privacy</div>
        <div>(a) The Supplier may collect your personal information (as defined in the Privacy Act 1988 (Cth)) to
        provide you with the HRM Service.</div>
        <div>(b) The Supplier will disclose your personal information to RAC and you consent to such disclosure. RAC
        will treat your personal information in accordance with its Privacy Policy located on
        www.rac.com.au/privacy.</div>
        <div>(c) The Supplier may have to disclose your personal information to other third parties who assist with
        the provision of the HRM Service.</div>
        <div>(d) If you don’t provide the information requested, the Supplier may not be able to provide the HRM
        Service.</div>
        <div>(e) You may request to access the information collected by contacting the Supplier on 1300 655 057.</div>

        <div class="heading">8. Bookings via website</div>
        <div>(a) To use the Website and make a Booking, you must first agree to these Terms.</div>
        <div>(b) You accept these Terms by clicking accept/agree to the Terms.</div>
        <div>(c) Notwithstanding clause 8.(b), you may not accept the Terms or access the Website if:</div>
        <div>(i) you are not of legal age to form a binding contract with the Supplier; or</div>
        <div>(ii) you are a person barred from entering into contracts under legislation,</div>
        <div>(d) You confirm that you are at least 18 years of age and possess the legal authority to use the Booking
        System in accordance with these Terms. You also agree that all information supplied by you in using the
        Website and the Booking System will be true and accurate at all times.</div>
        <div>(e) All locksmith and other related security services are advertised and procured by RAC Security under its Security Agent Licence number SA 45421.</div>
        <div>(f) If you do not understand any part of the Terms you should seek independent legal advice.</div>

        <div class="heading">9. Booking System</div>
        <div>(a) To make a Booking you must use the Booking System to request a Booking. You will receive a Booking
        confirmation, that constitutes the contractual relationship between you and the Supplier that you are deemed
        to have entered for the provision of the HRM Services.</div>
        <div>(b) You can request a preferred booking window. The Supplier will take your preference into account when
        facilitating the HRM Service, however a specific booking window cannot be guaranteed and will depend on
        availability.</div>
        <div>(c) If the requested Booking cannot be facilitated, the Supplier will arrange an alternative time for the
        HRM Service with you.</div>
        <div>(d) Once a booking window is confirmed the Supplier will make all efforts to attend within this window,
        however cannot guarantee that the Supplier will arrive within this timeframe. Should this be the case, the
        Supplier will contact you to advise.</div>
        <div>(e) The coordination and booking of HRM Services can only occur via the Booking System on the Website or
        via phone call to the Call Centre.</div>
        <div>(f) The Booking System is provided on the Website to enable you to make legitimate Bookings and for no
        other purposes.</div>
        <div>(g) Any speculative, false or fraudulent Booking is prohibited. You agree that the Booking System will
        only be used to make legitimate Bookings for you or another person for whom you are legally authorised to
        act. You acknowledge that abuse of the Booking System may result in you being denied access to the Booking
        System.</div>
        <div>(h) The Supplier has the right at any time to add, change or withdraw functions available on the Website
        at its own discretion.</div>

        <div class="heading">10. Dispute Resolution Policy</div>
        <div>(a) The Supplier will use reasonable endeavours to resolve any dispute concerning the use of the Website.
        </div>
        <div>(b) The Supplier will use reasonable endeavours to resolve any dispute concerning the delivery of the HRM
        Service and should be given an opportunity to inspect the Goods or HRM Service and may need to do so to deal
        with any dispute. </div>

        <div class="heading">11. Indemnity and Liability</div>
        <div>(a) You agree to indemnify the Supplier, on demand, against any claim, action, damage, loss, liability,
        cost, charge, expense or payment which the Supplier may pay, suffer, incur or are liable for, in relation to
        any act you do or cause to be done, in breach of these Terms.</div>
        <div>(b) The Supplier will not be liable to you in contract, tort or equity in relation to any direct,
        indirect or consequential loss you incur in relation to the contents or use of or reliance on the contents
        of the Website.</div>
        <div>(c) To the extent permitted by law, GHR will not be responsible and will be excluded from all liability,
        for any loss or damage whatsoever (including personal injury, loss of life and damage to property) that you
        or another person may suffer in connection with the offer, advice or supply of (or default in supplying) the
        HRM Services.</div>
        <div>(d) You acknowledge and agree that some of the Site Content may be provided by way of blogs or comments
        made by other users of the Website, and that the Supplier does not accept any liability for the accuracy of
        such information or your reliance on the same. The Site Content is provided to you as general information
        only and is not intended to substitute or replace the advice of a duly qualified professional (where
        applicable).</div>

        <div class="heading">12. General</div>
        <div>(a) The laws of Western Australia govern these Terms. </div>
        <div>(b) A term or part of a term in these Terms that is void, illegal or unenforceable may be severed from
        the terms and the remaining terms continue in force.</div>
        <div>(c) The Supplier may subcontract some or all of the HRM Service. </div>
        <div>(d) The Supplier reserves the right to amend these Terms and any changes will take effect from the date
        of notification.</div>
        <div>(e) Any notice requires to be served on a party may be served personally, sent by email or letter
        addressed to that party at the party’s address specified in the Service Invoice. </div>

        <div class="heading">13. Interpretation </div>
        <div>In these Terms: </div>
        <div>(a) Additional Services means services provided by the Supplier which are in addition to and are distinct
        from the HRM Services originally contemplated. For the avoidance of doubt, Additional Services are
        considered to be those extra services requested by you once the Supplier arrives at your property and are
        not simply changes or variations to those HRM Services originally contemplated.</div>
        <div>(b) Area Wide Disruption to Essential Services means where essential services in an area are disrupted or
        affected due to an event or incident, including where there is a power blackout affecting a street or suburb
        caused by electricity lines or the local electricity grid being down due to a major storm. </div>
        <div>(c) Authorised Service Agent has the meaning given in section 4(c)(i)B.</div>
        <div>(d) Booking means booking made by you on the Website for the provision of HRM Services.</div>
        <div>(e) Booking System means online system which enables you to make a Booking.</div>
        <div>(f) Defect Notice has the meaning given in section 4(a).</div>
        <div>(g) Estimate has the meaning given in section 3.1(a).</div>
        <div>(h) HRM Service means the services described in section 2, and will include Additional Services when the
        context so requires.</div>
        <div>(i) HRM Service Provider has the meaning given in section 2.1(b).</div>
        <div>(j) Goods means any materials, parts, appliances or equipment purchased from the Supplier by the Customer
        as part of the provision of the Services.</div>
        <div>(k) GST means a tax imposed under the A New Tax System (Goods and Services Tax) Act 1999 (Cth).</div>
        <div>(l) Major Disasters include earthquakes, tornadoes, hurricanes or cyclones, explosions, fire, flood,
        political or industrial disturbances, riots or civil commotion, tsunami, tidal wave, storm surge, landslide,
        acts of terrorism or war and use, existence or escape of any nuclear or radioactive material, any biological
        chemical, nuclear pollution or contamination, , or any other event that either or both of RAC and the
        Insurance Council of Australia declare as a major disaster or catastrophic event.</div>
        <div>(m) Manufacturer’s Warranty has the meaning given in section 4(c)(i)B.</div>
        <div>(n) Quote has meaning given in 3.1(c).</div>
        <div>(o) Materials means any products purchased by the Supplier as part of the installation and/or repair of
        the Goods.</div>
        <div>(p) RAC means The Royal Automobile Club of W.A. (Inc), RACWA Holdings Pty Ltd (ACN 008 985 877) and its
        subsidiaries and includes their officers, employees and agents.</div>
        <div>(q) Scope has meaning given in 3.1(b)</div>
        <div>(r) Site Content means the word and images on Website.</div>
        <div>(s) Supplier means Global Home Response Pty Ltd as Trustee for Global Home Response Unit Trust (ABN 96
        350 743 6000) including its officers, employees and agents and where the context so requires, the HRM
        Service Provider.</div>
        <div>(t) Warranty Period has the meaning given in section 4(a).</div>
        <div>(u) Website means the RAC online pages related to the HRM Service or the Supplier’s online pages required
        for Bookings.</div>
        <div>(v) Terms means these terms and conditions.</div>
    </div>
    <div class="privacyContainer" *ngIf="clientID == clients.AANZ">
        <div class="title">AA Home Book a Job Terms and Conditions</div>
        <div class="heading"></div>
        <div>
            These Terms and Conditions are for <strong>Book a Job services</strong> booked through AA Home.
            <br /><br />
            Throughout these Terms and Conditions, there are words in bold. These words have a specific meaning and are explained in the ‘Definitions’ section at the end of this document. In addition to this, the words ‘we’, ‘us’ or ‘our’ mean AA Home Limited. The words ‘you’ or ‘your’ mean the subscriber.
            <br /><br />
            We encourage you to review these terms and conditions on a regular basis, as they may be updated from time to time.
        </div>

        <div class="heading">Book a Job services</div>
        <div>
            Where your property is within the <strong>Book a Job service area, Book a Job service</strong> is available and includes those jobs that a <strong>service provider</strong> has expertise and capacity to undertake. 
            <br /><br />
            You or a person you nominate must be present at your property for the Book a Job service. Where assistance is required to enter your property, proof of identity will be required prior to the home being unlocked. This can be in the form of a current NZ drivers licence, passport, or 18+ card.
            <br /><br />
            A <strong>service provider</strong> may refuse to deliver <strong>Book a Job service</strong> at its own discretion.
            <br /><br />
            We use reasonable efforts to monitor the quality of <strong>Book a Job service</strong>s offered by a <strong>service provider</strong>. Any issues regarding safety, quality, condition or description should be notified to us.
            <br /><br />
            If you have a complaint of any nature, please report this to AA Home within seven (7) days following <strong>service</strong>. Supporting material and evidence will be required.
        </div>

        <div class="heading">Booking system</div>
        <div>
            Bookings for <strong>Book a Job service</strong> are made through this online <strong>booking system.</strong> When received, we will pass your booking request to the most appropriate <strong>service provider.</strong>
            <br /><br />
            You will be able to request a preferred booking window, which will be taken into account, however the provision of a specific booking window will depend on availability.
            <br /><br />
            Where a <strong>Book a Job service</strong> cannot be facilitated as requested, we will reasonably endeavor to arrange an alternative time for the <strong>Book a Job service</strong> with you and a <strong>service provider.</strong>
            <br /><br />
            We may refuse to offer <strong>Book a Job service</strong> where we reasonably suspect any speculative, fraudulent, abuse or other misuse in relation to the booking system or <strong>services.</strong>
            <br /><br />
            Once a <strong>Book a Job service</strong> booking is confirmed, this create a contractual obligation between you and us. 
            <br /><br />
            We reserve the right at any time to add, change or withdraw particular <strong>Book a Job service</strong> at our own discretion.
        </div>

        <div class="heading">Pricing and Payment</div>
        <div>
            Upon booking a <strong>Book a Job service</strong>, you will be provided with an estimated price to complete the job based on information you provided and the <strong>service provider’s</strong> experience delivering these services.
            <br /><br />
            If required, the <strong>service provider</strong> attending to complete your <strong>Book a Job service</strong> will conduct an assessment and will either confirm that the job can be completed for the price estimate provided or advise you of any additional costs required to complete the job.
            <br /><br />
            Either before commencing or during the job, you will be required to sign off acceptance of the final price, this sign off constitutes agreeance that you will make payment in full upon completion of the job.
            <br /><br />
            If you do not agree to the final price, this will constitute a cancellation. You can cancel or amend a <strong>Book a Job service</strong> free of charge, up to 24 hours before the <strong>Book a Job service</strong> is scheduled to begin. If you cancel a <strong>Book a Job service</strong> within 24 hours before the <strong>Book a Job service</strong> is scheduled to begin, you may have to pay a cancellation fee of $35.
            <br /><br />
            On completion of the job, you will be sent a link to a payment gateway where you will be expected to pay the final price by a valid Credit Card (VISA / Mastercard).
            <br /><br />
            In the event of an outstanding fees being owed to AA Home, a <strong>service provider</strong> will not be dispatched to your property until full payment of the fee, including any applicable surcharges, is received.
        </div>

        <div class="heading">Your Property</div>
        <div>
            Your property must be located within the Book a Job service area, reasonably accessible by a <strong>service provider’s</strong>’s vehicle on a formed public road, and must be a residential address which is not used for:
            <br /><br />
            <ul>
                <li>commercial, retail, office, industrial or professional purposes, or</li>
                <li>commercial farming or agricultural purposes.</li>
            </ul>
            <br /><br />
            Your property must not be a portable, temporary or non-fixed dwelling, building or structure such as a caravan, trailer, campervan, recreational vehicle or otherwise.
        </div>

        <div class="heading">Make Safe Procedures</div>
        <div>
            <strong>Reusable materials</strong> provided in <strong>make safe</strong> procedures remain the property of, and are returnable to the <strong>service provider</strong> once the <strong>Book a Job service</strong> is complete but no later than 30 days following <strong>Book a Job service</strong>. These reusable materials must not be removed from your property, willfully damaged, sold, loaned, or used for any purpose other than that for which they were provided. Should any loss or damage to <strong>reusable materials</strong> occur, you may be held liable for this.
        </div>

        <div class="heading">Environmental and Occupational Health and Safety</div>
        <div>
            <strong>Book a Job services</strong> will not be provided or may be limited where, in the opinion of the <strong>service provider</strong> attending, there is a risk to the life, health, safety or property of any person, or where there is a risk of causing significant damage to the environment. Such risks may include, but are not limited to, the requirement for scaffolding due to the height of a building or issues caused by weather.
        </div>

        <div class="heading">Your Responsibilities</div>
        <div>
            It is your responsibility to maintain your home in good order; otherwise the <strong>service provider</strong> may be unable to assist. AA Home reserves the right to limit or refuse to provide Book a Job service where, in the opinion of AA Home, any of the following responsibilities are not adhered to:
            <br /><br />
            <ul>
                <li>Upon request for <strong>Book a Job service</strong>, you must advise of the presence of dogs or any other known hazards</li>
                <li>You must act in a reasonable manner towards AA Home and the <strong>service provider</strong>. You or any other person at the property must not act or behave in a manner that is inappropriate, improper, hostile, threatening, abusive or dangerous</li>
                <li>Your use of <strong>Book a Job services</strong> must not be excessive or unreasonable.</li>
            </ul>
        </div>

        <div class="heading">Authorisation</div>
        <div>
            By booking <strong>Book a Job service</strong>, you:
            <br /><br />
            <ul>
                <li>represent that you have authority, and accordingly authorise that the <strong>Book a Job service</strong> be undertaken at your property;</li>
                <li>authorise AA Home to share your <strong>personal information</strong> with our third party <strong>service providers</strong> in order to provide you with the <strong>Book a Job service</strong>;</li>
                <li>agree to be bound by these Terms and Conditions and our Privacy Policy on the AA Home website <a href="http://www.aahome.co.nz" target="_blank">www.aahome.co.nz</a></li>
            </ul>
        </div>

        <div class="heading">Liability</div>
        <div>
            Without limiting any rights you may have at law which may not be excluded, including under the Consumer Guarantees Act 1993, AA Home may accept liability for damage to your property directly caused by <strong>Book a Job services</strong> where notice of the damage is given to AA Home within seven (7) days of service. In these cases, AA Home must be given the opportunity to inspect the property within a timeframe which is agreed between you and AA Home, and before further repairs are carried out. To the maximum extent permitted by law, liability will only be accepted should it be found that the damage was directly caused by the negligence of the <strong>service provider.</strong>
            <br /><br />
            Given the nature of the services, to the maximum extent permitted by law, AA Home does not represent that the property (or part thereof) to which it provides service will be, or will remain for any period of time, in working order.
            <br /><br />
            AA Home accepts no liability for providing <strong>Book a Job services</strong> which would void a warranty, cause you to breach a contractual obligation, or prejudice or adversely affect an existing or subsequent insurance claim or legal action. It is your responsibility to advise the <strong>service provider</strong> not to repair an item where doing so may affect you in this manner.
            <br /><br />
            To the maximum extent permitted by law, AA Home will not be liable or in default for any failure or delay in providing <strong>Book a Job services</strong>, either in whole or in part, where failure or delay arises directly or indirectly out of causes beyond the reasonable control of AA Home. This includes, but is not limited to, a <strong>major disaster</strong>; adverse weather conditions; unavailability of materials, parts, qualified personnel, equipment, fuel or the like; failures in telecommunications, satellite and global positioning systems; and otherwise where the <strong>Book a Job service</strong> is not reasonably available.
            <br /><br />
            To the maximum extent permitted by law, AA Home will not be liable for any type of <strong>consequential loss</strong> as a result of <strong>Book a Job services.</strong>
            <br /><br />
            Despite anything to the contrary contained or implied in these terms and conditions, these terms and conditions do not exclude, restrict or modify the application of any statutory provision where to do so would contravene any applicable law or cause any part of these terms and conditions to be void.
        </div>

        <div class="heading">Privacy Disclaimer</div>
        <div>
            AA Home is committed to protecting your privacy and acting in accordance with the Privacy Act 1993. You should read the Privacy Policy on the AA Home website www.aahome.co.nz. We encourage you to review our Privacy Policy on a regular basis, as it may be updated from time to time.
        </div>

        <div class="heading">Governing Law</div>
        <div>
            These Terms and Conditions are governed by New Zealand law, and the New Zealand courts have exclusive jurisdiction over any legal proceedings about them.
        </div>

        <div class="heading">Definitions and Interpretation</div>
        <div>
            <strong>Book a Job service</strong>
            <br /><br />
            Work available in a <strong>Book a Job service area</strong> that a <strong>service provider</strong> has expertise and capacity to undertake.
            <br /><br />
            <strong>Book a Job service area</strong>
            <br /><br />
            The areas available for <strong>Book a Job service</strong> as listed on the AA Home website www.aahome.co.nz.
            <br /><br />
            <strong>consequential loss</strong>
            <br /><br />
            Additional expense, cost, liability or loss arising as a consequence of <strong>Book a Job service</strong>. This includes any loss of income, loss of value, loss of use, additional costs, and liability
            <br /><br />
            <strong>make safe</strong>
            <br /><br />
            The work required to carry out a temporary repair to the property in order to reduce risk of further damage occurring and creating, as far as is reasonably practicable, a secure and safe environment until permanent repairs can be carried out. This work does not provide a permanent repair to the damaged area.
            <br /><br />
            <strong>reusable materials</strong>
            <br /><br />
            Materials which are used for <strong>make safe</strong> which are returnable to the <strong>service provider</strong>. You will be advised of these during the <strong>service period</strong>.
            <br /><br />
            <strong>service provider</strong>
            <br /><br />
            An employee, supplier, agent or contractor engaged by AA Home to provide <strong>Book a Job services</strong> on its behalf.
        </div>
    </div>
</my-content>
