<my-content placeholder="main">
  <div class="popupBlocker" *ngIf="showEmergencyPopup"></div>
  <div class="popupContainer" *ngIf="showEmergencyPopup">
    <div class="popupHeader">
      <div class="popupTitle">PLEASE NOTE</div>
      <div class="popupClose buttonPointer" (click)="toggleEmergencyPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        Call us now on <b>{{ metaData.contactEmergencyNumber }}</b>
        <span *ngIf="clientID == clients.AANZ"> and we will arrange to send a plumber as soon as possible.</span>
        <span *ngIf="clientID != clients.AANZ">{{ clientID == clients.RACWA ? 'at any time and one of our team will be able to help you' : 'between the hours of ' + metaData.contactPhoneHours + ' and we will arrange to send a plumber as soon as possible' }}.</span>
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleEmergencyPopup(false)">Confirm</div>
      </div>
    </div>
  </div>

  <div class="stepContainer" *ngIf="showPostcodeCheck">
    <div class="postcodeStep">
      <div class="stepTitle">WHERE IS YOUR JOB?</div>
      <div class="stepDescription" *ngIf="jobDataService.trade.id != tradeTypes.StormReadiness">{{ coverageAreaText }}</div>
      <div class="stepDescription" *ngIf="jobDataService.trade.id == tradeTypes.StormReadiness">
          The Storm Readiness Service is available in Townsville and Cairns metro areas.
      </div>
      <input style="margin-top: 160px;" type="tel" placeholder="Enter your postcode" [(ngModel)]="postcode"
        class="postcodeText" />
      <button type="button" (click)="checkPostcode()" class="wizardButton searchNowButton">CHECK</button>
    </div>
  </div>
  
  <div class="stepContainer" *ngIf="showMemberShipCheck">
      <div style="text-align: center;">
        <div class="stepTitleRaw">{{ jobDataService.capability.memberShipConfig.question }}</div>
        <div class="stepDescription">{{ jobDataService.capability.memberShipConfig.description }}</div>
        <div *ngIf="jobDataService.capability.memberShipConfig.questionType === questionTypes.YesNo">
          <div class="yesNoButtonContainer">
            <div class="optionSelect standardButton buttonPointer yesNoButton"
                (click)="setMemberShipAnswer(true)">YES</div>
            <div class="optionSelect standardButton buttonPointer yesNoButton"
              (click)="setMemberShipAnswer(false)">NO</div>
          </div>
        </div>
    </div>
  </div>

  <div class="stepContainer" *ngIf="showCommonPropertyCheck">
    <div style="text-align: center;">
      <div class="stepTitleRaw">IS YOUR JOB ON COMMON PROPERTY?</div>
      <div class="commonPropertyLink"><a href="assets/img/3013/commonproperty.jpg" target="_blank"><span class="commonPropertyClick">Click here</span> for information on common property.</a></div>
      <div class="yesNoButtonContainer">
        <div class="optionSelect standardButton buttonPointer yesNoButton"
        (click)="setCommonPropertyAnswer(true)">YES</div>
        <div class="optionSelect standardButton buttonPointer yesNoButton"
        (click)="setCommonPropertyAnswer(false)">NO</div>
      </div>
    </div>
  </div>
 
  <div *ngIf="showAreaNotCovered" class="stepContainer">
    <div class="postcodeStep" *ngIf="!emailSubmitted">
      <!-- <div *ngIf="clientID != clients.AMA">
        <div class="stepTitle">SORRY, THIS SERVICE IS NOT CURRENTLY AVAILABLE IN YOUR AREA</div>
        <div class="stepDescription">We are currently expanding our coverage area, please enter your email below if you
          would like to be notified when we are servicing this area.</div>
        <input type="text" [(ngModel)]="serviceAreaEmail" placeholder="Enter your email address" [(ngModel)]="email"
          class="postcodeText" />
        <button type="button" (click)="submitServiceAreaEmail()" class="wizardButton searchNowButton">SUBMIT</button>
      </div> -->
      <div *ngIf="clientID != clients.AANZ">
        <div class="stepTitle">SORRY, THIS SERVICE IS NOT CURRENTLY AVAILABLE ONLINE</div>
        <div class="stepDescription">Please call us to discuss your booking request.</div>
        <div class="stepTitle notAvailablePhone"><a href="tel:{{ metaData.contactPhoneNumber }}">{{ metaData.contactPhoneNumber }}</a></div>
      </div>
      <div *ngIf="clientID == clients.AANZ">
        <div class="stepTitle">SORRY, THIS SERVICE IS NOT CURRENTLY AVAILABLE IN YOUR REGION</div>
      </div>
    </div>
    <div *ngIf="emailSubmitted">
      <div class="stepTitle">THANK YOU FOR YOUR FEEDBACK</div>
      <div class="stepDescription">We've kept a record of your request and will be in touch as soon as we can service
        your area.</div>
      <button type="button" class="wizardButton searchNowButton" [routerLink]="['/home']">HOME</button>
    </div>
  </div>

  <div *ngIf="showServicesList" class="stepContainer" style="margin-bottom: 30px;">
    <div class="stepTitle">{{ trade.name.toUpperCase() }} SERVICES</div>
    <div class="stepDescription">Please select <span style="text-decoration: underline;">all</span> the services you need.</div>
    <div class="jobTypeContainer greyBox buttonPointer" [class.selected]="jts.selected == true" *ngFor="let jts of jobTypeSelectors" (click)="jobTypeClick(jts)">
      <div class="jobTypeIcon">
        <img src="assets/img/{{ clientID }}/icons/jobtypes/{{ jts.jobType.icon }}" />
      </div>
      <div class="jobTypeName">
          {{ jts.jobType.name }}<br /><span class="inclusions">{{ jts.jobType.inclusions }}</span>
      </div>
      <div style="clear: both;"></div>
    </div>
    <button type="button" class="wizardButton searchNowButton" *ngIf="bundlingEnabled == true" (click)="selectJobTypes()">SELECT SERVICES</button>
  </div>

  <div class="popupBlocker" *ngIf="emailSubmitLoading"></div>
  <div class="loadingSpinner" *ngIf="emailSubmitLoading">
    <fa-icon style="font-size: 3em;" [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>

  <div class="popupBlocker" *ngIf="metaDataLoading"></div>
  <div class="loadingSpinner" *ngIf="metaDataLoading">
    <fa-icon style="font-size: 3em;" [icon]="faSpinner" [spin]="true"></fa-icon>
  </div>

  <!-- COMMON PROPERTY CALL POPUP -->
  <div class="popupBlocker" *ngIf="showCommonPropertyCallPopup"></div>
  <div class="popupContainer" *ngIf="showCommonPropertyCallPopup">
    <div class="popupHeader">
      <div class="popupTitle">COMMON PROPERTY JOBS</div>
      <div class="popupClose buttonPointer" (click)="toggleCommonPropertyCallPopup(false)">X</div>
    </div>
    <div class="popupBody">
      <div class="popupContent">
        It looks like you need work done on common property, please call us on {{ metaData.contactPhoneNumber }} between the hours of {{ metaData.contactPhoneHours }}
      </div>
      <div class="popupButtons">
        <div class="standardButton buttonPointer" (click)="toggleCommonPropertyCallPopup(false)">Close</div>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <!-- END COMMON PROPERTY CALL POPUP -->
</my-content>