import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

import { Clients } from '../services/globals.service';

@Component({
  selector: 'HomeServices-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  public clients: Clients = null;
  public clientID: number = 0;


  constructor(_clients: Clients) {
    this.clients = _clients;
    this.clientID = environment.clientID;
  }

  ngOnInit() {
  }

}
