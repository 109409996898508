<my-content placeholder="main">
  <div class="completeContainer">
    <div class="stepTitle">BOOKING RECEIVED</div>
    <div class="jobReferenceTitle">Your job reference number is</div>
    <div class="jobReference">{{ jobData.addedJobRef }}</div>
    <div class="completeInfo">We have sent you an email confirmation with your booking details.</div>

    <div class="optionSelect standardButton buttonPointer backToHome bookAnotherJob" [routerLink]="['/home']">Book Another Job</div>

    <div class="completeStepsContainer">
      <div class="completeStep">
        <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step2.png" /></div>
        <div class="completeStepInfo">
          <div class="completeStepHeading">STEP 1</div>
          <div class="completeStepDetails">
            {{ 'Complete.Step1' | translate:pageContentParams }}
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>
      
      <div class="completeStep">
        <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step3.png" /></div>
        <div class="completeStepInfo">
          <div class="completeStepHeading">STEP 2</div>
          <div class="completeStepDetails">
            {{ 'Complete.Step2' | translate:pageContentParams }}
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>

      <div class="completeStep">
        <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step4.png" /></div>
        <div class="completeStepInfo">
          <div class="completeStepHeading">STEP 3</div>
          <div class="completeStepDetails">
            {{ 'Complete.Step3' | translate:pageContentParams }}
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>

      <div class="completeStep">
        <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step5.png" /></div>
        <div class="completeStepInfo">
          <div class="completeStepHeading">STEP 4</div>
          <div class="completeStepDetails">
            {{ 'Complete.Step4' | translate:pageContentParams }}
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>

      <div class="completeStep">
        <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step6.png" /></div>
        <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 5</div>
          <div class="completeStepDetails">
            {{ 'Complete.Step5' | translate:pageContentParams }}
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>
      
      <!-- RACWA only step -->
      <div class="completeStep" *ngIf="clientID == clients.RACWA">
        <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step6.png" /></div>
        <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 6</div>
          <div class="completeStepDetails">
            We'll email you a completion report and customer survey
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>
      
      <div class="contactNumberContainer">
        <div class="contactNumberInfo">If you have any further questions please contact</div>
        <div class="contactNumber">{{ 'Complete.ContactInfo' | translate:pageContentParams }}</div>
      </div>

      <div class="ratingContainer" *ngIf="showRatingArea && ratingEnabled">
        <div class="starsContainer" *ngIf="showRatingStars">
          <div class="ratingHeading">RATE YOUR ONLINE EXPERIENCE</div>
          <div class="ratingStarsContainer" *ngIf="showRatingStars">
            <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
          </div>
        </div>
        <div class="commentContainer" *ngIf="!showRatingStars">
          <div class="ratingHeading">LEAVE A COMMENT</div>
          <div class="ratingStarsContainer">
            <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
          </div>
          <textarea style="margin-top: 35px;" [(ngModel)]="ratingComment"></textarea>
          <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 25px;" (click)="submitRatingComment()">Submit</div>
        </div>
      </div>
      <div class="ratingContainer" *ngIf="!showRatingArea && ratingEnabled">
        <div class="ratingHeading">THANK YOU FOR YOUR FEEDBACK</div>
      </div>

      <div class="optionSelect standardButton buttonPointer backToHome" [routerLink]="['/home']">Back to Home</div>

    </div>
  </div>
</my-content>