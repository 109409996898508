<my-content placeholder="main">
    <div class="completeContainer">
      <div class="stepTitle">BOOKING RECEIVED</div>
      <div class="jobReferenceTitle">Your job reference number is</div>
      <div class="jobReference">{{ jobData.addedJobRef }}</div>
      <div class="completeInfo">We have sent you an email confirmation with your booking details.</div>
  
      <div class="completeStepsContainer">
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step1.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 1</div>
            <div class="completeStepDetails">
                One of our friendly representatives will be in touch shortly to confirm one of your booking times (our business hours are 8:00am - 5:00pm Mon-Fri AEDT), we will also take payment for your Pre-Purchase Building & Pest Inspection
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step2.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 2</div>
            <div class="completeStepDetails">
              You will receive an email confirming your booking window
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step3.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 3</div>
            <div class="completeStepDetails">
              Our Building Inspector will arrive and complete the Pre-Purchase Building & Pest Inspection
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step4.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 4</div>
            <div class="completeStepDetails">
                Once the job is finished, you will receive an SMS asking you to rate your experience and then an email containing your completed Pre-Purchase Building & Pest Inspection within 24 hours
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="contactNumberContainer">
          <div class="contactNumberInfo">If you have any further questions please contact</div>
          <div class="contactNumber">{{ metaData.contactPhoneNumber }}</div>
        </div>
  
        <div class="ratingContainer" *ngIf="showRatingArea && ratingEnabled">
          <div class="starsContainer" *ngIf="showRatingStars">
            <div class="ratingHeading">RATE YOUR ONLINE EXPERIENCE</div>
            <div class="ratingStarsContainer" *ngIf="showRatingStars">
              <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
            </div>
          </div>
          <div class="commentContainer" *ngIf="!showRatingStars">
            <div class="ratingHeading">LEAVE A COMMENT</div>
            <div class="ratingStarsContainer">
              <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
            </div>
            <textarea style="margin-top: 35px;" [(ngModel)]="ratingComment"></textarea>
            <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 25px;" (click)="submitRatingComment()">Submit</div>
          </div>
        </div>
        <div class="ratingContainer" *ngIf="!showRatingArea && ratingEnabled">
          <div class="ratingHeading">THANK YOU FOR YOUR FEEDBACK</div>
        </div>
  
        <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 30px;" [routerLink]="['/home']">Home</div>
  
      </div>
    </div>
  </my-content>