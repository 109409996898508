/// <reference types="@types/googlemaps" />

import {
  Component,
  NgModule,
  OnInit,
  Injectable,
  Inject,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  NgZone,
  AfterViewInit,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';

import { Location } from '@angular/common';

import { MapsAPILoader } from '@agm/core';

import { MetaDataService, TriageQuestion, TriageQuestionData, JobType, FAQItem } from '../services/meta-data.service';
import { Router } from '@angular/router';
import { JobDataService, HomeServiceItem, TriageResponseItem, JobBookingWindow, JobTimeWindow, JobData, InclusionData, InclusionSection } from '../services/job-data.service';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreModule } from '@angular/fire/firestore';

import { environment } from '../../environments/environment';
import { Clients, QuestionTypes, TradeTypes, JobChannelType } from '../services/globals.service';

import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PricingService, TradeTypePricing, JobTypePricing } from '../services/pricing.service';
import { MatCalendar } from '@angular/material/datepicker';
import { PostcodeManagerService } from '../services/postcode-manager.service';
import { PopupService, SpecialPopupType } from '../services/popup.service';

@Component({
  selector: 'app-triage',
  templateUrl: './triage.component.html',
  styleUrls: ['./triage.component.css']
})
export class TriageComponent implements OnInit {
  
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.jobDataService.trade.jobTypes.length == 1) {
      (<any>window).skipToHome = true;
    }
  }

  // fontawesome icons
  public faSpinner = faSpinner;
  public faTrash = faTrash;

  public isPublicHoliday(d: Date): boolean {var publicHolidayCheck: boolean = false;
    this.postcodeManager.selectedPublicHolidays.forEach(b => {
      if (b.toDateString() == d.toDateString()) {
        publicHolidayCheck = true;
      }
    });

    return publicHolidayCheck;
  } // isPublicHoliday

  public isDateOutOfHours(d: Date): boolean {
    const day = d.getDay();

    var publicHolidayCheck: boolean = this.isPublicHoliday(d);

    if (publicHolidayCheck) {
      return true;
    }

    if (day == 0 || day == 6) {
      return true;
    }

    return false;
  }

  public isOutOfHours(w: BookingWindow): boolean {
    if (w == null) {
      return false;
    }

    if (w.date == null || w.time == null) {
      return false;
    }

    if (this.isDateOutOfHours(w.date)) {
      return true;
    }

    if (w.time.outOfHours) {
      return true;
    }

    return false;
  }

  public dateFilter = (d: Date): boolean => {
    var blackoutDays = environment.defaultBlackoutDays;
    const today = new Date();
    const day = d.getDay();
    
    if (this.jobDataService.trade.overrideBlackoutDays != null
      && this.jobDataService.trade.overrideBlackoutDays != -1) {
      blackoutDays = this.jobDataService.trade.overrideBlackoutDays;
    }
    
    if (today.getHours() <= environment.nextDayBookingEnd && this.postcodeManager.selectedPostcodeNextDayBooking == true) {
      if (this.jobDataService.trade.nextDayBooking == true) {
        blackoutDays = 0;
      }
    }
    
    var publicHolidayCheck: boolean = this.isPublicHoliday(d);

    if (publicHolidayCheck) {
      if (this.outOfHoursEnabled == false) {
        return false;
      }
    }
    
    var diff = Math.abs(d.getTime() - today.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    
    if ((d.getTime() - today.getTime()) < 0) {
      return false;
    }
    
    // block out monday if the booking is being done on a weekend
    // monday = 1, saturday = 6, sunday = 0
    if (diffDays < 5 && environment.blockMondayOnWeekend && d.getDay() == 1 && (today.getDay() == 6 || today.getDay() == 0)) {
      return false;
    }
    
    var diffDaysThreshold = blackoutDays + 1;
    
    if (this.outOfHoursEnabled == false) {
      if (today.getDay() == 6) {
        diffDaysThreshold += 1;
      } else if (today.getDay() + blackoutDays >= 6) {
        diffDaysThreshold += 2;
      }

      /* if (today.getDay() == 4 || today.getDay() == 5) { // thursday and friday
        diffDaysThreshold += 2;
      } else if (today.getDay() == 6) { // Saturday
        diffDaysThreshold += 1;
      } */
    }

    // only current day is blacked out for home inspections
    if (this.jobDataService.trade.id == this.tradeTypes.HomeInspections) {
      diffDaysThreshold = 1;
    }

    // disable blacked out days
    if (diffDays < diffDaysThreshold) {
      return false;
    }
    
    // temporary RACWA holiday trade blackouts
    if (this.clientID == this.clients.RACWA
        && (
          this.jobDataService.trade.id == this.tradeTypes.Handyman
          || this.jobDataService.trade.id == this.tradeTypes.Carpentry
          || this.jobDataService.trade.id == this.tradeTypes.Painting
          || this.jobDataService.trade.id == this.tradeTypes.BrickLaying
          || this.jobDataService.trade.id == this.tradeTypes.BrickPaving
          || this.jobDataService.trade.id == this.tradeTypes.Gardening
          || this.jobDataService.trade.id == this.tradeTypes.HomeInspections
          || this.jobDataService.trade.id == this.tradeTypes.HVAC
        )) {
      if (d >= new Date('2020/12/25') && d <= new Date('2021/1/3')) {
        return false;
      }
    }
    
    if (this.clientID == this.clients.RACWA && this.jobDataService.trade.id == this.tradeTypes.HomeInspections) {
      if (d >= new Date('2020/12/21') && d <= new Date('2021/1/11')) {
        return false;
      }
    }
    
    if (this.clientID == this.clients.RACWA && this.jobDataService.trade.id == this.tradeTypes.HVAC) {
      if (d >= new Date('2020/12/21') && d <= new Date('2021/4/6')) {
        return false;
      }
    }
    
    // can't book more than 120 days in the future
    if (diffDays > 120) {
      return false;
    }

    if (this.outOfHoursEnabled == false) {
      // Prevent saturday and sunday from being selected
      return day !== 0 && day !== 6;
    } else {
      return true;
    }
  }

  public dateClass = (d: Date) => {
    if (this.outOfHoursEnabled == false) {
      return undefined;
    }

    if (this.dateFilter(d) == false) {
      return undefined;
    }

    var oohClass = 'outOfHoursDate';

    if (this.isDateOutOfHours(d)) {
      return oohClass;
    } else {
      return undefined;
    }
  }

  public specialPopupType = SpecialPopupType;

  public bundlingEnabled: boolean = environment.bundlingEnabled;
  
  public productName: string = environment.productName;
  public whereDidYouHearAboutUs: string = '';

  public triageQuestions: Array<TriageQuestionData> = [];
  public currentQuestion: TriageQuestionData = null;

  public standardModel: boolean = true;
  public calculatingPrice: boolean = false;
  
  public confirmEmail: string = '';

  // used to display discounts
  public fullPrice: number = 0;
  public discountApplied: boolean = false;
  public discountText: string = 'MEMBER PRICE';

  public currentPrice: number = 0;
  public estimatedDuration: number = 1;
  //public currentQuantity: number = 1;

  public stepsProgress: Array<boolean> = [];

  public showMoreDetails: boolean = false;
  public showFAQ: boolean = false;
  public showBackToQuestionsButtons: boolean = false;

  public showPopup: boolean = false;
  public popupContent: string = '';
  public showEndPopup: boolean = false;
  public endLoading: boolean = false;
  public showLocksmithPopup: boolean = false;
  public showHandymanPopup: boolean = false;
  public showGoBackPopup: boolean = false;
  public showCarpentryPopup: boolean = false;
  public showStormReadinessPopup: boolean = false;

  public showDeleteServicePopup: boolean = false;
  public deletingJobType: JobType = null;

  public showAddServicesPopup: boolean = false;

  public faqItems: Array<FAQItem> = [];
  public commonHandymanJobs: Array<string> = [];
  public commonLocksmithJobs: Array<string> = [];
  public commonCarpentryJobs: Array<string> = [];

  public bookingPhotos: Array<BookingPhoto> = [];

  public timeWindowOptions: Array<TimeWindow> = [];
  public bookingWindows: Array<BookingWindow> = [];
  public currentBookingWindow: number = 0;
  public showBookingWindow: boolean = false;
  public selectedBookingDate: Date = null;
  public selectedBookingTime: TimeWindow = null;
  public showTimeWindowDropdown: boolean = false;
  public currentBookingOptionValid: boolean = false;
  public currentWindowOutOfHours: boolean = false;

  public addressRef: ElementRef;

  public siteContactSameAsBooking: boolean = false;
  public agreeTermsPrivacy: boolean = false;
  public agreePriceEstimate: boolean = false;
  public marketingOptIn: boolean = false;

  //public currentBasePartsCost: number = 0;
  //public currentPremiumPartsCost: number = 0;

  public clientID: number = 0;

  public currentJobTypeIndex: number = 0;

  // make it accessible in the component html
  public outOfHoursEnabled: boolean = environment.outOfHoursEnabled;
  public outOfHoursPriceApplied: boolean = false;

  public showOfferMessage: boolean = false;
  public offerSuccess: boolean = false;
  public applyFlatDiscount: boolean = false;
  public flatDiscount: number = 0;

  public offerTheBlock: boolean = false;
  public offerBenefits: boolean = false;
  public benefitsApplied: boolean = false;

  public addServicesList: Array<JobTypeSelector> = [];

  @ViewChild('address', {static: false}) set content(content: ElementRef) {
    if (content === undefined || content === null) {
      return;
    }

    this.addressRef = content;

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.addressRef.nativeElement, {
        types: ["address"]
      });

      autocomplete.setComponentRestrictions({ 'country': environment.googleMapsCountries });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          } else {
            // valid
            var streetNumber = '';
            var streetName = '';
            var postcode = '';
            var suburbName = '';
            var country = '';
            var unit = '';
            
            var administrative_area_level_1 = '';

            place.address_components.forEach(s => {
              s.types.forEach(t => {
                if (t == "country") {
                  country = s.short_name;
                }
              });
            });

            place.address_components.forEach(s => {
              s.types.forEach(t => {
                if (t == 'street_number') {
                  streetNumber = s.long_name;
                } else if (t == 'route') {
                  streetName = s.long_name;
                } else if (t == 'postal_code') {
                  postcode = s.long_name;
                } else if (t == 'locality') {
                  if (country.toUpperCase() == 'AU') {
                    suburbName = s.long_name;
                  }
                } else if (t == 'sublocality') {
                  if (country.toUpperCase() == 'NZ') {
                    suburbName = s.long_name;
                  }
                } else if (t == "subpremise") {
                  unit = s.long_name;
                }
                else if (t == 'administrative_area_level_1') {
                  administrative_area_level_1 = s.long_name;
                }
              });
            });

            // try to auto remove accent characters/diacritics
            suburbName = suburbName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            streetName = streetName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            this.jobDataService.jobData.postcode = postcode;
            this.jobDataService.jobData.suburbName = suburbName;
            this.jobDataService.jobData.streetAddress = streetNumber + ' ' + streetName;
            this.jobDataService.jobData.streetUnit = unit;
          }
        });
      });
    });
  } // address setter

  constructor(
    public metaData: MetaDataService,
    public router: Router,
    public jobDataService: JobDataService,
    public mapsAPILoader: MapsAPILoader,
    public ngZone: NgZone,
    public toastr: ToastrService,
    public db: AngularFirestore,
    public pricingService: PricingService,
    public clients: Clients,
    public questionTypes: QuestionTypes,
    public tradeTypes: TradeTypes,
    public location: Location,
    public postcodeManager: PostcodeManagerService,
    public popupService: PopupService
  ) {
    this.clientID = environment.clientID;

    if (this.jobDataService.jobTypes.length == 0) {
      this.router.navigate(['/home']);
      return;
    }

    if (this.jobDataService.trade.oohHourlyRate == null || this.jobDataService.trade.oohHourlyRate == 0) {
      this.outOfHoursEnabled = false;
    }

    this.metaData.setCurrentPricingArea();

    this.jobDataService.offerCode = '';

    var jobTypeIndex: number = 0;
    var questionIndex: number = 0;

    this.currentJobTypeIndex = 0;

    this.jobDataService.jobTypes.forEach(jt => {
      jt.questions.forEach(q => {
        var questionData = new TriageQuestionData();
        questionData.question = q;
        questionData.show = false;
        questionData.index = questionIndex;
        questionData.answer = q.defaultAnswer;
        questionData.fullAnswer = questionData.answer;
        questionData.jobTypeIndex = jobTypeIndex;
        this.triageQuestions.push(questionData);

        if (jt.firstQuestionIndex == null || jt.firstQuestionIndex < 0) {
          jt.firstQuestionIndex = questionIndex;
        }
  
        questionIndex += 1;
      });

      jt.currentBasePartsCost = jt.partsCostBase;
      jt.currentPremiumPartsCost = jt.partsCostPremium;
      jt.jobTypeIndex = jobTypeIndex;
      jt.currentAttendanceHours = jt.attendanceHours;
      jt.currentHourlyMultiplier = null;

      jt.estimatedCurrentPrice = 0;
      jt.estimatedFullPrice = 0;
      jt.estimatedDuration = jt.attendanceHours;
      jt.currentQuantity = 1;

      jt.standardModel = true;

      jobTypeIndex += 1;
    });

    this.metaData.standardQuestions.forEach(q => {
      var questionData = new TriageQuestionData();
      questionData.question = q;
      questionData.show = false;
      questionData.index = questionIndex;
      questionData.answer = q.defaultAnswer;
      questionData.fullAnswer = questionData.answer;
      questionData.jobTypeIndex = -100;
      this.triageQuestions.push(questionData);

      questionIndex += 1;
    });

    this.bookingPhotos.length = 0;

    if (this.triageQuestions.length > 0) {
      this.triageQuestions[0].show = true;
      this.currentQuestion = this.triageQuestions[0];
      this.metaData.currentTriageQuestion = this.currentQuestion;

      if (this.currentQuestion.question.startPricing == true) {
        this.calculatingPrice = true;
        this.calculatePrice();
      }
    }

    this.stepsProgress.push(true);
    this.stepsProgress.push(false);
    this.stepsProgress.push(false);
    this.stepsProgress.push(false);

    this.bookingWindows.push(new BookingWindow());
    this.bookingWindows.push(new BookingWindow());

    this.updateAddServicesList();

    var component = this;

    environment.bookingWindows.forEach(function (w) {
      // booking windows defined in the client's environment file
      if (w.outOfHours == true && component.outOfHoursEnabled == false) {
        // don't add out of hours windows when it's disabled
      } else {
        component.timeWindowOptions.push(new TimeWindow(w.start, w.end, w.outOfHours));
      }
    });

    if (this.validateStep(false) == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }

    var providerName: string = 'Global Home Response';

    if (this.clientID == this.clients.Huski || this.clientID == this.clients.RACWA || component.clientID == component.clients.AANZ) {
      providerName = environment.productName;
    }

    // DEFAULT VALUES
    if (this.jobDataService.jobTypes[this.currentJobTypeIndex].showGeneralNotes == null) {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].showGeneralNotes = true;
    }

    this.pushRACDataLayerPageView();
    
    var tradie: string = 'Tradie';
    if (this.clientID == this.clients.AANZ) {
      tradie = 'Tradesperson';
    }

    var tradies: string = 'Tradies';
    if (this.clientID == this.clients.AANZ) {
      tradies = 'Tradespeople';
    }

    this.faqItems.push(new FAQItem('How do I know that I’m getting a fair price?', providerName + ' provides our ' + tradies + ' with consistent workflow, systems and processes to help them manage and grow their business efficiently. This results in you receiving a high-quality service at a fair price.'));
    this.faqItems.push(new FAQItem('How will I know if my booking has been confirmed?', 'You will receive an email and SMS confirmation for one of the booking windows of your choice. If for some reason we can’t achieve one of your requested times we will be in touch to organise another time that suits you.'));
    
    if (this.clientID == this.clients.AANZ) {
      this.faqItems.push(new FAQItem('When can I book a job?', 'You can book within two hour booking windows commencing at 7.30am and ending at 4.30pm, Monday to Friday (excluding Public Holidays and weekends).'));
    } else {
      this.faqItems.push(new FAQItem('When can I book a job?', 'You can book within two hour booking windows commencing at 7.30am (' + (this.clientID == this.clients.RACWA ? 'AWST' : 'AEST') + '), Monday to Friday (excluding Public Holidays and weekends).' + (this.clientID == this.clients.RACWA ? ' Please call 1300 655 057 for Public Holiday and weekend bookings.' : '')));
    }
    
    this.faqItems.push(new FAQItem('What if the job takes longer/shorter than I booked for?', 'The ' + tradie + ' will review the information you have provided when the booking was made and will inform you whether there will be less/additional time required to complete the job. If you are still happy to continue with the job, you will be able to sign off to enable the ' + tradie + ' to proceed.'));
    
    if (this.clientID == this.clients.AANZ) {
      this.faqItems.push(new FAQItem('What payment methods are available?', 'For safety and convenience, ' + providerName + ' is cashless. Your payment is processed securely and easily on Visa or Mastercard via a link that is texted to you on completion of the works.'));
    } else {
      this.faqItems.push(new FAQItem('What payment methods are available?', 'For safety and convenience, ' + providerName + ' is cashless.  Your payment is processed securely and easily over the phone via Visa or Mastercard on completion of the works.'));
    }
    
    if (this.clientID == this.clients.AANZ) {
      this.faqItems.push(new FAQItem('What if the ' + tradie + ' wants me to pay them directly?', 'Payments should only be made via the secure payment link texted to you on completion of the works, or over the phone with an AA Home representative.'));
    } else {
      this.faqItems.push(new FAQItem('What if the ' + tradie + ' wants me to pay them directly?', 'Payments can only be made over the phone with a ' + providerName + ' representative.'));
    }

    if (this.clientID != this.clients.AANZ) {
      this.commonHandymanJobs.push('Clean gutters and rubbish disposal');
      this.commonHandymanJobs.push('Stain deck');
      this.commonHandymanJobs.push('Realign gate');
      this.commonHandymanJobs.push('Restring clothes line');
      this.commonHandymanJobs.push('Retractable clothes line');
      this.commonHandymanJobs.push('Install lift up clothes line');
      this.commonHandymanJobs.push('Hang & paint internal door');
      this.commonHandymanJobs.push('Install new internal door');
      this.commonHandymanJobs.push('Door bell');
      this.commonHandymanJobs.push('Paint door');
      this.commonHandymanJobs.push('Paint door & hang external hollow core');
      this.commonHandymanJobs.push('New fly screen');
      this.commonHandymanJobs.push('Re-mesh fly screen or screen door');
      this.commonHandymanJobs.push('Repair sliding door new wheels');
      this.commonHandymanJobs.push('Repair security door');
      this.commonHandymanJobs.push('Fly screen sliding door');
      this.commonHandymanJobs.push('Screen or security door');
      this.commonHandymanJobs.push('Sliding doors rollers');
      this.commonHandymanJobs.push('New door closer');
      this.commonHandymanJobs.push('Sliding door wheels set');
      this.commonHandymanJobs.push('Adjust cupboard doors');
      this.commonHandymanJobs.push('Replace broken cupboard door hinges');
      this.commonHandymanJobs.push('Fill and paint door small hole');
      this.commonHandymanJobs.push('Plaster repair patch & paint (fist size hole)');
      this.commonHandymanJobs.push('Security door lock');
      this.commonHandymanJobs.push('Install or replace a new or existing lock');
      this.commonHandymanJobs.push('Door lock/handle basic');
      this.commonHandymanJobs.push('Replace garage door lock or handle');
      this.commonHandymanJobs.push('Mould paint bathroom ceiling');
      this.commonHandymanJobs.push('Repair shower tiles');
      this.commonHandymanJobs.push('Replace cracked tiles & grout');
      this.commonHandymanJobs.push('Repair shower screen rollers');
      this.commonHandymanJobs.push('Silicone shower');
      this.commonHandymanJobs.push('Toilet seat');
      this.commonHandymanJobs.push('Toilet roll holder');
      this.commonHandymanJobs.push('Towel rail');
      this.commonHandymanJobs.push('Adjust sticking door');
      this.commonHandymanJobs.push('Change light globe');
      this.commonHandymanJobs.push('Replace smoke detector battery');
      this.commonHandymanJobs.push('Replace NBN battery');
      this.commonHandymanJobs.push('Plaster wall repair');
      this.commonHandymanJobs.push('Picture hanging');
      this.commonHandymanJobs.push('Move existing furniture to a new location within the house');
      this.commonHandymanJobs.push('Install standard window winder');
      this.commonHandymanJobs.push('Install new window latch or lock');
    }
    else // AANZ
    {
      this.commonHandymanJobs.push('Leak repairs');
      this.commonHandymanJobs.push('Repair, plaster and paint hole in wall');
      this.commonHandymanJobs.push('Multiple small items');
      this.commonHandymanJobs.push('Multiple odd jobs');
      this.commonHandymanJobs.push('Picture hanging');
      this.commonHandymanJobs.push('Change light bulbs');
      this.commonHandymanJobs.push('Install hooks');
      this.commonHandymanJobs.push('Fit knobs & handles');
      this.commonHandymanJobs.push('Install towel rail');
      this.commonHandymanJobs.push('Gutter cleaning');
      this.commonHandymanJobs.push('Deck, paving, concrete cleaning');
      this.commonHandymanJobs.push('Water blasting');
      this.commonHandymanJobs.push('Install window latch or lock');
      this.commonHandymanJobs.push('Exterior house wash');
      this.commonHandymanJobs.push('Appliance fitting and installation');
      this.commonHandymanJobs.push('Roof cleaning');
      this.commonHandymanJobs.push('Fence & gate repairs');
      this.commonHandymanJobs.push('Hang & paint a door');
      this.commonHandymanJobs.push('Move furniture');
      this.commonHandymanJobs.push('Adjust sticking door');
      this.commonHandymanJobs.push('Balcony & deck staining');
      this.commonHandymanJobs.push('Installing cupboards and shelves');
      this.commonHandymanJobs.push('Furniture assembly');
      this.commonHandymanJobs.push('Replace cracked tiles and grout');
      this.commonHandymanJobs.push('Garden maintenance');
      this.commonHandymanJobs.push('Water tank installation');
      this.commonHandymanJobs.push('Garden shed assembly');
    }

    this.commonLocksmithJobs.push('Re-key locks');
    this.commonLocksmithJobs.push('Door not closing');
    this.commonLocksmithJobs.push('Install window locks');
    this.commonLocksmithJobs.push('Upgrade security of the property');
    this.commonLocksmithJobs.push('My front door lock isn’t working');
    this.commonLocksmithJobs.push('I need all my locks changed');
    this.commonLocksmithJobs.push('Lost our roller door keys');
    this.commonLocksmithJobs.push('Install dead locks to apartments');

    this.commonCarpentryJobs.push('Door repairs');
    this.commonCarpentryJobs.push('Wall repairs');
    this.commonCarpentryJobs.push('Gyprocking');
    this.commonCarpentryJobs.push('Wall framing');
    this.commonCarpentryJobs.push('Deck extensions');
    this.commonCarpentryJobs.push('Patio extensions');
    this.commonCarpentryJobs.push('Repairs to skirting boards');
    this.commonCarpentryJobs.push('Tile replacements');
    this.commonCarpentryJobs.push('Stairway alterations');
    this.commonCarpentryJobs.push('Kitchen cabinetry alterations');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  } // onInit

  public test() {
    //console.log(this.triageQuestions[0].answer);
  }

  public toggleLocksmithPopup(value: boolean) {
    this.showLocksmithPopup = value;
  }

  public toggleHandymanPopup(value: boolean) {
    this.showHandymanPopup = value;
  }

  public toggleCarpentryPopup(value: boolean) {
    this.showCarpentryPopup = value;
  }

  public toggleStormReadinessPopup(value: boolean) {
    this.showStormReadinessPopup = value;
  }

  public toggleGoBackPopup(value: boolean) {
    this.showGoBackPopup = value;
  }

  public toggleDeleteServicePopup(value: boolean) {
    this.showDeleteServicePopup = value;
  }

  public toggleAddServicesPopup(value: boolean) {
    this.showAddServicesPopup = value;
  }

  public openDeleteServicePopup(jobType: JobType) {
    this.deletingJobType = jobType;
    this.toggleDeleteServicePopup(true);
  }

  public closeDeleteServicePopup(answer: boolean) {
    if (answer == true) {
      this.deleteService(this.deletingJobType);
      this.deletingJobType = null;
    }

    this.toggleDeleteServicePopup(false);
  }

  public deleteService(jobType: JobType) {
    if (jobType == null) {
      return;
    }

    if (this.jobDataService.jobTypes.length <= 1) {
      this.toggleGoBackPopup(true);
      return;
    }

    var index: number = 0;
    var deleteIndex: number = -1;

    this.jobDataService.jobTypes.forEach(jt => {
      if (jt.productCode == jobType.productCode) {
        deleteIndex = index;
      }
      index += 1;
    });
    
    if (deleteIndex > -1) {
      this.jobDataService.jobTypes.splice(deleteIndex, 1);

      var jobTypeIndex: number = 0;

      this.jobDataService.jobTypes.forEach(jt => {
        jt.jobTypeIndex = jobTypeIndex;
        jobTypeIndex += 1;
      });

      var questionIndex: number = 0;
      var questionDeleteIndex: number = -1;
      var questionDeleteCount: number = 0;

      this.triageQuestions.forEach(q => {
        if (q.jobTypeIndex == deleteIndex) {
          if (questionDeleteIndex == -1) {
            questionDeleteIndex = questionIndex;
          }
          questionDeleteCount += 1;
        } else {
          if (questionDeleteIndex > -1 && q.jobTypeIndex != -100) {
            q.jobTypeIndex -= 1;
          }
        }

        questionIndex += 1;
      });

      if (questionDeleteIndex > -1) {
        this.triageQuestions.splice(questionDeleteIndex, questionDeleteCount);
      }

      this.fixIndexes();
      this.updateAddServicesList();
      this.goToStep(0);
      this.calculatePrice();
    }
  } // deleteService

  public addServicesFromPopup() {
    this.addServicesList.forEach(s => {
      if (s.selected == true) {
        var newJobTypeIndex: number = -1;

        this.jobDataService.jobTypes.forEach(jt => {
          if (jt.jobTypeIndex > newJobTypeIndex) {
            newJobTypeIndex = jt.jobTypeIndex;
          }
        });

        newJobTypeIndex += 1;

        this.jobDataService.jobTypes.push(s.jobType);

        var metaDataQuestionStart: number = -1;
        var tempIndex: number = 0;

        this.triageQuestions.forEach(q => {
          if (q.jobTypeIndex == -100 && metaDataQuestionStart == -1) {
            metaDataQuestionStart = tempIndex;
          }
          tempIndex += 1;
        });

        s.jobType.questions.forEach(q => {
          var questionData: TriageQuestionData = new TriageQuestionData();
          questionData.question = q;
          questionData.show = false;
          questionData.answer = q.defaultAnswer;
          questionData.fullAnswer = questionData.answer;
          questionData.jobTypeIndex = newJobTypeIndex;

          this.triageQuestions.splice(metaDataQuestionStart, 0, questionData);
          metaDataQuestionStart += 1;
  
          //if (s.jobType.firstQuestionIndex == null || s.jobType.firstQuestionIndex < 0) {
            //s.jobType.firstQuestionIndex = questionIndex;
          //}
        });
  
        s.jobType.currentBasePartsCost = s.jobType.partsCostBase;
        s.jobType.currentPremiumPartsCost = s.jobType.partsCostPremium;
        s.jobType.jobTypeIndex = newJobTypeIndex;
        s.jobType.currentAttendanceHours = s.jobType.attendanceHours;
        s.jobType.currentHourlyMultiplier = null;
  
        s.jobType.estimatedCurrentPrice = 0;
        s.jobType.estimatedFullPrice = 0;
        s.jobType.estimatedDuration = s.jobType.attendanceHours;
        s.jobType.currentQuantity = 1;
        s.jobType.standardModel = true;
      }
    });

    this.fixIndexes();
    this.updateAddServicesList();
    this.toggleAddServicesPopup(false);
    this.calculatePrice();
  }

  public updateAddServicesList() {
    this.addServicesList.length = 0;

    if (this.jobDataService.trade.id == this.tradeTypes.Plumbing) {
      this.metaData.tapsJobTypes.forEach((jt: JobType) => {
        var alreadySelected: boolean = false;
        var invalidType: boolean = false;

        this.jobDataService.jobTypes.forEach(sjt => {
          if (sjt.productCode == jt.productCode) {
            alreadySelected = true;
          }
        });

        if (jt.route != 'triage') {
          invalidType = true;
        }

        if (alreadySelected == false && invalidType == false) {
          var selector: JobTypeSelector = new JobTypeSelector();
          selector.jobType = jt;
          selector.selected = false;
          this.addServicesList.push(selector);
        }
      });
    }

    this.jobDataService.trade.jobTypes.forEach((jt: JobType) => {
      var alreadySelected: boolean = false;
      var invalidType: boolean = false;

      this.jobDataService.jobTypes.forEach(sjt => {
        if (sjt.productCode == jt.productCode) {
          alreadySelected = true;
        }
      });

      if (jt.route != 'triage') {
        invalidType = true;
      }

      if (alreadySelected == false && invalidType == false) {
        var selector: JobTypeSelector = new JobTypeSelector();
        selector.jobType = jt;
        selector.selected = false;
        this.addServicesList.push(selector);
      }
    });
  } // updateAddServicesList

  private fixIndexes() {
    var questionIndex: number = 0;
    this.triageQuestions.forEach(q => {
      q.index = questionIndex;
      questionIndex += 1;
    });

    var jobIndex: number = 0;
    this.jobDataService.jobTypes.forEach(jt => {
      this.triageQuestions.forEach(q => {
        if (q.jobTypeIndex == jt.jobTypeIndex && q.jobTypeIndex != -100) {
          q.jobTypeIndex = jobIndex;
        }
      });
      jt.jobTypeIndex = jobIndex;

      jobIndex += 1;
    });
  } // fixIndexes

  public jumpToJobType(jobType: JobType) {
    var index : number = 0;
    var goToIndex: number = -1;

    this.triageQuestions.forEach(q => {
      if (goToIndex == -1 && q.jobTypeIndex == jobType.jobTypeIndex) {
        goToIndex = index;
      }
      index += 1;
    });

    if (goToIndex > -1) {
      this.goToStep(goToIndex);
    }
  } // jumpToJobType

  public jobTypeSelectorClicked(selector: JobTypeSelector) {
    if (selector.selected == false) {
      selector.selected = true;
    } else {
      selector.selected = false;
    }
  }

  public closeGoBackPopup(value: boolean) {
    if (value == false) {
      this.toggleGoBackPopup(false);
      return;
    } else {

      var queryParamsObject: any = {};

      if (this.jobDataService.capability.hasMemberShip) {
        queryParamsObject.isMember = this.jobDataService.capability.memberShipConfig.answer;
      }
  
      // if it's a one service straight-through trade type it needs to go back to home here
      if (this.jobDataService.trade.id == this.tradeTypes.Locksmith
        || this.jobDataService.trade.id == this.tradeTypes.Handyman
        || this.jobDataService.trade.id == this.tradeTypes.HomeInspections
        || this.jobDataService.trade.id == this.tradeTypes.Glazing
        || this.jobDataService.trade.id == this.tradeTypes.Carpentry) {
        this.router.navigate(['/home']);
      } else {

        queryParamsObject.trade = this.jobDataService.trade.urlID;
        queryParamsObject.postcode = this.jobDataService.postcode;

        this.router.navigate(['/start'], { queryParams: queryParamsObject });
      }
    }
  }

  public freeTextChange() {
    if (this.validateStep() == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }
  }

  public bookingPhotoSelected(input: any, files: FileList) {
    var component = this;

    for (var i = 0; i < files.length; i++) {
      var file: File = files[i];
      var exists: boolean = false;

      component.bookingPhotos.forEach(f => {
        if (f.name == file.name) {
          exists = true;
        }
      });

      if (!exists && component.bookingPhotos.length < 5) {
        var p: BookingPhoto = new BookingPhoto(file.name, file);
        component.bookingPhotos.push(p);
      } else {
        if (component.bookingPhotos.length >= 5) {
          component.toastr.error('You can\'t choose more than 5 photos.', 'Error');
        }
      }
    }

    // clear file input
    input.value = '';

  } // bookingPhotoSelected

  public removeBookingPhoto(photo: BookingPhoto) {
    var index: number = -1;

    for (var i = 0; i < this.bookingPhotos.length; i++) {
      if (this.bookingPhotos[i].name == photo.name) {
        index = i;
      }
    }

    if (index > -1) {
      this.bookingPhotos.splice(index, 1);
    }
  }

  public setAnswer(question: TriageQuestionData, answer: string, fullAnswer: string = '') {
    question.answer = answer;
    if (fullAnswer.length == 0) {
      question.fullAnswer = question.answer;
    } else {
      question.fullAnswer = fullAnswer;
    }
    
    this.checkAnswer(question);
    this.calculatePrice();
  }
  
  public checkAnswer(question: TriageQuestionData) {
    if (question.question.modifiedBasePartsCost > 0) {
      if (question.question.modifiedBasePartsAnswer.length == 0 || question.question.modifiedBasePartsAnswer == question.answer) {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentBasePartsCost = question.question.modifiedBasePartsCost;
      } else {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentBasePartsCost = this.jobDataService.jobTypes[this.currentJobTypeIndex].partsCostBase;
      }
    } else {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].currentBasePartsCost = this.jobDataService.jobTypes[this.currentJobTypeIndex].partsCostBase;
    }

    if (question.question.modifiedPremiumPartsCost > 0) {
      if (question.question.modifiedPremiumPartsAnswer.length == 0 || question.question.modifiedPremiumPartsAnswer == question.answer) {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentPremiumPartsCost = question.question.modifiedPremiumPartsCost;
      } else {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentPremiumPartsCost = this.jobDataService.jobTypes[this.currentJobTypeIndex].partsCostPremium;
      }
    } else {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].currentPremiumPartsCost = this.jobDataService.jobTypes[this.currentJobTypeIndex].partsCostPremium;
    }
    
    if (question.question.modifiedAttendanceHours > 0) {
      if (question.question.modifiedAttendanceHoursAnswer.length == 0 || question.question.modifiedAttendanceHoursAnswer == question.answer) {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentAttendanceHours = question.question.modifiedAttendanceHours;
      } else {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentAttendanceHours = this.jobDataService.jobTypes[this.currentJobTypeIndex].attendanceHours;
      }
    } else {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].currentAttendanceHours = this.jobDataService.jobTypes[this.currentJobTypeIndex].attendanceHours;
    }
    
    if (question.question.modifiedHourlyMultiplier > 0) {
      if (question.question.modifiedHourlyMultiplierAnswer.length == 0 || question.question.modifiedHourlyMultiplierAnswer == question.answer) {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentHourlyMultiplier = question.question.modifiedHourlyMultiplier;
      } else {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentHourlyMultiplier = question.question.hourlyMultiplier;
      }
    } else {
      if (question.question.hourlyMultiplier > 0) {
        this.jobDataService.jobTypes[this.currentJobTypeIndex].currentHourlyMultiplier = question.question.hourlyMultiplier;
      }
    }

    if (this.validateStep() == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }
  } // checkAnswer

  public initBookingOption(window: number) {
    this.currentBookingWindow = window;
    this.showBookingWindow = true;
    this.currentQuestion.show = false;
    this.selectedBookingDate = null;
    this.selectedBookingTime = null;
    this.showBackToQuestionsButtons = true;
    this.currentBookingOptionValid = false;

    this.currentWindowOutOfHours = false;
  }

  public calendarOpened() {
    if (this.outOfHoursEnabled) {
      var d: HTMLDivElement = document.createElement("div");
      d.className = 'outOfHoursDisclaimer';

      var t = document.createTextNode("* Highlighted dates will be charged at an out of hours rate.");
      d.appendChild(t);

      setTimeout(() => {
        var element = document.getElementsByTagName('mat-calendar');
        element[0].appendChild(d);
      });
    }

    var db: HTMLDivElement = document.createElement("div");
    db.className = 'outOfHoursDisclaimer blackout';

    var tb: Text = document.createTextNode("* Greyed out dates are not available.");
    db.appendChild(tb);

    setTimeout(() => {
      var element: HTMLCollectionOf<Element> = document.getElementsByTagName('mat-calendar');
      element[0].appendChild(db);
    });
    
    if (this.clientID == this.clients.RACWA
      && (
        this.jobDataService.trade.id == this.tradeTypes.Handyman
        || this.jobDataService.trade.id == this.tradeTypes.Carpentry
        || this.jobDataService.trade.id == this.tradeTypes.Painting
        || this.jobDataService.trade.id == this.tradeTypes.BrickLaying
        || this.jobDataService.trade.id == this.tradeTypes.BrickPaving
        || this.jobDataService.trade.id == this.tradeTypes.Gardening
        || this.jobDataService.trade.id == this.tradeTypes.HomeInspections
        || this.jobDataService.trade.id == this.tradeTypes.HVAC
      )) {
      var dTemp: HTMLDivElement = document.createElement("div");
      dTemp.className = 'outOfHoursDisclaimer blackout';

      var tb: Text = document.createTextNode("* Dates 29th, 30th and 31st December are fully booked due to high demand.");
      dTemp.appendChild(tb);

      setTimeout(() => {
        var element: HTMLCollectionOf<Element> = document.getElementsByTagName('mat-calendar');
        element[0].appendChild(dTemp);
      });
    }
  }

  public validateBookingOption() {
    if (this.selectedBookingDate == null || this.selectedBookingTime == null) {
      this.currentBookingOptionValid = false;
    } else {
      this.currentBookingOptionValid = true;
    }

    if (this.selectedBookingDate != null && this.isDateOutOfHours(this.selectedBookingDate)) {
      this.currentWindowOutOfHours = true;
    } else {
      this.currentWindowOutOfHours = false;
    }
  }

  public confirmBookingOption() {
    if (this.selectedBookingDate == null || this.selectedBookingTime == null) {
      this.toastr.error('Please select a valid date and time.', 'Error');
      return;
    }

    this.bookingWindows[this.currentBookingWindow].date = this.selectedBookingDate;
    this.bookingWindows[this.currentBookingWindow].time = this.selectedBookingTime;
    this.bookingWindows[this.currentBookingWindow].valid = true;
    this.closeBookingOption();

    if (this.outOfHoursEnabled) {
      this.calculatePrice();
    }

    if (this.validateStep(false) == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }
  }

  public validateOnChange() {
    if (this.validateStep(false) == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }
  }

  public closeBookingOption() {
    this.showBookingWindow = false;
    this.currentQuestion.show = true;
    this.showBackToQuestionsButtons = false;

    this.selectedBookingDate = null;
    this.selectedBookingTime = null;
  }

  public toggleTimeWindowDropdown() {
    if (this.showTimeWindowDropdown) {
      this.showTimeWindowDropdown = false;
    } else {
      this.showTimeWindowDropdown = true;
    }
  }

  public setTimeWindow(window: TimeWindow) {
    this.selectedBookingTime = window;
    this.showTimeWindowDropdown = false;
    this.validateBookingOption();
  }

  public validateStep(showToast: boolean = true): boolean {
    if (this.currentQuestion == null) {
      return true;
    }

    if (this.currentQuestion.question.required == false) {
      return true;
    }

    switch (this.currentQuestion.question.questionTypeID) {
      case 1: // free text
        {
          if (this.currentQuestion.answer.length == 0) {
            if (showToast) { this.toastr.error('Please enter an answer.', 'Error'); }
            return false;
          }
        }
        break;

      case 2: // booking windows
        {
          if (this.bookingWindows[0].valid == false || this.bookingWindows[1].valid == false) {
            if (showToast) { this.toastr.error('Please enter your two preferred booking windows.', 'Error'); }
            return false;
          } else {
            if (this.bookingWindows[0].date.toDateString() == this.bookingWindows[1].date.toDateString()) {
              if (this.bookingWindows[0].time.toString() == this.bookingWindows[1].time.toString()) {
                if (showToast) { this.toastr.error('Please enter two different booking windows.', 'Error'); }
                return false;
              }
            }
          }
        }
        break;

      case 3: // contact details
        {
          if (this.siteContactSameAsBooking) {
            this.jobDataService.jobData.siteContact.contactName = this.jobDataService.jobData.customerContact.contactName;
            this.jobDataService.jobData.siteContact.contactPhone = this.jobDataService.jobData.customerContact.contactPhone;
          }

          this.calculatePrice();

          // customer name
          if (this.jobDataService.jobData.customerContact.contactName.length == 0) {
            if (showToast) { this.toastr.error('Please enter your name.', 'Error'); }
            return false;
          } else {
            if (/[/.:{(})?=@;."]/.test(this.jobDataService.jobData.customerContact.contactName) == true) {
              if (showToast) { this.toastr.error('Please enter a valid name.', 'Error'); }
              return false;
            }
          }

          // site contact name
          if (this.jobDataService.jobData.siteContact.contactName.length == 0) {
            if (showToast) { this.toastr.error('Please enter your onsite contact name.', 'Error'); }
            return false;
          } else {
            if (/[/.:{(})?=@;."]/.test(this.jobDataService.jobData.siteContact.contactName) == true) {
              if (showToast) { this.toastr.error('Please enter a valid onsite contact name.', 'Error'); }
              return false;
            }
          }

          // customer email
          if (this.jobDataService.jobData.customerContact.contactEmail.length == 0) {
            if (showToast) { this.toastr.error('Please enter your email address.', 'Error'); }
            return false;
          } else {
            if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.jobDataService.jobData.customerContact.contactEmail) == false) {
              if (showToast) { this.toastr.error('Please enter a valid email address.', 'Error'); }
              return false;
            }
          }
          
          if (this.jobDataService.jobData.customerContact.contactEmail != this.confirmEmail) {
            if (showToast) { this.toastr.error('Email must match confirm email.', 'Error'); }
            return false;
          }

          // customer phone number
          if (this.jobDataService.jobData.customerContact.contactPhone.length == 0) {
            if (showToast) { this.toastr.error('Please enter your contact phone number.', 'Error'); }
            return false;
          } else {
            if (/[a-zA-Z:/@.'";=?]/.test(this.jobDataService.jobData.customerContact.contactPhone) == true) {
              if (showToast) { this.toastr.error('Please enter a valid phone number.', 'Error'); }
              return false;
            }
          }
          
          if (this.clientID == this.clients.AANZ && this.showMembershipInput()) {
            if (this.jobDataService.capability.memberShipConfig.memberNumber.length > 16) {
              if (showToast) { this.toastr.error('AA member number can\'t be more than 16 characters.', 'Error'); }
              return false;
            }
          }

          // site contact phone number
          if (this.jobDataService.jobData.siteContact.contactPhone.length == 0) {
            if (showToast) { this.toastr.error('Please enter your onsite contact phone number.', 'Error'); }
            return false;
          } else {
            if (/[a-zA-Z:/@.'";=?]/.test(this.jobDataService.jobData.siteContact.contactPhone) == true) {
              if (showToast) { this.toastr.error('Please enter a valid onsite contact phone number.', 'Error'); }
              return false;
            }
          }

          // home address
          if (this.jobDataService.jobData.streetAddress.length == 0) {
            if (showToast) { this.toastr.error('Please enter your street address.', 'Error'); }
            return false;
          }

          // subrub name will come from google autocomplete
          if (this.jobDataService.jobData.suburbName.length == 0) {
            if (showToast) { this.toastr.error('Please enter your suburb.', 'Error'); }
            return false;
          }

          // postcode will come from google autocomplete
          if (this.jobDataService.jobData.postcode.length == 0) {
            if (showToast) { this.toastr.error('Please enter your postcode.', 'Error'); }
            return false;
          } else {
            if (isNaN(Number(this.jobDataService.jobData.postcode)) == true) {
              if (showToast) { this.toastr.error('Please enter a valid postcode.', 'Error'); }
              return false;
            } else {
              // validate postcode coverage
              this.metaData.checkPostcode(this.jobDataService.jobData.postcode);

              if (this.metaData.postcodeCheck == false) {
                if (showToast) { this.toastr.error('Your postcode is not currently covered by our service.', 'Error'); }
                return false;
              }
            }
          }

          if (this.agreeTermsPrivacy == false) {
            if (showToast) { this.toastr.error('To continue, you must agree to our Terms and Privacy Policy.', 'Error'); }
            return false;
          }

          if (this.agreePriceEstimate == false) {
            if (showToast) { this.toastr.error('To continue, you must agree that you understand your price is an estimate.', 'Error'); }
            return false;
          }

        }
        break;

      case 4: // confirmation
        {
          // not needed on this step
        }
        break;

      case 5: // quantity
        {
          // not needed on this step
        }
        break;

      case 6: // 2 options
        {
          if (this.currentQuestion.answer.length == 0) {
            if (showToast) { this.toastr.error('Please select an option.', 'Error'); }
            return false;
          }
        }
        break;

      case 7: // select tap model
        {
          // not needed on this step
        }
        break;

      case 8: // numeric
        {
          // not needed on this step
        }
        break;

      case 9: // yes/no
        {
          if (this.currentQuestion.answer.length == 0) {
            if (showToast) { this.toastr.error('Please select an option.', 'Error'); }
            return false;
          }
        }
        break;

      case 10: // simple 2 options
        {
          if (this.currentQuestion.answer.length == 0) {
            if (showToast) { this.toastr.error('Please select an option.', 'Error'); }
            return false;
          }
        }
        break;

      case 11: // handyman duration
        {
          if (this.currentQuestion.answer.length == 0) {
            if (showToast) { this.toastr.error('Please select an option.', 'Error'); }
            return false;
          }
        }
        break;

      case 12: // Job notes & photos
        {
          // not mandatory
        }
        break;
    }

    return true;
  }

  public nextStep(stepIndex: number, showPopups: boolean = true) {
    if (stepIndex >= (this.triageQuestions.length - 1)) {
      return;
    }

    if (this.validateStep() == false) {
      this.currentQuestion.valid = false;
      return;
    } else {
      this.currentQuestion.valid = true;
    }

    if (showPopups) {
      if (this.triageQuestions[stepIndex].question.nextPopup.length > 0 && this.triageQuestions[stepIndex].popupShown == false) {
        this.showPopup = true;
        this.popupContent = this.triageQuestions[stepIndex].question.nextPopup;
        this.triageQuestions[stepIndex].popupShown = true;
        return; // user will get to the next step by closing the popup
      }
    }

    this.triageQuestions[stepIndex].show = false;
    this.triageQuestions[stepIndex + 1].show = true;
    this.currentQuestion = this.triageQuestions[stepIndex + 1];
    this.metaData.currentTriageQuestion = this.currentQuestion;

    if (this.currentQuestion.jobTypeIndex != null && this.currentQuestion.jobTypeIndex > -1) {
      this.currentJobTypeIndex = this.currentQuestion.jobTypeIndex;
    }

    window.scrollTo(0, 0);
    //document.getElementById("stepCountContainer").scrollIntoView();

    this.pushRACDataLayerPageView();

    this.location.go('/triage?jobType=' + this.jobDataService.jobTypes[this.currentJobTypeIndex].productCode + '&stepType=' + this.currentQuestion.question.questionTypeID);

    // append member flag to params if req
    var isMemberString: string = "";
    if (this.jobDataService.capability.hasMemberShip) {
      isMemberString = "&isMember=" + this.jobDataService.capability.memberShipConfig.answer;
    }
    if (environment.GATrackingID.length > 0) {
      this.location.go('/triage?jobType=' + this.jobDataService.jobTypes[this.currentJobTypeIndex].productCode + '&stepType=' + this.currentQuestion.question.questionTypeID);
    }

    if (this.triageQuestions[stepIndex + 1].question.startPricing == true) {
      this.calculatingPrice = true;
    }

    // move up the progress bar if needed
    this.stepsProgress[this.triageQuestions[stepIndex + 1].question.progressStep - 1] = true;

    if (this.validateStep(false) == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }

    this.calculatePrice();
  } // next step

  public prevStep(stepIndex: number) {
    if (stepIndex <= 0) {
      this.toggleGoBackPopup(true);
      return;
    }

    /* if (this.validateStep() == false) { don't think I should validate on back button
      return;
    } */

    this.triageQuestions[stepIndex].show = false;
    this.triageQuestions[stepIndex - 1].show = true;
    this.currentQuestion = this.triageQuestions[stepIndex - 1];
    this.metaData.currentTriageQuestion = this.currentQuestion;
    
    if (this.currentQuestion.jobTypeIndex != null && this.currentQuestion.jobTypeIndex > -1) {
      this.currentJobTypeIndex = this.currentQuestion.jobTypeIndex;
    }

    window.scrollTo(0, 0);
    //document.getElementById("stepCountContainer").scrollIntoView();

    this.pushRACDataLayerPageView();

    // append member flag to params if req
    var isMemberString: string = "";
    if (this.jobDataService.capability.hasMemberShip) {
      isMemberString = "&isMember=" + this.jobDataService.capability.memberShipConfig.answer;
    }
    if (environment.GATrackingID.length > 0) {
      this.location.go('/triage?jobType=' + this.jobDataService.jobTypes[this.currentJobTypeIndex].productCode + '&stepType=' + this.currentQuestion.question.questionTypeID);
    }

    this.calculatePrice();
  } // prev step

  public goToStep(index: number) {
    if (this.currentQuestion.index < index) {
      if (this.validateStep() == false) {
        this.currentQuestion.valid = false;
        return;
      } else {
        this.currentQuestion.valid = true;
      }
    }

    if (this.currentQuestion.index == index) {
      return;
    }

    this.currentQuestion.show = false;
    this.currentQuestion = this.triageQuestions[index];
    this.metaData.currentTriageQuestion = this.currentQuestion;
    this.currentQuestion.show = true;

    if (this.currentQuestion.jobTypeIndex != null && this.currentQuestion.jobTypeIndex > -1) {
      this.currentJobTypeIndex = this.currentQuestion.jobTypeIndex;
    }

    this.pushRACDataLayerPageView();

    // append member flag to params if req
    var isMemberString: string = "";
    if (this.jobDataService.capability.hasMemberShip) {
      isMemberString = "&isMember=" + this.jobDataService.capability.memberShipConfig.answer;
    }
    if (environment.GATrackingID.length > 0) {
      this.location.go('/triage?jobType=' + this.jobDataService.jobTypes[this.currentJobTypeIndex].productCode + '&stepType=' + this.currentQuestion.question.questionTypeID);
    }

    this.showMoreDetails = false;
    this.showFAQ = false;
    this.showBackToQuestionsButtons = false;

    // move up the progress bar if needed
    this.stepsProgress[this.triageQuestions[index].question.progressStep - 1] = true;

    window.scrollTo(0, 0);
    //document.getElementById("stepCountContainer").scrollIntoView();
    this.calculatePrice();
  } // go to step

  public closePopup() {
    this.showPopup = false;
    this.nextStep(this.currentQuestion.index, false);
  }

  public openTermsPopup() {
    this.popupService.openSpecialPopup(this.specialPopupType.TermsAndConditions);
  }
  
  public openPrivacyPopup() {
    this.popupService.openSpecialPopup(this.specialPopupType.PrivacyPolicy);
  }

  public closeTermsPopup() {
    this.popupService.closeSpecialPopup(this.specialPopupType.TermsAndConditions);
  }

  public closePrivacyPopup() {
    this.popupService.closeSpecialPopup(this.specialPopupType.PrivacyPolicy);
  }
  
  public closeMobileInfoStep() {
    this.currentQuestion.show = true;
    this.showMoreDetails = false;
    this.showFAQ = false;
    this.showBackToQuestionsButtons = false;
  }

  public goToStepType(type: number) {
    var questionIndex: number = 0;
    var goIndex: number = -1;

    this.triageQuestions.forEach(q => {
      if (q.question.questionTypeID == type) {
        goIndex = questionIndex;
      }
      questionIndex += 1;
    });

    if (goIndex > -1) {
      if (this.currentQuestion.index < goIndex) {
        if (this.validateStep() == false) {
          this.currentQuestion.valid = false;
          return;
        } else {
          this.currentQuestion.valid = true;
        }
      }

      this.goToStep(goIndex);
    }
  }

  public confirmBooking() {
    this.showEndPopup = true;
  }

  public showMembershipInput(): boolean {
    var result: boolean = false;
    if (this.jobDataService.capability.hasMemberShip) {
      result = this.jobDataService.capability.memberShipConfig.answer;
    }
    return result;
  }

  public getMemberShipNumber(): string {
    var result: string;
    if (this.jobDataService.capability.hasMemberShip) {
      result = this.jobDataService.capability.memberShipConfig.memberNumber;
    }
    return result;
  }

  public hideEndPopup() {
    this.showEndPopup = false;
  }

  public closeEndPopup() {
    this.metaData.checkPostcode(this.jobDataService.jobData.postcode);

    if (this.metaData.postcodeCheck == false) {
      return;
    }

    var component: any = this;
    var newJobRef = 1001;
    var jobService = this.jobDataService;
    var questions = this.triageQuestions;
    var metaData = this.metaData;
    var db = this.db;
    var router = this.router;
    var toastr = this.toastr;
    var bookingWindows = this.bookingWindows;
    var price = this.currentPrice;
    var hours = this.estimatedDuration;
    var siteContactSameAsBooking = this.siteContactSameAsBooking;
    var questionTypes = this.questionTypes;

    this.endLoading = true;

    var latestBooking = this.db.collection('/jobrefs').ref.orderBy('jobref', 'desc').limit(1);

    latestBooking.get().then(function (results) {
      if (!results.empty) {
        newJobRef = (results.docs[0].data().jobref + 1);
      }

      jobService.jobData.services = [];

      var jobTypeIndex: number = 0;

      jobService.jobTypes.forEach(jt => {
        var newService: HomeServiceItem = new HomeServiceItem();
        newService.externalServiceID = jt.productCode;
        newService.estimatedPrice = parseFloat((jt.estimatedCurrentPrice / (1 + environment.GST)).toFixed(2)); // ex GST price
        newService.estimatedDuration = jt.estimatedDuration;

        if (jobService.trade.id == component.tradeTypes.HomeInspections) {
          questions.forEach(function (q) {
            // if the question belongs to the current job type
            if (q.jobTypeIndex == jobTypeIndex && q.question.outgoingField) {
              // for home inspections the question is in the data field
              newService.triageResponses.push(new TriageResponseItem(q.question.questionTypeID, q.answer, q.question.data, q.fullAnswer));
            }
          });
        } else {
          var triageNote: string = '';
          var triageItem: TriageResponseItem = new TriageResponseItem(0, '', jt.productCode, '', true);

          triageItem.inclusionData = new InclusionData();
          triageItem.inclusionData.header = jt.name;

          questions.forEach(function (q) {
            // if the question belongs to the current job type
            if (q.jobTypeIndex == jobTypeIndex && q.question.outgoingField) {
              var questionNote: string = q.fullAnswer;
              if (questionNote == null || questionNote.length == 0) {
                questionNote = q.answer;
              }

              triageNote += q.question.description + ': ' + questionNote + ' ';
              triageItem.triageAnswer = triageNote;
              triageItem.triageFullAnswer = triageNote;

              var newInclusionSection: InclusionSection = new InclusionSection();
              newInclusionSection.question = q.question.description;
              newInclusionSection.answer = questionNote;
              triageItem.inclusionData.sections.push(newInclusionSection);
            }
          });

          newService.triageResponses.push(triageItem);
        }

        /* questions.forEach(function (q) {
          // if the question belongs to the current job type
          if (q.jobTypeIndex == jobTypeIndex && q.question.outgoingField) {
            if (jobService.trade.id == component.tradeTypes.HomeInspections) { // for home inspections the question is in the data field
              newService.triageResponses.push(new TriageResponseItem(q.question.questionTypeID, q.answer, q.question.data, q.fullAnswer));
            } else {
              newService.triageResponses.push(new TriageResponseItem(q.question.questionTypeID, q.answer, q.question.description, q.fullAnswer));
            }
          }
        }); */

        jobTypeIndex += 1;
        jobService.jobData.services.push(newService);
      });

      /* var newService: HomeServiceItem = new HomeServiceItem();
      newService.externalServiceID = jobService.jobType.productCode;

      questions.forEach(function (q) {
        if (q.question.outgoingField) {
          if (jobService.trade.id == component.tradeTypes.HomeInspections) { // for home inspections the question is in the data field
            newService.triageResponses.push(new TriageResponseItem(q.question.questionTypeID, q.answer, q.question.data, q.fullAnswer));
          } else {
            newService.triageResponses.push(new TriageResponseItem(q.question.questionTypeID, q.answer, q.question.description, q.fullAnswer));
          }
        }
      });

      jobService.jobData.services.push(newService); */

      jobService.addedJobRef = newJobRef;

      // temporary sun50 offer
      if (component.applyFlatDiscount == true) {
        price += component.flatDiscount; // send it through without the displayed discount so it can be handled in easylink
      }

      var exGST: number = price / (1 + environment.GST);

      jobService.jobData.estimatedPrice = parseFloat(exGST.toFixed(2));
      jobService.jobData.estimatedHours = hours;

      if (siteContactSameAsBooking) {
        jobService.jobData.siteContact.contactName = jobService.jobData.customerContact.contactName;
        jobService.jobData.siteContact.contactPhone = jobService.jobData.customerContact.contactPhone;
      }

      var window1: JobBookingWindow = new JobBookingWindow();
      window1.date = bookingWindows[0].date;
      window1.time = new JobTimeWindow();
      window1.time.from = bookingWindows[0].time.from;
      window1.time.to = bookingWindows[0].time.to;

      var window2: JobBookingWindow = new JobBookingWindow();
      window2.date = bookingWindows[1].date;
      window2.time = new JobTimeWindow();
      window2.time.from = bookingWindows[1].time.from;
      window2.time.to = bookingWindows[1].time.to;
      
      jobService.jobData.services[0].triageResponses.push(new TriageResponseItem(0, JobChannelType[jobService.jobChannel], 'Booking Channel', JobChannelType[jobService.jobChannel]));

      // end popup answer and booking windows added as questions to the first job type
      jobService.jobData.services[0].triageResponses.push(new TriageResponseItem(0, bookingWindows[0].date.toDateString() + ' ' + bookingWindows[0].time.toString(), 'Booking window 1', bookingWindows[0].date.toDateString() + ' ' + bookingWindows[0].time.toString()));
      jobService.jobData.services[0].triageResponses.push(new TriageResponseItem(0, bookingWindows[1].date.toDateString() + ' ' + bookingWindows[1].time.toString(), 'Booking window 2', bookingWindows[1].date.toDateString() + ' ' + bookingWindows[1].time.toString()));

      var addedGeneralNotes: boolean = false;
      
      questions.forEach(function (q) {
        // if the question belongs to the current job type
        if (!addedGeneralNotes && q.jobTypeIndex == -100 && q.question.questionTypeID == questionTypes.JobNotesPhotos) {
          var questionNote: string = q.fullAnswer;
          if (questionNote == null || questionNote.length == 0) {
            questionNote = q.answer;
          }
          
          jobService.jobData.services[0].triageResponses.push(new TriageResponseItem(0, questionNote, q.question.description, questionNote));
        }
      });
      
      var packageJobData: JobData = JSON.parse(JSON.stringify(jobService.jobData));

      if (packageJobData.streetUnit.length > 0) {
        packageJobData.streetAddress = packageJobData.streetUnit + '/' + packageJobData.streetAddress;
      }

      packageJobData.bookingWindows = [];
      packageJobData.bookingWindows.push(window1);
      packageJobData.bookingWindows.push(window2);

      var now: Date = new Date();
      var membershipNumber: string = component.getMemberShipNumber();

      if (component.jobDataService.capability.hasMemberShip == true && membershipNumber == null) {
        membershipNumber = '';
      }

      var newBooking: any = {};
      newBooking.jobref = newJobRef;
      newBooking.data = JSON.stringify(packageJobData);
      newBooking.processed = false;
      newBooking.processedDate = null;
      newBooking.insertedDate = new Date(now.toUTCString());
      newBooking.bookingType = jobService.trade.bookingType;
      // temporary mortgage HIP offer code / the block offer code
      newBooking.offerCode = jobService.offerCode;
      newBooking.channel = jobService.jobChannel;
      newBooking.marketingOptIn = component.marketingOptIn;
      newBooking.whereDidYouHearAboutUs = component.whereDidYouHearAboutUs;

      if (component.jobDataService.capability.hasMemberShip == true) {
        newBooking.membershipNumber = component.getMemberShipNumber();
      }
      
      newBooking.tries = 0;

      var newRef: any = {};
      newRef.jobref = newJobRef;

      db.doc('/bookings/' + environment.jobPrefix + '_' + newJobRef.toString()).set(newBooking).then(function () {
        db.doc('/jobrefs/' + environment.jobPrefix + '_' + newJobRef.toString()).set(newRef).then(function () {
          var photoIndex: number = 0;

          component.bookingPhotos.forEach(p => {
            var newPhoto: any = {};
            newPhoto.jobref = newJobRef;
            newPhoto.name = p.name;
            newPhoto.data = p.base64;
            newPhoto.processed = false;
            newPhoto.tries = 0;
            newPhoto.processedDate = null;

            db.doc('/bookingphotos/' + environment.jobPrefix + '_' + newJobRef.toString() + '_' + photoIndex).set(newPhoto).then(function () { });

            photoIndex += 1;
          });

          component.endLoading = false;

          var queryParamsObject: any = {};
          if (jobService.capability.hasMemberShip) {
            queryParamsObject.isMember = jobService.capability.memberShipConfig.answer;
          }

          if (jobService.trade.id == component.tradeTypes.HomeInspections) {
            router.navigate(['/completeinspection']);
          } else {
            router.navigate(['/complete'], { queryParams: { jobType: ''/*jobService.jobType.productCode TODO: fix complete step product code*/ } });
          }
        });

      }).catch(function (error) {
        toastr.error('Booking failed, please try again.');
        console.log('ERROR: ' + error);
        component.endLoading = false;
      });
    }).catch(function (error) {
      toastr.error('Booking failed, please try again.');
      console.log('ERROR: ' + error);
      component.endLoading = false;
    });
  } // closeEndPopup

  public selectModel(modelName: string, question: TriageQuestionData) {
    if (modelName == 'standard') {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].standardModel = true;
      question.answer = 'standard';
      question.fullAnswer = question.answer;
    } else {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].standardModel = false;
      question.answer = 'premium';
      question.fullAnswer = question.answer;
    }

    if (this.validateStep() == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }

    this.calculatePrice();
  }

  public updateNumAnswer(question: TriageQuestionData, amount: number) {
    var currentAmount: number = parseFloat(question.answer);
    currentAmount += amount;

    if (currentAmount < 1) {
      currentAmount = 1;
    }

    question.answer = currentAmount.toString();
    question.fullAnswer = question.answer;

    if (this.currentQuestion.question.quantity) {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].currentQuantity = currentAmount;
    }

    if (question.question.hourlyMultiplier > 0 && this.jobDataService.jobTypes[this.currentJobTypeIndex].currentHourlyMultiplier == null) {
      this.jobDataService.jobTypes[this.currentJobTypeIndex].currentHourlyMultiplier = question.question.hourlyMultiplier;
    }
    
    if (this.validateStep() == false) {
      this.currentQuestion.valid = false;
    } else {
      this.currentQuestion.valid = true;
    }

    this.calculatePrice();
  }

  public calculatePrice() {
    if (this.calculatingPrice == false) {
      return;
    }

    var isOutOfHours: boolean = false;

    if (this.outOfHoursEnabled) {
      if (this.isOutOfHours(this.bookingWindows[0]) || this.isOutOfHours(this.bookingWindows[1])) {
        isOutOfHours = true;
      }
    }

    var component = this;
    component.fullPrice = 0;
    component.currentPrice = 0;
    component.estimatedDuration = 0;

    var jobTypesProcessed: number = 0;

    this.jobDataService.jobTypes.forEach(jt => {
      var newPrice: number = 0;
      var newDuration: number = 0;

      var additionalCalloutFee: number = 0;
      var calloutFee: number = component.jobDataService.trade.calloutFee;
      var hourlyRate: number = component.jobDataService.trade.hourlyRate;
      var attendanceHours: number = jt.currentAttendanceHours;
      var hourlyMultiplier: number = (jt.currentHourlyMultiplier == null ? attendanceHours : jt.currentHourlyMultiplier);
      var basePartsCost: number = jt.currentBasePartsCost;
      var premiumPartsCost: number = jt.currentPremiumPartsCost;
      var adminFee: number = environment.flatAdminFee;

      if (isOutOfHours) {
        if (component.jobDataService.trade.oohCalloutFee != null) {
          calloutFee = component.jobDataService.trade.oohCalloutFee;
        }

        if (component.jobDataService.trade.oohHourlyRate != null) {
          hourlyRate = component.jobDataService.trade.oohHourlyRate;
        }

        if (jt.oohAttendanceHours != null) {
          attendanceHours = jt.oohAttendanceHours;
        }

        component.outOfHoursPriceApplied = true;
      } else {
        component.outOfHoursPriceApplied = false;
      }

      // RAC temporary discount
      var newFullPrice: number = 0;
      var fullCalloutFee: number = component.jobDataService.trade.calloutFee;
      var fullHourlyRate: number = component.jobDataService.trade.hourlyRate;
      var fullAdminFee: number = environment.flatAdminFee;

      if (jt.calloutFee > 0) {
        calloutFee = jt.calloutFee;

        if (isOutOfHours && jt.oohCalloutFee != null) {
          calloutFee = jt.oohCalloutFee;
        }
      }
      
      if (component.pricingService.currentPricingArea != null) {
        var tradeTypePricing: TradeTypePricing = null;
        var jobTypePricing: JobTypePricing = null;

        component.pricingService.currentPricingArea.tradeTypes.forEach(t => {
          if (t.id == component.jobDataService.trade.id) {
            tradeTypePricing = t;
          }
        });

        if (tradeTypePricing != null) {
          tradeTypePricing.jobTypes.forEach(j => {
            if (j.id == jt.id) {
              jobTypePricing = j;
            }
          });

          if (tradeTypePricing.hourlyRate > 0) {
            hourlyRate = tradeTypePricing.hourlyRate;

            if (isOutOfHours && tradeTypePricing.oohHourlyRate != null) {
              hourlyRate = tradeTypePricing.oohHourlyRate;
            }
          }

          if (tradeTypePricing.calloutFee > 0) {
            calloutFee = tradeTypePricing.calloutFee;

            if (isOutOfHours && tradeTypePricing.oohCalloutFee != null) {
              hourlyRate = tradeTypePricing.oohCalloutFee;
            }
          }
        }

        if (jobTypePricing != null) {
          additionalCalloutFee = jobTypePricing.additionalCalloutFee;
        }
      }

      fullHourlyRate = hourlyRate;
      fullCalloutFee = calloutFee;

      if (this.jobDataService.capability.hasMemberShip) {
        if (component.clientID == component.clients.RACWA) {
          if (this.jobDataService.capability.memberShipConfig.answer) {
            var hourlyDiscount: number = hourlyRate * 0.1;
            var calloutDiscount: number = calloutFee * 0.1;

            hourlyRate -= hourlyDiscount;
            calloutFee -= calloutDiscount;

            // AA don't want this discounted anymore
            // if (adminFee > 0) {
            //   var adminFeeDiscount: number = adminFee * 0.1;
            //   adminFee -= adminFeeDiscount;
            // }
          }
        }
      }
      
      // only apply the callout fee for the first job
      if (jobTypesProcessed > 0) {
        calloutFee = 0;
        fullCalloutFee = 0;
      }

      newPrice += calloutFee + additionalCalloutFee;
      newPrice += hourlyRate * attendanceHours;

      newFullPrice += fullCalloutFee + additionalCalloutFee + fullAdminFee;
      newFullPrice += fullHourlyRate * attendanceHours;

      var partsCost: number = basePartsCost;
      if (jt.standardModel == false) {
        partsCost = premiumPartsCost;
      }

      newPrice += partsCost;

      // RAC temporary discount
      newFullPrice += partsCost;

      newDuration += attendanceHours;

      var quantity: number = 0;

      component.triageQuestions.forEach(element => {
        if (element.jobTypeIndex == jt.jobTypeIndex) {
          if (element.question.quantity) {
            quantity = parseFloat(element.answer) - 1;

            if (quantity > 0) {
              var hoursPerQuantity: number = (hourlyMultiplier > 0 ? hourlyMultiplier : attendanceHours);
              newPrice += (hourlyRate * hoursPerQuantity) * quantity;
              newPrice += (jt.additionalPartsFree ? 0 : partsCost * quantity);
              newDuration += hoursPerQuantity * quantity;

              // RAC temporary discount
              newFullPrice += (fullHourlyRate * hoursPerQuantity) * quantity;
              newFullPrice += (jt.additionalPartsFree ? 0 : partsCost * quantity);
            }
          }

          if (element.question.additionalFixedCost > 0) {
            if (element.question.additionalFixedCostAnswer.length == 0 || element.question.additionalFixedCostAnswer == element.answer) {
              newPrice += element.question.additionalFixedCost;

              // RAC temporary discount
              newFullPrice += element.question.additionalFixedCost;
            }
          }

          if (element.question.additionalFixedCostAnswerMatrix != null)
          {
            element.question.additionalFixedCostAnswerMatrix.forEach(item => {
              if (element.answer == item.answer) {
                var itemPrice: number = item.price;

                if(component.jobDataService.capability.hasMemberShip) {
                  if (component.clientID == component.clients.RACWA) {
                    if (component.jobDataService.capability.memberShipConfig.answer) {
                      var itemPriceDiscount: number = itemPrice * 0.1;
                      itemPrice -= itemPriceDiscount;
                    }
                  }
                }

                newPrice += itemPrice;

                // RAC temporary discount
                newFullPrice += item.price;
              }
            });
          }

          if (element.question.additionalPerCost > 0) {
            if (element.question.additionalPerCostAnswer.length == 0 || element.question.additionalPerCostAnswer == element.answer) {
              newPrice += element.question.additionalPerCost * (quantity + 1);

              // RAC temporary discount
              newFullPrice += element.question.additionalPerCost * (quantity + 1);
            }
          }
        }
      });

      // AANZ temporary discount
      if (this.jobDataService.capability.hasMemberShip) {
        if (component.clientID == component.clients.AANZ && this.jobDataService.capability.memberShipConfig.answer) {
          var discount: number = newPrice * 0.1;
          newPrice -= discount;
        }
      }
      
      if (this.jobDataService.capability.hasMemberShip) {
        if (component.clientID == component.clients.AANZ || component.clientID == component.clients.RACWA) {
          if (this.jobDataService.capability.memberShipConfig.answer) {
            component.discountApplied = true;
          } else {
            component.discountApplied = false;
          }
        }
      }
      
      newPrice += adminFee;

      // RAC temporary discount
      jt.estimatedFullPrice = newFullPrice;
      component.fullPrice += newFullPrice;
      
      jt.estimatedCurrentPrice = newPrice;
      component.currentPrice += newPrice;

      jt.estimatedDuration = newDuration;
      component.estimatedDuration += newDuration;

      jobTypesProcessed += 1;
    }); // for each job type
    
  } // calculatePrice

  public pushRACDataLayerPageView() {
    if (this.clientID != this.clients.RACWA) {
      return;
    }

    var page = '/triage/' + this.convertURLName(this.jobDataService.trade.name)
      + '/' + (this.currentQuestion.index + 1).toString()
      + '/' + this.currentQuestion.question.questionTypeID.toString()
      + '/' + this.jobDataService.capability.memberShipConfig.answer.toString()
      + '/' + (this.currentJobTypeIndex + 1).toString()
      + '/' + this.jobDataService.jobTypes.length.toString();

    var title = 'triage'
    + ' - ' + this.convertURLName(this.jobDataService.jobTypes[this.currentJobTypeIndex].name)
    + ' - question: ' + (this.currentQuestion.index + 1).toString()
    + ' - ' + this.convertURLName(this.currentQuestion.question.description)
    + ' -  service ' + (this.currentJobTypeIndex + 1).toString() + ' of ' + this.jobDataService.jobTypes.length.toString();

    this.metaData.pushRACDataLayerPageView(page, title);
  }

  public convertURLName(name: string) {
    var newName = name.split(' ').join('-');
    newName = newName.split('_').join('-');

    return newName.toLowerCase();
  }

  public expandFAQ(item: FAQItem) {
    if (item.show) {
      item.show = false;
    } else {
      item.show = true;
    }
  } // expandFAQ

  public toggleMoreDetails() {
    if (this.showMoreDetails) {
      this.showMoreDetails = false;
      this.currentQuestion.show = true;
      this.showBackToQuestionsButtons = false;
    } else {
      this.showMoreDetails = true;
      this.currentQuestion.show = false;
      this.showBackToQuestionsButtons = true;
    }

    this.showBookingWindow = false;
    this.showFAQ = false;
  } // toggleMoreDetails

  public toggleFAQ() {
    if (this.showFAQ) {
      this.showFAQ = false;
      this.currentQuestion.show = true;
      this.showBackToQuestionsButtons = false;
    } else {
      this.showFAQ = true;
      this.currentQuestion.show = false;
      this.showBackToQuestionsButtons = true;
    }

    this.showBookingWindow = false;
    this.showMoreDetails = false;
  } // toggleFAQ

}

export class BookingWindow {
  public date: Date = null;
  public time: TimeWindow = null;
  public valid: boolean = false;
}

export class TimeWindow {
  public to: string;
  public from: string;
  public outOfHours: boolean;

  constructor(_from: string, _to: string, _outOfHours: boolean) {
    this.to = _to;
    this.from = _from;
    this.outOfHours = _outOfHours;
  }

  public toString = (): string => {
    return this.from + ' - ' + this.to;
  }
}

export class BookingPhoto {
  public data: Blob = null;
  public name: string = '';
  public base64: string = '';

  constructor(_name: string, _data: File) {
    var obj = this;

    this.base64 = 'assets/img/loading_spinner.gif';

    (<any>window).imageConversion.compressAccurately(_data, 600).then(res => {
      obj.data = res;
      obj.name = _name;

      var reader = new FileReader();

      reader.onloadend = function () {
        obj.base64 = reader.result.toString();
      }

      reader.readAsDataURL(obj.data);
    });
  }
}

export class JobTypeSelector {
  public jobType: JobType = null;
  public selected: boolean = false;
}