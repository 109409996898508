import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  public specialPopups: Array<SpecialPopup> = [];

  private standardPopupOpen: boolean = false;
  private standardPopTitle: string = '';
  private standardPopContent: string = '';
  private standardPopupButtons: Array<PopupButton> = [];

  constructor() {
    this.specialPopups.length = 0;

    Object.keys(SpecialPopupType).filter(k => typeof SpecialPopupType[k as any] === "number").forEach(type => {
      this.specialPopups.push(new SpecialPopup(SpecialPopupType[type]));
    });
  }

  public open(title: string, content: string, buttons: Array<PopupButton> = null) {
    this.standardPopTitle = title;
    this.standardPopContent = content;
    
    if (buttons == null) {
      this.standardPopupButtons.push(new PopupButton('Close', '', () => {
        this.close();
      }));
    } else {
      this.standardPopupButtons = buttons;
    }

    this.standardPopupOpen = true;
  }

  public close() {
    this.standardPopTitle = '';
    this.standardPopContent = '';
    this.standardPopupButtons.length = 0;
    this.standardPopupOpen = false;
  }

  public getSpecialPopup(type: SpecialPopupType): SpecialPopup {
    var ret: SpecialPopup = null;

    this.specialPopups.forEach(p => {
      if (p.type == type) {
        ret = p;
      }
    });
    
    return ret;
  }

  public openSpecialPopup(type: SpecialPopupType) {
    var p = this.getSpecialPopup(type);

    if (p != null) {
      p.open = true;
    }
  }

  public closeSpecialPopup(type: SpecialPopupType) {
    var p = this.getSpecialPopup(type);

    if (p != null) {
      p.open = false;
    }
  }
}

export class PopupButton {
  public text: string = '';
  public classes: string = 'standardButton buttonPointer';
  public callback: () => void = null;

  constructor(text: string, classes: string, callback: () => void = null, replaceClasses: boolean = false) {
    this.text = text;
    this.classes = (replaceClasses == true ? classes : this.classes + classes);
    this.callback = callback;
  }

  public onClick() {
    if (this.callback != null) {
      this.callback();
    }
  }
}

export class SpecialPopup {
  public type: SpecialPopupType;
  public open: boolean = false;

  constructor(type: SpecialPopupType) {
    this.type = type;
  }
}

export enum SpecialPopupType {
  TermsAndConditions = 0,
  PrivacyPolicy = 1,
  FAQ = 2,
  ContactUs = 3,
}
