import { Injectable } from '@angular/core';
import { PostcodeManagerService } from './postcode-manager.service';
import { ServiceTypes } from './globals.service';
import { environment } from '../../environments/environment';
import { Clients, QuestionTypes, TradeTypes } from '../services/globals.service';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  public ServiceTypes: typeof ServiceTypes = ServiceTypes;

  public postcodeManager: PostcodeManagerService = null;

  public pricingAreas: Array<PricingArea> = [];
  public currentPricingArea: PricingArea = null;

  public clients: Clients = null;

  constructor(_postcodeManager: PostcodeManagerService, _clients: Clients) {
    this.postcodeManager = _postcodeManager;
    this.clients = _clients;
  }

  public setupPricingAreas() {
    var vicArea = new PricingArea();

    vicArea.coveredSuburbs = this.postcodeManager.victoriaPostcodes;

    var vicElectrical = new TradeTypePricing();
    vicElectrical.id = 2;

    var e18 = new JobTypePricing();
    e18.id = 18;
    e18.additionalCalloutFee = 28;
    
    var e16 = new JobTypePricing();
    e16.id = 16;
    e16.additionalCalloutFee = 28;

    var e17 = new JobTypePricing();
    e17.id = 17;
    e17.additionalCalloutFee = 28;

    var e21 = new JobTypePricing();
    e21.id = 21;
    e21.additionalCalloutFee = 28;

    var e20 = new JobTypePricing();
    e20.id = 20;
    e20.additionalCalloutFee = 28;

    var e19 = new JobTypePricing();
    e19.id = 19;
    e19.additionalCalloutFee = 28;

    var e15 = new JobTypePricing();
    e15.id = 15;
    e15.additionalCalloutFee = 28;

    var e13 = new JobTypePricing();
    e13.id = 13;
    e13.additionalCalloutFee = 28;

    var e22 = new JobTypePricing();
    e22.id = 22;
    e22.additionalCalloutFee = 28;

    vicElectrical.jobTypes.push(e18);
    vicElectrical.jobTypes.push(e16);
    vicElectrical.jobTypes.push(e17);
    vicElectrical.jobTypes.push(e21);
    vicElectrical.jobTypes.push(e20);
    vicElectrical.jobTypes.push(e19);
    vicElectrical.jobTypes.push(e18);
    vicElectrical.jobTypes.push(e13);
    vicElectrical.jobTypes.push(e22);

    var vicHandyman = new TradeTypePricing();
    vicHandyman.id = 4;

    var h23 = new JobTypePricing();
    h23.id = 23;
    h23.additionalCalloutFee = 28;

    vicHandyman.jobTypes.push(h23);

    vicArea.tradeTypes.push(vicElectrical);
    vicArea.tradeTypes.push(vicHandyman);

    var nswArea = new PricingArea();
    nswArea.coveredSuburbs = this.postcodeManager.sydneyPostcodes;

    this.pricingAreas.push(vicArea);
    this.pricingAreas.push(nswArea);
  }
}

export class PricingArea {
  public beginActiveTime: string = null; // if null it's always active
  public endActiveTime: string = null;

  public coveredSuburbs: Array<string> = [];
  public tradeTypes: Array<TradeTypePricing> = [];
}

export class TradeTypePricing {
  public id: number = 0;

  public jobTypes: Array<JobTypePricing> = [];

  public hourlyRate: number = 0;
  public calloutFee: number = 0;

  // out of hours
  public oohHourlyRate: number = 0;
  public oohCalloutFee: number = 0;
}

export class JobTypePricing {
  public id: number = 0;

  public triageQuestions: Array<TriageQuestionPricing> = [];

  public partsCostBase: number = 0;
  public partsCostPremium: number = 0;
  public attendanceHours: number = 1;

  public additionalCalloutFee: number = 0;
}

export class TriageQuestionPricing {
  public additionalFixedCost: number = 0;
  public additionalPerCost: number = 0;

  public modifiedBasePartsCost: number = 0;
  public modifiedPremiumPartsCost: number = 0;

  public hourlyMultiplier: number = 0;
  public partsMultiplier: number = 0;
  public partsAdd: number = 0;
}
