import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
export class PostcodeStateData {
    constructor() {
        this.postcodes = [];
        this.country = '';
        this.state = '';
        this.publicHolidays = [];
        this.nextDayBooking = false;
        this.covered = false;
    }
}
export class PostcodeManagerService {
    constructor() {
        this.coveredPostcodes = [];
        this.publicHolidays = [];
        this.selectedPublicHolidays = [];
        this.selectedPostcodeNextDayBooking = false;
        this.QLDPostcodes = [];
        this.victoriaPostcodes = [];
        this.sydneyPostcodes = [];
        this.WAPostcodes = [];
        this.NZPostcodes = [];
        // think special group
        this.thinkPostcodes = [];
        // storm ready
        this.SRPostcodes = [];
        this.QLDPublicHolidays = [];
        this.VICPublicHolidays = [];
        this.NSWPublicHolidays = [];
        this.WAPublicHolidays = [];
        this.NZPublicHolidays = [];
        // think special group
        this.thinkPublicHolidays = [];
        this.postcodeMap = [];
    }
    setupPostcodes() {
        this.coveredPostcodes.length = 0;
        this.publicHolidays.length = 0;
        this.QLDPostcodes.length = 0;
        this.victoriaPostcodes.length = 0;
        this.sydneyPostcodes.length = 0;
        this.WAPostcodes.length = 0;
        // think special group
        this.thinkPostcodes.length = 0;
        // storm ready
        this.SRPostcodes.length = 0;
        this.QLDPublicHolidays.length = 0;
        this.VICPublicHolidays.length = 0;
        this.NSWPublicHolidays.length = 0;
        this.WAPublicHolidays.length = 0;
        // think special group
        this.thinkPublicHolidays.length = 0;
        this.generatePostcodes();
        this.generatePublicHolidays();
        this.generatePostcodeMap();
        environment.coveredStates.forEach(state => {
            this.postcodeMap.forEach(map => {
                if (state.country == map.country && state.state == map.state) {
                    if (state.nextDayBooking != null) {
                        map.nextDayBooking = state.nextDayBooking;
                    }
                    this.coveredPostcodes = this.coveredPostcodes.concat(map.postcodes);
                    this.publicHolidays = this.publicHolidays.concat(map.publicHolidays);
                    map.covered = true;
                }
            });
        });
    }
    generatePostcodeMap() {
        var vic = new PostcodeStateData();
        vic.postcodes = this.victoriaPostcodes;
        vic.country = 'AU';
        vic.state = 'VIC';
        vic.publicHolidays = this.VICPublicHolidays;
        var nsw = new PostcodeStateData();
        nsw.postcodes = this.sydneyPostcodes;
        nsw.country = 'AU';
        nsw.state = 'NSW';
        nsw.publicHolidays = this.NSWPublicHolidays;
        var wa = new PostcodeStateData();
        wa.postcodes = this.WAPostcodes;
        wa.country = 'AU';
        wa.state = 'WA';
        wa.publicHolidays = this.WAPublicHolidays;
        var qld = new PostcodeStateData();
        qld.postcodes = this.QLDPostcodes;
        qld.country = 'AU';
        qld.state = 'QLD';
        qld.publicHolidays = this.QLDPublicHolidays;
        var nz = new PostcodeStateData();
        nz.postcodes = this.NZPostcodes;
        nz.country = 'NZ';
        nz.state = 'NZ';
        nz.publicHolidays = this.NZPublicHolidays;
        // think special group
        var think = new PostcodeStateData();
        think.postcodes = this.thinkPostcodes;
        think.country = 'AU';
        think.state = 'THINK';
        //think.publicHolidays = this.thinkPublicHolidays;
        think.publicHolidays = this.WAPublicHolidays;
        // storm ready
        var sr = new PostcodeStateData();
        sr.postcodes = this.SRPostcodes;
        sr.country = 'AU';
        sr.state = 'SR';
        sr.publicHolidays = this.QLDPublicHolidays;
        this.postcodeMap.push(vic);
        this.postcodeMap.push(nsw);
        this.postcodeMap.push(wa);
        this.postcodeMap.push(qld);
        this.postcodeMap.push(think);
        this.postcodeMap.push(nz);
    }
    generatePublicHolidays() {
        this.NZPublicHolidays.push(new Date('2020/1/1'));
        this.NZPublicHolidays.push(new Date('2020/1/2'));
        this.NZPublicHolidays.push(new Date('2020/2/6'));
        this.NZPublicHolidays.push(new Date('2020/4/10'));
        this.NZPublicHolidays.push(new Date('2020/4/25'));
        this.NZPublicHolidays.push(new Date('2020/4/27'));
        this.NZPublicHolidays.push(new Date('2020/6/1'));
        this.NZPublicHolidays.push(new Date('2020/10/26'));
        this.NZPublicHolidays.push(new Date('2020/12/25'));
        this.NZPublicHolidays.push(new Date('2020/12/26'));
        this.NZPublicHolidays.push(new Date('2020/12/28'));
        this.NZPublicHolidays.push(new Date('2021/01/01'));
        this.NZPublicHolidays.push(new Date('2021/01/02'));
        this.NZPublicHolidays.push(new Date('2021/01/04'));
        this.NZPublicHolidays.push(new Date('2021/01/25'));
        this.NZPublicHolidays.push(new Date('2021/02/01'));
        this.NZPublicHolidays.push(new Date('2021/02/06'));
        this.NZPublicHolidays.push(new Date('2021/02/08'));
        this.NZPublicHolidays.push(new Date('2021/03/08'));
        this.NZPublicHolidays.push(new Date('2021/03/22'));
        this.NZPublicHolidays.push(new Date('2021/04/02'));
        this.NZPublicHolidays.push(new Date('2021/04/05'));
        this.NZPublicHolidays.push(new Date('2021/04/06'));
        this.NZPublicHolidays.push(new Date('2021/04/25'));
        this.NZPublicHolidays.push(new Date('2021/04/26'));
        this.NZPublicHolidays.push(new Date('2021/06/07'));
        this.NZPublicHolidays.push(new Date('2021/09/27'));
        this.NZPublicHolidays.push(new Date('2021/10/22'));
        this.NZPublicHolidays.push(new Date('2021/10/25'));
        this.NZPublicHolidays.push(new Date('2021/11/01'));
        this.NZPublicHolidays.push(new Date('2021/11/12'));
        this.NZPublicHolidays.push(new Date('2021/11/29'));
        this.NZPublicHolidays.push(new Date('2021/12/25'));
        this.NZPublicHolidays.push(new Date('2021/12/26'));
        this.NZPublicHolidays.push(new Date('2021/12/27'));
        this.NZPublicHolidays.push(new Date('2021/12/28'));
        this.QLDPublicHolidays.push(new Date('2019/10/7'));
        this.QLDPublicHolidays.push(new Date('2019/12/25'));
        this.QLDPublicHolidays.push(new Date('2019/12/26'));
        this.QLDPublicHolidays.push(new Date('2020/1/1'));
        this.QLDPublicHolidays.push(new Date('2020/1/27'));
        this.QLDPublicHolidays.push(new Date('2020/4/10'));
        this.QLDPublicHolidays.push(new Date('2020/4/11'));
        this.QLDPublicHolidays.push(new Date('2020/4/12'));
        this.QLDPublicHolidays.push(new Date('2020/4/13'));
        this.QLDPublicHolidays.push(new Date('2020/4/25'));
        this.QLDPublicHolidays.push(new Date('2020/5/4'));
        this.QLDPublicHolidays.push(new Date('2020/8/12'));
        this.QLDPublicHolidays.push(new Date('2020/10/5'));
        this.QLDPublicHolidays.push(new Date('2020/12/25'));
        this.QLDPublicHolidays.push(new Date('2020/12/26'));
        this.QLDPublicHolidays.push(new Date('2020/12/28'));
        this.VICPublicHolidays.push(new Date('2019/9/27'));
        this.VICPublicHolidays.push(new Date('2019/11/5'));
        this.VICPublicHolidays.push(new Date('2019/12/25'));
        this.VICPublicHolidays.push(new Date('2019/12/26'));
        this.VICPublicHolidays.push(new Date('2020/1/1'));
        this.VICPublicHolidays.push(new Date('2020/1/27'));
        this.VICPublicHolidays.push(new Date('2020/3/9'));
        this.VICPublicHolidays.push(new Date('2020/4/10'));
        this.VICPublicHolidays.push(new Date('2020/4/11'));
        this.VICPublicHolidays.push(new Date('2020/4/12'));
        this.VICPublicHolidays.push(new Date('2020/4/13'));
        this.VICPublicHolidays.push(new Date('2020/4/25'));
        this.VICPublicHolidays.push(new Date('2020/6/8'));
        //this.VICPublicHolidays.push(new Date('2020//')); TBA grand final day
        this.VICPublicHolidays.push(new Date('2020/11/3'));
        this.VICPublicHolidays.push(new Date('2020/12/25'));
        this.VICPublicHolidays.push(new Date('2020/12/26'));
        this.VICPublicHolidays.push(new Date('2020/12/28'));
        this.NSWPublicHolidays.push(new Date('2019/10/7'));
        this.NSWPublicHolidays.push(new Date('2019/12/25'));
        this.NSWPublicHolidays.push(new Date('2019/12/26'));
        this.NSWPublicHolidays.push(new Date('2020/1/1'));
        this.NSWPublicHolidays.push(new Date('2020/1/27'));
        this.NSWPublicHolidays.push(new Date('2020/4/10'));
        this.NSWPublicHolidays.push(new Date('2020/4/11'));
        this.NSWPublicHolidays.push(new Date('2020/4/12'));
        this.NSWPublicHolidays.push(new Date('2020/4/13'));
        this.NSWPublicHolidays.push(new Date('2020/4/25'));
        this.NSWPublicHolidays.push(new Date('2020/6/8'));
        this.NSWPublicHolidays.push(new Date('2020/10/5'));
        this.NSWPublicHolidays.push(new Date('2020/12/25'));
        this.NSWPublicHolidays.push(new Date('2020/12/26'));
        this.NSWPublicHolidays.push(new Date('2020/12/28'));
        this.WAPublicHolidays.push(new Date('2019/9/30'));
        this.WAPublicHolidays.push(new Date('2019/12/25'));
        this.WAPublicHolidays.push(new Date('2019/12/26'));
        this.WAPublicHolidays.push(new Date('2020/1/1'));
        this.WAPublicHolidays.push(new Date('2020/1/27'));
        this.WAPublicHolidays.push(new Date('2020/3/2'));
        this.WAPublicHolidays.push(new Date('2020/4/10'));
        this.WAPublicHolidays.push(new Date('2020/4/13'));
        this.WAPublicHolidays.push(new Date('2020/4/25'));
        this.WAPublicHolidays.push(new Date('2020/4/27'));
        this.WAPublicHolidays.push(new Date('2020/6/1'));
        this.WAPublicHolidays.push(new Date('2020/9/28'));
        this.WAPublicHolidays.push(new Date('2020/12/25'));
        this.WAPublicHolidays.push(new Date('2020/12/26'));
        this.WAPublicHolidays.push(new Date('2020/12/28'));
        this.WAPublicHolidays.push(new Date('2021/01/01'));
        this.WAPublicHolidays.push(new Date('2021/01/26'));
        this.WAPublicHolidays.push(new Date('2021/03/01'));
        this.WAPublicHolidays.push(new Date('2021/04/02'));
        this.WAPublicHolidays.push(new Date('2021/04/05'));
        this.WAPublicHolidays.push(new Date('2021/04/25'));
        this.WAPublicHolidays.push(new Date('2021/04/26'));
        this.WAPublicHolidays.push(new Date('2021/06/07'));
        this.WAPublicHolidays.push(new Date('2021/09/27'));
        this.WAPublicHolidays.push(new Date('2021/12/25'));
        this.WAPublicHolidays.push(new Date('2021/12/26'));
        this.WAPublicHolidays.push(new Date('2021/12/27'));
        this.WAPublicHolidays.push(new Date('2021/12/28'));
    } // generatePublicHolidays
    generatePostcodes() {
        // AANZ
        this.NZPostcodes.push('0600');
        this.NZPostcodes.push('0602');
        this.NZPostcodes.push('0604');
        this.NZPostcodes.push('0610');
        this.NZPostcodes.push('0612');
        this.NZPostcodes.push('0614');
        this.NZPostcodes.push('0616');
        this.NZPostcodes.push('0618');
        this.NZPostcodes.push('0620');
        this.NZPostcodes.push('0622');
        this.NZPostcodes.push('0624');
        this.NZPostcodes.push('0626');
        this.NZPostcodes.push('0627');
        this.NZPostcodes.push('0629');
        this.NZPostcodes.push('0630');
        this.NZPostcodes.push('0632');
        this.NZPostcodes.push('0792');
        this.NZPostcodes.push('0810');
        this.NZPostcodes.push('0812');
        this.NZPostcodes.push('0814');
        this.NZPostcodes.push('0816');
        this.NZPostcodes.push('0820');
        this.NZPostcodes.push('0930');
        this.NZPostcodes.push('0931');
        this.NZPostcodes.push('0932');
        this.NZPostcodes.push('0943');
        this.NZPostcodes.push('0944');
        this.NZPostcodes.push('0945');
        this.NZPostcodes.push('0946');
        this.NZPostcodes.push('0950');
        this.NZPostcodes.push('0951');
        this.NZPostcodes.push('0952');
        this.NZPostcodes.push('0993');
        this.NZPostcodes.push('1010');
        this.NZPostcodes.push('1011');
        this.NZPostcodes.push('1021');
        this.NZPostcodes.push('1022');
        this.NZPostcodes.push('1023');
        this.NZPostcodes.push('1024');
        this.NZPostcodes.push('1025');
        this.NZPostcodes.push('1026');
        this.NZPostcodes.push('1041');
        this.NZPostcodes.push('1042');
        this.NZPostcodes.push('1050');
        this.NZPostcodes.push('1051');
        this.NZPostcodes.push('1052');
        this.NZPostcodes.push('1060');
        this.NZPostcodes.push('1061');
        this.NZPostcodes.push('1062');
        this.NZPostcodes.push('1071');
        this.NZPostcodes.push('1072');
        this.NZPostcodes.push('2010');
        this.NZPostcodes.push('2012');
        this.NZPostcodes.push('2013');
        this.NZPostcodes.push('2014');
        this.NZPostcodes.push('2016');
        this.NZPostcodes.push('2018');
        this.NZPostcodes.push('2019');
        this.NZPostcodes.push('2022');
        this.NZPostcodes.push('2023');
        this.NZPostcodes.push('2024');
        this.NZPostcodes.push('2025');
        this.NZPostcodes.push('2102');
        this.NZPostcodes.push('2103');
        this.NZPostcodes.push('2104');
        this.NZPostcodes.push('2105');
        this.NZPostcodes.push('2110');
        this.NZPostcodes.push('2112');
        this.NZPostcodes.push('2113');
        this.NZPostcodes.push('2120');
        this.NZPostcodes.push('2577');
        this.NZPostcodes.push('2580');
        this.NZPostcodes.push('2582');
        this.NZPostcodes.push('2584');
        this.NZPostcodes.push('2676');
        this.NZPostcodes.push('2676');
        // think special group
        this.thinkPostcodes.push('6101');
        this.thinkPostcodes.push('6035');
        this.thinkPostcodes.push('6000');
        this.thinkPostcodes.push('6065');
        this.thinkPostcodes.push('6030');
        this.thinkPostcodes.push('6180');
        this.thinkPostcodes.push('6031');
        this.thinkPostcodes.push('6122');
        this.thinkPostcodes.push('6164');
        this.thinkPostcodes.push('6155');
        this.thinkPostcodes.push('6104');
        this.thinkPostcodes.push('6171');
        this.thinkPostcodes.push('6055');
        this.thinkPostcodes.push('3042');
        this.thinkPostcodes.push('3020');
        this.thinkPostcodes.push('3037');
        this.thinkPostcodes.push('3039');
        this.thinkPostcodes.push('3805');
        this.thinkPostcodes.push('3442');
        this.thinkPostcodes.push('3219');
        this.thinkPostcodes.push('3216');
        this.thinkPostcodes.push('3072');
        this.thinkPostcodes.push('3111');
        this.thinkPostcodes.push('3977');
        this.thinkPostcodes.push('3824');
        this.thinkPostcodes.push('3198');
        this.thinkPostcodes.push('3029');
        this.thinkPostcodes.push('3178');
        this.thinkPostcodes.push('3166');
        this.thinkPostcodes.push('3250');
        this.thinkPostcodes.push('3028');
        this.thinkPostcodes.push('3175');
        this.thinkPostcodes.push('3034');
        this.thinkPostcodes.push('3105');
        this.thinkPostcodes.push('3765');
        this.thinkPostcodes.push('3076');
        this.thinkPostcodes.push('3194');
        this.thinkPostcodes.push('3450');
        this.thinkPostcodes.push('3207');
        this.thinkPostcodes.push('3075');
        this.thinkPostcodes.push('3046');
        this.thinkPostcodes.push('3085');
        this.thinkPostcodes.push('3181');
        this.thinkPostcodes.push('3204');
        this.thinkPostcodes.push('3805');
        this.thinkPostcodes.push('3064');
        this.thinkPostcodes.push('3165');
        this.thinkPostcodes.push('3153');
        this.thinkPostcodes.push('3088');
        this.thinkPostcodes.push('3212');
        this.thinkPostcodes.push('3201');
        this.thinkPostcodes.push('3072');
        this.thinkPostcodes.push('3217');
        this.thinkPostcodes.push('3216');
        this.thinkPostcodes.push('3049');
        this.thinkPostcodes.push('3109');
        this.thinkPostcodes.push('3106');
        this.thinkPostcodes.push('3350');
        this.thinkPostcodes.push('3205');
        this.thinkPostcodes.push('3220');
        this.thinkPostcodes.push('5086');
        this.thinkPostcodes.push('5125');
        this.thinkPostcodes.push('5173');
        this.thinkPostcodes.push('5168');
        this.thinkPostcodes.push('5163');
        this.thinkPostcodes.push('5540');
        this.thinkPostcodes.push('5165');
        this.thinkPostcodes.push('5114');
        this.thinkPostcodes.push('5084');
        this.thinkPostcodes.push('5700');
        this.thinkPostcodes.push('4102');
        this.thinkPostcodes.push('2147');
        this.thinkPostcodes.push('2340');
        this.thinkPostcodes.push('2198');
        this.thinkPostcodes.push('2320');
        this.thinkPostcodes.push('2557');
        this.thinkPostcodes.push('2198');
        this.thinkPostcodes.push('2259');
        this.thinkPostcodes.push('2560');
        this.thinkPostcodes.push('2913');
        this.thinkPostcodes.push('2914');
        // storm ready
        this.SRPostcodes.push('4810');
        this.SRPostcodes.push('4811');
        this.SRPostcodes.push('4812');
        this.SRPostcodes.push('4814');
        this.SRPostcodes.push('4815');
        this.SRPostcodes.push('4817');
        this.SRPostcodes.push('4818');
        this.SRPostcodes.push('4865');
        this.SRPostcodes.push('4868');
        this.SRPostcodes.push('4869');
        this.SRPostcodes.push('4870');
        this.SRPostcodes.push('4871');
        this.SRPostcodes.push('4878');
        this.SRPostcodes.push('4879');
        this.SRPostcodes.push('4881');
        // WA
        this.WAPostcodes.push('6000');
        this.WAPostcodes.push('6003');
        this.WAPostcodes.push('6004');
        this.WAPostcodes.push('6005');
        this.WAPostcodes.push('6006');
        this.WAPostcodes.push('6007');
        this.WAPostcodes.push('6008');
        this.WAPostcodes.push('6009');
        this.WAPostcodes.push('6010');
        this.WAPostcodes.push('6011');
        this.WAPostcodes.push('6012');
        this.WAPostcodes.push('6014');
        this.WAPostcodes.push('6015');
        this.WAPostcodes.push('6016');
        this.WAPostcodes.push('6017');
        this.WAPostcodes.push('6018');
        this.WAPostcodes.push('6019');
        this.WAPostcodes.push('6020');
        this.WAPostcodes.push('6021');
        this.WAPostcodes.push('6022');
        this.WAPostcodes.push('6023');
        this.WAPostcodes.push('6024');
        this.WAPostcodes.push('6025');
        this.WAPostcodes.push('6026');
        this.WAPostcodes.push('6027');
        this.WAPostcodes.push('6028');
        this.WAPostcodes.push('6029');
        this.WAPostcodes.push('6030');
        this.WAPostcodes.push('6031');
        this.WAPostcodes.push('6032');
        this.WAPostcodes.push('6033');
        this.WAPostcodes.push('6034');
        this.WAPostcodes.push('6035');
        this.WAPostcodes.push('6036');
        this.WAPostcodes.push('6037');
        this.WAPostcodes.push('6038');
        this.WAPostcodes.push('6050');
        this.WAPostcodes.push('6051');
        this.WAPostcodes.push('6052');
        this.WAPostcodes.push('6053');
        this.WAPostcodes.push('6054');
        this.WAPostcodes.push('6055');
        this.WAPostcodes.push('6056');
        this.WAPostcodes.push('6057');
        this.WAPostcodes.push('6058');
        this.WAPostcodes.push('6059');
        this.WAPostcodes.push('6060');
        this.WAPostcodes.push('6061');
        this.WAPostcodes.push('6062');
        this.WAPostcodes.push('6063');
        this.WAPostcodes.push('6064');
        this.WAPostcodes.push('6065');
        this.WAPostcodes.push('6066');
        this.WAPostcodes.push('6067');
        this.WAPostcodes.push('6069');
        this.WAPostcodes.push('6070');
        this.WAPostcodes.push('6071');
        this.WAPostcodes.push('6072');
        this.WAPostcodes.push('6073');
        this.WAPostcodes.push('6074');
        this.WAPostcodes.push('6076');
        this.WAPostcodes.push('6077');
        this.WAPostcodes.push('6078');
        this.WAPostcodes.push('6081');
        this.WAPostcodes.push('6082');
        this.WAPostcodes.push('6083');
        this.WAPostcodes.push('6084');
        this.WAPostcodes.push('6090');
        this.WAPostcodes.push('6100');
        this.WAPostcodes.push('6101');
        this.WAPostcodes.push('6102');
        this.WAPostcodes.push('6103');
        this.WAPostcodes.push('6104');
        this.WAPostcodes.push('6105');
        this.WAPostcodes.push('6106');
        this.WAPostcodes.push('6107');
        this.WAPostcodes.push('6108');
        this.WAPostcodes.push('6109');
        this.WAPostcodes.push('6110');
        this.WAPostcodes.push('6111');
        this.WAPostcodes.push('6112');
        this.WAPostcodes.push('6121');
        this.WAPostcodes.push('6122');
        this.WAPostcodes.push('6123');
        this.WAPostcodes.push('6124');
        this.WAPostcodes.push('6125');
        this.WAPostcodes.push('6126');
        this.WAPostcodes.push('6147');
        this.WAPostcodes.push('6148');
        this.WAPostcodes.push('6149');
        this.WAPostcodes.push('6150');
        this.WAPostcodes.push('6151');
        this.WAPostcodes.push('6152');
        this.WAPostcodes.push('6153');
        this.WAPostcodes.push('6154');
        this.WAPostcodes.push('6155');
        this.WAPostcodes.push('6156');
        this.WAPostcodes.push('6157');
        this.WAPostcodes.push('6158');
        this.WAPostcodes.push('6159');
        this.WAPostcodes.push('6160');
        this.WAPostcodes.push('6162');
        this.WAPostcodes.push('6163');
        this.WAPostcodes.push('6164');
        this.WAPostcodes.push('6165');
        this.WAPostcodes.push('6166');
        this.WAPostcodes.push('6167');
        this.WAPostcodes.push('6168');
        this.WAPostcodes.push('6169');
        this.WAPostcodes.push('6170');
        this.WAPostcodes.push('6171');
        this.WAPostcodes.push('6172');
        this.WAPostcodes.push('6173');
        this.WAPostcodes.push('6174');
        this.WAPostcodes.push('6175');
        this.WAPostcodes.push('6176');
        this.WAPostcodes.push('6180');
        this.WAPostcodes.push('6181');
        this.WAPostcodes.push('6207');
        this.WAPostcodes.push('6208');
        this.WAPostcodes.push('6209');
        this.WAPostcodes.push('6210');
        this.WAPostcodes.push('6211');
        this.WAPostcodes.push('6556');
        this.WAPostcodes.push('6558');
        this.WAPostcodes.push('6560');
        // VICTORIA
        this.victoriaPostcodes.push('3000');
        this.victoriaPostcodes.push('3001');
        this.victoriaPostcodes.push('3002');
        this.victoriaPostcodes.push('3002');
        this.victoriaPostcodes.push('3003');
        this.victoriaPostcodes.push('3004');
        this.victoriaPostcodes.push('3004');
        this.victoriaPostcodes.push('3005');
        this.victoriaPostcodes.push('3006');
        this.victoriaPostcodes.push('3006');
        this.victoriaPostcodes.push('3008');
        this.victoriaPostcodes.push('3010');
        this.victoriaPostcodes.push('3011');
        this.victoriaPostcodes.push('3011');
        this.victoriaPostcodes.push('3011');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3012');
        this.victoriaPostcodes.push('3013');
        this.victoriaPostcodes.push('3013');
        this.victoriaPostcodes.push('3015');
        this.victoriaPostcodes.push('3015');
        this.victoriaPostcodes.push('3015');
        this.victoriaPostcodes.push('3016');
        this.victoriaPostcodes.push('3016');
        this.victoriaPostcodes.push('3018');
        this.victoriaPostcodes.push('3018');
        this.victoriaPostcodes.push('3019');
        this.victoriaPostcodes.push('3019');
        this.victoriaPostcodes.push('3020');
        this.victoriaPostcodes.push('3020');
        this.victoriaPostcodes.push('3020');
        this.victoriaPostcodes.push('3020');
        this.victoriaPostcodes.push('3021');
        this.victoriaPostcodes.push('3021');
        this.victoriaPostcodes.push('3021');
        this.victoriaPostcodes.push('3021');
        this.victoriaPostcodes.push('3022');
        this.victoriaPostcodes.push('3022');
        this.victoriaPostcodes.push('3023');
        this.victoriaPostcodes.push('3023');
        this.victoriaPostcodes.push('3023');
        this.victoriaPostcodes.push('3023');
        this.victoriaPostcodes.push('3023');
        this.victoriaPostcodes.push('3023');
        this.victoriaPostcodes.push('3024');
        this.victoriaPostcodes.push('3025');
        this.victoriaPostcodes.push('3025');
        this.victoriaPostcodes.push('3025');
        this.victoriaPostcodes.push('3026');
        this.victoriaPostcodes.push('3027');
        this.victoriaPostcodes.push('3028');
        this.victoriaPostcodes.push('3028');
        this.victoriaPostcodes.push('3028');
        this.victoriaPostcodes.push('3029');
        this.victoriaPostcodes.push('3029');
        this.victoriaPostcodes.push('3029');
        this.victoriaPostcodes.push('3030');
        this.victoriaPostcodes.push('3030');
        this.victoriaPostcodes.push('3030');
        this.victoriaPostcodes.push('3030');
        this.victoriaPostcodes.push('3030');
        this.victoriaPostcodes.push('3031');
        this.victoriaPostcodes.push('3031');
        this.victoriaPostcodes.push('3032');
        this.victoriaPostcodes.push('3032');
        this.victoriaPostcodes.push('3032');
        this.victoriaPostcodes.push('3032');
        this.victoriaPostcodes.push('3033');
        this.victoriaPostcodes.push('3033');
        this.victoriaPostcodes.push('3034');
        this.victoriaPostcodes.push('3036');
        this.victoriaPostcodes.push('3036');
        this.victoriaPostcodes.push('3037');
        this.victoriaPostcodes.push('3037');
        this.victoriaPostcodes.push('3037');
        this.victoriaPostcodes.push('3037');
        this.victoriaPostcodes.push('3038');
        this.victoriaPostcodes.push('3038');
        this.victoriaPostcodes.push('3038');
        this.victoriaPostcodes.push('3038');
        this.victoriaPostcodes.push('3039');
        this.victoriaPostcodes.push('3040');
        this.victoriaPostcodes.push('3040');
        this.victoriaPostcodes.push('3040');
        this.victoriaPostcodes.push('3041');
        this.victoriaPostcodes.push('3041');
        this.victoriaPostcodes.push('3041');
        this.victoriaPostcodes.push('3041');
        this.victoriaPostcodes.push('3042');
        this.victoriaPostcodes.push('3042');
        this.victoriaPostcodes.push('3042');
        this.victoriaPostcodes.push('3043');
        this.victoriaPostcodes.push('3043');
        this.victoriaPostcodes.push('3043');
        this.victoriaPostcodes.push('3044');
        this.victoriaPostcodes.push('3044');
        this.victoriaPostcodes.push('3045');
        this.victoriaPostcodes.push('3046');
        this.victoriaPostcodes.push('3046');
        this.victoriaPostcodes.push('3046');
        this.victoriaPostcodes.push('3047');
        this.victoriaPostcodes.push('3047');
        this.victoriaPostcodes.push('3047');
        this.victoriaPostcodes.push('3047');
        this.victoriaPostcodes.push('3048');
        this.victoriaPostcodes.push('3048');
        this.victoriaPostcodes.push('3049');
        this.victoriaPostcodes.push('3049');
        this.victoriaPostcodes.push('3050');
        this.victoriaPostcodes.push('3051');
        this.victoriaPostcodes.push('3051');
        this.victoriaPostcodes.push('3052');
        this.victoriaPostcodes.push('3052');
        this.victoriaPostcodes.push('3053');
        this.victoriaPostcodes.push('3053');
        this.victoriaPostcodes.push('3054');
        this.victoriaPostcodes.push('3054');
        this.victoriaPostcodes.push('3055');
        this.victoriaPostcodes.push('3056');
        this.victoriaPostcodes.push('3057');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3058');
        this.victoriaPostcodes.push('3059');
        this.victoriaPostcodes.push('3060');
        this.victoriaPostcodes.push('3060');
        this.victoriaPostcodes.push('3061');
        this.victoriaPostcodes.push('3062');
        this.victoriaPostcodes.push('3063');
        this.victoriaPostcodes.push('3064');
        this.victoriaPostcodes.push('3064');
        this.victoriaPostcodes.push('3064');
        this.victoriaPostcodes.push('3065');
        this.victoriaPostcodes.push('3066');
        this.victoriaPostcodes.push('3067');
        this.victoriaPostcodes.push('3068');
        this.victoriaPostcodes.push('3068');
        this.victoriaPostcodes.push('3070');
        this.victoriaPostcodes.push('3070');
        this.victoriaPostcodes.push('3071');
        this.victoriaPostcodes.push('3072');
        this.victoriaPostcodes.push('3072');
        this.victoriaPostcodes.push('3072');
        this.victoriaPostcodes.push('3072');
        this.victoriaPostcodes.push('3072');
        this.victoriaPostcodes.push('3073');
        this.victoriaPostcodes.push('3073');
        this.victoriaPostcodes.push('3073');
        this.victoriaPostcodes.push('3074');
        this.victoriaPostcodes.push('3075');
        this.victoriaPostcodes.push('3076');
        this.victoriaPostcodes.push('3076');
        this.victoriaPostcodes.push('3078');
        this.victoriaPostcodes.push('3078');
        this.victoriaPostcodes.push('3079');
        this.victoriaPostcodes.push('3079');
        this.victoriaPostcodes.push('3079');
        this.victoriaPostcodes.push('3081');
        this.victoriaPostcodes.push('3081');
        this.victoriaPostcodes.push('3081');
        this.victoriaPostcodes.push('3081');
        this.victoriaPostcodes.push('3082');
        this.victoriaPostcodes.push('3083');
        this.victoriaPostcodes.push('3083');
        this.victoriaPostcodes.push('3084');
        this.victoriaPostcodes.push('3084');
        this.victoriaPostcodes.push('3084');
        this.victoriaPostcodes.push('3084');
        this.victoriaPostcodes.push('3084');
        this.victoriaPostcodes.push('3085');
        this.victoriaPostcodes.push('3085');
        this.victoriaPostcodes.push('3085');
        this.victoriaPostcodes.push('3087');
        this.victoriaPostcodes.push('3087');
        this.victoriaPostcodes.push('3088');
        this.victoriaPostcodes.push('3088');
        this.victoriaPostcodes.push('3088');
        this.victoriaPostcodes.push('3088');
        this.victoriaPostcodes.push('3089');
        this.victoriaPostcodes.push('3090');
        this.victoriaPostcodes.push('3091');
        this.victoriaPostcodes.push('3093');
        this.victoriaPostcodes.push('3094');
        this.victoriaPostcodes.push('3095');
        this.victoriaPostcodes.push('3095');
        this.victoriaPostcodes.push('3095');
        this.victoriaPostcodes.push('3096');
        this.victoriaPostcodes.push('3097');
        this.victoriaPostcodes.push('3097');
        this.victoriaPostcodes.push('3099');
        this.victoriaPostcodes.push('3099');
        this.victoriaPostcodes.push('3101');
        this.victoriaPostcodes.push('3101');
        this.victoriaPostcodes.push('3102');
        this.victoriaPostcodes.push('3103');
        this.victoriaPostcodes.push('3103');
        this.victoriaPostcodes.push('3103');
        this.victoriaPostcodes.push('3104');
        this.victoriaPostcodes.push('3105');
        this.victoriaPostcodes.push('3106');
        this.victoriaPostcodes.push('3107');
        this.victoriaPostcodes.push('3107');
        this.victoriaPostcodes.push('3108');
        this.victoriaPostcodes.push('3109');
        this.victoriaPostcodes.push('3109');
        this.victoriaPostcodes.push('3109');
        this.victoriaPostcodes.push('3110');
        this.victoriaPostcodes.push('3111');
        this.victoriaPostcodes.push('3113');
        this.victoriaPostcodes.push('3113');
        this.victoriaPostcodes.push('3113');
        this.victoriaPostcodes.push('3114');
        this.victoriaPostcodes.push('3115');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3121');
        this.victoriaPostcodes.push('3122');
        this.victoriaPostcodes.push('3122');
        this.victoriaPostcodes.push('3123');
        this.victoriaPostcodes.push('3124');
        this.victoriaPostcodes.push('3124');
        this.victoriaPostcodes.push('3124');
        this.victoriaPostcodes.push('3124');
        this.victoriaPostcodes.push('3124');
        this.victoriaPostcodes.push('3124');
        this.victoriaPostcodes.push('3125');
        this.victoriaPostcodes.push('3125');
        this.victoriaPostcodes.push('3126');
        this.victoriaPostcodes.push('3126');
        this.victoriaPostcodes.push('3127');
        this.victoriaPostcodes.push('3127');
        this.victoriaPostcodes.push('3128');
        this.victoriaPostcodes.push('3128');
        this.victoriaPostcodes.push('3128');
        this.victoriaPostcodes.push('3128');
        this.victoriaPostcodes.push('3129');
        this.victoriaPostcodes.push('3129');
        this.victoriaPostcodes.push('3129');
        this.victoriaPostcodes.push('3129');
        this.victoriaPostcodes.push('3130');
        this.victoriaPostcodes.push('3130');
        this.victoriaPostcodes.push('3130');
        this.victoriaPostcodes.push('3130');
        this.victoriaPostcodes.push('3131');
        this.victoriaPostcodes.push('3131');
        this.victoriaPostcodes.push('3131');
        this.victoriaPostcodes.push('3132');
        this.victoriaPostcodes.push('3132');
        this.victoriaPostcodes.push('3133');
        this.victoriaPostcodes.push('3133');
        this.victoriaPostcodes.push('3134');
        this.victoriaPostcodes.push('3134');
        this.victoriaPostcodes.push('3134');
        this.victoriaPostcodes.push('3134');
        this.victoriaPostcodes.push('3135');
        this.victoriaPostcodes.push('3135');
        this.victoriaPostcodes.push('3135');
        this.victoriaPostcodes.push('3136');
        this.victoriaPostcodes.push('3136');
        this.victoriaPostcodes.push('3136');
        this.victoriaPostcodes.push('3136');
        this.victoriaPostcodes.push('3137');
        this.victoriaPostcodes.push('3137');
        this.victoriaPostcodes.push('3141');
        this.victoriaPostcodes.push('3142');
        this.victoriaPostcodes.push('3142');
        this.victoriaPostcodes.push('3143');
        this.victoriaPostcodes.push('3143');
        this.victoriaPostcodes.push('3143');
        this.victoriaPostcodes.push('3144');
        this.victoriaPostcodes.push('3144');
        this.victoriaPostcodes.push('3145');
        this.victoriaPostcodes.push('3145');
        this.victoriaPostcodes.push('3145');
        this.victoriaPostcodes.push('3145');
        this.victoriaPostcodes.push('3145');
        this.victoriaPostcodes.push('3145');
        this.victoriaPostcodes.push('3146');
        this.victoriaPostcodes.push('3146');
        this.victoriaPostcodes.push('3147');
        this.victoriaPostcodes.push('3147');
        this.victoriaPostcodes.push('3148');
        this.victoriaPostcodes.push('3148');
        this.victoriaPostcodes.push('3148');
        this.victoriaPostcodes.push('3149');
        this.victoriaPostcodes.push('3149');
        this.victoriaPostcodes.push('3149');
        this.victoriaPostcodes.push('3150');
        this.victoriaPostcodes.push('3150');
        this.victoriaPostcodes.push('3150');
        this.victoriaPostcodes.push('3151');
        this.victoriaPostcodes.push('3151');
        this.victoriaPostcodes.push('3152');
        this.victoriaPostcodes.push('3152');
        this.victoriaPostcodes.push('3152');
        this.victoriaPostcodes.push('3152');
        this.victoriaPostcodes.push('3153');
        this.victoriaPostcodes.push('3153');
        this.victoriaPostcodes.push('3154');
        this.victoriaPostcodes.push('3155');
        this.victoriaPostcodes.push('3156');
        this.victoriaPostcodes.push('3156');
        this.victoriaPostcodes.push('3156');
        this.victoriaPostcodes.push('3156');
        this.victoriaPostcodes.push('3156');
        this.victoriaPostcodes.push('3161');
        this.victoriaPostcodes.push('3161');
        this.victoriaPostcodes.push('3162');
        this.victoriaPostcodes.push('3162');
        this.victoriaPostcodes.push('3162');
        this.victoriaPostcodes.push('3163');
        this.victoriaPostcodes.push('3163');
        this.victoriaPostcodes.push('3163');
        this.victoriaPostcodes.push('3164');
        this.victoriaPostcodes.push('3165');
        this.victoriaPostcodes.push('3165');
        this.victoriaPostcodes.push('3166');
        this.victoriaPostcodes.push('3166');
        this.victoriaPostcodes.push('3166');
        this.victoriaPostcodes.push('3166');
        this.victoriaPostcodes.push('3167');
        this.victoriaPostcodes.push('3168');
        this.victoriaPostcodes.push('3168');
        this.victoriaPostcodes.push('3169');
        this.victoriaPostcodes.push('3169');
        this.victoriaPostcodes.push('3170');
        this.victoriaPostcodes.push('3170');
        this.victoriaPostcodes.push('3171');
        this.victoriaPostcodes.push('3171');
        this.victoriaPostcodes.push('3172');
        this.victoriaPostcodes.push('3172');
        this.victoriaPostcodes.push('3172');
        this.victoriaPostcodes.push('3173');
        this.victoriaPostcodes.push('3174');
        this.victoriaPostcodes.push('3174');
        this.victoriaPostcodes.push('3174');
        this.victoriaPostcodes.push('3175');
        this.victoriaPostcodes.push('3175');
        this.victoriaPostcodes.push('3175');
        this.victoriaPostcodes.push('3175');
        this.victoriaPostcodes.push('3176');
        this.victoriaPostcodes.push('3177');
        this.victoriaPostcodes.push('3177');
        this.victoriaPostcodes.push('3178');
        this.victoriaPostcodes.push('3179');
        this.victoriaPostcodes.push('3180');
        this.victoriaPostcodes.push('3180');
        this.victoriaPostcodes.push('3181');
        this.victoriaPostcodes.push('3181');
        this.victoriaPostcodes.push('3181');
        this.victoriaPostcodes.push('3182');
        this.victoriaPostcodes.push('3182');
        this.victoriaPostcodes.push('3182');
        this.victoriaPostcodes.push('3183');
        this.victoriaPostcodes.push('3183');
        this.victoriaPostcodes.push('3184');
        this.victoriaPostcodes.push('3184');
        this.victoriaPostcodes.push('3185');
        this.victoriaPostcodes.push('3185');
        this.victoriaPostcodes.push('3185');
        this.victoriaPostcodes.push('3185');
        this.victoriaPostcodes.push('3186');
        this.victoriaPostcodes.push('3186');
        this.victoriaPostcodes.push('3186');
        this.victoriaPostcodes.push('3187');
        this.victoriaPostcodes.push('3187');
        this.victoriaPostcodes.push('3188');
        this.victoriaPostcodes.push('3188');
        this.victoriaPostcodes.push('3188');
        this.victoriaPostcodes.push('3189');
        this.victoriaPostcodes.push('3189');
        this.victoriaPostcodes.push('3189');
        this.victoriaPostcodes.push('3190');
        this.victoriaPostcodes.push('3191');
        this.victoriaPostcodes.push('3192');
        this.victoriaPostcodes.push('3192');
        this.victoriaPostcodes.push('3192');
        this.victoriaPostcodes.push('3192');
        this.victoriaPostcodes.push('3193');
        this.victoriaPostcodes.push('3193');
        this.victoriaPostcodes.push('3193');
        this.victoriaPostcodes.push('3194');
        this.victoriaPostcodes.push('3194');
        this.victoriaPostcodes.push('3194');
        this.victoriaPostcodes.push('3195');
        this.victoriaPostcodes.push('3195');
        this.victoriaPostcodes.push('3195');
        this.victoriaPostcodes.push('3195');
        this.victoriaPostcodes.push('3195');
        this.victoriaPostcodes.push('3195');
        this.victoriaPostcodes.push('3196');
        this.victoriaPostcodes.push('3196');
        this.victoriaPostcodes.push('3196');
        this.victoriaPostcodes.push('3196');
        this.victoriaPostcodes.push('3197');
        this.victoriaPostcodes.push('3197');
        this.victoriaPostcodes.push('3198');
        this.victoriaPostcodes.push('3198');
        this.victoriaPostcodes.push('3199');
        this.victoriaPostcodes.push('3199');
        this.victoriaPostcodes.push('3199');
        this.victoriaPostcodes.push('3199');
        this.victoriaPostcodes.push('3199');
        this.victoriaPostcodes.push('3200');
        this.victoriaPostcodes.push('3200');
        this.victoriaPostcodes.push('3201');
        this.victoriaPostcodes.push('3202');
        this.victoriaPostcodes.push('3204');
        this.victoriaPostcodes.push('3204');
        this.victoriaPostcodes.push('3204');
        this.victoriaPostcodes.push('3205');
        this.victoriaPostcodes.push('3205');
        this.victoriaPostcodes.push('3206');
        this.victoriaPostcodes.push('3206');
        this.victoriaPostcodes.push('3207');
        this.victoriaPostcodes.push('3207');
        this.victoriaPostcodes.push('3207');
        this.victoriaPostcodes.push('3207');
        this.victoriaPostcodes.push('3335');
        this.victoriaPostcodes.push('3335');
        this.victoriaPostcodes.push('3337');
        this.victoriaPostcodes.push('3337');
        this.victoriaPostcodes.push('3338');
        this.victoriaPostcodes.push('3427');
        this.victoriaPostcodes.push('3428');
        this.victoriaPostcodes.push('3429');
        this.victoriaPostcodes.push('3750');
        this.victoriaPostcodes.push('3752');
        this.victoriaPostcodes.push('3754');
        this.victoriaPostcodes.push('3754');
        this.victoriaPostcodes.push('3755');
        this.victoriaPostcodes.push('3759');
        this.victoriaPostcodes.push('3800');
        this.victoriaPostcodes.push('3802');
        this.victoriaPostcodes.push('3803');
        this.victoriaPostcodes.push('3804');
        this.victoriaPostcodes.push('3805');
        this.victoriaPostcodes.push('3805');
        this.victoriaPostcodes.push('3805');
        this.victoriaPostcodes.push('3805');
        this.victoriaPostcodes.push('3806');
        this.victoriaPostcodes.push('3806');
        this.victoriaPostcodes.push('3807');
        this.victoriaPostcodes.push('3807');
        this.victoriaPostcodes.push('3808');
        this.victoriaPostcodes.push('3809');
        this.victoriaPostcodes.push('3809');
        this.victoriaPostcodes.push('3810');
        this.victoriaPostcodes.push('3810');
        this.victoriaPostcodes.push('3810');
        this.victoriaPostcodes.push('3810');
        this.victoriaPostcodes.push('3810');
        this.victoriaPostcodes.push('3910');
        this.victoriaPostcodes.push('3911');
        this.victoriaPostcodes.push('3911');
        this.victoriaPostcodes.push('3912');
        this.victoriaPostcodes.push('3930');
        this.victoriaPostcodes.push('3975');
        this.victoriaPostcodes.push('3975');
        this.victoriaPostcodes.push('3976');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3977');
        this.victoriaPostcodes.push('3978');
        this.victoriaPostcodes.push('3978');
        this.victoriaPostcodes.push('3978');
        this.victoriaPostcodes.push('3980');
        this.victoriaPostcodes.push('3980');
        this.victoriaPostcodes.push('8001');
        this.victoriaPostcodes.push('8001');
        this.victoriaPostcodes.push('8002');
        this.victoriaPostcodes.push('8003');
        this.victoriaPostcodes.push('8004');
        this.victoriaPostcodes.push('8005');
        this.victoriaPostcodes.push('8007');
        this.victoriaPostcodes.push('8008');
        this.victoriaPostcodes.push('8009');
        this.victoriaPostcodes.push('8010');
        // BRISBANE
        this.QLDPostcodes.push('4110');
        this.QLDPostcodes.push('4035');
        this.QLDPostcodes.push('4010');
        this.QLDPostcodes.push('4051');
        this.QLDPostcodes.push('4161');
        this.QLDPostcodes.push('4115');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4103');
        this.QLDPostcodes.push('4070');
        this.QLDPostcodes.push('4054');
        this.QLDPostcodes.push('4108');
        this.QLDPostcodes.push('4007');
        this.QLDPostcodes.push('4060');
        this.QLDPostcodes.push('4034');
        this.QLDPostcodes.push('4066');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4036');
        this.QLDPostcodes.push('4171');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4014');
        this.QLDPostcodes.push('4065');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4070');
        this.QLDPostcodes.push('4153');
        this.QLDPostcodes.push('4117');
        this.QLDPostcodes.push('4205');
        this.QLDPostcodes.push('4159');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4304');
        this.QLDPostcodes.push('4034');
        this.QLDPostcodes.push('4304');
        this.QLDPostcodes.push('4124');
        this.QLDPostcodes.push('4006');
        this.QLDPostcodes.push('4017');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4035');
        this.QLDPostcodes.push('4017');
        this.QLDPostcodes.push('4008');
        this.QLDPostcodes.push('4000');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4118');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4171');
        this.QLDPostcodes.push('4304');
        this.QLDPostcodes.push('4055');
        this.QLDPostcodes.push('4156');
        this.QLDPostcodes.push('4116');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4152');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4170');
        this.QLDPostcodes.push('4157');
        this.QLDPostcodes.push('4130');
        this.QLDPostcodes.push('4152');
        this.QLDPostcodes.push('4152');
        this.QLDPostcodes.push('4152');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4034');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4133');
        this.QLDPostcodes.push('4155');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4068');
        this.QLDPostcodes.push('4032');
        this.QLDPostcodes.push('4032');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4011');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4163');
        this.QLDPostcodes.push('4019');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4301');
        this.QLDPostcodes.push('4108');
        this.QLDPostcodes.push('4151');
        this.QLDPostcodes.push('4075');
        this.QLDPostcodes.push('4130');
        this.QLDPostcodes.push('4132');
        this.QLDPostcodes.push('4127');
        this.QLDPostcodes.push('4503');
        this.QLDPostcodes.push('4076');
        this.QLDPostcodes.push('4017');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4303');
        this.QLDPostcodes.push('4077');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4116');
        this.QLDPostcodes.push('4077');
        this.QLDPostcodes.push('4102');
        this.QLDPostcodes.push('4009');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4169');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4037');
        this.QLDPostcodes.push('4304');
        this.QLDPostcodes.push('4340');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4113');
        this.QLDPostcodes.push('4078');
        this.QLDPostcodes.push('4051');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4053');
        this.QLDPostcodes.push('4053');
        this.QLDPostcodes.push('4103');
        this.QLDPostcodes.push('4055');
        this.QLDPostcodes.push('4055');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4018');
        this.QLDPostcodes.push('4280');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4078');
        this.QLDPostcodes.push('4118');
        this.QLDPostcodes.push('4006');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4051');
        this.QLDPostcodes.push('4034');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4031');
        this.QLDPostcodes.push('4075');
        this.QLDPostcodes.push('4051');
        this.QLDPostcodes.push('4124');
        this.QLDPostcodes.push('4120');
        this.QLDPostcodes.push('4503');
        this.QLDPostcodes.push('4154');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4007');
        this.QLDPostcodes.push('4171');
        this.QLDPostcodes.push('4110');
        this.QLDPostcodes.push('4174');
        this.QLDPostcodes.push('4011');
        this.QLDPostcodes.push('4118');
        this.QLDPostcodes.push('4006');
        this.QLDPostcodes.push('4101');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4118');
        this.QLDPostcodes.push('4121');
        this.QLDPostcodes.push('4121');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4077');
        this.QLDPostcodes.push('4068');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4340');
        this.QLDPostcodes.push('4280');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4030');
        this.QLDPostcodes.push('4503');
        this.QLDPostcodes.push('4169');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4117');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4031');
        this.QLDPostcodes.push('4059');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4054');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4114');
        this.QLDPostcodes.push('4021');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4112');
        this.QLDPostcodes.push('4503');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4110');
        this.QLDPostcodes.push('4501');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4114');
        this.QLDPostcodes.push('4133');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4129');
        this.QLDPostcodes.push('4131');
        this.QLDPostcodes.push('4179');
        this.QLDPostcodes.push('4030');
        this.QLDPostcodes.push('4124');
        this.QLDPostcodes.push('4178');
        this.QLDPostcodes.push('4109');
        this.QLDPostcodes.push('4156');
        this.QLDPostcodes.push('4509');
        this.QLDPostcodes.push('4179');
        this.QLDPostcodes.push('4179');
        this.QLDPostcodes.push('4122');
        this.QLDPostcodes.push('4019');
        this.QLDPostcodes.push('4132');
        this.QLDPostcodes.push('4053');
        this.QLDPostcodes.push('4131');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4064');
        this.QLDPostcodes.push('4053');
        this.QLDPostcodes.push('4070');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4105');
        this.QLDPostcodes.push('4170');
        this.QLDPostcodes.push('4066');
        this.QLDPostcodes.push('4165');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4122');
        this.QLDPostcodes.push('4122');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4125');
        this.QLDPostcodes.push('4172');
        this.QLDPostcodes.push('4503');
        this.QLDPostcodes.push('4111');
        this.QLDPostcodes.push('4124');
        this.QLDPostcodes.push('4303');
        this.QLDPostcodes.push('4005');
        this.QLDPostcodes.push('4051');
        this.QLDPostcodes.push('4020');
        this.QLDPostcodes.push('4006');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4170');
        this.QLDPostcodes.push('4304');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4509');
        this.QLDPostcodes.push('4280');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4013');
        this.QLDPostcodes.push('4014');
        this.QLDPostcodes.push('4014');
        this.QLDPostcodes.push('4012');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4160');
        this.QLDPostcodes.push('4075');
        this.QLDPostcodes.push('4064');
        this.QLDPostcodes.push('4110');
        this.QLDPostcodes.push('4125');
        this.QLDPostcodes.push('4125');
        this.QLDPostcodes.push('4115');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4502');
        this.QLDPostcodes.push('4000');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4008');
        this.QLDPostcodes.push('4178');
        this.QLDPostcodes.push('4127');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4154');
        this.QLDPostcodes.push('4059');
        this.QLDPostcodes.push('4301');
        this.QLDPostcodes.push('4301');
        this.QLDPostcodes.push('4020');
        this.QLDPostcodes.push('4165');
        this.QLDPostcodes.push('4118');
        this.QLDPostcodes.push('4077');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4303');
        this.QLDPostcodes.push('4109');
        this.QLDPostcodes.push('4123');
        this.QLDPostcodes.push('4123');
        this.QLDPostcodes.push('4106');
        this.QLDPostcodes.push('4340');
        this.QLDPostcodes.push('4022');
        this.QLDPostcodes.push('4113');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4107');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4017');
        this.QLDPostcodes.push('4020');
        this.QLDPostcodes.push('4170');
        this.QLDPostcodes.push('4073');
        this.QLDPostcodes.push('4128');
        this.QLDPostcodes.push('4157');
        this.QLDPostcodes.push('4075');
        this.QLDPostcodes.push('4017');
        this.QLDPostcodes.push('4304');
        this.QLDPostcodes.push('4124');
        this.QLDPostcodes.push('4073');
        this.QLDPostcodes.push('4127');
        this.QLDPostcodes.push('4101');
        this.QLDPostcodes.push('4280');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4000');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4300');
        this.QLDPostcodes.push('4127');
        this.QLDPostcodes.push('4067');
        this.QLDPostcodes.push('4053');
        this.QLDPostcodes.push('4053');
        this.QLDPostcodes.push('4280');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4116');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4109');
        this.QLDPostcodes.push('4109');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4018');
        this.QLDPostcodes.push('4270');
        this.QLDPostcodes.push('4128');
        this.QLDPostcodes.push('4068');
        this.QLDPostcodes.push('4121');
        this.QLDPostcodes.push('4005');
        this.QLDPostcodes.push('4105');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4061');
        this.QLDPostcodes.push('4158');
        this.QLDPostcodes.push('4164');
        this.QLDPostcodes.push('4173');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4066');
        this.QLDPostcodes.push('4119');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4069');
        this.QLDPostcodes.push('4055');
        this.QLDPostcodes.push('4122');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4165');
        this.QLDPostcodes.push('4014');
        this.QLDPostcodes.push('4076');
        this.QLDPostcodes.push('4154');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4500');
        this.QLDPostcodes.push('4133');
        this.QLDPostcodes.push('4133');
        this.QLDPostcodes.push('4012');
        this.QLDPostcodes.push('4160');
        this.QLDPostcodes.push('4101');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4074');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4503');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4110');
        this.QLDPostcodes.push('4306');
        this.QLDPostcodes.push('4051');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4030');
        this.QLDPostcodes.push('4122');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4285');
        this.QLDPostcodes.push('4114');
        this.QLDPostcodes.push('4019');
        this.QLDPostcodes.push('4102');
        this.QLDPostcodes.push('4030');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4178');
        this.QLDPostcodes.push('4178');
        this.QLDPostcodes.push('4305');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4105');
        this.QLDPostcodes.push('4104');
        this.QLDPostcodes.push('4520');
        this.QLDPostcodes.push('4034');
        // GOLD COAST
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4220');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4225');
        this.QLDPostcodes.push('4213');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4220');
        this.QLDPostcodes.push('4220');
        this.QLDPostcodes.push('4220');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4209');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4226');
        this.QLDPostcodes.push('4225');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4209');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4223');
        this.QLDPostcodes.push('4223');
        this.QLDPostcodes.push('4223');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4221');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4225');
        this.QLDPostcodes.push('4222');
        this.QLDPostcodes.push('4210');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4212');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4212');
        this.QLDPostcodes.push('4228');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4226');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4225');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4210');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4226');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4220');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4212');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4272');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4213');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4272');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4208');
        this.QLDPostcodes.push('4210');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4221');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4214');
        this.QLDPostcodes.push('4209');
        this.QLDPostcodes.push('4211');
        this.QLDPostcodes.push('4225');
        this.QLDPostcodes.push('4227');
        this.QLDPostcodes.push('4228');
        this.QLDPostcodes.push('4218');
        this.QLDPostcodes.push('4226');
        this.QLDPostcodes.push('4230');
        this.QLDPostcodes.push('4226');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4212');
        this.QLDPostcodes.push('4212');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4215');
        this.QLDPostcodes.push('4216');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4227');
        this.QLDPostcodes.push('4210');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4213');
        this.QLDPostcodes.push('4228');
        this.QLDPostcodes.push('4228');
        this.QLDPostcodes.push('4220');
        this.QLDPostcodes.push('4270');
        this.QLDPostcodes.push('4272');
        this.QLDPostcodes.push('4270');
        this.QLDPostcodes.push('4217');
        this.QLDPostcodes.push('4224');
        this.QLDPostcodes.push('4209');
        this.QLDPostcodes.push('4227');
        this.QLDPostcodes.push('4219');
        this.QLDPostcodes.push('4209');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4210');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4213');
        this.QLDPostcodes.push('4207');
        this.QLDPostcodes.push('4207');
        // SYDNEY (HIP)
        this.sydneyPostcodes.push('2205');
        this.sydneyPostcodes.push('2216');
        this.sydneyPostcodes.push('2019');
        this.sydneyPostcodes.push('2207');
        this.sydneyPostcodes.push('2207');
        this.sydneyPostcodes.push('2207');
        this.sydneyPostcodes.push('2207');
        this.sydneyPostcodes.push('2207');
        this.sydneyPostcodes.push('2019');
        this.sydneyPostcodes.push('2216');
        this.sydneyPostcodes.push('2032');
        this.sydneyPostcodes.push('2219');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2018');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2217');
        this.sydneyPostcodes.push('2217');
        this.sydneyPostcodes.push('2216');
        this.sydneyPostcodes.push('2020');
        this.sydneyPostcodes.push('2217');
        this.sydneyPostcodes.push('2035');
        this.sydneyPostcodes.push('2217');
        this.sydneyPostcodes.push('2217');
        this.sydneyPostcodes.push('2216');
        this.sydneyPostcodes.push('2018');
        this.sydneyPostcodes.push('2219');
        this.sydneyPostcodes.push('2219');
        this.sydneyPostcodes.push('2205');
        this.sydneyPostcodes.push('2205');
        this.sydneyPostcodes.push('2763');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2767');
        this.sydneyPostcodes.push('2761');
        this.sydneyPostcodes.push('2761');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2767');
        this.sydneyPostcodes.push('2766');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2761');
        this.sydneyPostcodes.push('2768');
        this.sydneyPostcodes.push('2761');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2155');
        this.sydneyPostcodes.push('2147');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2147');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2761');
        this.sydneyPostcodes.push('2768');
        this.sydneyPostcodes.push('2761');
        this.sydneyPostcodes.push('2148');
        this.sydneyPostcodes.push('2763');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2766');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2762');
        this.sydneyPostcodes.push('2147');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2768');
        this.sydneyPostcodes.push('2769');
        this.sydneyPostcodes.push('2146');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2770');
        this.sydneyPostcodes.push('2767');
        this.sydneyPostcodes.push('2134');
        this.sydneyPostcodes.push('2136');
        this.sydneyPostcodes.push('2134');
        this.sydneyPostcodes.push('2132');
        this.sydneyPostcodes.push('2136');
        this.sydneyPostcodes.push('2135');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2137');
        this.sydneyPostcodes.push('2137');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2137');
        this.sydneyPostcodes.push('2139');
        this.sydneyPostcodes.push('2138');
        this.sydneyPostcodes.push('2047');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2138');
        this.sydneyPostcodes.push('2137');
        this.sydneyPostcodes.push('2137');
        this.sydneyPostcodes.push('2138');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2046');
        this.sydneyPostcodes.push('2193');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2197');
        this.sydneyPostcodes.push('2191');
        this.sydneyPostcodes.push('2192');
        this.sydneyPostcodes.push('2143');
        this.sydneyPostcodes.push('2194');
        this.sydneyPostcodes.push('2193');
        this.sydneyPostcodes.push('2162');
        this.sydneyPostcodes.push('2190');
        this.sydneyPostcodes.push('2206');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2206');
        this.sydneyPostcodes.push('2213');
        this.sydneyPostcodes.push('2198');
        this.sydneyPostcodes.push('2190');
        this.sydneyPostcodes.push('2193');
        this.sydneyPostcodes.push('2195');
        this.sydneyPostcodes.push('2163');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2214');
        this.sydneyPostcodes.push('2190');
        this.sydneyPostcodes.push('2200');
        this.sydneyPostcodes.push('2211');
        this.sydneyPostcodes.push('2211');
        this.sydneyPostcodes.push('2213');
        this.sydneyPostcodes.push('2213');
        this.sydneyPostcodes.push('2143');
        this.sydneyPostcodes.push('2196');
        this.sydneyPostcodes.push('2143');
        this.sydneyPostcodes.push('2212');
        this.sydneyPostcodes.push('2212');
        this.sydneyPostcodes.push('2212');
        this.sydneyPostcodes.push('2196');
        this.sydneyPostcodes.push('2162');
        this.sydneyPostcodes.push('2163');
        this.sydneyPostcodes.push('2195');
        this.sydneyPostcodes.push('2199');
        this.sydneyPostcodes.push('2199');
        this.sydneyPostcodes.push('2015');
        this.sydneyPostcodes.push('2038');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2015');
        this.sydneyPostcodes.push('2008');
        this.sydneyPostcodes.push('2010');
        this.sydneyPostcodes.push('2008');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2011');
        this.sydneyPostcodes.push('2043');
        this.sydneyPostcodes.push('2015');
        this.sydneyPostcodes.push('2037');
        this.sydneyPostcodes.push('2037');
        this.sydneyPostcodes.push('2008');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2021');
        this.sydneyPostcodes.push('2011');
        this.sydneyPostcodes.push('2009');
        this.sydneyPostcodes.push('2016');
        this.sydneyPostcodes.push('2011');
        this.sydneyPostcodes.push('2010');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2000');
        this.sydneyPostcodes.push('2007');
        this.sydneyPostcodes.push('2017');
        this.sydneyPostcodes.push('2011');
        this.sydneyPostcodes.push('2017');
        this.sydneyPostcodes.push('2144');
        this.sydneyPostcodes.push('2141');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2161');
        this.sydneyPostcodes.push('2161');
        this.sydneyPostcodes.push('2141');
        this.sydneyPostcodes.push('2141');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2160');
        this.sydneyPostcodes.push('2160');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2141');
        this.sydneyPostcodes.push('2164');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2164');
        this.sydneyPostcodes.push('2161');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2177');
        this.sydneyPostcodes.push('2177');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2166');
        this.sydneyPostcodes.push('2166');
        this.sydneyPostcodes.push('2166');
        this.sydneyPostcodes.push('2166');
        this.sydneyPostcodes.push('2163');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2165');
        this.sydneyPostcodes.push('2165');
        this.sydneyPostcodes.push('2165');
        this.sydneyPostcodes.push('2165');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2175');
        this.sydneyPostcodes.push('2166');
        this.sydneyPostcodes.push('2161');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2164');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2176');
        this.sydneyPostcodes.push('2164');
        this.sydneyPostcodes.push('2218');
        this.sydneyPostcodes.push('2217');
        this.sydneyPostcodes.push('2209');
        this.sydneyPostcodes.push('2221');
        this.sydneyPostcodes.push('2218');
        this.sydneyPostcodes.push('2221');
        this.sydneyPostcodes.push('2221');
        this.sydneyPostcodes.push('2220');
        this.sydneyPostcodes.push('2220');
        this.sydneyPostcodes.push('2220');
        this.sydneyPostcodes.push('2208');
        this.sydneyPostcodes.push('2208');
        this.sydneyPostcodes.push('2221');
        this.sydneyPostcodes.push('2210');
        this.sydneyPostcodes.push('2223');
        this.sydneyPostcodes.push('2209');
        this.sydneyPostcodes.push('2223');
        this.sydneyPostcodes.push('2210');
        this.sydneyPostcodes.push('2210');
        this.sydneyPostcodes.push('2222');
        this.sydneyPostcodes.push('2210');
        this.sydneyPostcodes.push('2221');
        this.sydneyPostcodes.push('2159');
        this.sydneyPostcodes.push('2077');
        this.sydneyPostcodes.push('2081');
        this.sydneyPostcodes.push('2082');
        this.sydneyPostcodes.push('2082');
        this.sydneyPostcodes.push('2082');
        this.sydneyPostcodes.push('2159');
        this.sydneyPostcodes.push('2126');
        this.sydneyPostcodes.push('2081');
        this.sydneyPostcodes.push('2159');
        this.sydneyPostcodes.push('2159');
        this.sydneyPostcodes.push('2077');
        this.sydneyPostcodes.push('2077');
        this.sydneyPostcodes.push('2079');
        this.sydneyPostcodes.push('2080');
        this.sydneyPostcodes.push('2076');
        this.sydneyPostcodes.push('2120');
        this.sydneyPostcodes.push('2120');
        this.sydneyPostcodes.push('2077');
        this.sydneyPostcodes.push('2125');
        this.sydneyPostcodes.push('2120');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2110');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2110');
        this.sydneyPostcodes.push('2131');
        this.sydneyPostcodes.push('2041');
        this.sydneyPostcodes.push('2041');
        this.sydneyPostcodes.push('2041');
        this.sydneyPostcodes.push('2050');
        this.sydneyPostcodes.push('2133');
        this.sydneyPostcodes.push('2203');
        this.sydneyPostcodes.push('2133');
        this.sydneyPostcodes.push('2042');
        this.sydneyPostcodes.push('2045');
        this.sydneyPostcodes.push('2040');
        this.sydneyPostcodes.push('2049');
        this.sydneyPostcodes.push('2040');
        this.sydneyPostcodes.push('2204');
        this.sydneyPostcodes.push('2204');
        this.sydneyPostcodes.push('2204');
        this.sydneyPostcodes.push('2050');
        this.sydneyPostcodes.push('2042');
        this.sydneyPostcodes.push('2049');
        this.sydneyPostcodes.push('2049');
        this.sydneyPostcodes.push('2039');
        this.sydneyPostcodes.push('2044');
        this.sydneyPostcodes.push('2048');
        this.sydneyPostcodes.push('2130');
        this.sydneyPostcodes.push('2044');
        this.sydneyPostcodes.push('2044');
        this.sydneyPostcodes.push('2048');
        this.sydneyPostcodes.push('2071');
        this.sydneyPostcodes.push('2070');
        this.sydneyPostcodes.push('2072');
        this.sydneyPostcodes.push('2071');
        this.sydneyPostcodes.push('2070');
        this.sydneyPostcodes.push('2070');
        this.sydneyPostcodes.push('2074');
        this.sydneyPostcodes.push('2076');
        this.sydneyPostcodes.push('2073');
        this.sydneyPostcodes.push('2069');
        this.sydneyPostcodes.push('2069');
        this.sydneyPostcodes.push('2074');
        this.sydneyPostcodes.push('2075');
        this.sydneyPostcodes.push('2075');
        this.sydneyPostcodes.push('2074');
        this.sydneyPostcodes.push('2076');
        this.sydneyPostcodes.push('2074');
        this.sydneyPostcodes.push('2073');
        this.sydneyPostcodes.push('2065');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2066');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2179');
        this.sydneyPostcodes.push('2555');
        this.sydneyPostcodes.push('2565');
        this.sydneyPostcodes.push('2556');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2557');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2178');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2565');
        this.sydneyPostcodes.push('2174');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2745');
        this.sydneyPostcodes.push('2557');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2173');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2565');
        this.sydneyPostcodes.push('2174');
        this.sydneyPostcodes.push('2178');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2179');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2565');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2172');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2557');
        this.sydneyPostcodes.push('2168');
        this.sydneyPostcodes.push('2172');
        this.sydneyPostcodes.push('2752');
        this.sydneyPostcodes.push('2172');
        this.sydneyPostcodes.push('2752');
        this.sydneyPostcodes.push('2170');
        this.sydneyPostcodes.push('2173');
        this.sydneyPostcodes.push('2171');
        this.sydneyPostcodes.push('2088');
        this.sydneyPostcodes.push('2088');
        this.sydneyPostcodes.push('2062');
        this.sydneyPostcodes.push('2090');
        this.sydneyPostcodes.push('2090');
        this.sydneyPostcodes.push('2090');
        this.sydneyPostcodes.push('2065');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2061');
        this.sydneyPostcodes.push('2089');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2061');
        this.sydneyPostcodes.push('2089');
        this.sydneyPostcodes.push('2089');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2065');
        this.sydneyPostcodes.push('2065');
        this.sydneyPostcodes.push('2060');
        this.sydneyPostcodes.push('2065');
        this.sydneyPostcodes.push('2100');
        this.sydneyPostcodes.push('2107');
        this.sydneyPostcodes.push('2107');
        this.sydneyPostcodes.push('2093');
        this.sydneyPostcodes.push('2093');
        this.sydneyPostcodes.push('2104');
        this.sydneyPostcodes.push('2100');
        this.sydneyPostcodes.push('2085');
        this.sydneyPostcodes.push('2085');
        this.sydneyPostcodes.push('2107');
        this.sydneyPostcodes.push('2100');
        this.sydneyPostcodes.push('2105');
        this.sydneyPostcodes.push('2107');
        this.sydneyPostcodes.push('2093');
        this.sydneyPostcodes.push('2108');
        this.sydneyPostcodes.push('2097');
        this.sydneyPostcodes.push('2097');
        this.sydneyPostcodes.push('2097');
        this.sydneyPostcodes.push('2084');
        this.sydneyPostcodes.push('2099');
        this.sydneyPostcodes.push('2096');
        this.sydneyPostcodes.push('2108');
        this.sydneyPostcodes.push('2085');
        this.sydneyPostcodes.push('2099');
        this.sydneyPostcodes.push('2084');
        this.sydneyPostcodes.push('2101');
        this.sydneyPostcodes.push('2105');
        this.sydneyPostcodes.push('2094');
        this.sydneyPostcodes.push('2087');
        this.sydneyPostcodes.push('2086');
        this.sydneyPostcodes.push('2086');
        this.sydneyPostcodes.push('2096');
        this.sydneyPostcodes.push('2108');
        this.sydneyPostcodes.push('2101');
        this.sydneyPostcodes.push('2087');
        this.sydneyPostcodes.push('2105');
        this.sydneyPostcodes.push('2095');
        this.sydneyPostcodes.push('2095');
        this.sydneyPostcodes.push('2093');
        this.sydneyPostcodes.push('2103');
        this.sydneyPostcodes.push('2105');
        this.sydneyPostcodes.push('2101');
        this.sydneyPostcodes.push('2099');
        this.sydneyPostcodes.push('2106');
        this.sydneyPostcodes.push('2106');
        this.sydneyPostcodes.push('2093');
        this.sydneyPostcodes.push('2099');
        this.sydneyPostcodes.push('2100');
        this.sydneyPostcodes.push('2101');
        this.sydneyPostcodes.push('2100');
        this.sydneyPostcodes.push('2108');
        this.sydneyPostcodes.push('2107');
        this.sydneyPostcodes.push('2096');
        this.sydneyPostcodes.push('2105');
        this.sydneyPostcodes.push('2092');
        this.sydneyPostcodes.push('2084');
        this.sydneyPostcodes.push('2102');
        this.sydneyPostcodes.push('2102');
        this.sydneyPostcodes.push('2100');
        this.sydneyPostcodes.push('2107');
        this.sydneyPostcodes.push('2097');
        this.sydneyPostcodes.push('2119');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2118');
        this.sydneyPostcodes.push('2118');
        this.sydneyPostcodes.push('2118');
        this.sydneyPostcodes.push('2119');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2145');
        this.sydneyPostcodes.push('2117');
        this.sydneyPostcodes.push('2117');
        this.sydneyPostcodes.push('2122');
        this.sydneyPostcodes.push('2121');
        this.sydneyPostcodes.push('2115');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2150');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2118');
        this.sydneyPostcodes.push('2127');
        this.sydneyPostcodes.push('2121');
        this.sydneyPostcodes.push('2151');
        this.sydneyPostcodes.push('2151');
        this.sydneyPostcodes.push('2152');
        this.sydneyPostcodes.push('2117');
        this.sydneyPostcodes.push('2146');
        this.sydneyPostcodes.push('2150');
        this.sydneyPostcodes.push('2150');
        this.sydneyPostcodes.push('2142');
        this.sydneyPostcodes.push('2116');
        this.sydneyPostcodes.push('2147');
        this.sydneyPostcodes.push('2128');
        this.sydneyPostcodes.push('2127');
        this.sydneyPostcodes.push('2117');
        this.sydneyPostcodes.push('2146');
        this.sydneyPostcodes.push('2127');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2749');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2749');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2759');
        this.sydneyPostcodes.push('2745');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2745');
        this.sydneyPostcodes.push('2178');
        this.sydneyPostcodes.push('2745');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2748');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2745');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2750');
        this.sydneyPostcodes.push('2759');
        this.sydneyPostcodes.push('2760');
        this.sydneyPostcodes.push('2745');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2747');
        this.sydneyPostcodes.push('2753');
        this.sydneyPostcodes.push('2021');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2031');
        this.sydneyPostcodes.push('2031');
        this.sydneyPostcodes.push('2034');
        this.sydneyPostcodes.push('2033');
        this.sydneyPostcodes.push('2032');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2035');
        this.sydneyPostcodes.push('2035');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2036');
        this.sydneyPostcodes.push('2031');
        this.sydneyPostcodes.push('2034');
        this.sydneyPostcodes.push('2031');
        this.sydneyPostcodes.push('2113');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2114');
        this.sydneyPostcodes.push('2112');
        this.sydneyPostcodes.push('2114');
        this.sydneyPostcodes.push('2113');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2113');
        this.sydneyPostcodes.push('2113');
        this.sydneyPostcodes.push('2122');
        this.sydneyPostcodes.push('2114');
        this.sydneyPostcodes.push('2114');
        this.sydneyPostcodes.push('2113');
        this.sydneyPostcodes.push('2112');
        this.sydneyPostcodes.push('2112');
        this.sydneyPostcodes.push('2111');
        this.sydneyPostcodes.push('2114');
        this.sydneyPostcodes.push('2140');
        this.sydneyPostcodes.push('2140');
        this.sydneyPostcodes.push('2140');
        this.sydneyPostcodes.push('2136');
        this.sydneyPostcodes.push('2156');
        this.sydneyPostcodes.push('2153');
        this.sydneyPostcodes.push('2155');
        this.sydneyPostcodes.push('2153');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2157');
        this.sydneyPostcodes.push('2154');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2158');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2157');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2156');
        this.sydneyPostcodes.push('2157');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2155');
        this.sydneyPostcodes.push('2156');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2158');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2765');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2158');
        this.sydneyPostcodes.push('2155');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2756');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2153');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2775');
        this.sydneyPostcodes.push('2026');
        this.sydneyPostcodes.push('2026');
        this.sydneyPostcodes.push('2022');
        this.sydneyPostcodes.push('2024');
        this.sydneyPostcodes.push('2030');
        this.sydneyPostcodes.push('2030');
        this.sydneyPostcodes.push('2026');
        this.sydneyPostcodes.push('2022');
        this.sydneyPostcodes.push('2026');
        this.sydneyPostcodes.push('2024');
        this.sydneyPostcodes.push('2064');
        this.sydneyPostcodes.push('2069');
        this.sydneyPostcodes.push('2068');
        this.sydneyPostcodes.push('2067');
        this.sydneyPostcodes.push('2067');
        this.sydneyPostcodes.push('2068');
        this.sydneyPostcodes.push('2065');
        this.sydneyPostcodes.push('2068');
        this.sydneyPostcodes.push('2063');
        this.sydneyPostcodes.push('2068');
        this.sydneyPostcodes.push('2068');
        this.sydneyPostcodes.push('2068');
        this.sydneyPostcodes.push('2023');
        this.sydneyPostcodes.push('2027');
        this.sydneyPostcodes.push('2028');
        this.sydneyPostcodes.push('2027');
        this.sydneyPostcodes.push('2027');
        this.sydneyPostcodes.push('2021');
        this.sydneyPostcodes.push('2027');
        this.sydneyPostcodes.push('2029');
        this.sydneyPostcodes.push('2030');
        this.sydneyPostcodes.push('2030');
        this.sydneyPostcodes.push('2030');
        this.sydneyPostcodes.push('2025');
    } // generatePostcodes
}
PostcodeManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostcodeManagerService_Factory() { return new PostcodeManagerService(); }, token: PostcodeManagerService, providedIn: "root" });
