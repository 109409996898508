import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) { }

  public init() {
    this.translate.setDefaultLang('default');
    this.translate.use(environment.clientID.toString());
  }
}
