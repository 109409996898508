import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class LanguageService {
    constructor(translate) {
        this.translate = translate;
    }
    init() {
        this.translate.setDefaultLang('default');
        this.translate.use(environment.clientID.toString());
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.TranslateService)); }, token: LanguageService, providedIn: "root" });
