import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { FAQItem, MetaDataService, TradeType } from '../services/meta-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCalendar } from '@angular/material/datepicker';

import { environment } from '../../environments/environment';
import { Clients, JobChannelType } from '../services/globals.service';
import { JobDataService, JobData } from '../services/job-data.service';
import { PopupService, SpecialPopupType } from '../services/popup.service';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {
  
  public pageContentParams = {
    contactPhoneHours: '',
  };
  
  public emergencyEnabled = environment.emergencyEnabled;

  public clientID: number = 0;
  public JobChannelType: typeof JobChannelType = JobChannelType;

  public showContactUs: boolean = false;
  public showFAQPopup: boolean = false;

  public faqLoaded: boolean = false;

  public faqItems: Array<FAQItem> = [];
  public metaDataLoading: boolean = false;

  SpecialPopupType = SpecialPopupType;

  constructor(
    public router: Router,
    public metaData: MetaDataService,
    public sanitizer: DomSanitizer,
    public clients: Clients,
    public jobData: JobDataService,
    public popupService: PopupService,
    public toastr: ToastrService,
    public languageService: LanguageService) {

    this.languageService.init();

    this.clientID = environment.clientID;

    var component: MasterComponent = this;

    if (this.metaData.metaDataLoaded == false) {
      // this.metaDataLoading = true;
      this.metaData.loadMetaData().then(function() {
        //  this.metaDataLoading = false;
        component.loadFAQs();
        component.loadMetaData();
      });
    } else {
      component.loadFAQs();
      this.loadMetaData();
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.indexOf('?') > -1) {
          this.metaData.currentPage = event.urlAfterRedirects.substr(0, event.urlAfterRedirects.indexOf('?'));
        } else {
          this.metaData.currentPage = event.urlAfterRedirects;
        }

        var isIE = /*@cc_on!@*/false || !!(<any>document).documentMode;
      }
    });
  }
  
  private loadMetaData() {
    // load params used in text content
    this.pageContentParams.contactPhoneHours = this.metaData.contactPhoneHours;
  }

  ngOnInit() {
    
  }

  public expandFAQ(item: FAQItem) {
    if (item.show) {
      item.show = false;
    } else {
      item.show = true;
    }
  }

  public openContactUs() {
    this.showContactUs = true;
  }

  public closeContactUs() {
    this.showContactUs = false;
  }

  public openFAQPopup() {
    this.showFAQPopup = true;
  }

  public closeFAQPopup() {
    this.showFAQPopup = false;
  }

  private loadFAQs() {
    if (this.faqLoaded == true) {
      return;
    }

    this.faqItems = [];
    
    this.faqItems.length = 0;
    var component = this;
    
    this.metaData.faqData.forEach(faq => {
      component.faqItems.push(faq);
    });

    this.faqLoaded = true;
  }

}
