<my-content placeholder="main">
    <div class="stepTitle">REPLACEMENT TAP</div>
    <div class="stepDescription">Please select where your tap is located.</div>

    <div class="optionBox boxNoFill lighterBox">
        <div style="float: left;">
            <img class="optionTypeIcon" src="assets/img/{{ clientID }}/icons/jobtypes/g_kitchenlaundry.png" />
        </div>
        <div style="float: left;" class="optionInfoBox">
            <div class="optionInfoBoxTapsTitle">KITCHEN / LAUNDRY</div>
            <div class="optionSelect standardButton buttonPointer" [class.selected]="selectedJobType != null && selectedJobType.id == 2" (click)="tapsTypeClick(2)">SELECT</div>
        </div>
        <div style="clear: both;"></div>
    </div>

    <div class="optionBox boxNoFill lighterBox">
        <div style="float: left;">
            <img class="optionTypeIcon" src="assets/img/{{ clientID }}/icons/jobtypes/g_garden.png" />
        </div>
        <div style="float: left;" class="optionInfoBox">
            <div class="optionInfoBoxTapsTitle">GARDEN</div>
            <div class="optionSelect standardButton buttonPointer" [class.selected]="selectedJobType != null && selectedJobType.id == 3" (click)="tapsTypeClick(3)">SELECT</div>
        </div>
        <div style="clear: both;"></div>
    </div>

    <div class="optionBox boxNoFill lighterBox">
        <div style="float: left;">
            <img class="optionTypeIcon" src="assets/img/{{ clientID }}/icons/jobtypes/g_bathroom.png" />
        </div>
        <div style="float: left;" class="optionInfoBox">
            <div class="optionInfoBoxTapsTitle">BATHROOM</div>
            <div class="optionSelect standardButton buttonPointer" [class.selected]="selectedJobType != null && selectedJobType.id == 4" (click)="tapsTypeClick(4)">SELECT</div>
        </div>
        <div style="clear: both;"></div>
    </div>

    <div class="wizardButtonContainer">
        <div class="wizardButtonBack buttonPointer" (click)="backClick()">{{(clientID != clients.RACWA ? '< BACK' : '< Back')}}</div>
        <div class="wizardButtonNext buttonPointer" [class.valid]="stepValid" (click)="nextClick()">NEXT ></div>
        <div style="clear: both;"></div>
    </div>
</my-content>
