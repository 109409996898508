import * as i0 from "@angular/core";
export class GlobalsService {
    constructor() { }
}
GlobalsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalsService_Factory() { return new GlobalsService(); }, token: GlobalsService, providedIn: "root" });
// https://stackoverflow.com/questions/26501688/a-typescript-guid-class
export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
Guid.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Guid_Factory() { return new Guid(); }, token: Guid, providedIn: "root" });
export class General {
}
export class EmergencyTradeTypes {
    constructor() {
        this.Plumbing = 1;
        this.Electrical = 2;
        this.Locksmith = 3;
    }
}
export class TradeTypes {
    constructor() {
        this.Plumbing = 1;
        this.Electrical = 2;
        this.Locksmith = 3;
        this.Handyman = 4;
        this.HomeInspections = 5;
        this.Glazing = 6;
        this.Carpentry = 7;
        this.Plastering = 8;
        this.Painting = 9;
        this.HVAC = 10;
        this.RoofPlumbing = 11;
        this.Fencing = 12;
        this.BrickLaying = 13;
        this.BrickPaving = 14;
        this.Antennas = 15;
        this.StormReadiness = 16;
        this.Tiling = 17;
        this.Gardening = 18;
        this.GarageDoors = 19;
        this.SpecialOffers = 20;
    }
}
export class Clients {
    constructor() {
        this.Demo = 0;
        this.Huski = 3355;
        this.RACWA = 3412;
        this.AANZ = 3978;
    }
}
export class QuestionTypes {
    constructor() {
        this.FreeText = 1;
        this.BookingWindows = 2;
        this.ContactDetails = 3;
        this.Confirmation = 4;
        this.Quantity = 5;
        this.TwoOptions = 6;
        this.SelectTapModel = 7;
        this.Numeric = 8;
        this.YesNo = 9;
        this.SimpleTwoOptions = 10;
        this.HandymanDuration = 11;
        this.JobNotesPhotos = 12;
        this.CarpentryDuration = 13;
        this.PlasteringDuration = 14;
        this.PaintingDuration = 15;
        this.HVACDuration = 16;
        this.RoofPlumbingDuration = 17;
        this.FencingDuration = 18;
        this.BrickLayingDuration = 19;
        this.BrickPavingDuration = 20;
        this.AntennasDuration = 21;
        this.StormReadinessText = 22;
        this.TilingDuration = 23;
        this.PaintingWholeApartment = 24;
        this.PaintingSingleRoom = 25;
        this.GardeningDuration = 26;
        this.GutterCleaningDuration = 27;
        this.SolarCleaningDuration = 28;
        this.AirConditioningDuration = 29;
        this.SmokeAlarmDuration = 30;
    }
}
export var ServiceTypes;
(function (ServiceTypes) {
    // plumbing
    ServiceTypes[ServiceTypes["ReplacementKitchenLaundryTap"] = 0] = "ReplacementKitchenLaundryTap";
    ServiceTypes[ServiceTypes["ReplacementGardenTap"] = 1] = "ReplacementGardenTap";
    ServiceTypes[ServiceTypes["ReplacementBathroomTap"] = 2] = "ReplacementBathroomTap";
    ServiceTypes[ServiceTypes["ReplacementTapWashers"] = 3] = "ReplacementTapWashers";
    ServiceTypes[ServiceTypes["ReplacementDishwasher"] = 4] = "ReplacementDishwasher";
    ServiceTypes[ServiceTypes["NoHotWater"] = 5] = "NoHotWater";
    ServiceTypes[ServiceTypes["UnblockToiletDrain"] = 6] = "UnblockToiletDrain";
    ServiceTypes[ServiceTypes["BurstPipe"] = 7] = "BurstPipe";
    ServiceTypes[ServiceTypes["PlumbingGeneral"] = 8] = "PlumbingGeneral";
    // electrical
    ServiceTypes[ServiceTypes["ReplaceLightSwitch"] = 9] = "ReplaceLightSwitch";
    ServiceTypes[ServiceTypes["InstallNewInternalPowerPoint"] = 10] = "InstallNewInternalPowerPoint";
    ServiceTypes[ServiceTypes["ReplaceInternalPowerPoint"] = 11] = "ReplaceInternalPowerPoint";
    ServiceTypes[ServiceTypes["InstallNewExternalPowerPoint"] = 12] = "InstallNewExternalPowerPoint";
    ServiceTypes[ServiceTypes["ReplaceExternalPowerPoint"] = 13] = "ReplaceExternalPowerPoint";
    ServiceTypes[ServiceTypes["ReplaceCeilingFan"] = 14] = "ReplaceCeilingFan";
    ServiceTypes[ServiceTypes["InstallNewExhaustFan"] = 15] = "InstallNewExhaustFan";
    ServiceTypes[ServiceTypes["ReplaceSmokeAlarm"] = 16] = "ReplaceSmokeAlarm";
    ServiceTypes[ServiceTypes["InstallExtraInternetPoint"] = 17] = "InstallExtraInternetPoint";
    ServiceTypes[ServiceTypes["ElectricalGeneral"] = 18] = "ElectricalGeneral";
    // single service trades
    ServiceTypes[ServiceTypes["Locksmith"] = 19] = "Locksmith";
    ServiceTypes[ServiceTypes["Handyman"] = 20] = "Handyman";
    ServiceTypes[ServiceTypes["Plastering"] = 21] = "Plastering";
    ServiceTypes[ServiceTypes["Painting"] = 22] = "Painting";
    ServiceTypes[ServiceTypes["HVAC"] = 23] = "HVAC";
    ServiceTypes[ServiceTypes["RoofPlumbing"] = 24] = "RoofPlumbing";
    ServiceTypes[ServiceTypes["Fencing"] = 25] = "Fencing";
    ServiceTypes[ServiceTypes["BrickLaying"] = 26] = "BrickLaying";
    ServiceTypes[ServiceTypes["BrickPaving"] = 27] = "BrickPaving";
    ServiceTypes[ServiceTypes["Carpentry"] = 28] = "Carpentry";
    ServiceTypes[ServiceTypes["Glazing"] = 29] = "Glazing";
    ServiceTypes[ServiceTypes["HomeInspections"] = 30] = "HomeInspections";
    ServiceTypes[ServiceTypes["Antennas"] = 31] = "Antennas";
    ServiceTypes[ServiceTypes["Gardening"] = 32] = "Gardening";
    ServiceTypes[ServiceTypes["GarageDoors"] = 35] = "GarageDoors";
    // painting
    ServiceTypes[ServiceTypes["PaintingWholeApartment"] = 33] = "PaintingWholeApartment";
    ServiceTypes[ServiceTypes["PaintingSingleRoom"] = 34] = "PaintingSingleRoom";
    //special offers
    ServiceTypes[ServiceTypes["GutterCleaning"] = 36] = "GutterCleaning";
    ServiceTypes[ServiceTypes["SolarCleaning"] = 37] = "SolarCleaning";
    ServiceTypes[ServiceTypes["AirConditioning"] = 38] = "AirConditioning";
    ServiceTypes[ServiceTypes["SmokeAlarm"] = 39] = "SmokeAlarm";
})(ServiceTypes || (ServiceTypes = {}));
export var JobChannelType;
(function (JobChannelType) {
    JobChannelType[JobChannelType["ONLINE"] = 1] = "ONLINE";
    JobChannelType[JobChannelType["ADMIN"] = 2] = "ADMIN";
    JobChannelType[JobChannelType["TMC"] = 3] = "TMC";
    JobChannelType[JobChannelType["CALLCENTRE"] = 4] = "CALLCENTRE";
})(JobChannelType || (JobChannelType = {}));
