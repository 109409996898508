<my-content placeholder="main">
    <div class="completeContainer" *ngIf="success == true">
      <div class="stepTitle">BOOKING RECEIVED</div>
      <div class="jobReferenceTitle">Your job reference number is</div>
      <div class="jobReference">{{ booking }}</div>
  
      <div class="completeStepsContainer">
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step2.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 1</div>
            <div class="completeStepDetails">
              You will receive an email confirming your booking and a payment receipt from Global Home Response
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step4.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 2</div>
            <div class="completeStepDetails">
              When our Tradie is on the way to your job, we will send you an SMS enabling you to track their arrival so you know exactly when to be home
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step5.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 3</div>
            <div class="completeStepDetails">
              We will attend within an hour and spend up to an hour at your home to fix the issue or make it safe
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="completeStep">
          <div class="completeStepIcon"><img src="assets/img/{{ clientID }}/icons/complete/step6.png" /></div>
          <div class="completeStepInfo">
            <div class="completeStepHeading">STEP 4</div>
            <div class="completeStepDetails">
              Once the job is finished we will email you a job completion document and follow up with you if additional works are needed
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
  
        <div class="contactNumberContainer">
          <div class="contactNumberInfo">If you have any further questions please contact</div>
          <div class="contactNumber">{{ metaData.contactPhoneNumber }}</div>
        </div>
  
        <div class="ratingContainer" *ngIf="showRatingArea">
          <div class="starsContainer" *ngIf="showRatingStars">
            <div class="ratingHeading">RATE YOUR ONLINE EXPERIENCE</div>
            <div class="ratingStarsContainer" *ngIf="showRatingStars">
              <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
            </div>
          </div>
          <div class="commentContainer" *ngIf="!showRatingStars">
            <div class="ratingHeading">LEAVE A COMMENT</div>
            <div class="ratingStarsContainer">
              <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
            </div>
            <textarea style="margin-top: 35px;" [(ngModel)]="ratingComment"></textarea>
            <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 25px;" (click)="submitRatingComment()">Submit</div>
          </div>
        </div>
        <div class="ratingContainer" *ngIf="!showRatingArea">
          <div class="ratingHeading">THANK YOU FOR YOUR FEEDBACK</div>
        </div>
  
        <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 30px;" [routerLink]="['/home']">Back to Home</div>
  
      </div>
    </div>

    <div class="completeContainer" *ngIf="success == false">
      <div class="stepTitle">BOOKING CANCELLED</div>
      <div class="jobReferenceTitle">Your reference number is</div>
      <div class="jobReference">{{ booking }}</div>
      <div class="completeInfo">It looks like you cancelled your booking and didn't complete the payment.</div>
      <div class="completeInfo" style="margin-top: 0px;">If this is not correct please contact us using the reference number above.</div>

      <div class="contactNumberContainer">
        <div class="contactNumberInfo">If you have any further questions please contact</div>
        <div class="contactNumber">{{ metaData.contactPhoneNumber }}</div>
      </div>

      <div class="ratingContainer" *ngIf="showRatingArea">
        <div class="starsContainer" *ngIf="showRatingStars">
          <div class="ratingHeading">RATE YOUR ONLINE EXPERIENCE</div>
          <div class="ratingStarsContainer" *ngIf="showRatingStars">
            <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
          </div>
        </div>
        <div class="commentContainer" *ngIf="!showRatingStars">
          <div class="ratingHeading">LEAVE A COMMENT</div>
          <div class="ratingStarsContainer">
            <img *ngFor="let star of stars; index as i" src="assets/img/{{ clientID }}/icons/complete/{{ star == true ? 'star_full' : 'star' }}.png" (click)="setStars(i + 1)" />
          </div>
          <textarea style="margin-top: 35px;" [(ngModel)]="ratingComment"></textarea>
          <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 25px;" (click)="submitRatingComment()">Submit</div>
        </div>
      </div>
      <div class="ratingContainer" *ngIf="!showRatingArea">
        <div class="ratingHeading">THANK YOU FOR YOUR FEEDBACK</div>
      </div>

      <div class="optionSelect standardButton buttonPointer" style="margin-left: auto; margin-right: auto; margin-top: 30px;" [routerLink]="['/home']">Back to Home</div>
    </div>
  </my-content>