/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./master.module";
var MasterModuleNgFactory = i0.ɵcmf(i1.MasterModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i1.ContentService, i1.ContentService, []), i0.ɵmpd(1073742336, i1.MasterModule, i1.MasterModule, [])]); });
export { MasterModuleNgFactory as MasterModuleNgFactory };
var styles_ContentComponent = [];
var RenderType_ContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentComponent, data: {} });
export { RenderType_ContentComponent as RenderType_ContentComponent };
export function View_ContentComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_ContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "my-content", [], null, null, null, View_ContentComponent_0, RenderType_ContentComponent)), i0.ɵdid(1, 114688, null, 0, i1.ContentComponent, [i0.ElementRef, i1.ContentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentComponentNgFactory = i0.ɵccf("my-content", i1.ContentComponent, View_ContentComponent_Host_0, { placeholder: "placeholder" }, {}, ["*"]);
export { ContentComponentNgFactory as ContentComponentNgFactory };
var styles_PlaceholderComponent = [];
var RenderType_PlaceholderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PlaceholderComponent, data: {} });
export { RenderType_PlaceholderComponent as RenderType_PlaceholderComponent };
export function View_PlaceholderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_PlaceholderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "my-placeholder", [], null, null, null, View_PlaceholderComponent_0, RenderType_PlaceholderComponent)), i0.ɵdid(1, 180224, null, 0, i1.PlaceholderComponent, [i0.ViewContainerRef, i1.ContentService], null, null)], null, null); }
var PlaceholderComponentNgFactory = i0.ɵccf("my-placeholder", i1.PlaceholderComponent, View_PlaceholderComponent_Host_0, { name: "name" }, {}, ["*"]);
export { PlaceholderComponentNgFactory as PlaceholderComponentNgFactory };
