import * as i0 from "@angular/core";
export class PopupService {
    constructor() {
        this.specialPopups = [];
        this.standardPopupOpen = false;
        this.standardPopTitle = '';
        this.standardPopContent = '';
        this.standardPopupButtons = [];
        this.specialPopups.length = 0;
        Object.keys(SpecialPopupType).filter(k => typeof SpecialPopupType[k] === "number").forEach(type => {
            this.specialPopups.push(new SpecialPopup(SpecialPopupType[type]));
        });
    }
    open(title, content, buttons = null) {
        this.standardPopTitle = title;
        this.standardPopContent = content;
        if (buttons == null) {
            this.standardPopupButtons.push(new PopupButton('Close', '', () => {
                this.close();
            }));
        }
        else {
            this.standardPopupButtons = buttons;
        }
        this.standardPopupOpen = true;
    }
    close() {
        this.standardPopTitle = '';
        this.standardPopContent = '';
        this.standardPopupButtons.length = 0;
        this.standardPopupOpen = false;
    }
    getSpecialPopup(type) {
        var ret = null;
        this.specialPopups.forEach(p => {
            if (p.type == type) {
                ret = p;
            }
        });
        return ret;
    }
    openSpecialPopup(type) {
        var p = this.getSpecialPopup(type);
        if (p != null) {
            p.open = true;
        }
    }
    closeSpecialPopup(type) {
        var p = this.getSpecialPopup(type);
        if (p != null) {
            p.open = false;
        }
    }
}
PopupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupService_Factory() { return new PopupService(); }, token: PopupService, providedIn: "root" });
export class PopupButton {
    constructor(text, classes, callback = null, replaceClasses = false) {
        this.text = '';
        this.classes = 'standardButton buttonPointer';
        this.callback = null;
        this.text = text;
        this.classes = (replaceClasses == true ? classes : this.classes + classes);
        this.callback = callback;
    }
    onClick() {
        if (this.callback != null) {
            this.callback();
        }
    }
}
export class SpecialPopup {
    constructor(type) {
        this.open = false;
        this.type = type;
    }
}
export var SpecialPopupType;
(function (SpecialPopupType) {
    SpecialPopupType[SpecialPopupType["TermsAndConditions"] = 0] = "TermsAndConditions";
    SpecialPopupType[SpecialPopupType["PrivacyPolicy"] = 1] = "PrivacyPolicy";
    SpecialPopupType[SpecialPopupType["FAQ"] = 2] = "FAQ";
    SpecialPopupType[SpecialPopupType["ContactUs"] = 3] = "ContactUs";
})(SpecialPopupType || (SpecialPopupType = {}));
