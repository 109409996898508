import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';
import { MetaDataService } from '../services/meta-data.service';

@Component({
  selector: 'HomeServices-emergency-complete',
  templateUrl: './emergency-complete.component.html',
  styleUrls: ['./emergency-complete.component.css']
})
export class EmergencyCompleteComponent implements OnInit {

  public booking: string = '';
  public success: boolean = false;

  public stars: Array<boolean> = [];
  public starRating: number = 0;
  public ratingComment: string = '';
  public showRatingStars: boolean = true;
  public showRatingArea: boolean = true;

  public clientID: number = 0;
  public capability: any = {};

  constructor(
    public db: AngularFirestore,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public metaData: MetaDataService
  ) {
    this.clientID = environment.clientID;
    this.capability = environment.capability;

    this.stars.push(false);
    this.stars.push(false);
    this.stars.push(false);
    this.stars.push(false);
    this.stars.push(false);

    this.metaData.pushRACDataLayerPageView('/emergencycomplete', 'emergencycomplete');
  }

  ngOnInit() {
    this.route.queryParams // get querystring params
    .subscribe(params => {
      this.booking = params.booking;
      this.success = (params.success == 'true');
    });
  }

  public setStars(value: number) {
    this.starRating = value;

    for (var i = 0; i < this.stars.length; i++) {
      if (i < this.starRating) {
        this.stars[i] = true;
      } else {
        this.stars[i] = false;
      }
    }

    this.showRatingStars = false;
  }

  public submitRatingComment() {
    //
  }

}
