<my-content placeholder="headerContent">
    <div class="heroHomeLogoContainer">
        <img class="heroHomeLogo" src="assets/img/{{ clientID }}/herologo.png" />
    </div>
    <div class="homeTitle">{{ 'Home.Title' | translate }}</div>
    <div class="homeSubTitle">{{ 'Home.SubTitle' | translate:pageContentParams }}</div>

    <div class="emergencyScheduledContainer" *ngIf="environment.emergencyEnabled == true && environment.emergencyTileEnabled == false">

        <div class="emergencyContainer buttonPointer" (click)="emergencyClicked()">
            <div *ngIf="clientID == clients.Huski" class="huskiCharacter">
                <img src="assets/img/{{ clientID }}/home/character.png" />
            </div>
            
            <div *ngIf="clientID == clients.Huski" class="huskiCharacterArm">
                <img src="assets/img/{{ clientID }}/home/characterarm.png" />
            </div>

            <div class="background">
                <div class="topBoxLeft">
                    <div class="topBoxIcon">
                        <img src="assets/img/{{ clientID }}/home/emergency.png"  />
                    </div>
                    <div class="topBoxIconSubtext">
                        AVAILABLE 24/7
                    </div>
                </div>
                <div class="topBoxRight">
                    <div class="topBoxTitle">{{ 'Home.EmergencyTitle' | translate:pageContentParams }}</div>
                    <div class="topBoxDescription">{{ 'Home.EmergencySubTitle' | translate:pageContentParams }}</div>
                    <div class="topBoxButton standardButton buttonPointer">BOOK NOW</div>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
        <div class="scheduledContainer buttonPointer" (click)="scheduledClicked(servicesAnchor)">
            <div class="topBoxLeft">
                <div class="topBoxIcon">
                    <img src="assets/img/{{ clientID }}/home/scheduled.png"  />
                </div>
                <div class="topBoxIconSubtext">
                    DIRECT REPAIRS
                </div>
            </div>
            <div class="topBoxRight">
                <div class="topBoxTitle">SCHEDULED ASSISTANCE</div>
                <div class="topBoxDescription">I need to schedule a home maintenance specialist</div>
                <div class="topBoxButton standardButton buttonPointer">BOOK NOW</div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>

    <div class="sellingPointContainer" *ngIf="clientID != clients.RACWA">
        <div class="sellingPoint">
            <img src="assets/img/{{ clientID }}/home/homestar.png" />
            <br /><span class="sellingPointText">{{ 'Home.SellingPoint1' | translate:pageContentParams }}</span>
        </div>
        <div class="sellingPoint">
            <img src="assets/img/{{ clientID }}/home/hometrade.png" />
            <br /><span class="sellingPointText">{{ 'Home.SellingPoint2' | translate:pageContentParams }}</span>
        </div>
        <div class="sellingPoint">
            <img src="assets/img/{{ clientID }}/home/homeprice.png" />
            <br /><span class="sellingPointText">{{ 'Home.SellingPoint3' | translate:pageContentParams }}</span>
        </div>
    </div>
</my-content>

<my-content placeholder="main">
    <div class="popupBlocker" *ngIf="showSomethingElsePopup"></div>
    <div class="popupContainer" *ngIf="showSomethingElsePopup">
        <div class="popupHeader">
        <div class="popupTitle">SOMETHING ELSE</div>
        <div class="popupClose buttonPointer" (click)="toggleSomethingElsePopup(false)">X</div>
        </div>
        <div class="popupBody">
        <div class="popupContent">
            <span [innerHTML]="'Home.SomethingElseBody1' | translate:pageContentParams"></span>
            <br /><br />
            <span [innerHTML]="'Home.SomethingElseBody2' | translate:pageContentParams"></span>
        </div>
        <div class="popupButtons">
            <div class="standardButton buttonPointer" (click)="toggleSomethingElsePopup(false)">Close</div>
        </div>
        </div>
    </div>

    <div class="stepTitle" #servicesAnchor>{{ 'Home.ServicesTitle' | translate:pageContentParams }}</div>
    
    <div class="homeTradesContainer">
        <div class="homeTrade buttonPointer" (click)="emergencyClicked()" *ngIf="environment.emergencyEnabled == true && environment.emergencyTileEnabled == true">
            <img src="assets/img/{{ clientID }}/home/tradetypes/emergency.png" />
            <div class="tradeName">{{ 'Home.EmergencyTitle' | translate:pageContentParams }}</div>
            <div class="standardButton tradeBookButton buttonPointer">BOOK NOW</div>
        </div>

        <div *ngFor="let trade of metaData.tradeTypes" class="homeTrade buttonPointer"  (click)="gotoRoute(trade.urlID)">
            <img src="assets/img/{{ clientID }}/home/tradetypes/{{ trade.icon }}" />
            <div class="tradeName" [class.uppercaseLabel]="trade.name == 'HVAC'">{{ trade.name.toUpperCase() }}</div>
            <div class="standardButton tradeBookButton buttonPointer">BOOK NOW</div>
        </div>

        <div class="homeTrade buttonPointer" (click)="toggleSomethingElsePopup(true)" *ngIf="environment.somethingElseEnabled == true">
            <img src="assets/img/{{ clientID }}/home/tradetypes/somethingelse.png" />
            <div class="tradeName">SOMETHING ELSE?</div>
            <div class="standardButton tradeBookButton buttonPointer">BOOK NOW</div>
        </div>
    </div>

    <div *ngIf="environment.showHowItWorks">
        <div class="stepTitle howItWorksTitle" #servicesAnchor>HOW IT WORKS</div>
        <div class="homepageArea mobile">
            <div class="howItWorksPhoneContainer">
                <img src="assets/img/{{ clientID }}/home/phone.png" />
            </div>

            <div class="howItWorksInfoContainer">

                <div class="howItWorksPoint">
                    <div class="howItWorksIcon">
                        <img src="assets/img/{{ clientID }}/home/tools.png" />
                    </div>
                    <div class="howItWorksRightBox">
                        <div class="howItWorksHeader">SELECT A SERVICE</div>
                        <div class="howItWorksText">Select from a multitude of trade services, designed to cater for any home requirement.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="howItWorksPoint">
                    <div class="howItWorksIcon">
                        <img src="assets/img/{{ clientID }}/home/pchome.png" />
                    </div>
                    <div class="howItWorksRightBox">
                        <div class="howItWorksHeader">BOOK ONLINE</div>
                        <div class="howItWorksText">Make a booking online for either emergency or scheduled services through our online platform.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="howItWorksPoint">
                    <div class="howItWorksIcon">
                        <img src="assets/img/{{ clientID }}/home/tracking.png" />
                    </div>
                    <div class="howItWorksRightBox">
                        <div class="howItWorksHeader">TRADE COMPLETION</div>
                        <div class="howItWorksText">Track our tradie to know exactly when they will arrive, as they then complete your job requirements.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div style="clear: both;"></div>

            </div>
        </div>

        <div class="stepTitle differentTitle" #servicesAnchor>WHY WE ARE DIFFERENT</div>
        <div class="homepageArea">
            <div class="differentContainer">

                <div class="differentPoint marginBottom">
                    <div class="differentIcon">
                        <img src="assets/img/{{ clientID }}/home/time.png" />
                    </div>
                    <div class="differentRightBox">
                        <div class="differentHeader">ON TIME</div>
                        <div class="differentText">We understand your time is important and that's why we turn up when we say we will.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="differentPoint marginBottom">
                    <div class="differentIcon">
                        <img src="assets/img/{{ clientID }}/home/value.png" />
                    </div>
                    <div class="differentRightBox">
                        <div class="differentHeader">VALUE</div>
                        <div class="differentText">We offer unparalleled value for money, with no compromise on quality of service.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="differentPoint marginBottom">
                    <div class="differentIcon">
                        <img src="assets/img/{{ clientID }}/home/customerservice.png" />
                    </div>
                    <div class="differentRightBox">
                        <div class="differentHeader">CUSTOMER SERVICE</div>
                        <div class="differentText">Our ethos is to deliver exceptional customer service outcomes every time.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="differentPoint marginBottom">
                    <div class="differentIcon">
                        <img src="assets/img/{{ clientID }}/home/certified.png" />
                    </div>
                    <div class="differentRightBox">
                        <div class="differentHeader">QUALIFIED</div>
                        <div class="differentText">Each of our tradies are fully qualified and fit for purpose for any job requirement.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="differentPoint marginBottom">
                    <div class="differentIcon">
                        <img src="assets/img/{{ clientID }}/home/schedule.png" />
                    </div>
                    <div class="differentRightBox">
                        <div class="differentHeader">SCHEDULED SERVICE</div>
                        <div class="differentText">We're here to support you, that's why our flexible service offering allows you to book a time that works for you.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div class="differentPoint marginBottom">
                    <div class="differentIcon">
                        <img src="assets/img/{{ clientID }}/home/24hour.png" />
                    </div>
                    <div class="differentRightBox">
                        <div class="differentHeader">CALL US</div>
                        <div class="differentText">If you require Emergency Assistance then our team is here to support you 24/7/365.</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>

                <div style="clear: both;"></div>

            </div>
        </div>
    </div>

</my-content>
