export const environment = {
    
    // homepage modifiers
    emergencyEnabled: true,
    emergencyTileEnabled: false,
    showHowItWorks: true,

    jobPrefix: 'ER',
    production: false,
    apiUrl: "",
    clientID: 0,
    productName: "Home Services Demo",
    flatAdminFee: 0,
    GST: 0.1,
    coverageAreaText: "We are currently available in Melbourne, Sydney, Brisbane and the Gold Coast.",
    googleMapsCountries: [ 'au' ],
    outOfHoursEnabled: true,
    blockMondayOnWeekend: true,
    bundlingEnabled: true,
    emergencyTriageEnabled: true,
    emergencyAfterHoursStart: 17.00,
    emergencyAfterHoursEnd: 8.30,
    nextDayBookingEnd: 12.00,
    somethingElseEnabled: true,
    onlineRatingEnabled: true,
    GATrackingID: 'UA-128468582-9',
    bookingWindows: [
        { start: '7:30am', end: '8:30am', outOfHours: true },
        { start: '8:30am', end: '10:30am', outOfHours: false },
        { start: '10:30am', end: '12:30pm', outOfHours: false },
        { start: '12:30pm', end: '2:30pm', outOfHours: false },
        { start: '2:30pm', end: '4:30pm', outOfHours: false },
        { start: '5:00pm', end: '6:30pm', outOfHours: true },
    ],
    coveredStates: [
        { country: 'AU', state: 'VIC', nextDayBooking: false },
        { country: 'AU', state: 'NSW', nextDayBooking: false },
        { country: 'AU', state: 'QLD', nextDayBooking: false },
    ],
    firebase: {
        apiKey: 'AIzaSyBQccDDug4J1AqYf7oa1HpsQeHYuI4rPfY',
        authDomain: 'homeservices-demo.firebaseapp.com',
        projectId: 'homeservices-demo',
        storageBucket: 'homeservices-demo.appspot.com',
    },
    capability: { hasMemberShip: false, memberShipConfig : {}},
    defaultBlackoutDays: 2,
    webserviceURL: "https://easylinktestwebservice.johnslyng.com.au/",
    localURL: "https://demo.ghr-home.com",
    stripeAPIKey: "pk_test_a1vodSlxkr0BeMH7xZ5FhFiV",
};
