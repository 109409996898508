import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { GoogleAnalyticsModule, GA_TOKEN } from 'angular-ga';

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { ArchwizardModule } from 'ng2-archwizard';

/* Bootstrap */
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';

/* Page Components */
import { MasterModule } from './master/master.module';
import { MasterComponent } from './master/master.component';

/* Services */
import { JobDataService } from './services/job-data.service';
import { MetaDataService } from './services/meta-data.service';
import { PostcodeManagerService } from './services/postcode-manager.service';
import { PricingService } from './services/pricing.service';
import { PopupService } from './services/popup.service';
import { APIService } from './services/api.service';
import { LanguageService } from './services/language.service';

/* Views */
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { FaqComponent } from './faq/faq.component';
import { ContactusComponent } from './contactus/contactus.component';
import { TriageComponent } from './triage/triage.component';
import { CompleteComponent } from './complete/complete.component';
import { StartComponent } from './start/start.component';
import { TapsComponent } from './taps/taps.component';
import { CompleteinspectionComponent } from './completeinspection/completeinspection.component';
import { EmergencyComponent } from './emergency/emergency.component';
import { EmergencyCompleteComponent } from './emergency-complete/emergency-complete.component';
import { CardpaymentComponent } from './cardpayment/cardpayment.component';
import { CardpaymentCompleteComponent } from './cardpayment-complete/cardpayment-complete.component';

/* Directives */
import { SelectColourDirective } from './selectcolour.directive';

/* Material components */
import { HSMaterialModule } from '../material-module';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { environment } from '../environments/environment';
import { Clients, QuestionTypes, TradeTypes } from './services/globals.service';

const appRoutes: Routes = [
  {
    path: 'home',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: HomeComponent
      }
    ]
  },

  {
    path: 'faq',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: FaqComponent
      }
    ]
  },

  {
    path: 'contactus',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: ContactusComponent
      }
    ]
  },

  {
    path: 'privacy',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: PrivacyComponent
      }
    ]
  },

  {
    path: 'terms',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: TermsComponent
      }
    ]
  },

  {
    path: 'emergency',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmergencyComponent
      }
    ]
  },

  {
    path: 'triage',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: TriageComponent
      }
    ]
  },

  {
    path: 'complete',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: CompleteComponent
      }
    ]
  },

  {
    path: 'emergencycomplete',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: EmergencyCompleteComponent
      }
    ]
  },

  {
    path: 'completeinspection',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: CompleteinspectionComponent
      }
    ]
  },

  {
    path: 'start',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: StartComponent
      }
    ]
  },

  {
    path: 'taps',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: TapsComponent
      }
    ]
  },

  {
    path: 'cardpayment',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: CardpaymentComponent
      }
    ]
  },

  {
    path: 'cardpaymentcomplete',
    component: MasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: CardpaymentCompleteComponent
      }
    ]
  },

  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    MasterComponent,
    HomeComponent,
    TriageComponent,
    CompleteComponent,
    StartComponent,
    TapsComponent,
    SelectColourDirective,
    FaqComponent,
    ContactusComponent,
    PrivacyComponent,
    TermsComponent,
    CompleteinspectionComponent,
    EmergencyComponent,
    EmergencyCompleteComponent,
    CardpaymentComponent,
    CardpaymentCompleteComponent
  ],
  imports: [
    BrowserModule,
    MasterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ArchwizardModule,
    ToastrModule.forRoot(),
    GoogleAnalyticsModule.forRoot(),
    FontAwesomeModule,
    
    HSMaterialModule,
    MatNativeDateModule,
    BrowserAnimationsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    
    RouterModule.forRoot(
      appRoutes
    ),
    
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD1VK91D1yQKjZHgfNb2-W_AfYiBpdOym4',
      libraries: ['places'],
    }),

    TranslateModule.forRoot({
      defaultLanguage: 'default',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    JobDataService,
    MetaDataService,
    PostcodeManagerService,
    PricingService,
    Clients, QuestionTypes, TradeTypes,
    PopupService,
    APIService,
    LanguageService,
    { provide: StorageBucket, useValue: environment.firebase.storageBucket },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: GA_TOKEN, useValue: 'UA-128468582-9' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
