import { PostcodeManagerService } from './postcode-manager.service';
import { ServiceTypes } from './globals.service';
import { Clients } from '../services/globals.service';
import * as i0 from "@angular/core";
import * as i1 from "./postcode-manager.service";
import * as i2 from "./globals.service";
export class PricingService {
    constructor(_postcodeManager, _clients) {
        this.ServiceTypes = ServiceTypes;
        this.postcodeManager = null;
        this.pricingAreas = [];
        this.currentPricingArea = null;
        this.clients = null;
        this.postcodeManager = _postcodeManager;
        this.clients = _clients;
    }
    setupPricingAreas() {
        var vicArea = new PricingArea();
        vicArea.coveredSuburbs = this.postcodeManager.victoriaPostcodes;
        var vicElectrical = new TradeTypePricing();
        vicElectrical.id = 2;
        var e18 = new JobTypePricing();
        e18.id = 18;
        e18.additionalCalloutFee = 28;
        var e16 = new JobTypePricing();
        e16.id = 16;
        e16.additionalCalloutFee = 28;
        var e17 = new JobTypePricing();
        e17.id = 17;
        e17.additionalCalloutFee = 28;
        var e21 = new JobTypePricing();
        e21.id = 21;
        e21.additionalCalloutFee = 28;
        var e20 = new JobTypePricing();
        e20.id = 20;
        e20.additionalCalloutFee = 28;
        var e19 = new JobTypePricing();
        e19.id = 19;
        e19.additionalCalloutFee = 28;
        var e15 = new JobTypePricing();
        e15.id = 15;
        e15.additionalCalloutFee = 28;
        var e13 = new JobTypePricing();
        e13.id = 13;
        e13.additionalCalloutFee = 28;
        var e22 = new JobTypePricing();
        e22.id = 22;
        e22.additionalCalloutFee = 28;
        vicElectrical.jobTypes.push(e18);
        vicElectrical.jobTypes.push(e16);
        vicElectrical.jobTypes.push(e17);
        vicElectrical.jobTypes.push(e21);
        vicElectrical.jobTypes.push(e20);
        vicElectrical.jobTypes.push(e19);
        vicElectrical.jobTypes.push(e18);
        vicElectrical.jobTypes.push(e13);
        vicElectrical.jobTypes.push(e22);
        var vicHandyman = new TradeTypePricing();
        vicHandyman.id = 4;
        var h23 = new JobTypePricing();
        h23.id = 23;
        h23.additionalCalloutFee = 28;
        vicHandyman.jobTypes.push(h23);
        vicArea.tradeTypes.push(vicElectrical);
        vicArea.tradeTypes.push(vicHandyman);
        var nswArea = new PricingArea();
        nswArea.coveredSuburbs = this.postcodeManager.sydneyPostcodes;
        this.pricingAreas.push(vicArea);
        this.pricingAreas.push(nswArea);
    }
}
PricingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PricingService_Factory() { return new PricingService(i0.ɵɵinject(i1.PostcodeManagerService), i0.ɵɵinject(i2.Clients)); }, token: PricingService, providedIn: "root" });
export class PricingArea {
    constructor() {
        this.beginActiveTime = null; // if null it's always active
        this.endActiveTime = null;
        this.coveredSuburbs = [];
        this.tradeTypes = [];
    }
}
export class TradeTypePricing {
    constructor() {
        this.id = 0;
        this.jobTypes = [];
        this.hourlyRate = 0;
        this.calloutFee = 0;
        // out of hours
        this.oohHourlyRate = 0;
        this.oohCalloutFee = 0;
    }
}
export class JobTypePricing {
    constructor() {
        this.id = 0;
        this.triageQuestions = [];
        this.partsCostBase = 0;
        this.partsCostPremium = 0;
        this.attendanceHours = 1;
        this.additionalCalloutFee = 0;
    }
}
export class TriageQuestionPricing {
    constructor() {
        this.additionalFixedCost = 0;
        this.additionalPerCost = 0;
        this.modifiedBasePartsCost = 0;
        this.modifiedPremiumPartsCost = 0;
        this.hourlyMultiplier = 0;
        this.partsMultiplier = 0;
        this.partsAdd = 0;
    }
}
