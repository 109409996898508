/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./taps.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../master/master.module.ngfactory";
import * as i3 from "../master/master.module";
import * as i4 from "./taps.component";
import * as i5 from "../services/meta-data.service";
import * as i6 from "@angular/router";
import * as i7 from "../services/job-data.service";
import * as i8 from "ngx-toastr";
import * as i9 from "../services/globals.service";
var styles_TapsComponent = [i0.styles];
var RenderType_TapsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TapsComponent, data: {} });
export { RenderType_TapsComponent as RenderType_TapsComponent };
export function View_TapsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "my-content", [["placeholder", "main"]], null, null, null, i2.View_ContentComponent_0, i2.RenderType_ContentComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContentComponent, [i1.ElementRef, i3.ContentService], { placeholder: [0, "placeholder"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "div", [["class", "stepTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["REPLACEMENT TAP"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["class", "stepDescription"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please select where your tap is located."])), (_l()(), i1.ɵeld(6, 0, null, 0, 8, "div", [["class", "optionBox boxNoFill lighterBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["class", "optionTypeIcon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "optionInfoBox"], ["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "optionInfoBoxTapsTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["KITCHEN / LAUNDRY"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "optionSelect standardButton buttonPointer"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tapsTypeClick(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["SELECT"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["style", "clear: both;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, 0, 8, "div", [["class", "optionBox boxNoFill lighterBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [["class", "optionTypeIcon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "optionInfoBox"], ["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "optionInfoBoxTapsTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GARDEN"])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "optionSelect standardButton buttonPointer"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tapsTypeClick(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["SELECT"])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "div", [["style", "clear: both;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, 0, 8, "div", [["class", "optionBox boxNoFill lighterBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "img", [["class", "optionTypeIcon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 4, "div", [["class", "optionInfoBox"], ["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "div", [["class", "optionInfoBoxTapsTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["BATHROOM"])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "div", [["class", "optionSelect standardButton buttonPointer"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tapsTypeClick(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["SELECT"])), (_l()(), i1.ɵeld(32, 0, null, null, 0, "div", [["style", "clear: both;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, 0, 5, "div", [["class", "wizardButtonContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "div", [["class", "wizardButtonBack buttonPointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(35, null, ["", ""])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "div", [["class", "wizardButtonNext buttonPointer"]], [[2, "valid", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["NEXT >"])), (_l()(), i1.ɵeld(38, 0, null, null, 0, "div", [["style", "clear: both;"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "main"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "assets/img/", _co.clientID, "/icons/jobtypes/g_kitchenlaundry.png"); _ck(_v, 8, 0, currVal_1); var currVal_2 = ((_co.selectedJobType != null) && (_co.selectedJobType.id == 2)); _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "assets/img/", _co.clientID, "/icons/jobtypes/g_garden.png"); _ck(_v, 17, 0, currVal_3); var currVal_4 = ((_co.selectedJobType != null) && (_co.selectedJobType.id == 3)); _ck(_v, 21, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "assets/img/", _co.clientID, "/icons/jobtypes/g_bathroom.png"); _ck(_v, 26, 0, currVal_5); var currVal_6 = ((_co.selectedJobType != null) && (_co.selectedJobType.id == 4)); _ck(_v, 30, 0, currVal_6); var currVal_7 = ((_co.clientID != _co.clients.RACWA) ? "< BACK" : "< Back"); _ck(_v, 35, 0, currVal_7); var currVal_8 = _co.stepValid; _ck(_v, 36, 0, currVal_8); }); }
export function View_TapsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "HomeServices-taps", [], null, null, null, View_TapsComponent_0, RenderType_TapsComponent)), i1.ɵdid(1, 114688, null, 0, i4.TapsComponent, [i5.MetaDataService, i6.Router, i7.JobDataService, i8.ToastrService, i9.Clients], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TapsComponentNgFactory = i1.ɵccf("HomeServices-taps", i4.TapsComponent, View_TapsComponent_Host_0, {}, {}, []);
export { TapsComponentNgFactory as TapsComponentNgFactory };
