/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cardpayment.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../master/master.module.ngfactory";
import * as i3 from "../master/master.module";
import * as i4 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./cardpayment.component";
import * as i8 from "@angular/fire/firestore";
import * as i9 from "ngx-toastr";
import * as i10 from "@angular/router";
import * as i11 from "../services/meta-data.service";
import * as i12 from "../services/globals.service";
import * as i13 from "../services/api.service";
var styles_CardpaymentComponent = [i0.styles];
var RenderType_CardpaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardpaymentComponent, data: {} });
export { RenderType_CardpaymentComponent as RenderType_CardpaymentComponent };
export function View_CardpaymentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "my-content", [["placeholder", "main"]], null, null, null, i2.View_ContentComponent_0, i2.RenderType_ContentComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContentComponent, [i1.ElementRef, i3.ContentService], { placeholder: [0, "placeholder"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "div", [["class", "paymentTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["REDIRECTING TO PAYMENT"])), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "paymentTitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["style", "font-size: 3em;"]], [[8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(6, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaIconService], { iconProp: [0, "iconProp"], spin: [1, "spin"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "main"; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.faSpinner; var currVal_3 = true; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).renderedIconHTML; _ck(_v, 5, 0, currVal_1); }); }
export function View_CardpaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "HomeServices-cardpayment", [], null, null, null, View_CardpaymentComponent_0, RenderType_CardpaymentComponent)), i1.ɵdid(1, 114688, null, 0, i7.CardpaymentComponent, [i8.AngularFirestore, i9.ToastrService, i10.ActivatedRoute, i11.MetaDataService, i12.Clients, i13.APIService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardpaymentComponentNgFactory = i1.ɵccf("HomeServices-cardpayment", i7.CardpaymentComponent, View_CardpaymentComponent_Host_0, {}, {}, []);
export { CardpaymentComponentNgFactory as CardpaymentComponentNgFactory };
