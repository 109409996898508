import { Component, OnInit } from '@angular/core';

import { MetaDataService } from '../services/meta-data.service';
import { JobData, JobDataService } from '../services/job-data.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';

import { environment } from '../../environments/environment';
import { TradeTypes, Clients } from '../services/globals.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css']
})
export class CompleteComponent implements OnInit {
  
  public pageContentParams = {
    contactPhoneNumber: '',
    contactEmail: '',
  };

  public metaData: MetaDataService = null;
  public jobData: JobDataService = null;
  public toastr: ToastrService = null;
  public db: AngularFirestore = null;
  public clients: Clients = null;

  public stars: Array<boolean> = [];
  public starRating: number = 0;
  public ratingComment: string = '';
  public showRatingStars: boolean = true;
  public showRatingArea: boolean = true;
  public ratingEnabled: boolean = true;

  public clientID: number = 0;
  public capability: any = {};

  constructor(_metaData: MetaDataService, _jobData: JobDataService, _toastr: ToastrService, _db: AngularFirestore,
    public tradeTypes: TradeTypes,  _clients: Clients) {
    this.metaData = _metaData;
    this.jobData = _jobData;
    this.toastr = _toastr;
    this.db = _db;
    this.clients = _clients;

    this.stars.push(false);
    this.stars.push(false);
    this.stars.push(false);
    this.stars.push(false);
    this.stars.push(false);

    this.clientID = environment.clientID;
    this.capability = environment.capability;
    
    this.ratingEnabled = environment.onlineRatingEnabled;

    var _page = '/complete';
    var _title = 'complete';

    if (this.jobData.trade != null) {
      _page += '/' + this.convertURLName(this.jobData.trade.name);
      _title += ' - ' + this.convertURLName(this.jobData.trade.name);
    }
    
    // load params used in text content
    this.pageContentParams.contactEmail = this.metaData.contactEmail;
    this.pageContentParams.contactPhoneNumber = this.metaData.contactPhoneNumber;

    this.metaData.pushRACDataLayerPageView(_page, _title);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  public convertURLName(name: string) {
    var newName = name.split(' ').join('-');
    newName = newName.split('_').join('-');

    return newName.toLowerCase();
  }

  public setStars(value: number) {
    this.starRating = value;

    for (var i = 0; i < this.stars.length; i++) {
      if (i < this.starRating) {
        this.stars[i] = true;
      } else {
        this.stars[i] = false;
      }
    }

    this.showRatingStars = false;
  }

  public submitRatingComment() {
    if (this.jobData.addedJobRef == 0) {
      return;
    }

    var component: any = this;
    var toastr = this.toastr;
    var db = this.db;

    var now = new Date();

    var newRating: any = {};
    newRating.jobref = this.jobData.addedJobRef;
    newRating.score = this.starRating;
    newRating.comment = this.ratingComment;
    newRating.insertedDate = new Date(now.toUTCString());

    db.doc('/bookingratings/' + environment.jobPrefix + '_' + newRating.jobref.toString()).set(newRating).then(function () {
      component.showRatingArea = false;
    }).catch(function (error) {
      toastr.error('Rating failed, please try again.');
    });
  }

}
