import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class APIService {
    constructor(http) {
        this.http = http;
        this.apiURL = environment.webserviceURL;
    }
    callAPI(methodPath, params, callback, errorCallback = null) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        this.http.post(this.apiURL + methodPath, params, httpOptions).pipe(map(reply => {
            return reply;
        }), catchError(error => {
            console.log('API Error: ' + error.message);
            throw error.message;
            //return this.handleError(error);
        })).subscribe(reply => {
            callback(reply);
        }, error => {
            if (errorCallback != null) {
                errorCallback(error);
            }
        });
    }
    handleError(error) {
        return throwError('An unexpected error has occurred');
    }
}
APIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIService_Factory() { return new APIService(i0.ɵɵinject(i1.HttpClient)); }, token: APIService, providedIn: "root" });
