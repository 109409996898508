import { Component, OnInit } from '@angular/core';
import { MetaDataService } from '../services/meta-data.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { Clients } from '../services/globals.service';
import { APIService } from '../services/api.service';

import { environment } from '../../environments/environment';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'HomeServices-cardpayment',
  templateUrl: './cardpayment.component.html',
  styleUrls: ['./cardpayment.component.css']
})
export class CardpaymentComponent implements OnInit {

  public faSpinner = faSpinner;

  public clientID: number = 0;
  
  public sessionID: string = '0';
  public booking: number = 0;

  //public component: CardpaymentComponent = this;

  constructor(
    public db: AngularFirestore,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public metaData: MetaDataService,
    public clients: Clients,
    public apiService: APIService
  ) {
    this.clientID = environment.clientID;
  }

  ngOnInit() {
    this.route.queryParams // get querystring params
    .subscribe(params => {
      this.sessionID = params.session;
      this.booking = params.booking;
      
      var component = this;
      
      if (this.clientID == this.clients.AANZ) {
        var dtobj: any = {};
        dtobj.sessionID = component.sessionID;
        
        component.apiService.callAPI("rpcapi/HomeServices/GetWindcavePaymentURL", dtobj, async reply => {
          if (reply.status == true) {
            window.location.href = reply.windcavePaymentURL;
          } else {
            component.toastr.error('Redirecting to payment failed, please try again.');
            console.log('ERROR: ' + reply.windcavePaymentURL);
          }
        },
        async error => {
          // handle error
          component.toastr.error('Redirecting to payment failed, please try again.');
          console.log('ERROR: ' + error);
        });
      } else {
        var stripe: any = (<any>window).Stripe(environment.stripeAPIKey);

        stripe.redirectToCheckout({
          sessionId: component.sessionID,
          //successUrl: environment.localURL + '/cardpaymentcomplete?booking=' + component.booking.toString() + '&success=true',
          //cancelUrl: environment.localURL + '/cardpaymentcomplete?booking=' + component.booking.toString() + '&success=false',
        }).then(function (result) {
          component.toastr.error('Booking error: ' + result.error.message);
        });
      }
    });
  }

}
