import { Injectable } from '@angular/core';
import { TradeType, JobType } from './meta-data.service';
import { environment } from '../../environments/environment';
import { JobChannelType } from './globals.service';

@Injectable()
export class JobDataService {

  public jobData: JobData = new JobData();
  public addedJobRef: number = 0;

  public trade: TradeType = null;
  //public jobType: JobType = null;
  public jobTypes: Array<JobType> = [];
  public postcode: string = '';

  public offerCode: string = '';
  public brokerCode: string = '';

  public memberQuestionAnswered: boolean = false;
  public capability: any = environment.capability;

  // ** HERE **
  public jobChannel: JobChannelType = JobChannelType.ONLINE;

  constructor() { }

  public setJobData(_jobData: JobData) {
    this.jobData = _jobData;
  }

  public getJobData() {
    return this.jobData;
  }

}

export class JobData {

  public bookingWindows: Array<JobBookingWindow> = [];
  public customerContact: JobContact = new JobContact();
  public customerReference: string = '';
  public description: string = '';
  public externalJobID: string = '';
  public jobReference: string = '';
  public postcode: string = '';
  public services: Array<HomeServiceItem> = [];
  public siteContact: JobContact = new JobContact();
  public streetAddress: string = '';
  public streetUnit: string = '';
  public suburbName: string = '';
  public estimatedPrice: number = 0;
  public estimatedHours: number = 0;

  constructor() { }

}

export class JobBookingWindow {
  public date: Date = null;
  public time: JobTimeWindow = null;
}

export class JobTimeWindow {
  public from: string = '';
  public to: string = '';
}

export class JobContact {

  public contactEmail: string = '';
  public contactMobile: string = '';
  public contactName: string = '';
  public contactPhone: string = '';

  constructor() {

  }

}

export class HomeServiceItem {

  public externalServiceID: string = '';
  public priceMarkup: number = 0;
  public discount: number = 0;
  public estimatedPrice: number = 0;
  public estimatedDuration: number = 0;
  public triageResponses: Array<TriageResponseItem> = [];

  constructor() {

  }

}

export class TriageResponseItem {

  public questionTypeID: number = 0;
  public triageAnswer: string = '';
  public triageFullAnswer: string = '';
  public triageQuestion: string = '';
  public showOnSOW: boolean = false;
  public inclusionData: InclusionData = null;

  constructor(_typeID: number, _answer: string, _question: string, _fullAnswer: string, _showOnSOW: boolean = false) {
    this.questionTypeID = _typeID;
    this.triageAnswer = _answer;
    this.triageQuestion = _question;
    this.triageFullAnswer = _fullAnswer;
    this.showOnSOW = _showOnSOW;
  }

}

export class InclusionData {
  public header: string = '';
  public sections: Array<InclusionSection> = [];
}

export class InclusionSection {
  public question: string = '';
  public answer: string = '';
}

export class StripeLineItem {
  constructor(
    public Name: string,
    public Description: string,
    public Amount: number,
    public Currency: string = 'aud',
    public Quantity: number = 1
  ) {
    // initialise all through constructor
  }
}